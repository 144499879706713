import React, { useState, useEffect, useRef, createRef } from 'react';

import AnimatedPage from '../components/AnimatedPage';
import RegisterSection from '../components/landingPage/homePage/RegisterSection';

export const COMPONENT_REF = ['banner-title', 'life-cycle-title', 'clinet-review-title', 'how-its-work-title', 'work-block-scaling', 'key-features', 'video-section-title', "register-form-title", "not-sure", 'pricing-title', 'pricing-section']


const Register = () => {

    const refs = useRef(COMPONENT_REF.map(() => createRef()));
    const [isAnimationVisible, setIsAnimationVisible] = useState(false);

    useEffect(() => {
        const observers = refs.current.map((ref, index) =>
            new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        setIsAnimationVisible(prev => ({ ...prev, [index]: true }));
                    }
                },
                { threshold: 0.5 }
            )
        );

        refs.current.forEach((ref, index) => {
            if (ref.current) {
                observers[index].observe(ref.current);
            }
        });

        return () => {
            refs.current.forEach((ref, index) => {
                if (ref.current) {
                    observers[index].unobserve(ref.current);
                }
            });
        };
    }, []);

    return (
        <AnimatedPage>
            <RegisterSection registerationType='User' refs={refs} isAnimationVisible={isAnimationVisible} registerPage={true} />
        </AnimatedPage>
    );
};

export default Register;