import AnimatedPage from "../components/AnimatedPage";
import HeadingSubheadingContent from "../components/HeadingSubheadingContent";
import OfferBanner from "../components/OfferBanner";

export default function Policies() {
	return (
		<>
			<OfferBanner>
				<span className="c1 cp px0-5rem" data-scroll-to="cookiePolicy">Cookie Policy</span>
				<span className="c1 cp px0-5rem" data-scroll-to="dmcaPolicy">DMCA Policy</span>
				<span className="c1 cp px0-5rem" data-scroll-to="privacyPolicy">Privacy Policy</span>
				<span className="c1 cp px0-5rem" data-scroll-to="dataPrivacy/ethicsPolicy">Data Privacy & Ethics Policy</span>
				<span className="c1 cp px0-5rem" data-scroll-to="aiUsePolicy">AI Use Policy</span>
			</OfferBanner>
			<div className="container mxa py5rem dg gg1rem lh3">
				<HeadingSubheadingContent id="forCompanies" wrapperClass="tac mb2rem" heading="Policies" />

				<div className="br5px bg5 w50 shadow p2rem table-of-content">
					<h3 className="mb1rem">List of Policies:</h3>
					<ul className="list-table">
						<li className="df jcsb"><span className="c4 cp px0-5rem" data-scroll-to="cookiePolicy">Cookie Policy </span><i> Page 1</i></li>
						<li className="df jcsb"><span className="c4 cp px0-5rem" data-scroll-to="dmcaPolicy">DMCA Policy</span><i> Page 2</i></li>
						<li className="df jcsb"><span className="c4 cp px0-5rem" data-scroll-to="privacyPolicy">Privacy Policy</span><i> Page 3</i></li>
						<li className="df jcsb"><span className="c4 cp px0-5rem" data-scroll-to="dataPrivacy/ethicsPolicy">Data Privacy & Ethics Policy</span><i></i> Page 4</li>
						<li className="df jcsb"><span className="c4 cp px0-5rem" data-scroll-to="aiUsePolicy">AI Use Policy</span><i></i> Page 5</li>
					</ul>
				</div>
				<div className="pr bg1 p2rem shadow my2rem policy-page-data">
					<HeadingSubheadingContent id="cookiePolicy" wrapperClass="tac" subheading="Cookie Policy" />
					<p>Effective: June 30th 2022</p>
					<p>Use of cookies and similar technologies</p>
					<p>Diligentsia uses cookies and similar technologies, such as pixels, to provide, improve, protect, and promote our services. For the purposes of this policy we will refer to all of these technologies as “cookies.” This page includes information on the what, who, and how of these technologies in connection with the Diligentsia website and services.</p>
					<p>What are cookies?</p>
					<p>A cookie is small data file sent to your browser when you visit a website. A pixel tag ("pixel") is a small piece of code belonging to a third-party that can be embedded on websites and in emails. We use both our own cookies as well as third-party cookies. See the “How does Diligentsia use cookies” section below for further information on third-party cookies we use.</p>
					<p>We also use local storage, including HTML5 local storage and browser cache. Local storage allows us to store information locally on your browser or device to improve your experience, such as to remember your settings for whether you prefer to view your folders in a list or as thumbnails. </p>
					<p>How does Diligentsia use cookies?</p>
					<p>Diligentsia uses different categories of cookies to provide, protect, improve, and promote our website and services. Diligentsia only retains personal data for as long as is needed for the purposes for which it was collected. Information on these categories and their purposes are described below.</p>
					<p>Strictly necessary cookies</p>
					<p>These are cookies that are essential in order to operate the website and services.</p>
					<p>Category</p>
					<p>Purpose</p>
					<p>Data elements</p>
					<p>Expiration</p>
					<p>Third parties</p>
					<p>Strictly necessary</p>
					<p>Cookies that are used to operate the website and services, such as to log you in and keep your account secure.</p>
					<p>Categories of data collected for these purposes include authentication data, unique ID, browser information, and language settings.</p>
					<p>These cookies are set to expire after times ranging from 30 days to 4 years.</p>
					<p>Third party providers used include Google.</p>
					<p>Non-essential cookies</p>
					<p>These are all other cookies that are not considered strictly necessary but are used for a number of purposes when you interact with our website and services. These purposes include:</p>
					<p>Category</p>
					<p>Purpose</p>
					<p>Data elements</p>
					<p>Expiration</p>
					<p>Third parties</p>
					<p>Analytics</p>
					<p>Cookies that help us and our partners understand how our websites are engaged with, such as usage statistics, in order to improve and customize our services.</p>
					<p>Categories of data collected for these purposes include website usage data, number of visits, browser and/or device information, and unique ID.</p>
					<p>These cookies are set to expire after times ranging from session (~20 minutes) to 2 years.</p>
					<p>Third party providers used include Google, Salesforce, Coveo, and Tealium.</p>
					<p>General marketing and advertising</p>
					<p>Cookies that are used by Diligentsia and our partners to better understand the types of individuals who are interested in our products and services and how we can promote those services based on that information.</p>
					<p>Categories of data collected for these purposes include cross website tracking data, browser information, version data, and advertisement conversion data.</p>
					<p>These cookies are set to expire after times ranging from session (~20 minutes) to 2 years.</p>
					<p>Third party providers used include Adobe, Marketo, Yahoo, and Google.</p>
					<p>Performance and functionality</p>
					<p>Cookies which help us remember your preferences and settings in order to improve your experience when interacting with our sites and services.</p>
					<p>Categories of data collected for these purposes include website usage data, previous help center or chat history, email address, and unique ID.</p>
					<p>These cookies are set to expire after times ranging from session (~20 minutes) to 3 years.</p>
					<p>Third party providers used include SnapEngage.</p>
					<p>Social media advertising</p>
					<p>Cookies that are used by Diligentsia and our partners to understand the effectiveness of our advertisements on social media services. These cookies also help them understand your advertising preferences so they can more effectively show you our advertisements which are relevant to your interests.</p>
					<p>Categories of data collected for these purposes include social media account details, browser and/or device data, advertisement conversion data, website usage and/or activity data, and unique ID.</p>
					<p>These cookies are set to expire after times ranging from session (~20 minutes) to 2 years.</p>
					<p>Third party providers used include Facebook, Twitter, and LinkedIn.</p>
					<p>How can I control the data collected through cookies?</p>
					<p>Browser settings:</p>
					<p>You can set your browser to not accept cookies, but this may limit your ability to use our services. Due to a lack of an agreed upon standard for interpreting Do Not Track (DNT) signals, our systems currently don’t respond to DNT:1 signals from browsers visiting our websites.</p>
					<p>Cookie preferences:</p>
					<p>When you use Diligentsia’s services, you can select which cookies you consent for Diligentsia to use in by clicking Cookies & CCPA Preferences in the footer of this page under Support.</p>
					<p>Self-Regulatory:</p>
					<p>Diligentsia adheres to the Digital Advertising Alliance’s Self-Regulatory Principles for Online Behavioral Advertising ("Principles"). You can opt out of collection of information by third-parties that adhere to the Principles, and that we use to help us promote our services, by visiting www.aboutads.info/choices.</p>
					<p>Diligentsia also adheres to the European Interactive Digital Advertising Alliance’s self-regulatory program for data-driven advertising. You can opt out of collection of information used for online behavior advertising by third-parties that adhere to the program by visiting http://www.youronlinechoices.eu/.</p>
					<p>For more information on how to control and access your personal data, please see our Privacy Policy. If you’d like to reach out to Diligentsia regarding the use of data collected from cookies, contact our Data Protection Officer at privacy@Diligentsia.co.uk .</p>
					<p className="c4 df jcc mt1rem">Page 1</p>
				</div>

				<div className="pr bg1 p2rem shadow my2rem policy-page-data">
					<HeadingSubheadingContent id="dmcaPolicy" wrapperClass="tac" subheading="DMCA Policy" />
					<p>Effective: June 30th 2022</p>
					<p>Diligentsia Limited (“Diligentsia”) respects the intellectual property rights of others and expects its users to do the same. In accordance with the Digital Millennium Copyright Act of 1998, the text of which may be found on the U.S. Copyright Office website at https://www.copyright.gov/legislation/dmca.pdf, Diligentsia will respond expeditiously to claims of copyright infringement committed using the Diligentsia platform and/or the Diligentsia website (the “Site”) if such claims are reported to Diligentsia’s Designated Copyright Agent identified in the sample notice below.</p>
					<p>If you are a copyright owner, authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements taking place on or through the Site by completing the following DMCA Notice of Alleged Infringement and delivering it to Diligentsia’s designated Data Privacy Officer at privacy@diligentsia.co.uk . Upon receipt of Notice as described below, Diligentsia will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Site.</p>
					<p>DMCA Notice of Alleged Infringement (“Notice”):</p>
					<ol>
						<li>Identify the copyrighted work that you claim has been infringed, or - if multiple copyrighted works are covered by this Notice - you may provide a representative list of the copyrighted works that you claim have been infringed.</li>
						<li>Identify the material or link you claim is infringing (or the subject of infringing activity) and to which access is to be disabled, including at a minimum, if applicable, the URL of the link shown on the Site or the exact location where such material may be found.</li>
						<li>Provide your company affiliation (if applicable), mailing address, telephone number, and, if available, email address.</li>
						<li>Include both of the following statements in the body of the Notice:</li>
						<ol>
							<li>“I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorised by the copyright owner, its agent or the law (e.g. as a fair use)”.</li>
							<li>“I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of, the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed.”</li>
						</ol>
						<li>Provide your full legal name and your electronic or physical signature.</li>
					</ol>
					<p>Deliver this Notice, with all items completed, to Diligentsia’s designated Data Privacy Officer at privacy@diligentsia.co.uk :</p>
					<p>Data Privacy Officer</p>
					<p>Diligentsia Limited</p>
					<p>The Old Rectory Nock Verges, Stoney Stanton,</p>
					<p>Leicestershire, LE9 4LR, UK</p>
					<p className="c4 df jcc mt1rem">Page 2</p>
				</div>

				<div className="pr bg1 p2rem shadow my2rem policy-page-data">
					<HeadingSubheadingContent id="privacyPolicy" wrapperClass="tac" subheading="Privacy Policy" />
					<p>Effective: 30th June, 2022</p>
					<p>Thanks for using Diligentsia! Here we describe how we collect, use, and handle your personal data when you use our websites, software, and services (“Services”). </p>
					<p>What & Why</p>
					<p>We collect and use the following information to provide, improve, protect, and promote our Services.</p>
					<p>Account information. We collect, and associate with your organisation’s account, the information you and your organisation provide to us when you do things such as sign up for your account, upgrade to a paid plan, and set up two-factor authentication (like your name, email address, phone number, payment info, and physical address).</p>
					<p>Your Stuff. Our Services are designed as a simple and personalized way for you to store your files, documents, photos, comments, messages, and so on (“Your Stuff”), collaborate with others, and work across multiple devices and services. To make that possible, we store, process, and transmit Your Stuff as well as information related to it. This related information includes your organisation’s account  information that makes it easier to collaborate and share Your Stuff with others, as well as things like the size of the file, the time it was uploaded, collaborators, and usage activity.</p>
					<p>Contacts. You may choose to give us access to your contacts to make it easy for you, and your organisation and its employees and contractors to do things like share and collaborate on Your Stuff, send messages, and invite others to use the Services. If you do, we’ll store those contacts on our servers.</p>
					<p>Usage information. We collect information related to how you use the Services, including actions you take in your account (like sharing, editing, viewing, creating and moving files or folders). We use this information to provide, improve, and promote our Services, and protect Diligentsia users. Please refer to our Diligentsia Terms & Conditions for more information about how we use this usage information.</p>
					<p>Device information. We also collect information from and about the devices you use to access the Services. This includes things like IP addresses, the type of browser and device you use, the web page you visited before coming to our sites, and identifiers associated with your devices. Your devices (depending on their settings) may also transmit location information to the Services. For example, we use device information to detect abuse and identify and troubleshoot bugs.</p>
					<p>Cookies and other technologies. We use technologies like cookies and pixel tags to provide, improve, protect, and promote our Services. For example, cookies help us with things like remembering your username for your next visit, understanding how you are interacting with our Services, and improving them based on that information. You can set your browser to not accept cookies, but this may limit your ability to use the Services. If our systems receive a DNT:1 signal from your browser, we’ll respond to that signal. We may also use third-party service providers that set cookies and similar technologies to promote Diligentsia services. You can learn more about how cookies and similar technologies work, as well as how to opt out of the use of them for advertising purposes by contacting customersupport@diligentsia.co.uk.</p>
					<p>Marketing. We give users the option to use some of our Services free of charge. These free Services are made possible by the fact that some users upgrade to one of our paid Services. If you register for our Services, we will, from time to time, send you information about upgrades when permissible. Users who receive these marketing materials can opt out at any time. If you don’t want to receive a particular type of marketing material from us, click the ‘unsubscribe’ link in the corresponding emails, or update your preferences in the Notifications section of your personal account.</p>
					<p>We sometimes contact people who don’t have a Diligentsia account. For recipients in the EU, we or a third party will obtain consent before reaching out. If you receive an email and no longer wish to be contacted by Diligentsia, you can unsubscribe and remove yourself from our contact list via the message itself.</p>
					<p>Bases for processing your data. We collect and use the personal data described above in order to provide you with the Services in a reliable and secure manner. We also collect and use personal data for our legitimate business needs. To the extent we process your personal data for other purposes, we ask for your consent in advance or require that our partners obtain such consent..</p>
					<p>With Whom</p>
					<p>We may share information or your data uploaded via the Diligentsia platform, or received by it, as discussed below, but we won’t sell it to advertisers or other third parties.</p>
					<p>Others working for and with Diligentsia. Diligentsia uses certain trusted third parties (for example, providers of customer support and IT services) for the business purposes of helping us provide, improve, protect, and promote our Services. These third parties will access your information to perform tasks on our behalf, and we’ll remain responsible for their handling of your information per our instructions.  </p>
					<p>We need your permission to do things like hosting Your Stuff, backing it up and sharing it when you ask us to. It includes our setting up a Diligentsia corporate folder and sub-folders within one or more of your organisation’s third party cloud repositories, such as Sharepoint, Google Drive or Diligentsia.  You authorise us to upload Our Services to our Diligentsia databases and platform more generally.  We may also provide you with features like commenting, sharing, searching, image thumbnails, document previews, optical character recognition (OCR), easy sorting and organisation and personalisation to help reduce busywork. To provide these and other features, Diligentsia accesses, uploads, stores and scans Your Stuff within its own databases. You give us permission to do those things, and this permission extends to our affiliates and trusted third parties we work with.  In particular, unless you opt out, we are free to share your information with your accountants, lawyers, investors where you have been introduced by one or more of those organisations as their client or portfolio company.  Where you enter additional information directly into the Diligentsia folder within your cloud storage, you authorise us to synch that folder with its corresponding copy held within the Diligentsia databases in your organisation’s name.</p>
					<p>You authorise us to undertake data analytics on the information your organisation uploads to, and synch’s with, the Diligentsia folder.  We may utilise that information on an anonymised basis both to improve our and to provide additional services to you, either directly or through trusted third parties we work with, provided that you will authorise those third parties.</p>
					<p>Other Diligentsia Companies. Diligentsia may in the future share infrastructure, systems, and technology with other Diligentsia Companies to provide, improve, protect, and promote Diligentsia Company Services. We process your information across the Diligentsia Companies for these purposes, as permitted by applicable law and in accordance with their terms and policies. </p>
					<p>Other users. Our Services are designed to help you collaborate with others. If you register your Diligentsia account with an email address on a domain owned by your employer or organization or collaborate with other Diligentsia users, we may suggest you or your team as a potential collaborator to other users or teams. For example, if you interact with a person at a company, and that person frequently works with one of their coworkers, we may suggest you as a potential collaborator for that coworker. Collaborators and potential collaborators may see some of your basic information, like your name, profile picture, device, email address, and usage information. This helps you sync up with teams you can join and helps other users share files and folders with you, and your professional advisors/investors if your organisation has given them permission.</p>
					<p>Certain features let you make additional information available to others.</p>
					<p>Other applications. You can choose to connect your Diligentsia account with third-party services––for example, via Diligentsia APIs. By doing so, you’re enabling Diligentsia and those third parties to exchange information about you and data in your account so that Diligentsia and those third parties can provide, improve, protect, and promote their services. Please remember that third parties’ use of your information will be governed by their own privacy policies and terms of service.</p>
					<p>Business Team Admins. If you are the employee of an organisation using the Diligentsia platform, your organisation’s administrator may have the ability to access and control your account. Please refer to your organization’s internal policies if you have questions about this. If you aren’t a Diligentsia user but interact with a Diligentsia customer account or its contents (for example, by joining a shared folder or accessing stuff shared by that user), members of that organization may be able to view information about you (such as you or your organisation’s name, email address, and profile picture) and your interaction with the Diligentsia user or the user’s organisation (such as your IP address). If you share Your Stuff with a Diligentsia user, the administrator of the team account may have the ability to access and edit what you share.</p>
					<p>Law & Order and the Public Interest. We may disclose your information to third parties if we determine that such disclosure is reasonably necessary to: (a) comply with any applicable law, regulation, legal process, or appropriate government request; (b) protect any person from death or serious bodily injury; (c) prevent fraud or abuse of Diligentsia or our users; (d) protect Diligentsia’s rights, property, safety, or interest; or (e) perform a task carried out in the public interest.</p>
					<p>Stewardship of your data is critical to us and a responsibility that we embrace. We believe that your data should receive the same legal protections regardless of whether it’s stored on our Services or on your organisation’s own servers. We’ll abide by the following Government Request Principles when receiving, scrutinizing, and responding to government requests (including national security requests) for your data:</p>
					<ol>
						<li>Be transparent</li>
						<li>Fight blanket requests</li>
						<li>Protect all users, and</li>
						<li>Provide trusted services.</li>
					</ol>
					<p>How</p>
					<p>Security. We are dedicated to keeping your information secure and testing for vulnerabilities. We continue to work on features to keep your information safe in addition to things like two-factor authentication.  </p>
					<p>User Controls. You can access, amend, download, and delete your organisation’s information by logging into your Diligentsia account and going to your account settings page. </p>
					<p>Retention. When you sign up for an account with us, we’ll retain information you store on our Services for as long as your account exists or as long as we need it to provide you the Services. If you delete your account, we’ll initiate deletion of this information after 30 days. But please note: (1) there might be some latency in deleting this information from our servers and back-up storage; and (2) we may retain this information if necessary to comply with our legal obligations, resolve disputes, or enforce our agreements.</p>
					<p>Where</p>
					<p>Around the world. To provide you with the Services, we may store, process, and transmit data in the United States and other locations around the world—including those outside your country. Data may also be stored locally on the devices you use to access the Services.</p>
					<p>Data Transfers. When transferring data from the European Union, the European Economic Area, the United Kingdom, and Switzerland, Diligentsia relies upon a variety of legal mechanisms, such as contracts with our customers and affiliates, Standard Contractual Clauses, and the European Commission's adequacy decisions about certain countries, as applicable.</p>
					<p>Your Control and Access of Your Data</p>
					<p>Your organisation has control over your personal data and how it’s collected, used, and shared. For example, you can:</p>
					<ol>
						<li>Delete Your Stuff in your Diligentsia account. </li>
						<li>Change or correct personal data. </li>
						<li>Access and take your data elsewhere</li>
						<li>Object to the processing of your personal data. </li>
					</ol>
					<p>If you would like to submit a data access request or object to the processing of your personal data, please email us at privacy@Diligentsia.co.uk  </p>
					<p>Diligentsia as controller or processor. Diligentsia Limited acts as your service provider. </p>
					<p>Changes</p>
					<p>If we’re involved in a reorganization, merger, acquisition, or sale of our assets, your data may be transferred as part of that deal. We’ll notify you (for example, via a message to the email address associated with your account) of any such deal and outline your choices in that event.</p>
					<p>We may revise this Privacy Policy from time to time and will post the most current version on our website. If a revision meaningfully reduces your rights, we will notify you.</p>
					<p>Contact</p>
					<p>Have questions or concerns about Diligentsia, our Services, and privacy? Contact our Data Protection Officer at privacy@Diligentsia.co.uk . If they can’t answer your question, you have the right to contact your local data protection supervisory authority.</p>
					<p className="c4 df jcc mt1rem">Page 3</p>
				</div>

				<div className="pr bg1 p2rem shadow my2rem policy-page-data">
					<HeadingSubheadingContent id="dataPrivacy/ethicsPolicy" wrapperClass="tac" subheading="Data Privacy & Ethics Policy" />
					<p>Effective: June 30th 2022</p>
					<p>Scope and Acceptance of Policy:</p>
					<p>This Data Privacy and Ethics Policy ('Policy') applies to the use of your data by Diligentsia Limited ('Company', 'we', 'us', 'our') in relation to the use of an artificial intelligence tool, Chat GPT, developed by OpenAI. Your acceptance of this Policy, along with our Terms and Conditions, is indicated by your continued use of our services and/or by expressly clicking to accept where that option is presented to you.</p>
					<p>Data Usage:</p>
					<p>We use your data for the sole purpose of enhancing the quality of reports we generate for you through the application of AI technology. Your data may be used to create comprehensive reports via Chat GPT, but will not be used for any other purpose unless we obtain your explicit permission.​</p>
					<p>Data Protection:</p>
					<p>Your data is important and private to you, and we respect that. We ensure that your data is not shared with third parties, except with Chat GPT as outlined in this policy. We do not disseminate or transfer ownership of your data. Any reports we generate using your data and AI are digitally watermarked to ensure their authenticity and traceability.</p>
					<p>Data Retention:</p>
					<p>We will not retain your data for longer than is necessary for our services or as required by law. The data you supply to us that is processed through Chat GPT is not stored or reused by the AI system.</p>
					<p>Non-disclosure of Files:</p>
					<p>The contents of any data files you provide to us will not be shared with AI systems. We will not disclose the contents of these files outside our organisation unless we have obtained your prior express permission.</p>
					<p>Your Rights:</p>
					<p>​You have the right to access, rectify, delete or restrict the use of your personal data. You can exercise these rights at any time by contacting us using the contact details provided in our Terms and Conditions.</p>
					<p>Changes to the Policy:</p>
					<p>We reserve the right to update this Policy at any time to reflect changes in our practices, service offerings, or the latest laws and regulations. Any changes will be posted on this page, and we recommend that you check this page regularly to stay informed.</p>
					<p>Contact Us:</p>
					<p>If you have any questions or require further information about our data handling practices, please don't hesitate to contact our Data Privacy Officer at dpo@diligentsia.co.uk. We're committed to working with you to obtain a fair resolution of any privacy concerns."</p>
					<p className="c4 df jcc mt1rem">Page 4</p>
				</div>

				<div className="pr bg1 p2rem shadow my2rem policy-page-data">
					<HeadingSubheadingContent id="aiUsePolicy" wrapperClass="tac" subheading="AI Use Policy" />
					<p>Effective: 1st January 2024</p>
					<p>Introduction:</p>
					<p>At Diligentsia Limited ("we," "us," or "our"), we are committed to delivering high-quality services to our corporate customers, mainly SMEs, their professional advisors, and investors ("Customers"). We utilize generative AI technologies, including those from OpenAI, Google Bard, Microsoft Bing, and others, to assist in the creation of bespoke reports and deliver valuable insights to our Customers. We take your privacy and data security seriously, and this AI Use Policy outlines our responsible approach to using AI technologies.</p>
					<p>Data Usage and Protection:</p>
					<p>includes information provided by our Customers and may include text, documents, and other relevant data.
						Data Security: We employ robust security measures to protect your data from unauthorized access, disclosure, or alteration. We use encryption, access controls, and industry best practices to safeguard your information.
						Data Usage: We use the data provided by our Customers exclusively for the purpose of generating bespoke reports and delivering our services. We do not sell, market, or otherwise use your data for any purpose other than fulfilling our obligations to you.
						Anonymization and Aggregation: We may aggregate and anonymize data to improve our AI models, conduct research, and enhance our services. This aggregated and anonymized data cannot be traced back to individual Customers.</p>
					<p>Responsible AI Usage:</p>
					<p>AI Algorithms: We use AI algorithms to assist in the generation of reports, ensuring accuracy and efficiency. These algorithms are designed to provide valuable insights while maintaining a responsible and ethical approach.
						Transparency: We strive to be transparent about our AI usage. If you have any questions about how AI is used in our services, please feel free to contact us.</p>
					<p>Customer Rights:</p>
					<p>Access and Control: Customers have the right to access, correct, or delete their data. If you have concerns or requests regarding your data, please contact us, and we will promptly address them.
						Opt-Out: If you no longer wish to use our services, you have the right to opt-out and have your data removed from our systems.</p>
					<p>Compliance with Applicable Laws:</p>
					<p>We are committed to complying with all applicable data protection and privacy laws, including but not limited to the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).</p>
					<p>Contact Us:</p>
					<p className="df aic">If you have any questions, concerns, or requests related to our AI Use Policy or the use of AI in our services, please contact us at: <a className="db p0-5rem td250 c4-hover" href="mailto: support@diligentsia.co.uk">support@diligentsia.co.uk</a>.</p>
					<p>Changes to the AI Use Policy:</p>
					<p>We may update this AI Use Policy from time to time to reflect changes in our business practices or legal requirements. The most current version will be posted on our website, and we will notify Customers of any significant changes.</p>
					<p>By using our services, you agree to this AI Use Policy and our Terms and Conditions.</p>
					<p>Effective Date:</p>
					<p>This AI Use Policy is effective as of 1st January 2024.</p>
					<p className="c4 df jcc mt1rem">Page 5</p>
				</div>
			</div>
		</>
	);
}
