import { Link } from "react-router-dom";
import * as ROUTES from "../constants/routes";

export default function Footer() {
	return (
		<footer className="w100 zi-1 pf b0 l0 bg5">
			<div className="container py2rem py5rem-md dg gtcr2-md gtcr4a-xl footar-saf">
				<div className="footer-column p0-5rem gcs1">
					<Link to={ROUTES.HOME}>
						<img className="mb2rem h3rem" src={require(`../../images/logo.svg`)} alt="TODO" />
					</Link>
					<p className="mb1rem">Diligentsia Limited, The Old Rectory, Nock Verges, Stoney Stanton, Leicestershire, LE9 4LR UK</p>
					<p className="mb2rem">Diligentsia Limited is registered in England & Wales, Company Number 14256673, VAT number: GB 426 4891 73</p>
					<img className="bg1 shadow p0-5rem w2rem h2rem" src={require(`../../images/icons/facebook-f.svg`)} />
					<img className="bg1 mx2rem shadow p0-5rem w2rem h2rem" src={require(`../../images/icons/twitter.svg`)} />
					<img className="bg1 shadow p0-5rem w2rem h2rem" src={require(`../../images/icons/instagram.svg`)} />
				</div>
				<div className="footer-column p0-5rem">
					<h2 className="p0-5rem">Quick Links</h2>
					<Link className="db p0-5rem td250 c4-hover" to={ROUTES.ABOUT}>
						About Us
					</Link>
					<Link className="db p0-5rem td250 c4-hover" to={ROUTES.PRICING_PLAN}>
						Pricing Plans
					</Link>
					<a className="db p0-5rem td250 c4-hover" href="mailto: support@diligentsia.co.uk">
						Contact Us
					</a>
					<Link className="db p0-5rem td250 c4-hover" to={ROUTES.TERMS}>
						Terms &amp; Conditions
					</Link>
					<Link className="db p0-5rem td250 c4-hover" to={ROUTES.POLICIES}>
						Policies
					</Link>
				</div>
				<div className="footer-column p0-5rem">
					<h2 className="p0-5rem">For Companies</h2>
					<Link className="db p0-5rem td250 c4-hover" to={ROUTES.INVESTMENT_READY}>
						Investment Ready
					</Link>
					<Link className="db p0-5rem td250 c4-hover" to={ROUTES.GROWTH_READY}>
						Growth Ready
					</Link>
					<Link className="db p0-5rem td250 c4-hover" to={ROUTES.EXIT_READY}>
						Exit Ready
					</Link>
				</div>
				<div className="footer-column p0-5rem">
					<h2 className="p0-5rem">Advisors &amp; Investors</h2>
					<Link className="db p0-5rem td250 c4-hover" to={ROUTES.ACCOUNTANTS}>
						Accountants
					</Link>
					<Link className="db p0-5rem td250 c4-hover" to={ROUTES.LAWYERS}>
						Lawyers
					</Link>
					<Link className="db p0-5rem td250 c4-hover" to={ROUTES.ANGEL_NETWORKS}>
						Angel Networks
					</Link>
					<Link className="db p0-5rem td250 c4-hover" to={ROUTES.ANGEL_NETWORKS}>
						Angels
					</Link>
					<Link className="db p0-5rem td250 c4-hover" to={ROUTES.VENTURE_CAPITAL}>
						Venture Capital Firms
					</Link>
					<Link className="db p0-5rem td250 c4-hover" to={ROUTES.PRIVATE_EQUITY}>
						Private Equity Firms
					</Link>
				</div>
			</div>
		</footer>
	);
}
