import Cropper from 'react-cropper';
import Modal from '@mui/material/Modal';
import Switch from '@mui/material/Switch';
import { useSelector, useDispatch } from "react-redux";
import React, { useRef, useState, useEffect, Fragment } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import 'cropperjs/dist/cropper.css';
import 'react-circular-progressbar/dist/styles.css';
import AnimatedPage from "../../../../components/AnimatedPage";
import { toggleModal } from "../../../../reducers/modalReducer";
import { getRequest, postRequest, showMessage } from '../../../../config';

const cropper = React.createRef(null);

export default function AddEditInvestor(props) {
    let dispatch = useDispatch();

    let modal = useSelector(state => state.modal);
    let user = useSelector(state => state.user);

    let ref1 = useRef(null);
    let ref2 = useRef(null);
    let ref3 = useRef(null);
    let ref4 = useRef(null);

    let [isDPP, setIsDPP] = useState(false);
    let [editInput, setEditInput] = useState('');
    let [uploadType, setUploadType] = useState('');
    let [currentImage, setCurrentImage] = useState('');
    let [isImageModal, setIsImageModal] = useState(false);
    let [selectedStages, setSelectedStages] = useState([]);
    let [selectedSectors, setSelectedSectors] = useState([]);
    let [isUKRegistered, setIsUKRegistered] = useState(false);
    let [investorFirm, setInvestorFirm] = useState({
        cName: '',
        logo: '',
        ukRegNo: '',
        cWebsite: '',
        cPhone: '',
        cAddress: '',
        contactEmail: '',
        description: '',
        investmentCriteria: '',
        subscription: 'Free',
        employees: {
            ukEmployees: '',
            globalEmployees: '',
        },
        preferredSectors: {
            sector1: 'Not Selected',
            sector2: 'Not Selected',
            sector3: 'Not Selected',
            sector4: 'Not Selected',
            sector5: 'Not Selected',
        },
        stageFocus: {
            stage1: 'Not Selected',
            stage2: 'Not Selected',
            stage3: 'Not Selected',
        },
        fundSize: 'Not known',
        sweetSpot: 'Not known',
        annualRevenues: 'Not known',
        annualProfits: 'Not known',
        investment: {
            noOfInvestments: '',
            lastInvestmentDate: '',
        },
        isValidated: false,
    });
    let [investorContact, setInvestorContact] = useState({
        firstName: '',
        lastName: '',
        profilePicture: '',
        officeLocation: '',
        job: '',
        phone: '',
        email: '',
        bio: '',
        isRegistered: false,
    });

    let { onBack, setInvestorCompleted, action, investorId } = props;

    let sectionTabs = [
        { name: 'Investor Details', ref: ref1 },
        { name: 'Investor Investment Criteria - Objective', ref: ref2 },
        { name: 'Investor Investment Criteria - Subjective', ref: ref3 },
        { name: 'Investor Lead Contact Details', ref: ref4 },
    ];

    let dropdownOptions = {
        empty: ['', 'not known', 'not selected', 'none selected', 'nil'],
        cStages: ["Pre-Revenue", "Post-Revenue Early Stage", "Mature"],
        fundSizes: ['<£5m', '£5m-£100m', '£100m-£500m', '£500m-£1bn', '>£1bn', 'Not known'],
        sweetSpots: ['<£100,000', '£100,001-£1,000,000', '£1,000,001-£5,000,000', '£5,000,001-£20,000,000', '£20,000,001-£100,000,000', '>£100,000,001', 'Not known'],
        revenues: ['Nil', '<£100,000', '£100,000-£500,000', '£500,001-£1m', '>£1m ARR', '£1m-£5m', '£5m-£10m', '£10m-£20m', '£20m-£100m', '£100m+', 'Not known'],
        profits: ['Nil', '<£100,000', '£100,000-£500,000', '£500,001-£1m', '>£1m ARR', '£1m-£5m', '£5m-£10m', '£10m-£20m', '£20m-£100m', '£100m+', 'Not known'],
        stages: ['Angel', 'Pre-Seed', 'Seed', 'Venture Capital - Series A', 'Venture Capital - Series B+', 'Private Equity', 'Accelerator', 'Angel Group', 'Convertible Note', 'Corporate Centure Capital', 'Co-working Space', 'Crowdfunding', 'Debt', 'Early Stage Venture', 'Entrepreneurship Program', 'Family Investment Office', 'Fund of Funds', 'Government Office', 'Grant', 'Hedge Fund', 'Incubator', 'Initial Coin Offering', 'Investment Bank', 'IPO', 'Late Stage Venture', 'Non-Equity Assistance', 'Pension Funds', 'Pre-IPO', 'Post-IPO', 'Secondary Market', 'Secondary Purchaser', 'Startup Competition', 'Syndicate', 'University Program', 'Venture Debt', 'Other', 'Not Selected'],
        sectors: ['General', 'Accounting', 'Advertising', 'Advice', 'Aerospace', 'Ag Tech', 'Analytics', 'Angel Investment', 'Apps', 'Artificial Intelligence', 'Asset Management', 'Banking', 'Big Data', 'Biotechnology', 'Blockchain', 'Business Development', 'Business Information Systems', 'CleanTech', 'Cloud Infrastructure', 'Commerce & Shopping', 'Commercial Real Estate', 'Community & Lifestyle', 'Consumer', 'Consumer Electronics', 'Consumer Goods', 'Consulting', 'Credit', 'Cryptocurrency', 'Customer Service', 'Cybersecurity', 'Data & Analytics', 'Data Management', 'Database', 'Delivery', 'Developer APIs', 'Developer Platform', 'Document Management', 'E-Commerce', 'Education', 'Energy', 'Enterprise Software', 'Environmental Engineering', 'Events', 'Female Focus', 'Finance', 'Financial Services', 'FinTech', 'Food & Beverage', 'Gaming', 'Genetics', 'Government', 'Gov Tech', 'GreenTech', 'Hardware', 'Healthcare', 'Higher Education', 'Impact Investing', 'Information Technology', 'Infrastructure', 'Intellectual Property', 'Internet', 'Internet Services', 'Insurance', 'Insurtech', 'Incubators', 'Law Enforcement', 'Legal', 'Legal Tech', 'Lending & Investments', 'Life Science', 'Machine Learning', 'Management Consulting', 'Manufacturing', 'Marketplace', 'Media & Entertainment', 'Medical', 'Medical Device', 'Mobile', 'Mobile Apps', 'Oil & Gas', 'Payments', 'Privacy & Security', 'Productivity Tools', 'Professional Services', 'Prop Tech', 'Real Estate', 'Retail', 'Risk Management', 'SaaS', 'Sales & Marketing', 'Science & Engineering', 'Smart Cities', 'Software', 'Stock Exchanges', 'Sustainability', 'Tax Consulting', 'Transportation', 'Venture Capital', 'Video', 'Wealth Management', 'Web3', 'Web Development', 'Web Hosting', 'Wellness', 'Wholesale', 'Other', 'Not Selected'],
        investmentNo: ['<10', '10-20', '20-100', '>100', 'Not known'],
        lastInvestmentDates: ['Within 6 months', 'Within 12 months', 'More than 12 months', 'Not known'],
    };

    useEffect(() => {
        if (investorId && investorId !== '') getInvestorFirm(investorId);
    }, []);

    useEffect(() => {
        if (investorFirm) {
            if (investorFirm['contact']) setInvestorContact(investorFirm['contact']);
            if (investorFirm['ukRegNo'] && investorFirm['ukRegNo'] !== '') setIsUKRegistered(true);
            if (investorFirm['subscription'] && investorFirm['subscription'] === 'Pro') setIsDPP(true);

            let tempSectors = [];
            if (investorFirm['preferredSectors'] && !dropdownOptions['empty'].includes(investorFirm['preferredSectors']['sector1']?.toLowerCase()) && !tempSectors.includes(investorFirm['preferredSectors']['sector1'])) tempSectors.push(investorFirm['preferredSectors']['sector1']);
            if (investorFirm['preferredSectors'] && !dropdownOptions['empty'].includes(investorFirm['preferredSectors']['sector2']?.toLowerCase()) && !tempSectors.includes(investorFirm['preferredSectors']['sector2'])) tempSectors.push(investorFirm['preferredSectors']['sector2']);
            if (investorFirm['preferredSectors'] && !dropdownOptions['empty'].includes(investorFirm['preferredSectors']['sector3']?.toLowerCase()) && !tempSectors.includes(investorFirm['preferredSectors']['sector3'])) tempSectors.push(investorFirm['preferredSectors']['sector3']);
            if (investorFirm['preferredSectors'] && !dropdownOptions['empty'].includes(investorFirm['preferredSectors']['sector4']?.toLowerCase()) && !tempSectors.includes(investorFirm['preferredSectors']['sector4'])) tempSectors.push(investorFirm['preferredSectors']['sector4']);
            if (investorFirm['preferredSectors'] && !dropdownOptions['empty'].includes(investorFirm['preferredSectors']['sector5']?.toLowerCase()) && !tempSectors.includes(investorFirm['preferredSectors']['sector5'])) tempSectors.push(investorFirm['preferredSectors']['sector5']);
            setSelectedSectors(tempSectors);

            let tempStages = [];
            if (investorFirm['stageFocus'] && !dropdownOptions['empty'].includes(investorFirm['stageFocus']['stage1']?.toLowerCase()) && !tempStages.includes(investorFirm['stageFocus']['stage1'])) tempStages.push(investorFirm['stageFocus']['stage1']);
            if (investorFirm['stageFocus'] && !dropdownOptions['empty'].includes(investorFirm['stageFocus']['stage2']?.toLowerCase()) && !tempStages.includes(investorFirm['stageFocus']['stage2'])) tempStages.push(investorFirm['stageFocus']['stage2']);
            if (investorFirm['stageFocus'] && !dropdownOptions['empty'].includes(investorFirm['stageFocus']['stage3']?.toLowerCase()) && !tempStages.includes(investorFirm['stageFocus']['stage3'])) tempStages.push(investorFirm['stageFocus']['stage3']);
            setSelectedStages(tempStages);
        }
    }, [investorFirm]);

    useEffect(() => {
        if (!isUKRegistered) setInvestorFirm({ ...investorFirm, ukRegNo: '' });
    }, [isUKRegistered]);

    useEffect(() => {
        if (isDPP) setInvestorFirm({ ...investorFirm, subscription: 'Pro' });
        else setInvestorFirm({ ...investorFirm, subscription: 'Free' });
    }, [isDPP]);

    const scrollToContent = (ref) => {
        ref.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    const getInvestorFirm = async (investorId) => {
        let response = await getRequest(`/company/getInvestorFirm/${investorId}`);
        if (response['code'] == 200) setInvestorFirm(response['body']);
    };

    const uploadImage = async () => {
        let attachment = [];
        if (cropper.current && currentImage) {
            let imageToUpload = cropper?.current?.getCroppedCanvas()?.toDataURL()
            let res = await fetch(imageToUpload);
            let blob = await res.blob();
            const coverFile = new File([blob], "profile image", { type: "image/png" });
            attachment.push(coverFile);
            setCurrentImage(imageToUpload);

            const response = await postRequest(`/company/uploadFile`, { attachment }, true);

            if (response || response['code'] == 200) {
                if (uploadType === 'logo') setInvestorFirm({ ...investorFirm, logo: response?.['body']?.['report']?.['location'] });
                else if (uploadType === 'contactImage') setInvestorContact({ ...investorContact, profilePicture: response?.['body']?.['report']?.['location'], profile: response?.['body']?.['report']?.['location'] });

                setIsImageModal(false);
            }

            showMessage(response.message);
        }
    };

    const handleImage = async (e) => {
        let image = e.target.files[0]
        if (image && image.size > (5 * 1024 * 1024)) return showMessage("Photo Size must be less than 5MB");
        image = await URL.createObjectURL(image);

        setCurrentImage(image);
    };

    const handleInvestorChange = (event) => {
        const { name, value } = event['target'];

        if (name === 'ukEmployees' || name === 'globalEmployees') setInvestorFirm({ ...investorFirm, employees: { ...investorFirm['employees'], [name]: value } });
        else if (name === 'isValidated') setInvestorFirm({ ...investorFirm, [name]: !investorFirm[name] });
        else if (name === 'sector') {
            if (value.length <= 5) {
                setSelectedSectors(value);
                setInvestorFirm({
                    ...investorFirm,
                    preferredSectors: {
                        ...investorFirm['preferredSectors'],
                        sector1: value[0] ? value[0] : 'Not Selected',
                        sector2: value[1] ? value[1] : 'Not Selected',
                        sector3: value[2] ? value[2] : 'Not Selected',
                        sector4: value[3] ? value[3] : 'Not Selected',
                        sector5: value[4] ? value[4] : 'Not Selected',
                    }
                });
            } else showMessage('Please select up to 5 sectors');
        }
        else if (name === 'stage') {
            if (value.length <= 3) {
                setSelectedStages(value);
                setInvestorFirm({
                    ...investorFirm,
                    stageFocus: {
                        ...investorFirm['stageFocus'],
                        stage1: value[0] ? value[0] : 'Not Selected',
                        stage2: value[1] ? value[1] : 'Not Selected',
                        stage3: value[2] ? value[2] : 'Not Selected',
                    }
                });
            } else showMessage('Please select up to 3 stages');
        }
        else if (name === 'noOfInvestments' || name === 'lastInvestmentDate') setInvestorFirm({ ...investorFirm, investment: { ...investorFirm['investment'], [name]: value } });
        else setInvestorFirm({ ...investorFirm, [name]: value });
    };

    const handleContactChange = (event) => {
        const { name, value } = event['target'];

        if (name === 'isRegistered') setInvestorContact({ ...investorContact, [name]: !investorContact[name] });
        else setInvestorContact({ ...investorContact, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let response = await postRequest(`/company/addEditDeleteInvestor`, { action, investorId, investorData: investorFirm, contactData: investorContact });
        if (response['code'] == 200) {
            showMessage(response['message']);
            setInvestorCompleted(true);
        }
    };

    const getOverviewContent = () => {
        return <div className="minh100vh bg1 shadow p2rem br5px mb3rem">
            <div className="mt0-5rem mr1rem dg gg1rem minh100vh-report">
                <div className="h100 df jcsb aic">
                    <span onClick={onBack} className="w200px shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic fs0-75rem mb1rem">
                        <svg className="pen f1 h1rem mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path className="fa-primary pen" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
                            <path className="fa-secondary pen" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l-32-32l32-32H416C433.7 224 447.1 238.3 447.1 256z" />
                        </svg>
                        Back to Menu
                    </span>
                    <div className="logo-report w60">
                        <img className="h2rem" src={require(`../../../../../images/Dg-logo.png`)} alt="TODO" />
                    </div>

                </div>
                <div className="report-start">
                    <h2 className="w200 tac c6 lh2 fs1-75rem mb2rem">{action === 'add' ? 'Add Investor' : 'Edit Investor'}</h2>
                </div>
            </div>

            <div className="btn-group fit-btn-group">
                {sectionTabs.map((tab, idx) => {
                    return <button id={`content-btn-${idx}`} value={tab['name']} onClick={() => scrollToContent(tab['ref'])} className={`df c6 jcc ttc td250 c3-hover px1rem py0-5rem cp new-report-btn reports-btn-area mb0-75rem`}>
                        <span className="pen usn ass mr0-5rem tac fs1rem">{tab['name']}</span>
                    </button>
                })}
            </div>
        </div>
    };

    //********** investor Details **********//
    const investorDetails = () => {
        return (
            <>
                <Fragment>
                    <div id='Investor Details' ref={ref1} className="sectionWrapper fs0-75rem bg1 shadow p2rem pb3rem br5px mb3rem investor-modal-admin">
                        <div className="container df fdc">
                            <h2 className="w200 tac c6 lh2 fs1-75rem mb2rem">Investor Details</h2>
                            {/* Investor Details */}
                            <form autoComplete='off' className='df fdc gg1rem investor-details-form'>
                                <div className='form-group df jsc'>
                                    <div className='img-upload-area pr'>
                                        <div className='img-box pr'>
                                            <img src={investorFirm?.['logo'] || require('../../../../../images/profile-photo.jpeg')} alt="Profile" className="profile-img w7rem h7rem advisor-profile" />
                                            <div className='input-file'>
                                                <span className='add-btn bg5' onClick={() => { setIsImageModal(true), setUploadType('logo'), setCurrentImage('') }}>
                                                    <svg viewBox="0 0 487 487" className="zi3">
                                                        <g>
                                                            <path d="M308.1,277.95c0,35.7-28.9,64.6-64.6,64.6s-64.6-28.9-64.6-64.6s28.9-64.6,64.6-64.6S308.1,242.25,308.1,277.95z
                                                        M440.3,116.05c25.8,0,46.7,20.9,46.7,46.7v122.4v103.8c0,27.5-22.3,49.8-49.8,49.8H49.8c-27.5,0-49.8-22.3-49.8-49.8v-103.9
                                                        v-122.3l0,0c0-25.8,20.9-46.7,46.7-46.7h93.4l4.4-18.6c6.7-28.8,32.4-49.2,62-49.2h74.1c29.6,0,55.3,20.4,62,49.2l4.3,18.6H440.3z
                                                        M97.4,183.45c0-12.9-10.5-23.4-23.4-23.4c-13,0-23.5,10.5-23.5,23.4s10.5,23.4,23.4,23.4C86.9,206.95,97.4,196.45,97.4,183.45z
                                                        M358.7,277.95c0-63.6-51.6-115.2-115.2-115.2s-115.2,51.6-115.2,115.2s51.6,115.2,115.2,115.2S358.7,341.55,358.7,277.95z"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                            </div>
                                            <lable className="ml2rem">Upload investor company logo here</lable>
                                        </div>
                                    </div>
                                </div>
                                <div className="pr">
                                    <input
                                        name="cName"
                                        type="text"
                                        placeholder="Investor Company Name"
                                        defaultValue={investorFirm?.['cName'] || ''}
                                        className="bg5 pl4rem shadow w100 br5px p1rem"
                                        onChange={(event) => handleInvestorChange(event)}
                                    />
                                    <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                        <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                    </svg>
                                </div>
                                <div className='df jcsb w100 mb1rem'>
                                    <div className="pr mr1rem w100">
                                        <p className="bg5 pl4rem shadow br5px p1rem" >UK Registered? (Companies House)</p>
                                        <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                            <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                        </svg>
                                        <div className='switch-box pa r0'>
                                            <Switch
                                                name='isUKRegistered'
                                                checked={isUKRegistered || (investorFirm?.['ukRegNo'] !== '')}
                                                className="success w100"
                                                onChange={() => setIsUKRegistered(!isUKRegistered)}
                                            />
                                        </div>
                                    </div>
                                    <div className="pr ml1rem w100">
                                        <input
                                            name="ukRegNo"
                                            type="text"
                                            disabled={!isUKRegistered}
                                            placeholder="If Yes - Companies House Registration Number"
                                            value={investorFirm?.['ukRegNo'] || ''}
                                            className="bg5 pl4rem shadow w100 br5px p1rem"
                                            onChange={(event) => handleInvestorChange(event)}
                                        />
                                        <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                            <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className='df jcsb w100 mb1rem'>
                                    <div className="pr mr1rem w100">
                                        <input
                                            name="cWebsite"
                                            type="text"
                                            placeholder="Website URL"
                                            value={investorFirm?.['cWebsite'] || ''}
                                            className="bg5 pl4rem shadow w100 br5px p1rem"
                                            onChange={(event) => handleInvestorChange(event)}
                                        />
                                        <svg viewBox="0 0 512 512" className="center-vertically f3 pa l0 ml1rem w2rem">
                                            <g><g><path d="M256,0C114.62,0,0,114.62,0,256s114.62,256,256,256s256-114.62,256-256S397.38,0,256,0z M172.211,41.609
                                                                c-24.934,27.119-44.68,66.125-56.755,111.992H49.749C75.179,102.741,118.869,62.524,172.211,41.609z M25.6,256
                                                                c0-26.999,5.077-52.727,13.662-76.8h70.494c-4.608,24.294-7.356,49.963-7.356,76.8s2.748,52.506,7.347,76.8H39.262
                                                                C30.677,308.727,25.6,283,25.6,256z M49.749,358.4h65.707c12.083,45.867,31.821,84.872,56.755,111.991
                                                                C118.869,449.476,75.179,409.259,49.749,358.4z M243.2,485.188c-43.81-8.252-81.877-58.24-101.359-126.788H243.2V485.188z
                                                                M243.2,332.8H135.74c-4.924-24.166-7.74-49.997-7.74-76.8s2.816-52.634,7.74-76.8H243.2V332.8z M243.2,153.6H141.841
                                                                C161.323,85.052,199.39,35.063,243.2,26.812V153.6z M462.251,153.6h-65.707c-12.083-45.867-31.821-84.873-56.755-111.992
                                                                C393.131,62.524,436.821,102.741,462.251,153.6z M268.8,26.812c43.81,8.252,81.877,58.24,101.359,126.788H268.8V26.812z
                                                                M268.8,179.2h107.46c4.924,24.166,7.74,49.997,7.74,76.8s-2.816,52.634-7.74,76.8H268.8V179.2z M268.8,485.188V358.4h101.359
                                                                C350.677,426.948,312.61,476.937,268.8,485.188z M339.789,470.391c24.934-27.127,44.672-66.125,56.755-111.991h65.707
                                                                C436.821,409.259,393.131,449.476,339.789,470.391z M402.244,332.8c4.608-24.294,7.356-49.963,7.356-76.8
                                                                s-2.748-52.506-7.347-76.8h70.494c8.576,24.073,13.653,49.801,13.653,76.8c0,27-5.077,52.727-13.662,76.8H402.244z"/></g></g>
                                        </svg>
                                    </div>
                                    <div className="pr ml1rem w100">
                                        <input
                                            name="cPhone"
                                            type="text"
                                            placeholder="Telephone Number"
                                            value={investorFirm?.['cPhone'] || ''}
                                            className="bg5 pl4rem shadow w100 br5px p1rem"
                                            onChange={(event) => handleInvestorChange(event)}
                                        />
                                        <svg className="center-vertically f3 pa l0 ml1rem w2rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                            <path className="fa-primary" d="M352 48V384H32V48C32 21.5 53.5 0 80 0h224C330.5 0 352 21.5 352 48z" />
                                            <path className="fa-secondary" d="M32 384v80C32 490.5 53.5 512 80 512h224c26.5 0 48-21.5 48-48V384H32zM240 447.1C240 456.8 232.8 464 224 464H159.1C151.2 464 144 456.8 144 448S151.2 432 160 432h64C232.8 432 240 439.2 240 447.1z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className='df jcsb w100 mb1rem'>
                                    <div className="pr mr1rem w100">
                                        <input
                                            name="cAddress"
                                            type="text"
                                            placeholder="Address"
                                            value={investorFirm?.['cAddress'] || ''}
                                            className="bg5 pl4rem shadow w100 br5px p1rem"
                                            onChange={(event) => handleInvestorChange(event)}
                                        />
                                        <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                            <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                        </svg>
                                    </div>
                                    <div className="pr ml1rem w100">
                                        <input
                                            name="contactEmail"
                                            type="email"
                                            placeholder="Company Email for Pitches etc e.g. info@..."
                                            value={investorFirm?.['contactEmail'] || ''}
                                            className="bg5 pl4rem shadow w100 br5px p1rem"
                                            onChange={(event) => handleInvestorChange(event)}
                                        />
                                        <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 512 512">
                                            <path d="M256.47 352h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V400a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V183.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52zM464 64H48a48 48 0 0 0-48 48v19a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4 23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131v-19a48 48 0 0 0-48-48z" className="fa-secondary"></path>
                                            <path d="M512 131v52.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V131a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.69 12.12 49.75 41.4 72.93 41.4h.94c23.18 0 56.24-29.28 72.93-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131z" className="fa-primary"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className='df jcsb w100 mb1rem'>
                                    <div className="pr mr1rem w100">
                                        <input
                                            name="ukEmployees"
                                            type="number"
                                            placeholder="Number of Staff in London"
                                            value={investorFirm?.['employees']?.['ukEmployees'] || ''}
                                            className="bg5 pl4rem shadow w100 br5px p1rem"
                                            onChange={(event) => handleInvestorChange(event)}
                                        />
                                        <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                            <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                        </svg>
                                    </div>
                                    <div className="pr ml1rem w100">
                                        <input
                                            name="globalEmployees"
                                            type="number"
                                            placeholder="Number of Staff Globally"
                                            value={investorFirm?.['employees']?.['globalEmployees'] || ''}
                                            className="bg5 pl4rem shadow w100 br5px p1rem"
                                            onChange={(event) => handleInvestorChange(event)}
                                        />
                                        <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                            <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className='df jcsb w100 mb1rem'>
                                    <div className="pr mr1rem w100">
                                        <label className='fs1rem' style={{ color: '#7a7a7a' }}>Funds Available For Investment</label>
                                        <select
                                            name="fundSize"
                                            placeholder="As required"
                                            value={investorFirm?.['fundSize'] || 'Not known'}
                                            className="bg5 shadow w100 br5px p1rem"
                                            onChange={(event) => handleInvestorChange(event)}
                                        >
                                            {dropdownOptions.fundSizes.map(fund => <option value={fund}>{fund}</option>)}
                                        </select>
                                    </div>
                                    <div className="pr ml1rem w100">
                                        <label className='fs1rem' style={{ color: '#7a7a7a' }}>Number Of Investments Made</label>
                                        <select
                                            name="noOfInvestments"
                                            placeholder="No of Investments"
                                            value={investorFirm?.['investment']?.['noOfInvestments'] || 'Not known'}
                                            className="bg5 shadow w100 br5px p1rem"
                                            onChange={(event) => handleInvestorChange(event)}
                                        >
                                            {dropdownOptions.investmentNo.map(no => <option value={no}>{no}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="pr">
                                    <div className="pr mr1rem w100">
                                        <label className='fs1rem' style={{ color: '#7a7a7a' }}>Date Of Last Investment</label>
                                        <select
                                            name="lastInvestmentDate"
                                            placeholder="Last Investment Date"
                                            value={investorFirm?.['investment']?.['lastInvestmentDate'] || 'Not known'}
                                            className="bg5 shadow w100 br5px p1rem"
                                            onChange={(event) => handleInvestorChange(event)}
                                        >
                                            {dropdownOptions.lastInvestmentDates.map(date => <option value={date}>{date}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="pr">
                                    <p className="bg5 pl4rem shadow br5px p1rem" >Existing Diligentsia Portfolio Pro subscriber?</p>
                                    <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                        <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                    </svg>
                                    <div className='switch-box pa r0'>
                                        <Switch
                                            name='isDPP'
                                            checked={isDPP || investorFirm?.['subscription'] === 'Pro'}
                                            className="success w100"
                                            onChange={() => setIsDPP(!isDPP)}
                                        />
                                    </div>
                                </div>
                                <div className="pr">
                                    <p className="bg5 pl4rem shadow br5px p1rem" >Approved By MB</p>
                                    <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                        <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                    </svg>
                                    <div className='switch-box pa r0'>
                                        <Switch
                                            name='isValidated'
                                            checked={(investorFirm?.['isValidated'])}
                                            className="success w100"
                                            onChange={(event) => handleInvestorChange(event)}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fragment>
            </>
        )
    };

    //********** Investor Investment Criteria - Objective **********//
    const investmentCriteriaObjective = () => {
        return (
            <>
                <Fragment>
                    <div id='Investor Investment Criteria - Objective' ref={ref2} className="sectionWrapper fs0-75rem bg1 shadow p2rem pb3rem br5px mb3rem investor-modal-admin">
                        <div className="container df fdc">
                            <h2 className="w200 tac c6 lh2 fs1-75rem mb2rem">Investor Investment Criteria - Objective</h2>
                            {/* Edit Investor Investment Objective */}
                            <form autoComplete='off' className='df fdc gg1rem investor-details-form'>
                                <div className='df jcsb w100 mb1rem'>
                                    <div className="pr w100">
                                        <label className='fs1rem' style={{ color: '#7a7a7a' }}>Preferred Sectors</label>
                                        <Select
                                            multiple
                                            className="w100 bg5 shadow w100 br5px multiselect-invest"
                                            placeholder="Sectors"
                                            name="sector"
                                            id="preferred-sectors"
                                            labelId="preferred-sectors-label"
                                            onChange={(event) => handleInvestorChange(event)}
                                            renderValue={(selected) => selected.join(', ')}
                                            value={selectedSectors || []}
                                        >
                                            {dropdownOptions.sectors.map(sector => (
                                                <MenuItem value={sector} className="multi-dropdown-list">
                                                    <Checkbox checked={selectedSectors.includes(sector)} />
                                                    <ListItemText primary={sector} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <p className='ml0-5rem mt0-3rem fs0-75rem tal df jcfs w100'>Note: You can select up to 5 sectors.</p>
                                    </div>
                                </div>
                                <div className='df jcsb w100 mb1rem'>
                                    <div className="pr w100">
                                        <label className='fs1rem' style={{ color: '#7a7a7a' }}>Preferred Investment Stage</label>
                                        <Select
                                            multiple
                                            className="w100 bg5 shadow w100 br5px multiselect-invest"
                                            placeholder="Stage"
                                            name="stage"
                                            id="stage-focus"
                                            labelId="stage-focus-label"
                                            onChange={(event) => handleInvestorChange(event)}
                                            renderValue={(selected) => selected.join(', ')}
                                            value={selectedStages || []}
                                        >
                                            {dropdownOptions.stages.map(stage => (
                                                <MenuItem value={stage} className="multi-dropdown-list">
                                                    <Checkbox checked={selectedStages.includes(stage)} />
                                                    <ListItemText primary={stage} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <p className='ml0-5rem mt0-3rem fs0-75rem tal df jcfs w100'>Note: You can select up to 3 stages.</p>
                                    </div>
                                </div>
                                <div className='df jcsb w100 mb1rem'>
                                    <div className="pr w100">
                                        <label className='fs1rem' style={{ color: '#7a7a7a' }}>Sweet Spot</label>
                                        <select
                                            name="sweetSpot"
                                            placeholder="Sweet Spot"
                                            value={investorFirm?.['sweetSpot'] || 'Not known'}
                                            className="bg5 shadow w100 br5px p1rem"
                                            onChange={(event) => handleInvestorChange(event)}
                                        >
                                            {dropdownOptions.sweetSpots.map(spot => <option value={spot}>{spot}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className='df jcsb w100 mb1rem'>
                                    <div className="pr mr1rem w100">
                                        <label className='fs1rem' style={{ color: '#7a7a7a' }}>Minimum Annual Profit Current Year</label>
                                        <select
                                            name="annualProfits"
                                            placeholder="Annual Profit"
                                            value={investorFirm?.['annualProfits'] || 'Not known'}
                                            className="bg5 shadow w100 br5px p1rem"
                                            onChange={(event) => handleInvestorChange(event)}
                                        >
                                            {dropdownOptions.profits.map(profit => <option value={profit}>{profit}</option>)}
                                        </select>
                                    </div>
                                    <div className="pr ml1rem w100">
                                        <label className='fs1rem' style={{ color: '#7a7a7a' }}>Minimum Annual Revenues Current Year</label>
                                        <select
                                            name="annualRevenues"
                                            placeholder="Annual Revenue"
                                            value={investorFirm?.['annualRevenues'] || 'Not known'}
                                            className="bg5 shadow w100 br5px p1rem"
                                            onChange={(event) => handleInvestorChange(event)}
                                        >
                                            {dropdownOptions.revenues.map(revenue => <option value={revenue}>{revenue}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="pr">
                                    <p className="bg5 pl4rem shadow br5px p1rem" >Approved By MB</p>
                                    <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                        <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                    </svg>
                                    <div className='switch-box pa r0'>
                                        <Switch
                                            name='isValidated'
                                            checked={(investorFirm?.['isValidated'])}
                                            className="success w100"
                                            onChange={(event) => handleInvestorChange(event)}
                                        />
                                    </div>
                                </div>
                                <div className='pr'>
                                    <p><b>Please note:</b> completion of ALL fields counts towards your Investor Completeness Score, which influences our algorithm which determines the priority in which Diligentsia qualified leads are allocated to our investor partners.</p>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fragment>
            </>
        )
    };

    //********** Investor Investment Criteria - Subjective **********//
    const investmentSubjective = () => {
        return (
            <>
                <Fragment>
                    <div id='Investor Investment Criteria - Subjective' ref={ref3} className="sectionWrapper fs0-75rem bg1 shadow p2rem pb3rem br5px mb3rem investor-modal-admin">
                        <div className="container df fdc">
                            <h2 className="w200 tac c6 lh2 fs1-75rem mb2rem">Investor Investment Criteria - Subjective</h2>
                            {/* Edit Investor Investment Subbjective */}
                            <div className='df fdc gg1rem investor-details-form'>
                                <div className="pr">
                                    <h2 className='fs1-25rem mb1rem'>Company Description</h2>
                                    <div className='bg1 shadow br5px p1rem pr'>
                                        {(action === 'add' || editInput === 'description')
                                            ? (<p className='df tac p0rem w100 background-des'>
                                                <textarea
                                                    name="description"
                                                    rows={10}
                                                    className="c2 w100"
                                                    placeholder="Enter Description"
                                                    defaultValue={investorFirm?.['description'] || ''}
                                                    onBlur={(event) => { handleInvestorChange(event), setEditInput(null) }}
                                                    onChange={(event) => handleInvestorChange(event)}
                                                />

                                            </p>
                                            ) : (<p>
                                                {investorFirm?.['description']}
                                                <span className='edit-btn'>
                                                    <button onClick={() => setEditInput('description')} className='edit-pencil'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
                                                            <path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417,.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
                                                        </svg>
                                                    </button>
                                                </span>
                                            </p>)}
                                        <span className="tooltip-area-company-settings pa cp zi2" data-tooltip={"These descriptions have been generated by our<br /> AI system and are used for “soft”<br /> fit analysis with our applicant company’s<br /> own description, and appears in the Diligentsia<br /> “Fit Report”, including “Fit Score”<br /> that is circulated to both parties.<br /><br />We generate this since we have found that some<br /> investors’ web sites are fairly imprecise as<br /> to what they are looking for.<br />  (Apologies if this is not you!)"}>
                                            <i className="icon" data-tooltip={"These descriptions have been generated by our<br /> AI system and are used for “soft”<br /> fit analysis with our applicant company’s<br /> own description, and appears in the Diligentsia<br /> “Fit Report”, including “Fit Score”<br /> that is circulated to both parties.<br /><br />We generate this since we have found that some<br /> investors’ web sites are fairly imprecise as<br /> to what they are looking for.<br />  (Apologies if this is not you!)"}>
                                                <img src={require(`../../../../../images/info-icon.svg`)} alt="" data-tooltip={"These descriptions have been generated by our<br /> AI system and are used for “soft”<br /> fit analysis with our applicant company’s<br /> own description, and appears in the Diligentsia<br /> “Fit Report”, including “Fit Score”<br /> that is circulated to both parties.<br /><br />We generate this since we have found that some<br /> investors’ web sites are fairly imprecise as<br /> to what they are looking for.<br />  (Apologies if this is not you!)"} />
                                            </i>
                                        </span>
                                    </div>
                                </div>
                                <div className="pr">
                                    <h2 className='fs1-25rem mb1rem'>Investment Criteria</h2>
                                    <div className='bg1 shadow br5px p1rem pr'>
                                        {(action === 'add' || editInput === 'investmentCriteria')
                                            ? (<p className='df tac p0rem w100 background-des'>
                                                <textarea
                                                    name="investmentCriteria"
                                                    rows={10}
                                                    className="c2 w100"
                                                    placeholder="Enter Investment Criteria"
                                                    defaultValue={investorFirm?.['investmentCriteria'] || ''}
                                                    onBlur={(event) => { handleInvestorChange(event), setEditInput(null) }}
                                                    onChange={(event) => handleInvestorChange(event)}
                                                />
                                            </p>
                                            ) : (<p>
                                                {investorFirm?.['investmentCriteria']}
                                                <span className='edit-btn'>
                                                    <button onClick={() => setEditInput('investmentCriteria')} className='edit-pencil'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
                                                            <path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417,.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
                                                        </svg>
                                                    </button>
                                                </span>
                                            </p>)}
                                    </div>
                                </div>
                                <div className="pr">
                                    <p className="bg5 pl4rem shadow br5px p1rem" >Approved By MB</p>
                                    <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                        <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                    </svg>
                                    <div className='switch-box pa r0'>
                                        <Switch
                                            name='isValidated'
                                            checked={(investorFirm?.['isValidated'])}
                                            className="success w100"
                                            onChange={(event) => handleInvestorChange(event)}
                                        />
                                    </div>
                                </div>
                                <div className='pr'>
                                    <p><b>Please note:</b> Completion of all fields counts towards your Investor Completeness Score, which influences our algorithm which determines the priority in which Diligentsia qualified leads are allocated to our investor partners.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            </>
        )
    };

    //********** Lead Contact Details **********//
    const leadContactDetails = () => {
        return (
            <>
                <Fragment>
                    <div id='Investor Lead Contact Details' ref={ref4} className="sectionWrapper fs0-75rem bg1 shadow p2rem pb3rem br5px mb3rem investor-modal-admin">
                        <div className="container df fdc">
                            <h2 className="w200 tac c6 lh2 fs1-75rem mb2rem">Investor Lead Contact Details</h2>
                            {/* Edit Investor Lead Contact Details */}
                            <form onSubmit={(event) => handleSubmit(event)} autoComplete='off' className='w100 df fdc gg1rem investor-details-form'>
                                <div className='form-group df jsc'>
                                    <div className='img-upload-area pr'>
                                        <div className='img-box pr'>
                                            <img src={investorContact?.['profilePicture'] || require('../../../../../images/profile-photo.jpeg')} alt="Profile" className="profile-img w7rem h7rem advisor-profile" />
                                            <div className='input-file'>
                                                <span className='add-btn bg5' onClick={() => { setIsImageModal(true), setUploadType('contactImage'), setCurrentImage('') }}>
                                                    <svg viewBox="0 0 487 487" className="zi3">
                                                        <g>
                                                            <path d="M308.1,277.95c0,35.7-28.9,64.6-64.6,64.6s-64.6-28.9-64.6-64.6s28.9-64.6,64.6-64.6S308.1,242.25,308.1,277.95z
                                                        M440.3,116.05c25.8,0,46.7,20.9,46.7,46.7v122.4v103.8c0,27.5-22.3,49.8-49.8,49.8H49.8c-27.5,0-49.8-22.3-49.8-49.8v-103.9
                                                        v-122.3l0,0c0-25.8,20.9-46.7,46.7-46.7h93.4l4.4-18.6c6.7-28.8,32.4-49.2,62-49.2h74.1c29.6,0,55.3,20.4,62,49.2l4.3,18.6H440.3z
                                                        M97.4,183.45c0-12.9-10.5-23.4-23.4-23.4c-13,0-23.5,10.5-23.5,23.4s10.5,23.4,23.4,23.4C86.9,206.95,97.4,196.45,97.4,183.45z
                                                        M358.7,277.95c0-63.6-51.6-115.2-115.2-115.2s-115.2,51.6-115.2,115.2s51.6,115.2,115.2,115.2S358.7,341.55,358.7,277.95z"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                            </div>
                                            <lable className="ml2rem">Upload investor contact image pic here</lable>
                                        </div>
                                    </div>
                                </div>
                                <p className='fs0-75rem'>Diligentsia prides itself on only forwarding applicant company leads to your firm which have received Diligentsia Gold certification and scored highly on our automated “Fit Test”.  We ask that in return for being provided good quality leads (for FREE!), that includes a Fit Report and a Diligence Report, that if you are not the correct person in your organisation to receive them, that you reallocate this contact to a suitable named alternative colleague.  Each investor receives a “Prompt Response” score.</p>

                                <div className='df jcsb w100 mb1rem'>
                                    <div className="pr mr1rem w100">
                                        <input
                                            name="firstName"
                                            type="text"
                                            placeholder="Contact First Name"
                                            value={investorContact?.['firstName'] || ''}
                                            className="bg5 pl4rem shadow w100 br5px p1rem"
                                            onChange={(event) => handleContactChange(event)}
                                        />
                                        <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                            <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                        </svg>
                                    </div>
                                    <div className="pr ml1rem w100">
                                        <input
                                            name="lastName"
                                            type="text"
                                            placeholder="Contact Last Name"
                                            value={investorContact?.['lastName'] || ''}
                                            className="bg5 pl4rem shadow w100 br5px p1rem"
                                            onChange={(event) => handleContactChange(event)}
                                        />
                                        <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                            <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                        </svg>
                                    </div>
                                </div>

                                <div className="pr">
                                    <p className="bg5 pl4rem shadow br5px p1rem" >Contact Has Registered Account On Diligentsia</p>
                                    <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                        <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                    </svg>
                                    <div className='switch-box pa r0'>
                                        <Switch
                                            name='isRegistered'
                                            checked={investorContact?.['isRegistered']}
                                            className="success w100"
                                            onChange={(event) => handleContactChange(event)}
                                        />
                                    </div>
                                </div>
                                <div className='df jcsb w100 mb1rem'>
                                    <div className="pr mr1rem w100">
                                        <input
                                            name="job"
                                            type="text"
                                            placeholder="Job Title"
                                            value={investorContact?.['job'] || ''}
                                            className="bg5 pl4rem shadow w100 br5px p1rem"
                                            onChange={(event) => handleContactChange(event)}
                                        />
                                        <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                            <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                        </svg>
                                    </div>
                                    <div className="pr ml1rem w100">
                                        <input
                                            name="officeLocation"
                                            type="text"
                                            placeholder="Location Base e.g. London"
                                            value={investorContact?.['officeLocation'] || ''}
                                            className="bg5 pl4rem shadow w100 br5px p1rem"
                                            onChange={(event) => handleContactChange(event)}
                                        />
                                        <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                            <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="pr">
                                    <label className='fs1rem mb1rem' style={{ color: '#7a7a7a' }}>Office Telephone Number</label>
                                    <input
                                        name="phone"
                                        type="text"
                                        placeholder="Telephone Number"
                                        value={investorContact?.['phone'] || ''}
                                        className="bg5 pl4rem shadow w100 br5px p1rem"
                                        onChange={(event) => handleContactChange(event)}
                                    />
                                    <svg className="center-vertically f3 pa l0 ml1rem mt1rem w2rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                        <path className="fa-primary" d="M352 48V384H32V48C32 21.5 53.5 0 80 0h224C330.5 0 352 21.5 352 48z" />
                                        <path className="fa-secondary" d="M32 384v80C32 490.5 53.5 512 80 512h224c26.5 0 48-21.5 48-48V384H32zM240 447.1C240 456.8 232.8 464 224 464H159.1C151.2 464 144 456.8 144 448S151.2 432 160 432h64C232.8 432 240 439.2 240 447.1z" />
                                    </svg>
                                </div>
                                <div className="pr">
                                    <input
                                        name="email"
                                        type="email"
                                        placeholder="Contact’s Company Email address – e.g. john.smith@investor.vc"
                                        value={investorContact?.['email'] || ''}
                                        className="bg5 pl4rem shadow w100 br5px p1rem"
                                        onChange={(event) => handleContactChange(event)}
                                    />
                                    <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 512 512">
                                        <path d="M256.47 352h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V400a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V183.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52zM464 64H48a48 48 0 0 0-48 48v19a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4 23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131v-19a48 48 0 0 0-48-48z" className="fa-secondary"></path>
                                        <path d="M512 131v52.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V131a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.69 12.12 49.75 41.4 72.93 41.4h.94c23.18 0 56.24-29.28 72.93-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131z" className="fa-primary"></path>
                                    </svg>
                                </div>
                                <p className='fs0-75rem'>Please do not put generic enquiries@ email address here.  Having qualified the lead, we ask that it be provided to a named individual within your organisation </p>
                                <div className="pr">
                                    <h2 className='fs1-25rem mb1rem'>Contact Bio (Provided to our qualified leads)</h2>
                                    <div className='bg1 shadow br5px p1rem pr'>
                                        {(action === 'add' || editInput === 'bio')
                                            ? (<p className='df tac p0rem w100 background-des'>
                                                <textarea
                                                    name="bio"
                                                    rows={10}
                                                    className="c2 w100"
                                                    placeholder="Enter Contact Bio"
                                                    defaultValue={investorContact?.['bio'] || ''}
                                                    onBlur={(event) => { handleContactChange(event), setEditInput(null) }}
                                                    onChange={(event) => handleContactChange(event)}
                                                />
                                            </p>
                                            ) : (<p>
                                                {investorContact?.['bio']}
                                                <span className='edit-btn'>
                                                    <button onClick={() => setEditInput('bio')} className='edit-pencil'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30.72" height="32.887" viewBox="0 0 30.72 32.887">
                                                            <path id="Union_4" data-name="Union 4" d="M.7,32.887a.68.68,0,1,1,0-1.36h28.42a.68.68,0,0,1,0,1.36ZM.269,30.463a.91.91,0,0,1-.221-.937l.16-1.28,2.26,2.25-1.26.16v.02a.911.911,0,0,1-.939-.213ZM.5,27.207l.58-4.42a.378.378,0,0,1,0-.1,2.431,2.431,0,0,0,.55.831,2.524,2.524,0,0,0,2.1.72,2.518,2.518,0,0,0,2.849,2.81,2.523,2.523,0,0,0,.71,2.13v.049a2.814,2.814,0,0,0,.83.55h-.09l-4.28.68Zm7.55,1.239-.04-.04a1.471,1.471,0,0,1,0-2.09l14.9-14.791,2,2L9.627,28.777a1.458,1.458,0,0,1-1.58-.33Zm-2.93-2.92A1.48,1.48,0,0,1,5,23.586l.149-.13L20.008,8.6l2.07,2.06L7.2,25.527a1.489,1.489,0,0,1-2.08,0Zm-2.81-2.82-.04-.041a1.489,1.489,0,0,1-.339-1.58l15.3-15.27,2,2L4.368,22.676c-.05.05-.08.111-.13.16a1.47,1.47,0,0,1-1.93-.13ZM17.924,5.065,19.791,3.2l7.693,7.693-1.867,1.867Zm2.583-2.539L22.417,.6a2,2,0,0,1,2.85,0l4.85,4.85a2,2,0,0,1,0,2.86l-1.92,1.91Z" transform="translate(0)" />
                                                        </svg>
                                                    </button>
                                                </span>
                                            </p>)}
                                    </div>
                                </div>
                                <div className="pr">
                                    <p className="bg5 pl4rem shadow br5px p1rem" >Approved By MB</p>
                                    <svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
                                        <path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
                                    </svg>
                                    <div className='switch-box pa r0'>
                                        <Switch
                                            name='isValidated'
                                            value='isValidated'
                                            checked={(investorFirm?.['isValidated'])}
                                            className="success w100"
                                            onChange={(event) => handleInvestorChange(event)}
                                        />
                                    </div>
                                </div>

                                <div className='pr mt1rem'>
                                    <button className='shadow my1rem bg3 bg4-hover c1 td250 br5px p1rem fw600 cp ttu w100 bsbb tac df jcc aic' type='submit'>{action === 'add' ? 'Add' : 'Edit'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fragment>
            </>
        )
    };

    return (
        <AnimatedPage>
            <div className="td250 container w60 df">
                <div className="w100 gg1rem mt4rem">
                    {getOverviewContent()}
                    {investorDetails()}
                    {investmentCriteriaObjective()}
                    {investmentSubjective()}
                    {leadContactDetails()}
                </div>
            </div>

            {/* Upload Image */}
            <Modal open={isImageModal} onClose={() => setIsImageModal(false)} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
                <div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
                    <button className="close-popup" onClick={() => { setIsImageModal(false), setUploadType(''), setCurrentImage('') }}>
                        <svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                        </svg>
                    </button>

                    <h1 className="text-gradient fs2rem tac lh1 fw600 mb2rem">Upload Logo</h1>
                    {currentImage == ''
                        ? <label for="profile" className="w100 bsbb bg5 br5px tac p1rem bsd bo6 bw2px cp pr upload-pic-area">
                            <span className="pen fs1rem fw500 tac">Upload picture</span><br />
                            <div className='input-btn pa t0 w100 op0 l0 cp'>
                                <input className='w100 l0 h5rem cp' onChange={(e) => handleImage(e)} id="currentImage" type="file" accept="image/jpeg, image/jpg, image/png" />
                            </div>
                            <label >Max file size 5MB</label>
                        </label>
                        : <div className='img-box pr'>
                            <Cropper
                                ref={cropper}
                                guides={false}
                                aspectRatio={1 / 1}
                                className='cropper-settings'
                                src={currentImage}
                            />
                        </div>
                    }
                    <div className="df jcse">
                        <span onClick={() => setIsImageModal(false)} className="shadow bg4 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem">Cancel</span>
                        <span onClick={() => uploadImage()} className="shadow bg3 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem">
                            {Object.keys(currentImage).length == 0
                                ? 'Upload'
                                : 'Crop & Upload'
                            }
                        </span>
                    </div>
                </div>
            </Modal>

        </AnimatedPage >
    );
}