import React from 'react'
import AnimatedPage from '../../components/AnimatedPage'
import SmoothScrollingButton from '../../components/SmoothScrollingButton'
import CustomTab from '../../components/termsAndConditions/CustomTab'

const AllUse = () => {
    return (
        <AnimatedPage>
            <SmoothScrollingButton />
            <div className='poppins-font-style privacy-policy-page *'>
                <CustomTab type="policy" activeIdentifier="all-use" title={'Policies'} width="w60" />
                <div className="fw400 tal c14 fs1rem policy-box">
                    <p className="pt1rem pb1rem">Effective: 1st January 2024</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Introduction:</p>
                    <p className="pb1rem">At Diligentsia Limited ("we," "us," or "our"), we are committed to delivering high-quality services to our corporate customers, mainly SMEs, their professional advisors, and investors ("Customers"). We utilize generative AI technologies, including those from OpenAI, Google Bard, Microsoft Bing, and others, to assist in the creation of bespoke reports and deliver valuable insights to our Customers. We take your privacy and data security seriously, and this AI Use Policy outlines our responsible approach to using AI technologies.</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Data Usage and Protection:</p>
                    <p className="pb1rem">includes information provided by our Customers and may include text, documents, and other relevant data.
                        Data Security: We employ robust security measures to protect your data from unauthorized access, disclosure, or alteration. We use encryption, access controls, and industry best practices to safeguard your information.
                        Data Usage: We use the data provided by our Customers exclusively for the purpose of generating bespoke reports and delivering our services. We do not sell, market, or otherwise use your data for any purpose other than fulfilling our obligations to you.
                        Anonymization and Aggregation: We may aggregate and anonymize data to improve our AI models, conduct research, and enhance our services. This aggregated and anonymized data cannot be traced back to individual Customers.</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Responsible AI Usage:</p>
                    <p className="pb1rem">AI Algorithms: We use AI algorithms to assist in the generation of reports, ensuring accuracy and efficiency. These algorithms are designed to provide valuable insights while maintaining a responsible and ethical approach.
                        Transparency: We strive to be transparent about our AI usage. If you have any questions about how AI is used in our services, please feel free to contact us.</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Customer Rights:</p>
                    <p className="pb1rem">Access and Control: Customers have the right to access, correct, or delete their data. If you have concerns or requests regarding your data, please contact us, and we will promptly address them.
                        Opt-Out: If you no longer wish to use our services, you have the right to opt-out and have your data removed from our systems.</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Compliance with Applicable Laws:</p>
                    <p className="pb1rem">We are committed to complying with all applicable data protection and privacy laws, including but not limited to the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Contact Us:</p>
                    <p className="df aic">If you have any questions, concerns, or requests related to our AI Use Policy or the use of AI in our services, please contact us at: <a className="db p0-5rem td250 c4-hover" href="mailto: support@diligentsia.co.uk">support@diligentsia.co.uk</a>.</p>
                    <p className='fw500 tal c14 fs1-5rem  pb1rem'>Changes to the AI Use Policy:</p>
                    <p className="pb1rem">We may update this AI Use Policy from time to time to reflect changes in our business practices or legal requirements. The most current version will be posted on our website, and we will notify Customers of any significant changes.</p>
                    <p className="pb1rem">By using our services, you agree to this AI Use Policy and our Terms and Conditions.</p>
                    <p className='fw500 tal c14 fs1-5rem  pb1rem'>Effective Date:</p>
                    <p className="pb1rem">This AI Use Policy is effective as of 1st January 2024.</p>
                </div >
            </div>
        </AnimatedPage>
    )

}
export default AllUse