import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import ExpPackPayment from "./Company/ExpPackPayment";
import { getRequest, showMessage } from "../../config";
import AnimatedPage from "../../components/AnimatedPage";
import { toggleModal } from "../../reducers/modalReducer";
import HeadingSubheadingContent from "../../components/HeadingSubheadingContent";

export default function IndustryPack() {
	const dispatch = useDispatch();

	let loggedIn = localStorage.getItem("token") || localStorage.getItem("userId");
	const user = useSelector(state => state.user);
	const companies = useSelector(state => state.companies);
	let [company, setCompany] = useState(companies[location.pathname.split("/").slice(-1)[0]] || {});

	useEffect(() => {
		getCompany();
	}, []);

	const getCompany = async () => {
		const companyId = location.pathname.split("/").slice(-1)[0];
		if (companyId) {
			const res = await getRequest(`/company/get/${companyId}`);
			setCompany(res['body']);
		}
	}

	return (
		<AnimatedPage>
			<div className="expansionpack-page py4rem">
				<div className="container df fdc">
					<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Industry Expansion Pack" subheading="Software Development​" />
				</div>
				<div className="container df">
					<div className="ma lef-img-area">
						<div className='img-box'>
							<img className="h10rem" src={require('../../../images/left-person.png')} alt="" />
						</div>
					</div>
					<div className="ma w100 right-text-area">
						<p>Software Development companies wishing to undertake an in depth audit of its software development structure, processes and protections.​​</p>

						<div className="number-sec">
							<ul className="bg5 br5px shadow">
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>Number of Sections: </span> 20 - I.T. Staff, Products & Services, Software Development<br />Process, Software & Services Utilised, Hardware​<br />Network Infrastructure, Backup & Recovery, Documentation<br />Network Security, Cybersecurity<br />Compliance, Agreements, Quality<br />Financial</li>
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>Number of Questions: </span> `100</li>
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>AI Generated Company Specific Questions included? </span> Yes/No</li>
							</ul>
						</div>

						<div className="about-img">
							{/* <div className="img-box">
								<img className="br5px" src={require('../../../images/about-img.png')} alt="" />
							</div> */}
							<div className="text-box bg5 br5px shadow">
								<p>“This type of exercise is often undertaken following a problem such as a data breach or systems implosion which can represent an existential risk to the organisation.  Doing it advance significantly reduces the chances of these things happening in the first place”  Paul Smith,, CTO,​</p>
							</div>
						</div>
					</div>


				</div>
			</div>

			<div className="boost-sec bg-gradient c1">
				<div className="container py5rem">
					<div className="dg gg2rem gtcr3">
						<div className="h100 df fdc jcsb aic">
							<div className="img-box">
								<img className="h10rem" src={require('../../../images/speed-img.png')} alt="" />
							</div>
						</div>
						<div className="h100 df fdc jcsb py4rem">
							<h2 className="fds c1 fs2rem tac">Save the costs of external<br /><span className="c3 fds ">consultants…</span></h2>
						</div>
						<div className="h100 df fdc jcsb aic py4rem">
							{loggedIn
								? <>
									{!company?.['expensions']?.['DEPN004'] &&
										<button onClick={() => dispatch(toggleModal({ open: 'payment' }))} style={{ padding: '15px', position: 'relative' }} className="br5px shadow cp c1 px1rem my0-5rem fw600 m1rem bg4 bg3-hover td250">
											One off purchase – BUY NOW!
											<div className="tooltip-btn">
												<span>&pound; 500</span>
											</div>
										</button>
									}
								</>
								: <button onClick={() => showMessage("LogIn to - BUY NOW!")} style={{ padding: '15px', position: 'relative' }} className="br5px shadow cp c1 px1rem my0-5rem fw600 m1rem bg4 bg3-hover td250">
									One off purchase – BUY NOW!
									<div className="tooltip-btn">
										<span>&pound; 500</span>
									</div>
								</button>
							}
						</div>
					</div>
				</div>
			</div>

			<div className="benfits-sec py4rem">
				<div className="container df fdc">
					<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Benefits" />
				</div>
				<div className="container">
					<div className="dg gg2rem gtcr3">
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-1.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Enhanced process efficiency:</b></p>
								<p>Completing a comprehensive software development evaluation checklist can help a company identify inefficiencies and areas for improvement in its processes and procedures. This can lead to more streamlined workflows, increased productivity, and ultimately higher-quality software.</p>
							</div>
						</div>
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-2.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Improved risk management:</b></p>
								<p>A thorough evaluation of software development processes and procedures can help a company uncover potential risks and vulnerabilities. By addressing these issues proactively, the company can mitigate the likelihood of costly errors, delays, or security breaches.</p>
							</div>
						</div>
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-3.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Increased scalability:</b></p>
								<p>By evaluating and optimizing software development processes, a company can better prepare for future growth. Efficient, well-documented processes are more easily scalable, allowing the company to handle increased demand and expand its operations seamlessly.</p>
							</div>
						</div>

						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-4.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Attractiveness to investors:</b></p>
								<p>A company that demonstrates a strong commitment to best practices in software development is more likely to attract external investment. By completing a 100-question evaluation checklist, the company can showcase its adherence to industry standards, giving potential investors confidence in its ability to deliver high-quality products and services.</p>
							</div>
						</div>

						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-5.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Team alignment and collaboration:</b></p>
								<p>Working through a comprehensive evaluation checklist can help ensure that all team members are on the same page regarding the company's software development processes and procedures. This can foster better communication, collaboration, and a shared understanding of expectations, leading to a more cohesive and effective team.</p>
							</div>
						</div>

						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-6.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Competitive advantage:</b></p>
								<p>A company that has thoroughly evaluated and optimized its software development processes is more likely to produce high-quality, innovative products and services. This can give the company a competitive edge in the market, helping it to stand out among competitors and attract customers or clients.</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="modal-sec py4rem">
				<div className="container df fdc">
					<div className="heading">
						<img className="h5rem" src={require('../../../images/modle-title.png')} alt="" />
					</div>
				</div>
				<div className="container">
					<div className="dg gg2rem gtcr2">
						<div className="df tac  fdc" style={{ padding: '0 25px' }}>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Share Capital and Authority</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Business Plan</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Accounts</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Management Accounts</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Properties</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Assets,Debt & Stock</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Employment and Consultancy Arrangements</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Records & Registers</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Group Structure</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Borrowings & Facilities</p>
						</div>

						<div className="df tac  fdc" style={{ padding: '0 25px' }}>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Information</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Accounts</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Events Since The Accounts Date</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Litigation</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Intellectual Property</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Contracts with Connected Persons</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Statutory & Legal Requirements</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Insurance</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Agreements & Captial Commitments</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Social Obligations</p>
						</div>
					</div>
				</div>
			</div>
			<ExpPackPayment {...{ user, company, showExpension: 'DEPN004' }} />
		</AnimatedPage>
	);
};
