import AnimatedPage from "../components/AnimatedPage";
import HeadingSubheadingContent from "../components/HeadingSubheadingContent";
import { Link } from "react-router-dom";
import { HOME } from "../constants/routes";

export default function NotFound() {
	return (
		<AnimatedPage>
			<div id="hero" className="bg1 mih100vh df">
				<div className="container df fdc jcc aic">
					<HeadingSubheadingContent heading="Oops :(" subheading="404 Page Not Found" />
					<Link className="mt2rem db px1rem py0-5rem bss bw5px bo6 bg1 fs1-5rem fw600 td250 c4-hover" to={HOME}>
						Back to Home
					</Link>
				</div>
			</div>
		</AnimatedPage>
	);
}
