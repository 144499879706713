import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from '@mui/material/Modal';
import AnimatedPage from "../../../components/AnimatedPage";
import HeadingSubheadingContent from "../../../components/HeadingSubheadingContent";
import { getRequest, postRequest, showMessage } from '../../../config';
import { toggleModal } from "../../../reducers/modalReducer";
import ReactTable from 'react-table-6';

export default function AiChecklist(props) {

    let dispatch = useDispatch();

    let modal = useSelector(state => state.modal);

    const { onBack } = props;
    const [answers, setAnswers] = useState([]);

    let [aiReplyTable, setAiReplyTable] = useState([]);

    const [tData, setTData] = useState([]);
    //     {
    //         no: "S001",
    //         questions: "T collaborations with industry experts for innovation?	",
    //         space: "",
    //         risk: "Lack of external input can limit innovation.",
    //         recommendation: "Establish a dedicated product development team.",
    //     },

    
    const tColumns = [
        {
            Header: 'Section',
            accessor: 'section',
            minWidth: 100,
            style: { whiteSpace: 'normal', wordWrap: 'break-word' }
        },
        {
            Header: 'No',
            accessor: 'no',
            width: 80,
        },
        {
            Header: 'Questions',
            accessor: 'questions',
            minWidth: 200,
            style: { whiteSpace: 'normal', wordWrap: 'break-word' }
        },
        {
            Header: 'Space for Answer',
            accessor: 'space',
            width: 50,
            style: { whiteSpace: 'normal', wordWrap: 'break-word' }
        },
        {
            Header: 'Risk/Rationale',
            accessor: 'risk',
            minWidth: 200,
            style: { whiteSpace: 'normal', wordWrap: 'break-word' }
        },
        {
            Header: 'Recommendation',
            accessor: 'recommendation',
            minWidth: 200,
            style: { whiteSpace: 'normal', wordWrap: 'break-word' }
        },
    ];

    const [moduleDescription, setModuleDescription] = useState('');

    const [moduleName, setModuleName] = useState('');
    const defaultModuleName = "Book";
    const defaultModuleDescription = "This module is for book sellers";
    const defaultInstruction = `Generate a detailed due diligence checklist titled '{Checklist Title}' for a company based in 'United Kingdom'. The checklist is designed to evaluate '{Checklist Description/Purpose}'. The checklist should have {10} sections, each containing {10} questions. Each section should address different aspects of the due diligence topic. Each question should be structured in a table with columns for 'No.', 'Questions', 'Space for Answer', 'Risk/Rationale', and 'Recommendation'. Provide a recommendation for each question where a 'No' answer would indicate a potential risk.`;
    
    const [instructions, setInstructions] = useState("");
    const [aiResp, setAiResp] = useState({});
    const [chId, setChId] = useState("");
    let [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            let res = await getRequest(`/aiTest/getCurrentChecklist`);
            if (res?.code === 200) {
                console.log("res", res.body);
                if (res.body) {
                    setChId(res.body['_id']);
                    setInstructions(res.body['instructions']);
                    setModuleDescription(res.body['moduleDescription']);
                    setModuleName(res.body['moduleName']);
                    // setAiResp(res.body['checklistDescription']);
                    // processResponse(res.body['checklistDescription']);
                    parseData(res.body['checklistDescription']);
                }
                else {
                    setInstructions(defaultInstruction);
                    setModuleDescription(defaultModuleDescription);
                    setModuleName(defaultModuleName);
                    setAiResp("");
                }
            }
        };
        fetch();
    }, []);

    const padNumber = (num, size) => String(num).padStart(size, '0');

    const parseData = (text) => {
        const lines = text.split('\n');
        const data = [];
        let currentSection = '';
    
        let sectionNo = 0;
        let showSection = true;
        lines.forEach(line => {
          if (line.startsWith('**Section') || line.startsWith('Section')) {
            // currentSection = line.replace('**', '').trim();
            currentSection = line.split(':')[1].replace('**', '').trim();
            sectionNo++;
            showSection = true;
          } else if (line.startsWith('|') && !line.startsWith('| ---') && !line.startsWith('| No')) {
            const [, no, questions, space, risk, recommendation] = line.split('|').map(item => item.trim());
            data.push({
              section: showSection ? `S${padNumber( sectionNo, 3)}. ` + currentSection : "",
              no: `S${padNumber( sectionNo, 3)}.${no}`,
              questions,
              space,
              risk : risk !== "-" ? risk : "",
              recommendation : recommendation !== "-" ? recommendation : ""
            });
            showSection = false;
          }
        });

        setTData(data);
    };
      
    const processResponse = async (response) => {
        const data = JSON.parse(response);

        let formattedString = '';
        data.module.forEach((section, sectionIndex) => {
            const sectionNumber = padNumber(sectionIndex + 1, 3);
            formattedString += `S${sectionNumber}. "${section.sectionName}"\n`;
            
            section.questions.forEach((q, questionIndex) => {
                formattedString += `S${sectionNumber}.${questionIndex + 1}: "${q.question}"\n`;
            });
            formattedString += '\n'; // Add an extra newline between sections
        });
        setAiResp(formattedString);
    }
    const getAIResponse = async () => {
        setTData("");
        dispatch(toggleModal({ open: 'ChecklistModal' }));
        let res = await postRequest(`/aiTest/testChecklist`, { instructions, moduleName, moduleDescription });
        if (!res || res['code'] != 200) {
            showMessage("Sever is not responding\n");
            dispatch(toggleModal({ open: '' }));
            return;
        }

        let { aiResponse, id } = res['body'];
        console.log(":aiResponse: ", aiResponse);
        setChId(id);
        setAiResp(aiResponse);
        // processResponse(aiResponse);
        parseData(aiResponse);
        setRefresh(!refresh);
        dispatch(toggleModal({ open: '' }));
    }

    const selectCH = async () => {
        let result = confirm("Do you want to update this instruction as default?");
        if (result === false) {
            return;
        }
        if (chId == "") return;
        let res = await postRequest(`/aiTest/selectChecklists`, { chId });
        if (!res || res['code'] != 200) return;
        showMessage("Instruction is selected as default");
    }

    return (
        <AnimatedPage>
            <div className="container py5rem df fdc">
                <HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Admin Panel - Checklist Module Test" subheading="Checklist Modules" />
                <span onClick={onBack} className="mra shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
                    <svg className="pen f1 h2rem mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path className="fa-primary pen" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
                        <path className="fa-secondary pen" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l-32-32l32-32H416C433.7 224 447.1 238.3 447.1 256z" />
                    </svg>
                    Back to Menu
                </span>

                <div className="dg gg1rem mx1rem p1rem py0-5rem my0-5rem bss bw2px bo5 br10px">
                    <h3 className="usn pen fs1-25rem fw600">AI Checklist Module Test</h3>
                    <div className="pr">
                        <b className="w100 tac fs1-25rem">Name</b>
                        <textarea onChange={(e) => setModuleName(e.target.value)} defaultValue={moduleName} className="bss bw2px bo5 px4rem py2rem bg1 w100 br5px rv" />
                    </div>

                    <div className="pr">
                        <b className="w100 tac fs1-25rem">Description</b>
                        <textarea onChange={(e) => setModuleDescription(e.target.value)} defaultValue={moduleDescription} className="bss bw2px bo5 px4rem py2rem bg1 w100 br5px rv" />
                    </div>

                    <div className="pr">
                        <b className="w100 tac fs1-25rem">Instructions</b>
                        <textarea onChange={(e) => setInstructions(e.target.value)} defaultValue={instructions} className="bss bw2px bo5 px4rem py2rem bg1 w100 br5px rv" />
                    </div>

                    <div className="pr">
                        <b className="w100 tac fs1-25rem">AI Response</b>
                        {/* <textarea defaultValue={aiResp} className="bss bw2px bo5 px4rem py2rem bg1 w100 br5px rv" /> */}
                        { tData && tData.length > 0 &&
                            <ReactTable
                                data={tData}
                                columns={tColumns}
                                defaultPageSize={10}
                                className="-striped -highlight"
                            />
                        } 
                    </div>

                    <div className="w100 df jcc aic">
                        <button onClick={() => getAIResponse()} className="bg5 br5px px1rem py0-5rem fw600 cp fs1-25rem bsbb tac df jcc aic mr1rem">Ask ChatGPT</button>
                        <button onClick={() => selectCH()} className="bg5 br5px px1rem py0-5rem fw600 cp fs1-25rem bsbb tac df jcc aic">Go Live</button>
                    </div>
                </div>

                {/* Filing Assistant Modal  */}
                <Modal open={modal['open'] == 'ChecklistModal'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
                    <div className="pr oya bg1 shadow w100 p3rem pt5rem bsbb df fdc m1rem maw1000px br5px pr">
                        <span className="pa t0 l0 m1rem ttu c7 fs1-5rem fw500">PRO</span>
                        <img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
                        <button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
                            <svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                            </svg>
                        </button>
                        <h1 className="text-gradient fw600 tac mb1rem fs1-5em">{moduleName}</h1>
                        <h2 className="c4 fs1-25rem-sm ttu w100 tac mb2rem">Diligentsia Admin Checklist Test</h2>

                        <div className="tac">
                            <p>🔮 <b>Checklist Module Testing Magic:</b> We're now summoning the power of AI to get sections about this module! <br />Please stay with us during this magical incantation…</p>
                            <img className="h9rem" src={require(`../../../../images/wizard.gif`)} alt="" />
                        </div>
                    </div>
                </Modal>

            </div>
        </AnimatedPage>

    );
}
