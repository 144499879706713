import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from '@mui/material/Modal';
import AnimatedPage from "../../../components/AnimatedPage";
import HeadingSubheadingContent from "../../../components/HeadingSubheadingContent";
import { getRequest, postRequest, showMessage } from '../../../config';
import { toggleModal } from "../../../reducers/modalReducer";

export default function AiTwoHundredsComponent(props) {

    let dispatch = useDispatch();

    let modal = useSelector(state => state.modal);


    const { onBack } = props;
    const [answers, setAnswers] = useState([]);

    const [companyURL, setCompanyURL] = useState('');

    const [companyName, setCompanyName] = useState('');
    const defaultCompanyURL = "https://www.rolls-royce.com/";
    const defaultCompanyName = "ROLLS-ROYCE HOLDINGS PLC";
    const defaultInstruction = `This is company name and website. Please provide a summary of a minimum of 200 words for the business activities of this company. Please focus on describing what it does in terms of goods or services provided; please do not include Companies House information; if it is a regulated entity it would be useful to identify which regulator; please try to ignore including fluffy marketing adjectives in your answer - we want to focus on understanding what the company actually does as its main business activity, its business model etc. Reply only with the summary and nothing else. Do not add links to sources. Please start your answer with 'the company...'. Please provide answers only instead of asking followup questions and return response in plain text. Please ensure your answer contains a minimum of 200 words.`;
    // `Please provide a summary of a minimum of 200 word for the business activities of a company, ${newCompanyName} whose web site is ${newCompany['cWebsite'].replace(/'/g, "\\'")} also please search the internet to get additional information about ${newCompanyName}. Please focus on describing what it does in terms of goods or services provided; please do not include Companies House information; if it is a regulated entity it would be useful to identify which regulator; please try to ignore including fluffy marketing adjectives in your answer - we want to focus on understanding what the company actually does as its main business activity, its business model etc. ${newCompany['fiveWords'] && `We have been provided a further up to five words to describe the company - these are ${newCompany['fiveWords'].replace(/'/g, "\\'")}.`} Reply only with the summary and nothing else. Do not add links to sources. Please start your answer with 'the company...'. Please provide answers only instead of asking followup questions and return response in plain text. Please ensure your answer contains a minimum of 200 words.`
    const [instructions, setInstructions] = useState("");
    const [aiResp, setAiResp] = useState({});
    const [thwId, setTHWId] = useState("");
    let [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const fetch = async () => {
            let res = await getRequest(`/aiTest/getCurrentTHWordInfo`);
            if (res?.code === 200) {
                console.log("res", res.body);
                if (res.body) {
                    setTHWId(res.body['_id']);
                    setInstructions(res.body['instructions']);
                    setCompanyURL(res.body['companyURL']);
                    setCompanyName(res.body['companyName']);
                    setAiResp(res.body['companyDescription']);
                }
                else {
                    setInstructions(defaultInstruction);
                    setCompanyURL(defaultCompanyURL);
                    setCompanyName(defaultCompanyName);
                    setAiResp("");
                }
            }
        };
        fetch();
    }, []);

    const getAIResponse = async () => {
        dispatch(toggleModal({ open: 'TwoHundredsWords' }));
        let res = await postRequest(`/aiTest/testTwoHundredWords`, { instructions, companyName, companyURL });
        if (!res || res['code'] != 200) {
            dispatch(toggleModal({ open: '' }));
            return;
        }

        let { aiResponse, id } = res['body'];
        console.log(":aiResponse: ", aiResponse);
        setTHWId(id);
        setAiResp(aiResponse);
        setRefresh(!refresh);
        dispatch(toggleModal({ open: '' }));
    }

    const selectTH = async () => {
        let result = confirm("Do you want to update this instruction as default?");
        if (result === false) {
            return;
        }
        if (thwId == "") return;
        let res = await postRequest(`/aiTest/selectTwoHundredWords`, { thwId });
        if (!res || res['code'] != 200) return;
        showMessage("Instruction is selected as default");
    }

    return (
        <AnimatedPage>
            <div className="container py5rem df fdc">
                <HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Admin Panel - 200 Words Test" subheading="200 words" />
                <span onClick={onBack} className="mra shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
                    <svg className="pen f1 h2rem mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path className="fa-primary pen" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
                        <path className="fa-secondary pen" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l-32-32l32-32H416C433.7 224 447.1 238.3 447.1 256z" />
                    </svg>
                    Back to Menu
                </span>

                <div className="dg gg1rem mx1rem p1rem py0-5rem my0-5rem bss bw2px bo5 br10px">
                    <h3 className="usn pen fs1-25rem fw600">AI 200 Words Test</h3>
                    <div className="pr">
                        <b className="w100 tac fs1-25rem">Company Name</b>
                        <textarea onChange={(e) => setCompanyName(e.target.value)} defaultValue={companyName} className="bss bw2px bo5 px4rem py2rem bg1 w100 br5px rv" />
                    </div>

                    <div className="pr">
                        <b className="w100 tac fs1-25rem">Company URL</b>
                        <textarea onChange={(e) => setCompanyURL(e.target.value)} defaultValue={companyURL} className="bss bw2px bo5 px4rem py2rem bg1 w100 br5px rv" />
                    </div>

                    <div className="pr">
                        <b className="w100 tac fs1-25rem">Instructions</b>
                        <textarea onChange={(e) => setInstructions(e.target.value)} defaultValue={instructions} className="bss bw2px bo5 px4rem py2rem bg1 w100 br5px rv" />
                    </div>

                    <div className="pr">
                        <b className="w100 tac fs1-25rem">AI Response</b>
                        <textarea defaultValue={aiResp} className="bss bw2px bo5 px4rem py2rem bg1 w100 br5px rv" />
                    </div>

                    <div className="w100 df jcc aic">
                        <button onClick={() => getAIResponse()} className="bg5 br5px px1rem py0-5rem fw600 cp fs1-25rem bsbb tac df jcc aic mr1rem">Ask ChatGPT</button>
                        <button onClick={() => selectTH()} className="bg5 br5px px1rem py0-5rem fw600 cp fs1-25rem bsbb tac df jcc aic">Go Live</button>
                    </div>
                </div>

                {/* Filing Assistant Modal  */}
                <Modal open={modal['open'] == 'TwoHundredsWords'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
                    <div className="pr oya bg1 shadow w100 p3rem pt5rem bsbb df fdc m1rem maw1000px br5px pr">
                        <span className="pa t0 l0 m1rem ttu c7 fs1-5rem fw500">PRO</span>
                        <img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
                        <button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
                            <svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                            </svg>
                        </button>
                        <h1 className="text-gradient fw600 tac mb1rem fs1-5em">{companyName}</h1>
                        <h2 className="c4 fs1-25rem-sm ttu w100 tac mb2rem">Diligentsia Admin 200 Words Test</h2>

                        <div className="tac">
                            <p>🔮 <b>200 Words Testing Magic:</b> We're now summoning the power of AI to get 200 words about this company! <br />Please stay with us during this magical incantation…</p>
                            <img className="h9rem" src={require(`../../../../images/wizard.gif`)} alt="" />
                        </div>
                    </div>
                </Modal>

            </div>
        </AnimatedPage>

    );
}
