import { createAction, createSlice } from "@reduxjs/toolkit";

export default function getModalReducer(modal = { open: '' }) {
	const modalSlice = createSlice({
		name: "modal",
		initialState: modal,
		reducers: {
			update: (state, action) => {
				return { ...state, ...action.payload };
			}
		}
	});

	return modalSlice.reducer;
}

export const toggleModal = createAction("modal/update");

