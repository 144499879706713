import { useState, useEffect } from "react";

import AnimatedPage from "../../../components/AnimatedPage";
import HeadingSubheadingContent from "../../../components/HeadingSubheadingContent";
import { getRequest, postRequest } from '../../../config';

export default function AiTestComponent(props) {

	const { section, onBack } = props;
	const [answers, setAnswers] = useState([]);

	const defaultIntro = "I am writing a business audit report to the directors of ";
	const defaultInstructions = `I will give you report text, Please rephrase the standard report wording to make it more specific and tailored/bespoked, particularly to the company's activities and the specific industry in which it operates. Please also use the company's name (omitting the suffix "Limited") periodically and interchangeably with "the company". Please apply the instructions above to the generic report to make that content more relevant to the company and industry. Please do not change the text of line that start and end with ----- keep them as they are and do not move them.`;
	const defaultCompanyName = "ASTRAZENECA PLC";
	const defaultDescription = `The company AstraZeneca PLC is a global biopharmaceutical company that discovers, develops, and commercializes prescription medicines for a wide range of therapy areas, including oncology, cardiovascular, renal and metabolic diseases, infection, respiratory, and rare diseases.**
	AstraZeneca's business model is based on the research and development of innovative new medicines, which it then sells through its own commercial organization and through partners. The company is regulated by a number of different authorities, including the UK Medicines and Healthcare products Regulatory Agency (MHRA) and the US Food and Drug Administration (FDA).
	**AstraZeneca's main business activity is the development and commercialization of prescription medicines.** The company has a portfolio of over 50 marketed medicines, and a pipeline of over 100 new drugs in development. AstraZeneca's medicines are used to treat a wide range of diseases, including cancer, heart disease, stroke, diabetes, asthma, and arthritis.
	**AstraZeneca's business model is based on the following key activities:**
	* **Research and development:** AstraZeneca invests heavily in research and development, with the aim of discovering new medicines that can improve the lives of patients. The company has a global network of research and development centers, and employs over 16,000 scientists and researchers.
	* **Manufacturing:** AstraZeneca manufactures its own medicines at its own facilities around the world. The company also has a number of partnerships with other manufacturers.
	* **Commercialization:** AstraZeneca sells its medicines through its own commercial organization and through partners. The company has a global sales and marketing team, and operates in over 100 countries.
	**AstraZeneca is a major player in the global pharmaceutical industry. The company's medicines are used by millions of people around the world. AstraZeneca is committed to developing new medicines that can improve the lives of patients and transform the future of healthcare.**`;
	const [intro, setIntro] = useState('');
	const [instructions, setInstructions] = useState('');
	const [companyName, setCompanyName] = useState("");
	const [description, setDescription] = useState('');

	const [aiResp, setAiResp] = useState();

	useEffect(() => {
		const fetch = async () => {
			let res = await getRequest(`/audit/currentAuditInfo`);
			if (res?.code === 200) {
				let [intro, instructions, companyName, description] = res.body;

				setIntro(res.body[0]) ? setIntro(res.body[0]) : defaultIntro;
				setInstructions(res.body[1]) ? setInstructions(res.body[1]) : defaultInstructions;
				setCompanyName(res.body[2]) ? setCompanyName(res.body[2]) : defaultCompanyName;
				setDescription(res.body[3]) ? setDescription(res.body[3]) : defaultDescription;
			}
		};
		fetch();
	}, []);

	const getAIResponse = async () => {
		let res = await postRequest(`/company/ai_test`, { intro, instructions, companyName, description, section, answers });
		if (!res || res['code'] != 200) return;

		let { questions, risks, recommendations } = res['body'];
		if (!questions || !risks || !recommendations) {
			return;
		}
		let aiResp = { risks: [], recommendations: [] };
		for (let qi = 0; qi < section['questions'].length; qi++) {
			aiResp.risks[qi] = '';
			aiResp.recommendations[qi] = '';
			for (let i = 0; i < questions.length; i++) {
				if (section['questions'][qi]['qDescription'] == questions[i]) {
					aiResp.risks[qi] = risks[i];
					aiResp.recommendations[qi] = recommendations[i];
				}
			}
		}
		setAiResp(aiResp);
	}

	const radioButtonChanged = (e, index) => {
		const option = e.target.dataset.option;
		const wrapper = e.target.closest("[data-id]");
		if (!answers[index]) {
			answers[index] = { _id: index, status: '', radioOption: '', qNo: section['questions'][index]['qNo'] };
		}
		for (const showOrHideEl of wrapper.querySelectorAll(".showOrHide"))
			if (option === "Yes") {
				showOrHideEl.classList.remove("dn");
				setTimeout(() => showOrHideEl.classList.remove("op0", "h0"), 100);
			} else {
				showOrHideEl.classList.add("op0", "h0");
				setTimeout(() => showOrHideEl.classList.add("dn"), 300);
			}

		answers[index]['radioOption'] = option;
		if (option == 'N/A') answers[index]['status'] = 'Not Applicable';
		else answers[index]['status'] = 'Completed';

		setAnswers(answers);
	}

	const updateAnswer = async (answer) => {

	}

	return (
		<AnimatedPage>
			<div className="container py5rem df fdc">
				<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Admin Panel - AI Test" subheading={section['sectionNo'] + " - " + section['sectionName']} />
				<span onClick={onBack} class="mra shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
					<svg class="pen f1 h2rem mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
						<path class="fa-primary pen" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
						<path class="fa-secondary pen" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l-32-32l32-32H416C433.7 224 447.1 238.3 447.1 256z" />
					</svg>
					Back to Menu
				</span>
				<div class="fs1rem bss bw2px bo5 br10px p1rem jcsb" id={`${section['sectionNo']}_Audit`}>
					{section['questions'].map((question, questionIndex) => {
						const answer = answers[questionIndex] || { _id: questionIndex, status: '', radioOption: '' };
						return (
							<>
								<div class="sectionWrapper jcsb px2rem py0-5rem my0-5rem bss bw2px bo5 br10px">
									<div class="df jcsb">
										<span className="fs1rem fw600 auditSectionTitle">
											{question['qNo']} &nbsp; - &nbsp;
											<span className={answer['status'] === 'Completed' ? 'c7' : answer['status'] === 'Not Applicable' ? 'c8' : 'c9'}>
												{answer['status']}
											</span>
										</span>
										{/* <span class="sectionTitle">{answer['qNo']} - {answer['status']}</span> */}
										{/* {section.sectionName && <h3 class="fs2rem">{section.sectionName}</h3> || <h3 class="fs1-5rem">{section.name}</h3>}
									{getLogsHTML(logs.slice(0, 1))} */}
									</div>
									<div data-id={answer['_id']} class={(!answer || (answer.editPermission ?? true) ? "" : "op20 pen ") + "dg gtcr2-lg"}>
										<p className="fw500 fs1rem">{question['qDescription']}</p>
										<div class="df aic fww ml3rem">
											{["Yes", "No", "N/A"].map((option) =>
												<label for={question['_id'] + option} class="py0-5rem df cp">
													<span class="checkbox w2rem h2rem pr">
														<input defaultChecked={answer['radioOption'] == option} data-option={option} onChange={(e) => radioButtonChanged(e, questionIndex)} name={answer['_id']} id={answer['_id'] + option} class="bg5 shadow bo5 bw2px bss cp td250 w2rem h2rem br5px" type="radio" />
													</span>
													<span class="w100 fs1rem lh3 ml0-5rem usn mr2rem">{option}</span>
												</label>
											)}
										</div>
									</div>
								</div>
								{aiResp && aiResp.risks && aiResp.risks[questionIndex] &&
									<div class="sectionWrapper jcsb px2rem py0-5rem my0-5rem bss bw2px bo5 br10px">
										<div class="df jcsb">
											<span className="fs1rem ">
												Risk : {aiResp.risks[questionIndex]} <br /> <br />
												Recommendation : {aiResp.recommendations[questionIndex]} <br />
											</span>

										</div>

									</div>
								}
							</>
						)
					})}
				</div>

				<div class="dg gg1rem mx1rem p1rem py0-5rem my0-5rem bss bw2px bo5 br10px">
					<h3 className="usn pen fs1-25rem fw600">AI Test Phase</h3>
					<div class="pr">
						<b class="w100 tac fs1-25rem">Company Name</b>
						<textarea onChange={(e) => setCompanyName(e.target.value)} defaultValue={companyName} class="bss bw2px bo5 px4rem py2rem bg1 w100 br5px" />
					</div>
					<div class="pr">
						<b class="w100 tac fs1-25rem">Company Description</b>
						<textarea onChange={(e) => setDescription(e.target.value)} defaultValue={description} class="bss bw2px bo5 px4rem py2rem bg1 w100 br5px" />
					</div>
					<div class="pr">
						<b class="w100 tac fs1-25rem">Intro</b>
						<textarea onChange={(e) => setIntro(e.target.value)} defaultValue={intro} class="bss bw2px bo5 px4rem py2rem bg1 w100 br5px" />
					</div>

					<div class="pr">
						<b class="w100 tac fs1-25rem">Instructions</b>
						<textarea onChange={(e) => setInstructions(e.target.value)} defaultValue={instructions} class="bss bw2px bo5 px4rem pt2rem bg1 w100 br5px" />
					</div>

					{/* <div class="pr">
						{ aiResp && aiResp.map((v) => {
							return (<div class="pr">
								{
									Object.keys(v).map(key=>{
										return <span className="pen fs1rem fw500 tac">{v[key]}<br/></span>
									})
								}
							</div>);
						})}
					</div> */}

					<button onClick={() => getAIResponse()} class="bg5 c6 br5px px1rem py0-5rem fw600 cp fs1-25rem bsbb tac df jcc aic">Ask ChatGPT</button>
				</div>
			</div>
		</AnimatedPage>

	);
}
