import { useEffect, useState } from 'react';
import ExpPackPayment from "./Company/ExpPackPayment";
import { useSelector, useDispatch } from "react-redux";

import { getRequest, showMessage } from "../../config";
import AnimatedPage from "../../components/AnimatedPage";
import { toggleModal } from "../../reducers/modalReducer";
import HeadingSubheadingContent from "../../components/HeadingSubheadingContent";


export default function ExpansionPack() {
	const dispatch = useDispatch();

	let loggedIn = localStorage.getItem("token") || localStorage.getItem("userId");
	const user = useSelector(state => state.user);
	const companies = useSelector(state => state.companies);
	let [company, setCompany] = useState(companies[location.pathname.split("/").slice(-1)[0]] || {});

	useEffect(() => {
		getCompany();
	}, []);

	const getCompany = async () => {
		const companyId = location.pathname.split("/").slice(-1)[0];
		if (companyId) {
			const res = await getRequest(`/company/get/${companyId}`);
			setCompany(res['body']);
		}
	}

	return (
		<AnimatedPage>
			<div className="expansionpack-page py4rem">
				<div className="container df fdc">
					<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Expansion Pack" subheading="Warranty Disclosure Schedule​" />
				</div>
				<div className="container df">
					<div className="ma lef-img-area">
						<div className='img-box'>
							<img className="h10rem" src={require('../../../images/left-person.png')} alt="" />
						</div>
					</div>
					<div className="ma w100 right-text-area">
						<p>Any company looking to raise investment or exit.  Investors and purchasers will require warranties fro m the management/shareholders.  This process typically takes weeks if left to the end of the transaction and can cost £20,000+ if undertaken by lawyers.  By completing this module in advance of the transaction not only will you speed it up and save money on lawyers, you will also identify “blocking” issues and resolve them ahead of time.  You will also increase investor interest if you can disclose that you have undertaken this exericise and can demonstrate no blocking issues.  This version follows the UK British Venture Capital Association  standard schedule. ​</p>

						<div className="number-sec">
							<ul className="bg5 br5px shadow">
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>Number of Sections: </span> 20</li>
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>Number of Questions: </span> 100</li>
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>AI Generated Company Specific Questions included? </span> Yes/No</li>
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>Benefit: </span> Working though these questions will strengthen the business, identify weaknesses,  and giving you an opportunity to plug them prior to being subject to due diligence over a key area of your business</li>
							</ul>
						</div>

						<div className="about-img">
							<div className="img-box">
								<img className="br5px" src={require('../../../images/about-img.png')} alt="" />
							</div>
							<div className="text-box bg5 br5px shadow">
								<p>“Completing  Diligentsia’s warranty disclosure schedule at the start of my transaction rather than the end  saved us weeks of time in determining whether our sale would complete.  A must for companies looking for funding or to exit”</p>
								<p>Ted Bechman, CEO, The Income Group Limited</p>
							</div>
						</div>
					</div>


				</div>
			</div>

			<div className="boost-sec bg-gradient c1">
				<div className="container py5rem">
					<div className="dg gg2rem gtcr3">
						<div className="h100 df fdc jcsb aic">
							<div className="img-box">
								<img className="h10rem" src={require('../../../images/speed-img.png')} alt="" />
							</div>
						</div>
						<div className="h100 df fdc jcsb py4rem">
							<h2 className="fds c1 fs2rem tac">Boost your Diligentsia<br /><span className="c3 fds ">Certification Score...</span></h2>
						</div>
						<div className="h100 df fdc jcsb aic py4rem">
							{loggedIn
								? <>
									{!company?.['expensions']?.['DEPF006'] &&
									<button onClick={() => dispatch(toggleModal({ open: 'payment' }))} style={{ padding: '15px', position: 'relative' }} className="br5px shadow cp c1 px1rem my0-5rem fw600 m1rem bg4 bg3-hover td250">
										One off purchase – BUY NOW!
										<div className="tooltip-btn">
											<span>&pound; 500</span>
										</div>
									</button>
									}
								</>
								: <button onClick={() => showMessage("LogIn to - BUY NOW!")} style={{ padding: '15px', position: 'relative' }} className="br5px shadow cp c1 px1rem my0-5rem fw600 m1rem bg4 bg3-hover td250">
									One off purchase – BUY NOW!
									<div className="tooltip-btn">
										<span>&pound; 500</span>
									</div>
								</button>
							}
						</div>
					</div>
				</div>
			</div>

			<div className="benfits-sec py4rem">
				<div className="container df fdc">
					<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Benefits" />
				</div>
				<div className="container">
					<div className="dg gg2rem gtcr3">
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-1.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Streamlined due diligence process:</b></p>
								<p>Completing the<br />
									British Venture Capital Association (BVCA)
									warranty disclosure schedule in advance
									enables a faster and smoother due diligence process. It helps demonstrate to potential
									investors that the company has thoroughly assessed its business and is well-prepared for investment discussions.</p>
							</div>
						</div>
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-2.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Enhanced credibility and trust:</b></p>
								<p>CBy proactively completing the BVCA<br />
									warranty disclosure schedule, the company demonstrates a high level of professionalism and transparency. This can help build trust and credibility with potential inves    rities, such as refining the business plan, rather than scrambling to provide documentation and information at the last minute.​</p>
							</div>
						</div>
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-3.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Increased likelihood of successful investment:</b></p>
								<p>Completing the Overall, a company that has completed its own BVCA warranty disclosure schedule in advance demonstrates a higher level of preparedness and commitment to the investment process. This can make the <br />
									company more attractive to investors, <br />
									ultimately increasing the likelihood <br />
									of securing the desired external investment for investment discussions.</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="modal-sec py4rem">
				<div className="container df fdc">
					<div className="heading">
						<img className="h5rem" src={require('../../../images/modle-title.png')} alt="" />
					</div>
				</div>
				<div className="container">
					<div className="dg gg2rem gtcr2">
						<div className="df tac  fdc" style={{ padding: '0 25px' }}>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Share Capital and Authority</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Business Plan</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Accounts</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Management Accounts</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Properties</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Assets,Debt & Stock</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Employment and Consultancy Arrangements</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Records & Registers</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Group Structure</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Borrowings & Facilities</p>
						</div>

						<div className="df tac  fdc" style={{ padding: '0 25px' }}>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Information</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Accounts</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Events Since The Accounts Date</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Litigation</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Intellectual Property</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Contracts with Connected Persons</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Statutory & Legal Requirements</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Insurance</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Agreements & Captial Commitments</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Social Obligations</p>
						</div>
					</div>
				</div>
			</div>
			<ExpPackPayment {...{ user, company, showExpension: 'DEPF006' }} />
		</AnimatedPage >
	);
};
