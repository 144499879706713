import React from "react";
import moment from "moment";
import html2pdf from "html2pdf.js";
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import 'react-circular-progressbar/dist/styles.css';
import { toggleModal } from "../../../reducers/modalReducer";
import { getRequest, postRequest, showMessage } from '../../../config';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export default function FitReport({ user, company, module, tab, setTab, getFitInvestorFirms }) {

	const dispatch = useDispatch();
	const getHeight = child => child.offsetHeight + parseFloat(getComputedStyle(child).marginTop) + parseFloat(getComputedStyle(child).marginBottom);
	const modal = useSelector(state => state.modal);

	let [isReload, setIsReload] = useState(false);
	let [leadStatus, setLeadStatus] = useState('');
	let [fitMetrics, setFitMetrics] = useState({});
	let [investorFirm, setInvestorFirm] = useState({});
	let [isBlurContact, setIsBlurContact] = useState(true);
	let [isEditDeatils, setIsEditDeatils] = useState(false);
	let [sectionTab, setSectionTab] = useState(['About the Investor', 'About the Company', 'Fit Metrics', 'Diligence Reports', 'Detailed Answers', 'Investors Bespoked Q/A']);

	useEffect(() => {
		let scrollTo = '';
		let clientId = (location.href.split('#')[0]).split('/')[4];
		let investorId = (location.href.split('#').slice(-1)[0]).split(':').slice(-1)[0];
		if (investorId.includes('_')) {
			scrollTo = (investorId.split('_').slice(-1)[0]).replaceAll('-', ' ');
			investorId = investorId.split('_')[0];
		}
		if (investorId) getFitReport(investorId, scrollTo);
		if (clientId && investorId) getFitMetrics(clientId, investorId, scrollTo);
	}, []);

	useEffect(() => {
		document.getElementById("pdfContent").classList.add("a4-pdf", "a4-fit-pdf", "p2rem");
		const wrapper = document.getElementById("pdf");
		wrapper.innerHTML = "";
		const children = Array.from(document.getElementById("pdfContent").children);
		let i = 0;
		while (children.length && i < 250) {
			i++;
			const page = document.createElement("div");
			page.classList.add("a4-pdf", "a4-fit-pdf", "oyh", "bg1", "bsbb", "oh", "pr", "p3rem", "shadow", "new-report");

			wrapper.appendChild(page);
			const watermark = document.createElement("img");
			watermark.classList.add("op20", "pa");
			watermark.style.transform = "translate(-50%, -50%) rotate(30deg)";
			watermark.style.top = "50%";
			watermark.style.left = "50%";
			watermark.style.width = "297mm";
			page.appendChild(watermark);
			const pageHeight = getHeight(page);
			let usedPageHeight = 96;
			let childrenToRemove = 0;

			let footer = document.createElement("div");
			footer.classList.add("tac", "fw600", "df", "fdc", "jcfe", "h100");
			footer.innerText = "Page " + i
			let footerHeight = 8;

			if (i > 1) {
				const header = document.createElement("img");
				header.classList.add("db", "ma", "h3rem");
				header.innerText = "Page " + i
				page.appendChild(header);

				titleHeading(page);
			}
			for (const child of children) {
				const childHeight = getHeight(child);
				if (usedPageHeight + childHeight + footerHeight < pageHeight || childHeight > pageHeight - 96) {
					child.classList.add("pr");
					page.appendChild(child.cloneNode(true));
					usedPageHeight += childHeight;
					childrenToRemove++;
				} else {
					page.classList.add("df", "fdc");
					page.appendChild(footer);
					break;
				}
			}
			if (childrenToRemove == 0) {
				console.log("Remove", children.length, childrenToRemove);
				break;
			}
			children.splice(0, childrenToRemove);
		}
		wrapper.classList.remove("op0", "pen");
		document.getElementById("pdfContent").classList.remove("a4", "p3rem");

		let contentBtn = document.querySelectorAll("#pdf button");
		for (let j = 0; j < contentBtn.length; j++) {
			if (contentBtn[j]['id'] == 'bio') contentBtn[j].addEventListener('click', () => dispatch(toggleModal({ open: 'investorBio' })));
			if (contentBtn[j]['id'] == 'edit-details') contentBtn[j].addEventListener('click', () => { setIsEditDeatils(false), dispatch(toggleModal({ open: 'editDetails' })) });
			if (contentBtn[j]['id'] == 'view-contact') contentBtn[j].addEventListener('click', () => handleAcceptReject('Accepted'));
			if (contentBtn[j]['id'] == 'contact-details') contentBtn[j].addEventListener('click', () => dispatch(toggleModal({ open: 'companyContactDetails' })));
			if (contentBtn[j]['id'] == 'manage-file') contentBtn[j].addEventListener('click', () => dispatch(toggleModal({ open: 'manageFiles' })));
			if (contentBtn[j]['id'] == 'noLinkedIn') contentBtn[j].addEventListener('click', () => dispatch(toggleModal({ open: 'noLinkedIn' })));
			if (contentBtn[j]['id'] == 'investorLinkedIn') contentBtn[j].addEventListener('click', (e) => openWindow(`https://${e.target.id}`));
			if (contentBtn[j]['id'] == 'companyLinkedIn') contentBtn[j].addEventListener('click', (e) => openWindow(e.target.id));
			if (contentBtn[j]['id'] == 'diligence-report') contentBtn[j].addEventListener('click', (e) => { localStorage.setItem('isDiligenceReport', true); setTab(e.target.id) });
			if (contentBtn[j]['id'] == 'director-diligence-report') contentBtn[j].addEventListener('click', () => dispatch(toggleModal({ open: 'showDirectors' })));
			if (contentBtn[j]['id'] == 'readmore-description') contentBtn[j].addEventListener('click', () => dispatch(toggleModal({ open: 'readMoreDescription' })));
			if (contentBtn[j]['id'] == 'readmore-criteria') contentBtn[j].addEventListener('click', () => dispatch(toggleModal({ open: 'readMoreCriteria' })));
			if (contentBtn[j]['id'] == 'readmore-company-description') contentBtn[j].addEventListener('click', () => dispatch(toggleModal({ open: 'readMoreCompanyDescription' })));
			if (contentBtn[j]['id'] == 'copy') contentBtn[j].addEventListener('click', (e) => {
				navigator.clipboard.writeText(e.target.id);
				showMessage('Text Copied!');
			});
		}
	});

	useEffect(() => {
		if (isReload) location.reload();
	}, [location.href]);

	useEffect(() => {
		if (Object.keys(fitMetrics).length > 0) {
			if (fitMetrics['leadStatus'] == 'Accepted') setIsBlurContact(false);
			else setIsBlurContact(true);
		}

		let isDiligenceReport = localStorage.getItem('isDiligenceReport');
		if (isDiligenceReport) {
			dispatch(toggleModal({ open: '' }));
			localStorage.removeItem('isDiligenceReport');
			window.scrollTo(0, 5000);
		}
	}, [fitMetrics]);

	const handleInvestorLeads = async () => {
		await getFitInvestorFirms();
		setIsReload(true);
	};

	const getFitReport = async (investorId, scrollTo) => {
		let response = await getRequest(`/company/getInvestorFirm/${investorId}`);
		if (response['code'] == 200) {
			await setInvestorFirm(response['body']);
			if (scrollTo !== '') document.getElementById(scrollTo).scrollIntoView();
		}
	};

	const getFitMetrics = async (clientId, investorId, scrollTo) => {
		let response = await postRequest(`/company/getFitMetrics`, { clientId, investorId });
		if (response['code'] == 200) {
			await setFitMetrics(response['body']);
			if (scrollTo !== '') document.getElementById(scrollTo).scrollIntoView();
		}
	};

	const handleAcceptReject = (status) => {
		setLeadStatus(status);
		dispatch(toggleModal({ open: 'clientAcceptReject' }));
	};

	const sendLeadEmail = async (status) => {
		let clientId = (location.href.split('#')[0]).split('/')[4];
		let investorId = (location.href.split('#').slice(-1)[0]).split(':').slice(-1)[0];
		const data = {
			investorId,
			investorName: investorFirm['cName'],
			clientId,
			clientName: company['cName'],
			contactId: company?.['creatorId']?.['_id'],
			contactName: `${company?.['creatorId']?.['firstName']} ${company?.['creatorId']?.['lastName']}`,
			email: company?.['creatorId']?.['email'],
			status,
		};

		if (clientId && investorId && investorFirm && company && company?.['creatorId']) {
			let res = await postRequest(`/company/sendLeadEmail`, data);
			if (res['code'] == 200) {
				if (status == 'Accepted') setIsBlurContact(false);
				else setIsBlurContact(true);
				if (clientId && investorId) getFitMetrics(clientId, investorId, '');
				showMessage(res['message']);
				dispatch(toggleModal({ open: '' }));
			}
		}
	};

	const titleHeading = (page) => {
		const title = document.createElement("span");
		title.classList.add("db", "ma", "mb1-75rem", "fw700", "fs1-25rem", "tac", "c6");
		title.innerText = 'Fit Report' + ' - ' + `${user['role'] == "Investor" ? investorFirm['cName'] : company['cName']}`;
		page.appendChild(title);
	};

	const downloadPDF = async () => {
		try {
			const wrapper = document.getElementById("pdf");
			wrapper.classList.remove("gg2rem");
			for (const page of wrapper.children)
				page.classList.remove("shadow");
			for (const hideEl of wrapper.querySelectorAll(".hideOnDownload"))
				hideEl.classList.add("op0");
			const filename = 'Fit-Report';
			const opt = { filename, jsPDF: { unit: 'mm', format: 'a4', orientation: 'p' } };
			await html2pdf().set(opt).from(wrapper).save();
			wrapper.classList.add("gg2rem");
			for (const page of wrapper.children)
				page.classList.add("shadow");
			for (const hideEl of wrapper.querySelectorAll(".hideOnDownload"))
				hideEl.classList.remove("op0");
		} catch (e) { console.error(e); }
	};

	const openWindow = (url) => {
		if (window.innerWidth <= 640) {
			var a = document.createElement('a');
			a.setAttribute("href", url);
			a.setAttribute("target", "_blank");

			var dispatch = document.createEvent("HTMLEvents");
			dispatch.initEvent("click", true, true);

			a.dispatchEvent(dispatch);
		}
		else {
			var width = window.innerWidth * 0.8;
			var height = width * window.innerHeight / window.innerWidth;
			window.open(url, 'newwindow', 'width=' + width + ', height=' + height + ', top=' + ((window.innerHeight - height) / 2) + ', left=' + ((window.innerWidth - width) / 2));
		}
		return false;
	};

	const getOverviewContent = () => {
		return <div className="minh100vh">
			<h2 className="w200 tac c6 lh2 fs1-75rem mb1rem">{module?.heading + ' Fit Report' + ' - ' + `${user['role'] == "Investor" ? investorFirm['cName'] : company['cName']}`}</h2>
			<div className="mt0-5rem mr1rem dg gg1rem minh100vh-report">
				<div className="h100 df fdc jcsb aic my1rem">
					<div className="logo-report">
						<img className="h2rem" src={require(`../../../../images/Dg-logo.png`)} alt="image" />
					</div>
				</div>
				<div className="report-start">
					<div className="dg gtcr2a gg2rem mb1-3rem">
						<div className="h100 df fdc jcsb aic">
							<div>
								<p className="ass fs1rem mb0-5rem">Company Name</p>
								<h2 className="fs1rem fw700 lh1 ttu usn c6">{company['cName']}</h2>
								{company['testType'] &&
									<>
										<p className="ass fs1rem mt0-5rem mb0-5rem">Test Type</p>
										<h2 className="fs1rem fw700 lh1 ttu usn c6">{company['testType']}</h2>
									</>
								}
							</div>
						</div>
						<div>
							<ul className="right-list">
								{[["Subscription", ((company?.['creatorId']?.['createdBy']?.['role'] == 'Advisor' || company?.['creatorId']?.['createdBy']?.['role'] == 'Investor') && company['subscription'] == 'Pro') ? 'Portfolio PRO' : <>{company['subscription']?.toUpperCase()}</>],
								["Company Reg", company.ukRegNo], ["Issue Date", moment().format("MMM Do YYYY")], ["Generated By", user.email]].map((arr, idx) =>
									<li key={idx}>{arr[0]} <span>{arr[1]}</span></li>
								)}

							</ul>
						</div>
					</div>
					<div className="df fdc aic pr">
						<img className="shadow mb1rem h5rem w5rem br100 p0-5rem" src={(user['role'] == "Investor" ? company?.['logo'] : investorFirm?.['logo']) || require(`../../../../images/profile-photo.jpeg`)} alt="image" />
						<p className="lh2 fs1-25rem cp">{user['role'] == "Investor" ? company?.['cName'] : investorFirm?.['cName']}</p>
					</div>
					<div className={`progress-area-report progress-fit-report`}>
						<div className='progress-area' style={{ display: 'flex', position: 'relative', justifyContent: 'space-between', alignItems: 'center' }}>
							<div className='progress-box'>
								<div className="progress-bar" style={{ width: 60, height: 60, backgroundColor: '#ebeeff', borderRadius: '50%' }}>
									<CircularProgressbar
										className={`${module.stats.completionPercent > 67 ? 'green'
											: module.stats.completionPercent > 33 ? 'orange'
												: 'red'}`}
										value={module.stats.completionPercent || 0}
										text={(module.stats.completionPercent || 0) + "%"}
										styles={buildStyles({
											strokeLinecap: 'round',
											strokeWidth: '5px',
											textSize: '25px',
											pathTransitionDuration: 0.5,
											trailColor: '#d6d6d6',
											backgroundColor: '#fc7785',
											dominantBaseline: 'ideographic',
										})}
									/>
								</div>
								<span style={{ marginBottom: '0', fontSize: '14px' }} className="text-center-area">Completeness</span>
							</div>
							<div className='progress-box'>
								<div className="progress-bar" style={{ width: 60, height: 60, backgroundColor: '#ebeeff', borderRadius: '50%' }}>
									<CircularProgressbar
										className={`${fitMetrics['fitScorePercentage'] > 67 ? 'green'
											: fitMetrics['fitScorePercentage'] > 33 ? 'orange'
												: 'red'}`}
										value={fitMetrics['fitScorePercentage'] || 0}
										text={(fitMetrics['fitScorePercentage'] || 0) + "%"}
										styles={buildStyles({
											strokeLinecap: 'round',
											strokeWidth: '5px',
											textSize: '25px',
											pathTransitionDuration: 0.5,
											trailColor: '#d6d6d6',
											backgroundColor: '#fc7785',
											dominantBaseline: 'ideographic',
										})}
									/>
								</div>
								<span style={{ marginBottom: '0', fontSize: '14px' }} className="text-center-area">Fit Score</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="over-content-btn" className="btn-group fit-btn-group mt2rem">
				{sectionTab.map((tab, idx) => {
					return <button id={`content-btn-${idx}`} value={tab} data-scroll-to={tab} className={`df c6 jcc ttc td250 c3-hover px1rem py0-5rem cp new-report-btn reports-btn-area mb0-75rem`}>
						<span className="pen usn ass mr0-5rem tac fs1rem">{tab}</span>
					</button>
				})}
			</div>
		</div>
	};

	const sortQuestions = (a, b) => {
		let valueA = a.qNo.split('.')[a.qNo.split('.').length - 1]
		let valueB = b.qNo.split('.')[a.qNo.split('.').length - 1]

		return valueA - valueB;
	};

	const handleCopyClipboard = async (text) => {
		await navigator.clipboard.writeText(text);
		showMessage('Text Copied!');
	};

	// Get About Investor Content
	const getAboutInvestorContent = () => {
		return <>
			<div>
				<div className="mt1rem" id="About the Investor">
					<h2 className="df jcc aic mb1rem fs1-5rem">
						About the Investor
					</h2>
				</div>
				<div className="report-start invest-reports">
					<div className={`progress-area-report`}>
						<div className="pr bg1 p0-5rem shadow df fww aic mb1rem w80 mla mra br8px">
							<div className={`user-area w30`}>
								<div className="logo-box df jcc fww w100 mb0-5rem pt1rem">
									{investorFirm?.['source']?.['isBVCA'] && <img className="w30 mr0-5rem" src={require(`../../../../images/bvca-logo.png`)} alt="image" />}
									{investorFirm?.['source']?.['isCrunchBase'] && <img className="w30 mr0-5rem" src={require(`../../../../images/crunchbase-logo.png`)} alt="image" />}
									{investorFirm?.['source']?.['isSeedLegals'] && <img className="w2rem " src={require(`../../../../images/swwdleagals.png`)} alt="image" />}
								</div>
							</div>
							<div className={`user-area w40`}>
								<div className="logo-box df jcc fww w100 mb0-5rem pt1rem">
									<img className="shadow h4rem w4rem br100 mb1rem" src={investorFirm?.['logo'] || require(`../../../../images/profile-photo.jpeg`)} alt="image" />
									<a href={investorFirm['cWebsite']} target="_blank" className="user-text-fit db tac w100 jcc lh2 fs1rem tdu c3-hover cp my0-5rem">{investorFirm?.['cName']}</a>
								</div>
							</div>
							<div className='progress-box w30'>
								<div className="progress-bar" style={{ width: 40, height: 40 }}>
									<CircularProgressbar
										className={`${investorFirm?.['promptScore'] > 67 ? 'green'
											: investorFirm?.['promptScore'] > 33 ? 'orange'
												: 'red'}`}
										value={investorFirm?.['promptScore'] || 0}
										text={(investorFirm?.['promptScore'] || 0) + "%"}
										styles={buildStyles({
											strokeLinecap: 'round',
											strokeWidth: '5px',
											textSize: '20px',
											pathTransitionDuration: 0.5,
											trailColor: '#d6d6d6',
											backgroundColor: '#fc7785',
											dominantBaseline: 'ideographic',
										})}
									/>
								</div>
								<span style={{ marginBottom: '0', fontSize: '10px' }} className="text-center-area">Prompt Response Score</span>
							</div>
						</div>
						<div className='progress-area dg gtcr2a gg1rem mb1-3rem'>
							<div className="h100 df fdc jcsb aic">
								<ul className="list-company-detail">
									<li>
										<p className="ass fs0-75rem">Full Company Name: </p>
										<h2 className="fs0-75rem fw700 lh1 usn c6">{investorFirm['cName'] ? investorFirm['cName'] : '-'}</h2>
									</li>
									<li>
										<p className="ass fs0-75rem">Address: </p>
										<h2 className="fs0-75rem fw700 lh1 usn c6">{investorFirm['cAddress'] ? investorFirm['cAddress'] : '-'}</h2>
									</li>
									<li>
										<p className="ass fs0-75rem">Website: </p>
										<h2 className="fs0-75rem fw700 lh1 usn c6">{investorFirm['cWebsite']
											? <a className="tdu wordbreak" href={investorFirm['cWebsite']} target="_blank">{investorFirm['cWebsite']}</a>
											: '-'}</h2>
									</li>
									<li>
										<p className="df aic ass fs0-75rem tar">Tel:
											<h2 className="fs0-75rem fw700 lh1 usn c6 tar ml0-5rem">{(investorFirm['cPhone'] && investorFirm['cPhone'] !== '?')
												? <>{investorFirm?.['cPhone']} <button class="cp bg1" id='copy'><i id={investorFirm?.['cPhone']} class="fa fa-clipboard c4" style={{ fontSize: '12px' }}></i></button></>
												: '-'}</h2>
										</p>
									</li>
									<li>
										<p className="ass fs0-75rem">Business Location: </p>
										<h2 className="fs0-75rem fw700 lh1 usn c6">{investorFirm?.['cCountry'] ? investorFirm?.['cCountry'] : '-'}</h2>
									</li>
									<li>
										<p className="ass fs0-75rem">Funds for Investment: </p>
										<h2 className="fs0-75rem fw700 lh1 usn c6">{fitMetrics?.['fundsAvailable']?.['firmValue'] ? fitMetrics?.['fundsAvailable']?.['firmValue'] : '-'}</h2>
									</li>
									<li>
										<p className="ass fs0-75rem">No. of Investments made: </p>
										<h2 className="fs0-75rem fw700 lh1 usn c6">{fitMetrics?.['noOfInvestments']?.['firmValue'] ? fitMetrics?.['noOfInvestments']?.['firmValue'] : '-'}</h2>
									</li>
									<li>
										<p className="ass fs0-75rem">Date of last Investment: </p>
										<h2 className="fs0-75rem fw700 lh1 usn c6">{fitMetrics?.['lastInvestmentDate']?.['firmValue'] ? fitMetrics?.['lastInvestmentDate']?.['firmValue'] : '-'}</h2>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="description-box mt1rem mb1rem pr bg1 p1rem shadow df fww aic br8px">
					<h4 className="fs1rem mb0-75rem">Description</h4>
					<><p className="fs0-75rem">
						{investorFirm['description'] ? (
							investorFirm['description'].length > 800
								? <>{investorFirm['description'].substring(0, 800)}... <button id="readmore-description" className="cp tdu fs0-75rem fw600 bg1">read more</button></>
								: investorFirm['description']
						) : investorFirm['companyDescription'] ? (
							investorFirm['companyDescription'].length > 800
								? <>{investorFirm['companyDescription'].substring(0, 800)}... <button id="readmore-description" className="cp tdu fs0-75rem fw600 bg1">read more</button></>
								: investorFirm['companyDescription']
						) : '-'}
					</p></>
				</div>
				<div className="description-box mt1rem mb1rem pr bg1 p1rem shadow df fww aic br8px">
					<h4 className="fs1rem mb0-75rem">Investment Criteria</h4>
					<><p className="fs0-75rem">
						{investorFirm['investmentCriteria']
							? investorFirm['investmentCriteria'].length > 800
								? <>{investorFirm['investmentCriteria'].substring(0, 800)}... <button id="readmore-criteria" className="cp tdu fs0-75rem fw600 bg1">read more</button></>
								: investorFirm['investmentCriteria']
							: '-'}
					</p></>
				</div>
				{user['role'] == "Investor" &&
					<div className="df jcfe">
						<button id='edit-details' className="shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu bsbb tac fs0-75rem">Edit Details?</button>
					</div>
				}
				<div className="report-start invest-reports">
					<div className={`progress-area-report`}>
						<div className={`pr bg1 p0-5rem shadow df fww aic mb1rem w50 mla mra br8px`}>
							<div className={`user-area w20 ${(user['role'] !== 'Investor' && isBlurContact) ? ' blur-disabled' : ''}`}>
								<div className="user-img">
									<img className=" h4rem w4rem br100" src={investorFirm?.['contact']?.['image'] || require(`../../../../images/profile-photo.jpeg`)} alt="image" />
								</div>
							</div>
							<div className={`progress-box w50 ${(user['role'] !== 'Investor' && isBlurContact) ? ' blur-disabled' : ''}`}>
								<div className="text-box tal">
									<h4 className="fs0-75rem df">
										<p>{investorFirm?.['contact']?.['firstName'] ? investorFirm?.['contact']?.['firstName'] + ' ' + investorFirm?.['contact']?.['lastName'] : 'Name'}</p>
										<button id={investorFirm?.['contact']?.['linkedIn'] ? 'investorLinkedIn' : 'noLinkedIn'} className="icon ml0-5rem bg1 cp" style={{ width: '22px' }}>
											<i id={investorFirm?.['contact']?.['linkedIn']}><svg id={investorFirm?.['contact']?.['linkedIn']} version="1.1" className="f3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310">
												<g>
													<path d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
													C77.16,101.969,74.922,99.73,72.16,99.73z"/>
													<path d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
													c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"/>
													<path d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
													c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
													c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
													C310,145.43,300.549,94.761,230.454,94.761z"/>
												</g>
											</svg></i>
										</button>
									</h4>
									<p className="fs0-75rem c6">Phone: {investorFirm?.['contact']?.['phone']
										? <>{investorFirm?.['contact']?.['phone']} <button class="cp bg1" id='copy'><i id={investorFirm?.['contact']?.['phone']} class="fa fa-clipboard c4" style={{ fontSize: '12px' }}></i></button></>
										: '-'}</p>
								</div>
							</div>
							<div className="button-list w30">
								<button id='bio' className="shadow bg3 bg4-hover c1 td250 br5px py0-5rem px2rem fw600 usn cp ttu bsbb tac df jcc aic mla mra fs0-75rem">Bio</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	};

	// Get About Company Content
	const getAboutCompanyContent = () => {
		return <>
			<div>
				<div className="mt1rem" id="About the Company">
					<h2 className="df jcc aic mb1rem fs1-5rem">
						About the Company
					</h2>
				</div>
				<div className="report-start invest-reports">
					<div className={`progress-area-report`}>
						<div className='progress-area dg gtcr2a gg1rem mb0-75rem'>
							<div className="h100 df fdc jcsb aic">
								<img className="shadow h4rem w4rem br100 mb1rem" src={company?.['logo'] || require(`../../../../images/profile-photo.jpeg`)} alt="image" />
								<ul className="list-company-detail">
									<li>
										<p className="ass fs0-75rem">Company Name: </p>
										<h2 className="fs0-75rem fw700 lh1 usn c6">{company['cName'] ? company['cName'] : '-'}</h2>
									</li>
									<li>
										<p className="ass fs0-75rem">Address: </p>
										<h2 className="fs0-75rem fw700 lh1 usn c6">{company['cAddress'] ? company['cAddress'] : '-'}</h2>
									</li>
									<li>
										<p className="ass fs0-75rem">Tel: </p>
										<h2 className="fs0-75rem fw700 lh1 usn c6">{company['cPhone']
											? <>{company['cPhone']} <button class="cp bg1" id='copy'><i id={company['cPhone']} class="fa fa-clipboard c4" style={{ fontSize: '12px' }}></i></button></>
											: '-'}</h2>
									</li>
									<li>
										<p className="ass fs0-75rem">Website: </p>
										<h2 className="fs0-75rem fw700 lh1 usn c6">{company['cWebsite']
											? <a className="tdu wordbreak" href={company['cWebsite']} target="_blank">{company['cWebsite']}</a>
											: '-'}</h2>
									</li>
									<li>
										<p className="ass fs0-75rem">Sectors: </p>
										<h2 className="fs0-75rem fw700 lh1 usn c6">{fitMetrics?.['preferredSectors']?.['selectedValue'] ? fitMetrics?.['preferredSectors']?.['selectedValue'] : '-'}</h2>
									</li>
									<li>
										<p className="ass fs0-75rem">Full Time Employees: </p>
										<h2 className="fs0-75rem fw700 lh1 usn c6">{fitMetrics?.['fullTimeEmployees'] ? fitMetrics?.['fullTimeEmployees'] : '-'}</h2>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="description-box my0-5rem pr bg1 p1rem shadow df fww aic br8px">
					<h4 className="fs1rem mb0-75rem">Description </h4>
					<p className="fs0-75rem">
						{company['companyDescription']
							? company['companyDescription'].length > 800
								? <>{company['companyDescription'].substring(0, 800)}... <button id="readmore-company-description" className="cp tdu fs0-75rem fw600 bg1">read more</button></>
								: company['companyDescription']
							: '-'}
					</p>
				</div>
				<div className="report-start mt1-5rem">
					<div className={`dg gtcr-audit fs0-75rem bg6 c1 gtcr-audit-fit-report-about`}>
						<h3 className="tac"></h3>
						<h3 className="tac">Actual Previous Year</h3>
						<h3 className="tac">Expected Current Year</h3>
						<h3 className="tac">Forcast Next Year</h3>
					</div>
					<div className={`dg tableRow  fs0-75rem gtcr-audit-fit-report-about`}>
						<div className={`df fdc aic jcfs pt0-25rem`}>Revenues</div>
						<div className={`df fdc aic jcfs pt0-25rem`}>{fitMetrics?.['previousRevenues'] ? fitMetrics?.['previousRevenues'] : '-'}</div>
						<div className={`df fdc aic jcfs pt0-25rem`}>{fitMetrics?.['annualRevenues']?.['selectedValue'] ? fitMetrics?.['annualRevenues']?.['selectedValue'] : '-'}</div>
						<div className={`df fdc aic jcfs pt0-25rem`}>{fitMetrics?.['nextRevenues'] ? fitMetrics?.['nextRevenues'] : '-'}</div>
					</div>
					<div className={`dg tableRow  fs0-75rem gtcr-audit-fit-report-about`}>
						<div className={`df fdc aic jcfs pt0-25rem`}>Profits/(Loss)</div>
						<div className={`df fdc aic jcfs pt0-25rem`}>{fitMetrics?.['previousProfits'] ? fitMetrics?.['previousProfits'] : '-'}</div>
						<div className={`df fdc aic jcfs pt0-25rem`}>{fitMetrics?.['annualProfits']?.['selectedValue'] ? fitMetrics?.['annualProfits']?.['selectedValue'] : '-'}</div>
						<div className={`df fdc aic jcfs pt0-25rem`}>{fitMetrics?.['nextProfits'] ? fitMetrics?.['nextProfits'] : '-'}</div>
					</div>
				</div>
				<div className="pr bg1 p1rem shadow df fww aic mt1rem w80 mla mra br8px">
					<div className={`user-area tac w100 ${(user['role'] == 'Investor' && isBlurContact) ? ' blur-disabled' : ''}`}>
						<div className="user-img">
							<img className="h4rem w4rem br100" src={company?.['creatorId']?.['profile'] || require(`../../../../images/profile-photo.jpeg`)} alt="image" />
						</div>
						<div className="text-box tac">
							<h4 className="fs0-75rem df jcc">
								<span>{company?.['creatorId']?.['firstName']} {company?.['creatorId']?.['lastName']}</span>
								<button id={company?.['creatorId']?.['linkedIn'] ? 'companyLinkedIn' : 'noLinkedIn'} className="icon ml0-5rem bg1 cp" style={{ width: '22px' }}>
									<i id="icon"><svg id={company?.['creatorId']?.['linkedIn']} version="1.1" className="f3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310">
										<g>
											<path d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
													C77.16,101.969,74.922,99.73,72.16,99.73z"/>
											<path d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
													c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"/>
											<path d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
													c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
													c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
													C310,145.43,300.549,94.761,230.454,94.761z"/>
										</g>
									</svg></i>
								</button>
							</h4>
							<button id='contact-details' className="shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu bsbb tac df jcc aic mla mra w40 fs0-75rem">Contact Details</button>
						</div>
					</div>
					{(user['role'] == 'Investor' && isBlurContact) && (
						<button id='view-contact' className="pa t0 r0 mr1rem mt1rem view-btn-fit" style={{ backgroundColor: "transparent" }}>
							<i
								data-tooltip='<p  className="fs0-75rem">Note: By clicking this button, lead requested by this client will be accepted.</p>'
								class='fa fa-eye c8 cp'
								style={{ fontSize: '20px' }}
							/>
							<span className="fs0-75rem">Contact Details</span>
						</button>
					)}
				</div>
			</div>
		</>
	};

	// Get Fit Metrics Content
	const getFitMetricsContent = () => {
		return <>
			<div>
				<div className="mt1rem df jcc aic fdc" id="Fit Metrics">
					<h2 className="mb1rem fs1-5rem">Fit Metrics</h2>
					<img className="shadow mb0-5rem p0-5rem h4rem w4rem br100" src={investorFirm?.['logo'] || require(`../../../../images/profile-photo.jpeg`)} alt="image" />
					<p className="lh2 fs1-25rem cp">{investorFirm?.['cName']}</p>
				</div>
				<div className='progress-box pa' style={{ right: '10px', top: '15%' }}>
					<div className="progress-bar" style={{ width: 40, height: 40, backgroundColor: '#ebeeff', borderRadius: '50%' }}>
						<CircularProgressbar
							className={`${fitMetrics['fitScorePercentage'] > 67 ? 'green'
								: fitMetrics['fitScorePercentage'] > 33 ? 'orange'
									: 'red'}`}
							value={fitMetrics['fitScorePercentage'] || 0}
							text={(fitMetrics['fitScorePercentage'] || 0) + "%"}
							styles={buildStyles({
								strokeLinecap: 'round',
								strokeWidth: '5px',
								textSize: '25px',
								pathTransitionDuration: 0.5,
								trailColor: '#d6d6d6',
								backgroundColor: '#fc7785',
								dominantBaseline: 'ideographic',
							})}
						/>
					</div>
					<span style={{ marginBottom: '0', fontSize: '14px' }} className="text-center-area">Fit Score</span>
				</div>
				<div className="report-start fitmetrics-reports pr mt2rem mb1rem pr bg1 px1rem py2rem shadow df fww aic br8px">
					<div className="matrics-box dg gtcr5a gg0-5rem style-two mb1rem">
						<p></p>
						<div className="box-text"><strong className="fs1rem">Preferred Investment <br /> Criteria</strong></div>
						<i class="icon"></i>
						<div className="box-text"><strong className="fs1rem">Company Matching <br /> Information</strong></div>
						<h4>Fit</h4>
					</div>
					<div className="matrics-box dg gtcr5a gg0-5rem style-three">
						<p>Preferred Sectors:</p>
						<div className="box-text">{fitMetrics?.['preferredSectors']?.['firmValue'] ? fitMetrics?.['preferredSectors']?.['firmValue'] : '-'}</div>
						<i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg></i>
						<div className="box-text">{fitMetrics?.['preferredSectors']?.['selectedValue'] ? fitMetrics?.['preferredSectors']?.['selectedValue'] : '-'}</div>
						<i class={`fa fa-circle ${(fitMetrics?.['preferredSectors']?.['percentage']) > 0 ? 'green' : 'red'}`}></i>
					</div>
					<div className="matrics-box dg gtcr5a gg0-5rem style-three">
						<p>Preferred Investment Stage:</p>
						<div className="box-text">{fitMetrics?.['stageFocus']?.['firmValue'] ? fitMetrics?.['stageFocus']?.['firmValue'] : '-'}</div>
						<i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg></i>
						<div className="box-text">{fitMetrics?.['stageFocus']?.['selectedValue'] ? fitMetrics?.['stageFocus']?.['selectedValue'] : '-'}</div>
						<i class={`fa fa-circle ${(fitMetrics?.['stageFocus']?.['percentage']) > 0 ? 'green' : 'red'}`}></i>
					</div>
					<div className="matrics-box dg gtcr5a gg0-5rem style-three">
						<p>Investment Sweet Spot:</p>
						<div className="box-text">{fitMetrics?.['sweetSpot']?.['firmValue'] ? fitMetrics?.['sweetSpot']?.['firmValue'] : '-'}</div>
						<i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg></i>
						<div className="box-text">{fitMetrics?.['sweetSpot']?.['selectedValue'] ? fitMetrics?.['sweetSpot']?.['selectedValue'] : '-'}</div>
						<i class={`fa fa-circle ${(fitMetrics?.['sweetSpot']?.['percentage']) > 70 ? 'green' : (fitMetrics?.['sweetSpot']?.['percentage']) > 30 ? 'orange' : 'red'}`}></i>
					</div>
					<div className="matrics-box dg gtcr5a gg0-5rem style-three">
						<p>Minimum Annual Revenues:</p>
						<div className="box-text">{fitMetrics?.['annualRevenues']?.['firmValue'] ? fitMetrics?.['annualRevenues']?.['firmValue'] : '-'}</div>
						<i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg></i>
						<div className="box-text">{fitMetrics?.['annualRevenues']?.['selectedValue'] ? fitMetrics?.['annualRevenues']?.['selectedValue'] : '-'}</div>
						<i class={`fa fa-circle ${(fitMetrics?.['annualRevenues']?.['percentage']) > 70 ? 'green' : (fitMetrics?.['annualRevenues']?.['percentage']) > 30 ? 'orange' : 'red'}`}></i>
					</div>
					<div className="matrics-box dg gtcr5a gg0-5rem style-three">
						<p>Minimum Annual Profits:</p>
						<div className="box-text">{fitMetrics?.['annualProfits']?.['firmValue'] ? fitMetrics?.['annualProfits']?.['firmValue'] : '-'}</div>
						<i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg></i>
						<div className="box-text">{fitMetrics?.['annualProfits']?.['selectedValue'] ? fitMetrics?.['annualProfits']?.['selectedValue'] : '-'}</div>
						<i class={`fa fa-circle ${(fitMetrics?.['annualProfits']?.['percentage']) > 70 ? 'green' : (fitMetrics?.['annualProfits']?.['percentage']) > 30 ? 'orange' : 'red'}`}></i>
					</div>
					<div className="tac w100">
						<button id='manage-file' class="shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu bsbb tac df jcc aic mla mra fs0-75rem mt2rem">Click here to view pitch deck</button>
					</div>
				</div>
			</div>
		</>
	};

	// Get Diligence Reports
	const getDiligenceReports = () => {
		return <>
			<div>
				<div className="mt1rem" id="Diligence Reports">
					<h2 className="df jcc aic mb1rem fs1-5rem">Diligence Reports</h2>
				</div>
				<div className="report-start fitmetrics-reports">
					<table className="reports-table">
						<thead>
							<tr>
								<th>
									<p className="cp h50px">Repository</p>
									{/* Completeness */}
									<div className='progress-box mb0-5rem'>
										<div className="progress-bar" style={{ width: 30, height: 30, backgroundColor: '#ebeeff', borderRadius: '50%' }}>
											<CircularProgressbar
												className={`${company?.['moduleStats']?.['repository']?.['stats']?.['completionPercent'] > 67 ? 'green'
													: company?.['moduleStats']?.['repository']?.['stats']?.['completionPercent'] > 33 ? 'orange'
														: 'red'}`}
												value={company?.['moduleStats']?.['repository']?.['stats']?.['completionPercent'] || 0}
												text={(company?.['moduleStats']?.['repository']?.['stats']?.['completionPercent'] || 0) + "%"}
												styles={buildStyles({
													strokeLinecap: 'round',
													strokeWidth: '5px',
													textSize: '25px',
													pathTransitionDuration: 0.5,
													trailColor: '#d6d6d6',
													backgroundColor: '#fc7785',
													dominantBaseline: 'ideographic',
												})}
											/>
										</div>
										<span className="tac fw500" style={{ fontSize: '10px' }}>Completeness</span>
									</div>
									{company?.['modules']?.['repository'] && company?.['modules']?.['repository']?.['stats']?.['completionPercent'] > 1 &&
										<button id='diligence-report' className="cp w2rem h2rem df jcc aic br100 bg3 bg4-hover c1 report-btn-r" style={{ left: '10%', top: '22%' }}><span id='repository-report'>R</span></button>
									}
								</th>
								<th>
									<p className="cp h50px">Business Audit</p>
									<div className="df jcsb mb0-5rem">
										{/* Completeness */}
										<div className='progress-box'>
											<div className="progress-bar" style={{ width: 30, height: 30, backgroundColor: '#ebeeff', borderRadius: '50%' }}>
												<CircularProgressbar
													className={`${company?.['moduleStats']?.[`business-audit-${company['testType']}`]?.['stats']?.['completionPercent'] > 67 ? 'green'
														: company?.['moduleStats']?.[`business-audit-${company['testType']}`]?.['stats']?.['completionPercent'] > 33 ? 'orange'
															: 'red'}`}
													value={company?.['moduleStats']?.[`business-audit-${company['testType']}`]?.['stats']?.['completionPercent'] || 0}
													text={(company?.['moduleStats']?.[`business-audit-${company['testType']}`]?.['stats']?.['completionPercent'] || 0) + "%"}
													styles={buildStyles({
														strokeLinecap: 'round',
														strokeWidth: '5px',
														textSize: '25px',
														pathTransitionDuration: 0.5,
														trailColor: '#d6d6d6',
														backgroundColor: '#fc7785',
														dominantBaseline: 'ideographic',
													})}
												/>
											</div>
											<span className="tac fw500" style={{ fontSize: '10px' }}>Completeness</span>
										</div>
										{/* Risk */}
										<div className='progress-box'>
											<div className="progress-bar" style={{ width: 30, height: 30, backgroundColor: '#ebeeff', borderRadius: '50%' }}>
												<CircularProgressbar
													className={`${company?.['moduleStats']?.[`business-audit-${company['testType']}`]?.['stats']?.['riskPercent'] > 67 ? 'red'
														: company?.['moduleStats']?.[`business-audit-${company['testType']}`]?.['stats']?.['riskPercent'] > 33 ? 'orange'
															: 'green'}`}
													value={company?.['moduleStats']?.[`business-audit-${company['testType']}`]?.['stats']?.['riskPercent'] || 0}
													text={(company?.['moduleStats']?.[`business-audit-${company['testType']}`]?.['stats']?.['riskPercent'] || 0) + "%"}
													styles={buildStyles({
														strokeLinecap: 'round',
														strokeWidth: '5px',
														textSize: '25px',
														pathTransitionDuration: 0.5,
														trailColor: '#d6d6d6',
														backgroundColor: '#fc7785',
														dominantBaseline: 'ideographic',
													})}
												/>
											</div>
											<span className="tac fw500" style={{ fontSize: '10px' }}>Risk</span>
										</div>
									</div>
									{company?.['modules']?.[`business-audit-${company['testType']}`] && company?.['modules']?.[`business-audit-${company['testType']}`]?.['stats']?.['completionPercent'] > 0 &&
										<button id='diligence-report' className="cp w2rem h2rem df jcc aic br100 bg3 bg4-hover c1 report-btn-r" style={{ left: '30%', top: '22%' }}><span id={`business-audit-${company['testType']}-report`}>R</span></button>
									}
								</th>
								<th>
									<p className="cp h50px">Directors' Questionnaire</p>
									<div className="df jcsb mb0-5rem">
										{/* Completeness */}
										<div className='progress-box'>
											<div className="progress-bar" style={{ width: 30, height: 30, backgroundColor: '#ebeeff', borderRadius: '50%' }}>
												<CircularProgressbar
													className={`${company?.['directorsStats']?.['completionPercent'] > 67 ? 'green'
														: company?.['directorsStats']?.['completionPercent'] > 33 ? 'orange'
															: 'red'}`}
													value={company?.['directorsStats']?.['completionPercent'] || 0}
													text={(company?.['directorsStats']?.['completionPercent'] || 0) + "%"}
													styles={buildStyles({
														strokeLinecap: 'round',
														strokeWidth: '5px',
														textSize: '25px',
														pathTransitionDuration: 0.5,
														trailColor: '#d6d6d6',
														backgroundColor: '#fc7785',
														dominantBaseline: 'ideographic',
													})}
												/>
											</div>
											<span className="tac fw500" style={{ fontSize: '10px' }}>Completeness</span>
										</div>
										{/* Risk */}
										<div className='progress-box'>
											<div className="progress-bar" style={{ width: 30, height: 30, backgroundColor: '#ebeeff', borderRadius: '50%' }}>
												<CircularProgressbar
													className={`${company?.['directorsStats']?.['riskPercent'] > 67 ? 'red'
														: company?.['directorsStats']?.['riskPercent'] > 33 ? 'orange'
															: 'green'}`}
													value={company?.['directorsStats']?.['riskPercent'] || 0}
													text={(company?.['directorsStats']?.['riskPercent'] || 0) + "%"}
													styles={buildStyles({
														strokeLinecap: 'round',
														strokeWidth: '5px',
														textSize: '25px',
														pathTransitionDuration: 0.5,
														trailColor: '#d6d6d6',
														backgroundColor: '#fc7785',
														dominantBaseline: 'ideographic',
													})}
												/>
											</div>
											<span className="tac fw500" style={{ fontSize: '10px' }}>Risk</span>
										</div>
									</div>
									{company?.['expensions']?.['DEPF007'] &&
										<button id='director-diligence-report' className="cp w2rem h2rem df jcc aic br100 bg3 bg4-hover c1 report-btn-r" style={{ left: '50%', top: '22%' }}><span>R</span></button>
									}
								</th>
								<th>
									<p className="cp h50px">Warranty Disclosure Schedule</p>
									<div className="df jcsb mb0-5rem">
										{/* Completeness */}
										<div className='progress-box'>
											<div className="progress-bar" style={{ width: 30, height: 30, backgroundColor: '#ebeeff', borderRadius: '50%' }}>
												<CircularProgressbar
													className={`${company?.['moduleStats']?.['warranty-disclosure']?.['stats']?.['completionPercent'] > 67 ? 'green'
														: company?.['moduleStats']?.['warranty-disclosure']?.['stats']?.['completionPercent'] > 33 ? 'orange'
															: 'red'}`}
													value={company?.['moduleStats']?.['warranty-disclosure']?.['stats']?.['completionPercent'] || 0}
													text={(company?.['moduleStats']?.['warranty-disclosure']?.['stats']?.['completionPercent'] || 0) + "%"}
													styles={buildStyles({
														strokeLinecap: 'round',
														strokeWidth: '5px',
														textSize: '25px',
														pathTransitionDuration: 0.5,
														trailColor: '#d6d6d6',
														backgroundColor: '#fc7785',
														dominantBaseline: 'ideographic',
													})}
												/>
											</div>
											<span className="tac fw500" style={{ fontSize: '10px' }}>Completeness</span>
										</div>
										{/* Risk */}
										<div className='progress-box'>
											<div className="progress-bar" style={{ width: 30, height: 30, backgroundColor: '#ebeeff', borderRadius: '50%' }}>
												<CircularProgressbar
													className={`${company?.['moduleStats']?.['warranty-disclosure']?.['stats']?.['riskPercent'] > 67 ? 'red'
														: company?.['moduleStats']?.['warranty-disclosure']?.['stats']?.['riskPercent'] > 33 ? 'orange'
															: 'green'}`}
													value={company?.['moduleStats']?.['warranty-disclosure']?.['stats']?.['riskPercent'] || 0}
													text={(company?.['moduleStats']?.['warranty-disclosure']?.['stats']?.['riskPercent'] || 0) + "%"}
													styles={buildStyles({
														strokeLinecap: 'round',
														strokeWidth: '5px',
														textSize: '25px',
														pathTransitionDuration: 0.5,
														trailColor: '#d6d6d6',
														backgroundColor: '#fc7785',
														dominantBaseline: 'ideographic',
													})}
												/>
											</div>
											<span className="tac fw500" style={{ fontSize: '10px' }}>Risk</span>
										</div>
									</div>
									{company?.['modules']?.[`warranty-disclosure`] && company?.['modules']?.[`warranty-disclosure`]?.['stats']?.['completionPercent'] > 0 &&
										<button id='diligence-report' className="cp w2rem h2rem df jcc aic br100 bg3 bg4-hover c1 report-btn-r" style={{ left: '70%', top: '22%' }}><span id='warranty-disclosure-report'>R</span></button>
									}
								</th>
								<th>
									<p className="cp h30px">Certification</p>
									<button id='diligence-report'>
										<img id='unaudited-certification' className="cp w6rem" src={require(`../../../../images/certificate2.png`)} alt="" />
									</button>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<ul class="mt1rem">
										<li>20 section Data Repository e.g. legal, IP, material contracts</li>
										<li>160 subsections</li>
										<li>Checklist aids orderly filing of key documents</li>
										<li>Automated population from UK Companies House</li>
										<li><strong>Diligentsia AI Filing Assistant can reorder existing data rooms into a standard data structure across all your portfolio companies </strong></li>
										<li>Completeness scoring</li>
										<li>Can sync to Drive, Sharepoint, Dropbox</li>
										<li><strong>Completeness scoring </strong> and reporting</li>
									</ul>
								</td>
								<td>
									<ul class="mt1rem">
										<li>Detailed business audit of the 20 sections e.g. legal, IP, material contracts</li>
										<li>AI bespoked to the individual company and industry</li>
										<li>Multiple choice questions to aid quick completion</li>
										<li>At-a-glance problem area identification</li>
										<li><strong>Completeness and risk scoring</strong></li>
										<li>Recommendations for improvement in each area</li>
										<li>Comprehensive reporting with action tracking</li>
										<li>Can be included in a post-investment 100 plan</li>
									</ul>
								</td>
								<td>
									<ul class="mt1rem">
										<li>A pre-investment questionnaire completed by each Director</li>
										<li>Prepopulated from Companies House where possible</li>
										<li>Directors’ CVs, LinkedIn profiles etc.</li>
										<li>Can attach supporting files (automatically added to Data Repositiory)</li>
										<li>KYC info – proof of identity, address, headshot pics etc.</li>
										<li>Comprehensive report</li>
										<li><strong>Completeness and risk scoring</strong></li>
										<li><strong>Early identification of problems </strong></li>
									</ul>
								</td>
								<td>
									<ul class="mt1rem">
										<li>Based on the standard UK BVCA Disclosure Letter</li>
										<li>Takes company through the process of disclosure in advance of application for investment</li>
										<li><strong>Early identification of problems</strong></li>
										<li>Can attach supporting files</li>
										<li>Comprehensive report</li>
										<li><strong>Completeness and risk scoring </strong></li>
									</ul>
								</td>
								<td>
									<ul class="mt1rem">
										<li>UNAUDITED Diligentsia </li>
										<p>certification:</p>
										<ul>
											<li>Bronze – the basics</li>
											<li>Silver – potentially investable</li>
											<li>Gold – sophisticated</li>
										</ul>
										<p className="border-box"><strong>Please note:</strong> “Care should be taken to complete the diligence modules carefully, accurately and completely since your  answers may be reviewed as part of investors’ further due diligence”</p>
										<li>Diligentsia Pro PLUS: AUDITED certification from an advisor – lawyer or accountant</li>
									</ul>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
	};

	// Get Detailed Answers
	const getDetailedAnswers = () => {
		let array = [];
		if (module?.sections[0]?.subFolders && Object.values(module?.sections[0]?.subFolders).length > 0) array = Object.values(module.sections[0].subFolders).filter(s => !s.isHidden);
		return <>
			{array.length > 0 && array.map(section => {
				return (
					<div>
						<div className="mt1rem" id='Detailed Answers'>
							<h2 className="df jcc aic mb1rem fs1rem">
								{section['sectionNo']} - {section['sectionName']} Report &nbsp;-&nbsp;
								{section.stats.completionPercent && section.stats.completionPercent > 0
									? <span className={section.stats.completionPercent < 34 ? "c9" : section.stats.completionPercent < 67 ? 'c8' : 'c7'}>Completed {section.stats.completionPercent || 0}%</span>
									: <span>Not Started</span>
								}
							</h2>
							<div className={`dg gtcr-audit fs0-75rem bg6 c1 gtcr-audit-fit-report`}>
								<h3 className="tac">Question</h3>
								<h3 className="tac">Company’s Answers</h3>
							</div>
						</div>
						{section?.questions.slice().sort(sortQuestions).filter(q => q.answer).map((question, idx) => {
							return (
								<div key={idx} className={`dg tableRow  fs0-75rem gtcr-audit-fit-report`}>
									<div className={`df fdc aic jcfs pt0-25rem`}>{question['qDescription']}</div>
									<div className={`df fdc aic jcfs pt0-25rem`}>
										{question?.['answer']?.['dropdown'] || question?.['answer']?.['radioOption'] || question?.['answer']?.['comment'] || '-'}
									</div>
								</div>
							)
						})}
					</div>
				)
			})}
		</>
	};

	// Get Investor’s Bespoked Questions & Answer
	const getBespokedQA = () => {
		return <>
			<div>
				<div className="mt1rem" id="Investors Bespoked Q/A">
					<h2 className="df jcc aic mb1rem fs1-5rem">
						Investor's Bespoked Questions & Answer
					</h2>
				</div>
				<div className="report-start fitmetrics-reports pr mt1rem mb1rem pr bg1 p1rem shadow df fww aic br8px investor-leads-tooltip">
					<span className="tooltip-area cp" style={{ top: '-10px', right: '-10px' }}>
						<i className="icon">
							<img className="h2rem" src={require(`../../../../images/info-icon.svg`)} alt="" />
							<div className="overlay-box" style={{ left: '-400px' }}>
								<p>These questions & Answers reflect any specific questions and answers that an investor might want to ask applicant companies within the bespoking of  the Lead Generation Wizard which can be attached to an investor’s web site to vet applicant companies.  The questions can be set within the Investor Settings.</p>
							</div>
						</i>
					</span>
					<div className="matrics-box dg gtcr5a gg0-5rem style-two">
						<p><strong className="fs1rem">Investor’s Desired <br /> Questions</strong></p>
						<div className="box-text"><strong className="fs1rem">Investor's Desired <br /> Answer</strong></div>
						<i class="icon"></i>
						<div className="box-text"><strong className="fs1rem">Company's Actual <br /> Answer</strong></div>
						<h4 className="df fdc tac">
							Fit
							<span className="df jcsb dots-fit-report">
								<i class="fa fa-circle green" data-tooltip='<p  className="fs0-75rem">Perfect Match</p>'></i>
								<i class="fa fa-circle orange" data-tooltip='<p  className="fs0-75rem">Average Match</p>'></i>
								<i class="fa fa-circle red" data-tooltip='<p  className="fs0-75rem">No Match</p>'></i>
							</span>
						</h4>
					</div>
					<div className="matrics-box dg gtcr5a gg0-5rem style-three">
						<p>Q: Insert bespoked question 1</p>
						<div className="box-text">A1 Answer: Insert desired answer from company</div>
						<i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg></i>
						<div className="box-text">A1 Answer: ACTUAL answer from company</div>
						<i class="fa fa-circle c12"></i>
					</div>
					<div className="matrics-box dg gtcr5a gg0-5rem style-three">
						<p>Q: Insert bespoked question 1</p>
						<div className="box-text">A1 Answer: Insert desired answer from company</div>
						<i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg></i>
						<div className="box-text">A1 Answer: ACTUAL answer from company</div>
						<i class="fa fa-circle c12"></i>
					</div>
					<div className="matrics-box dg gtcr5a gg0-5rem style-three">
						<p>Q: Insert bespoked question 1</p>
						<div className="box-text">A1 Answer: Insert desired answer from company</div>
						<i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg></i>
						<div className="box-text">A1 Answer: ACTUAL answer from company</div>
						<i class="fa fa-circle c12"></i>
					</div>
					<div className="matrics-box dg gtcr5a gg0-5rem style-three">
						<p>Q: Insert bespoked question 1</p>
						<div className="box-text">A1 Answer: Insert desired answer from company</div>
						<i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg></i>
						<div className="box-text">A1 Answer: ACTUAL answer from company</div>
						<i class="fa fa-circle c12"></i>
					</div>
					<div className="matrics-box dg gtcr5a gg0-5rem style-three">
						<p>Q: Insert bespoked question 1</p>
						<div className="box-text">A1 Answer: Insert desired answer from company</div>
						<i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg></i>
						<div className="box-text">A1 Answer: ACTUAL answer from company</div>
						<i class="fa fa-circle c12"></i>
					</div>
					<div className="matrics-box dg gtcr5a gg0-5rem style-three">
						<p>Q: Insert bespoked question 1</p>
						<div className="box-text">A1 Answer: Insert desired answer from company</div>
						<i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg></i>
						<div className="box-text">A1 Answer: ACTUAL answer from company</div>
						<i class="fa fa-circle c12"></i>
					</div>
					<div className="matrics-box dg gtcr5a gg0-5rem style-three">
						<p>Q: Insert bespoked question 1</p>
						<div className="box-text">A1 Answer: Insert desired answer from company</div>
						<i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg></i>
						<div className="box-text">A1 Answer: ACTUAL answer from company</div>
						<i class="fa fa-circle c12"></i>
					</div>
					<div className="matrics-box dg gtcr5a gg0-5rem style-three">
						<p>Q: Insert bespoked question 1</p>
						<div className="box-text">A1 Answer: Insert desired answer from company</div>
						<i class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right" /></svg></i>
						<div className="box-text">A1 Answer: ACTUAL answer from company</div>
						<i class="fa fa-circle c12"></i>
					</div>
				</div>
			</div>
		</>
	};

	const handleLinkedIn = (linkedIn) => {
		if (linkedIn && linkedIn !== "") {
			if (linkedIn.includes('https')) openWindow(linkedIn);
			else openWindow(`https://${linkedIn}`);
		}
		else dispatch(toggleModal({ open: 'noLinkedIn' }))
	};

	const showPopupPercent = (value, type) => {
		let color;
		if (type == 'Risk') color = value > 67 ? 'red' : value > 33 ? 'yellow' : 'green';
		else if (type == 'Completeness') color = value > 67 ? 'green' : value > 33 ? 'yellow' : 'red';
		return <span className={`${color}`}>{value || 0}% <span className='c6'>{type}</span></span>
	};

	return <>
		<div className="td250 container bg1 py2rem shadow w100 df">
			<div className="w100 mr1rem dg bg5 py2rem">
				<div className="df aic jcc w100 mb2rem">
					<button onClick={() => downloadPDF()} className="h100 shadow bg3 bg4-hover fs0-75rem c1 td250 br5px px2rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
						<svg className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path className="fa-primary" d="M424.1 320.1l-88 88C334.4 411.5 328.5 416 320 416s-14.4-4.467-16.97-7.031l-88-88c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L296 334.1V184C296 170.8 306.8 160 320 160s24 10.75 24 24v150.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0S434.3 311.6 424.1 320.1z" /><path className="fa-secondary" d="M537.6 226.6C541.7 215.9 544 204.2 544 192c0-53-43-96-96-96c-19.69 0-38.09 6-53.31 16.2C367 64.2 315.3 32 256 32C167.6 32 96 103.6 96 192c0 2.703 .1094 5.445 .2031 8.133C40.2 219.8 0 273.2 0 336C0 415.5 64.5 480 144 480H512c70.69 0 128-57.3 128-128C640 290.1 596 238.4 537.6 226.6zM424.1 320.1l-88 88C334.4 411.5 328.5 416 320 416s-14.4-4.467-16.97-7.031l-88-88c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L296 334.1V184C296 170.8 306.8 160 320 160s24 10.75 24 24v150.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0S434.3 311.6 424.1 320.1z" /></svg>
						Download PDF
					</button>
					{user['role'] == 'User' && (
						<button onClick={() => handleInvestorLeads()} className="h100 ml1rem shadow bg3 bg4-hover fs0-75rem c1 td250 br5px px2rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							Back to Investor Leads
						</button>
					)}
				</div>
				{company && <>
					<table id="pdf" className="op0 pen ma td250 dg gg2rem"></table>
					{user['role'] == 'Investor' && <>
						<div id="actions" className="investor-leads-tooltip pr">
							<span className="tooltip-area cp mr16rem mt1rem" style={{ top: '-10px', right: '-10px' }}>
								<i className="icon">
									<img className="h2rem" src={require(`../../../../images/info-icon.svg`)} alt="" />
									<div className="overlay-box" style={{ left: '-400px' }}>
										<p>All Diligentsia pre-qyualified leads are provided for FREE to selected on the Diligentsia investor database.<br />Matching our companies with investors is included in the services for which our companies pay.<br />We ask that you respond promptly to retain your Prompt Response Score.<br />We do not want to get between you and the company. Once you have confirmed your interest below,<br /> when you return to the Fit Report, the contact details at the applicant company will be revealed<br /> for you to make further contact directly<b className="c4">– BUT YOU NEED TO CONFIRM YOUR INTERST BELOW FIRST.</b></p>
									</div>
								</i>
							</span>
						</div>
						{(fitMetrics['leadStatus'] !== 'Accepted' && fitMetrics['leadStatus'] !== 'Rejected') &&
							<div className="df jsc mt2rem">
								<div className="tac mr1rem ">
									<button id='Accepted' onClick={() => handleAcceptReject('Accepted')} class="w9rem shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu bsbb tac mla fs0-75rem mb0-5rem">
										<i className="icon">
											<svg className="w1rem f1 pr mr0-5rem tp5px" viewBox="0 0 1229 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
												<path d="M512.283136 1023.998976a102.399693 102.399693 0 0 1-72.703782-29.695911l-409.598771-409.598771a102.399693 102.399693 0 0 1 145.407564-145.407564l325.631023 326.65502L1044.761539 40.961925a102.399693 102.399693 0 1 1 163.839508 122.879631l-614.398157 819.197543a102.399693 102.399693 0 0 1-74.751775 40.959877z" />
											</svg>
										</i>
										Yes please
									</button>
									<br /><span className="fs0-75rem tdu">I’d like to find out more<br />from company</span>
								</div>
								<div className="tac ml1rem">
									<button id='Rejected' onClick={() => handleAcceptReject('Rejected')} class="w9rem shadow bg4 bg3-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu bsbb tac mra fs0-75rem mb0-5rem">
										<i className="icon">
											<svg className="w1rem f1 pr mr0-5rem tp5px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.704 55.703">
												<g>
													<path d="M27.852,0C19.905,0,12.743,3.363,7.664,8.72C7.628,8.751,7.583,8.762,7.549,8.796C7.495,8.85,7.476,8.922,7.426,8.98
														C2.833,13.949,0,20.568,0,27.852c0,15.357,12.493,27.851,27.851,27.851c15.356,0,27.851-12.494,27.851-27.851
														C55.703,12.494,43.208,0,27.852,0z M4.489,27.851c0-5.315,1.805-10.207,4.806-14.138l32.691,32.694
														c-3.93,3.001-8.819,4.806-14.135,4.806C14.969,51.213,4.489,40.732,4.489,27.851z M45.282,43.352l-32.933-32.93
														c4.13-3.678,9.551-5.934,15.503-5.934c12.881,0,23.362,10.48,23.362,23.363C51.213,33.803,48.958,39.225,45.282,43.352z"/>
												</g>
											</svg>
										</i>
										Sorry <br /><span className="fs0-5rem"></span>
									</button>
									<br /><span className="fs0-75rem tdu">not for us at the current time</span>
								</div>
							</div>
						}
						<div className="df jsc mt2rem">
							<div className="tac mr1rem">
								<a href="https://koalendar.com/e/meet-with-mark-bernstein" target="_blank" class="w9rem shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu bsbb tac mla fs0-75rem mb0-5rem">
									<i className="icon">
										<svg className="w1rem f1 pr mr0-5rem tp5px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.592 202.592">
											<g>
												<g>
													<path d="M198.048,160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001,0.433l-15.761,15.761
														c-0.995-0.551-2.026-1.124-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599,101.48,65.03,87.834,59.5,77.874
														c-0.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565l5.2-5.207c6.46-6.46,6.639-16.778,0.419-23.001L42.715,4.769
														c-6.216-6.216-16.541-6.027-23.001,0.433l-8.818,8.868l0.243,0.24c-2.956,3.772-5.429,8.124-7.265,12.816
														c-1.696,4.466-2.752,8.729-3.235,12.998c-4.13,34.25,11.52,65.55,53.994,108.028c58.711,58.707,106.027,54.273,108.067,54.055
														c4.449-0.53,8.707-1.593,13.038-3.275c4.652-1.818,9.001-4.284,12.769-7.233l0.193,0.168l8.933-8.747
														C204.079,176.661,204.265,166.343,198.048,160.105z M190.683,176.164l-3.937,3.93l-1.568,1.507
														c-2.469,2.387-6.743,5.74-12.984,8.181c-3.543,1.364-7.036,2.24-10.59,2.663c-0.447,0.043-44.95,3.84-100.029-51.235
														C14.743,94.38,7.238,67.395,10.384,41.259c0.394-3.464,1.263-6.95,2.652-10.593c2.462-6.277,5.812-10.547,8.181-13.02l5.443-5.497
														c2.623-2.63,6.714-2.831,9.112-0.433l31.286,31.286c2.394,2.401,2.205,6.492-0.422,9.13L45.507,73.24l1.95,3.282
														c1.084,1.829,2.23,3.879,3.454,6.106c5.812,10.482,13.764,24.83,29.121,40.173c15.317,15.325,29.644,23.27,40.094,29.067
														c2.258,1.249,4.32,2.398,6.17,3.5l3.289,1.95l21.115-21.122c2.634-2.623,6.739-2.817,9.137-0.426l31.272,31.279
														C193.5,169.446,193.31,173.537,190.683,176.164z"/>
												</g>
											</g>
										</svg>
									</i>
									Book a call
								</a>
								<br /><span className="fs0-75rem tdu">with Diligentsia CEO</span>
							</div>
							<div className="tac ml1rem">
								<a href="/pricing-plans" target="_blank" class="w9rem shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu bsbb tac mra fs0-75rem mb0-5rem">
									<i className="icon">
										<svg className="w1rem f1 pr mr0-5rem tp5px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488.4 488.4">
											<g>
												<g>
													<path d="M0,203.25c0,112.1,91.2,203.2,203.2,203.2c51.6,0,98.8-19.4,134.7-51.2l129.5,129.5c2.4,2.4,5.5,3.6,8.7,3.6
														s6.3-1.2,8.7-3.6c4.8-4.8,4.8-12.5,0-17.3l-129.6-129.5c31.8-35.9,51.2-83,51.2-134.7c0-112.1-91.2-203.2-203.2-203.2
														S0,91.15,0,203.25z M381.9,203.25c0,98.5-80.2,178.7-178.7,178.7s-178.7-80.2-178.7-178.7s80.2-178.7,178.7-178.7
														S381.9,104.65,381.9,203.25z"/>
												</g>
											</g>
										</svg>
									</i>
									Find out
								</a>
								<br /><span className="fs0-75rem tdu">More - Diligentsia Portfolio Pro<br /> (FREE for Investors)</span>
							</div>
						</div>
					</>}
					{user['role'] == 'User' &&
						<div className="df jcc mt1rem">
							<button onClick={() => handleInvestorLeads()} className="w20 ml1rem shadow bg3 bg4-hover fs0-75rem c1 td250 br5px px2rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
								Back to Investor Leads
							</button>
						</div>
					}
					<table id="pdfContent" className="bsbb w0 h0 oh op0 pen db">
						{getOverviewContent()}
						{getAboutInvestorContent()}
						{getAboutCompanyContent()}
						{getFitMetricsContent()}
						{getDiligenceReports()}
						{getDetailedAnswers()}
						{getBespokedQA()}
					</table>
				</>}
			</div>
		</div>

		{/* Investor Bio */}
		<Modal open={modal['open'] == 'investorBio'} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
			<div className="mah100 oya bg1 shadow w100 px3rem py1rem bsbb df fdc m1rem maw600px br5px pr">
				<button data-scroll-to='About the Investor' className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
					<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
						<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
					</svg>
				</button>
				<div className="pr modal-bio investor-leads-tooltip">
					<h2 className="tac c6 lh2 fs1-75rem mb2rem">Investor Bio</h2>
					<div className="user-area">
						<span className="tooltip-area cp" style={{ top: '35px', right: '20px' }}>
							<i className="icon">
								<img className="h2rem" src={require(`../../../../images/info-icon.svg`)} alt="" />
								<div className="overlay-box" style={{ left: '-400px' }}>
									<p>Please note that if you wish to contact the investor directly, then they will not received the additional validation of the Diligentsia Diligence Report nor the fit report, and it will be treated by the investor as an unsolicited approach, which historically result in less attention/success.</p>
								</div>
							</i>
						</span>
						<div className="user-img">
							<img className="h4rem w4rem br100" src={investorFirm?.['contact']?.['image'] || require(`../../../../images/profile-photo.jpeg`)} alt="image" />
						</div>
						<div className="text-box">
							<h4 className="fs0-75rem df">
								<span>{investorFirm?.['contact']?.['firstName'] ? investorFirm?.['contact']?.['firstName'] + ' ' + investorFirm?.['contact']?.['lastName'] : 'Name'}</span>
								<button onClick={() => handleLinkedIn(investorFirm['contact']['linkedIn'])} className="icon ml0-5rem bg1 cp" style={{ width: '22px' }}>
									<svg version="1.1" className="f3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310">
										<g>
											<path d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
													C77.16,101.969,74.922,99.73,72.16,99.73z"/>
											<path d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
													c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"/>
											<path d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
													c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
													c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
													C310,145.43,300.549,94.761,230.454,94.761z"/>
										</g>
									</svg>
								</button>
							</h4>
							<p className={`fs0-75rem df ${(user['role'] !== 'Investor' && isBlurContact) ? ' blur-disabled' : ''}`}>{investorFirm?.['contact']?.['email']
								? <>{investorFirm?.['contact']?.['email']} <button class="cp bg1" onClick={() => handleCopyClipboard(investorFirm?.['contact']?.['email'])}><i class="fa fa-clipboard c4" style={{ fontSize: '12px' }}></i></button></>
								: '-'}</p>
							<p className={`fs0-75rem df ${(user['role'] !== 'Investor' && isBlurContact) ? ' blur-disabled' : ''}`}>{investorFirm?.['contact']?.['phone']
								? <>{investorFirm?.['contact']?.['phone']} <button class="cp bg1" onClick={() => handleCopyClipboard(investorFirm?.['contact']?.['phone'])}><i class="fa fa-clipboard c4" style={{ fontSize: '12px' }}></i></button></>
								: '-'}</p>
						</div>
					</div>
					<div className="description-box mt1rem pr">
						<div className="df jcsb">
							<h4 className="fs1rem mb1rem">Bio</h4>
							{user['role'] == "Investor" && <button onClick={() => { setIsEditDeatils(true), dispatch(toggleModal({ open: 'editDetails' })) }} className="shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu bsbb tac fs0-75rem mb1rem">Edit Details?</button>}
						</div>
						<p className="fs0-75rem">{investorFirm?.['contact']?.['bio'] && investorFirm?.['contact']?.['bio'] !== 'n/a' ? investorFirm?.['contact']?.['bio'] : 'No information available from our search'}</p>
					</div>
					<div className="description-box mt1rem pr">
						<h4 className="fs1rem mb1rem">About</h4>
						<p className="fs0-75rem">{investorFirm?.['contact']?.['linkedinAbout'] ? investorFirm?.['contact']?.['linkedinAbout'] : 'No information available from our search'}</p>
					</div>

				</div>

			</div>
		</Modal>

		{/* Investor Read More Description */}
		<Modal open={modal['open'] == 'readMoreDescription'} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
			<div className="mah100 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
				<button data-scroll-to='About the Investor' className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
					<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
						<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
					</svg>
				</button>
				<div className="pr modal-bio">
					<h2 className="tac c6 lh2 fs1-75rem mb2rem">Investor Description</h2>
					<div className="description-box mt1rem pr">
						<h4 className="fs1rem mb1rem">Description</h4>
						<p className="fs0-75rem">{investorFirm['description']}</p>
					</div>
				</div>
			</div>
		</Modal>

		{/* Investor Read More Criteria */}
		<Modal open={modal['open'] == 'readMoreCriteria'} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
			<div className="mah100 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
				<button data-scroll-to='About the Investor' className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
					<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
						<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
					</svg>
				</button>
				<div className="pr modal-bio">
					<h2 className="tac c6 lh2 fs1-75rem mb2rem">Investment Criteria</h2>
					<div className="description-box mt1rem pr">
						<h4 className="fs1rem mb1rem">Criteria</h4>
						<p className="fs0-75rem">{investorFirm['investmentCriteria']}</p>
					</div>
				</div>
			</div>
		</Modal>

		{/* Company Read More Description */}
		<Modal open={modal['open'] == 'readMoreCompanyDescription'} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
			<div className="mah100 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
				<button data-scroll-to='About the Company' className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
					<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
						<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
					</svg>
				</button>
				<div className="pr modal-bio">
					<h2 className="tac c6 lh2 fs1-75rem mb2rem">Company Description</h2>
					<div className="description-box mt1rem pr">
						<h4 className="fs1rem mb1rem">Description</h4>
						<p className="fs0-75rem">{company['companyDescription']}</p>
					</div>
				</div>
			</div>
		</Modal>

		{/* Edit Details Popup */}
		<Modal open={modal['open'] == 'editDetails'} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
			<div className="mah90 oya bg1 shadow w100 px3rem py2rem bsbb df fdc m1rem maw600px br5px pr">
				<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="image" style={{ top: '15px' }} />
				<button data-scroll-to='About the Investor' className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
					<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
						<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
					</svg>
				</button>
				<h1 className="text-gradient fs2rem fw600 tac pt2rem pb1rem">Edit Details</h1>
				{isEditDeatils &&
					<p className="df tac fs1rem my1rem">We recognise that you may not be the contact person within your firm who should deal with these initial approaches, and you can nominate a colleague to be the point person in your place.  However, we wish to offer quality, not quantity of diligenced leads in both directions, and for this reason ask that you nominate a specific individual rather than a generic “enquiries @” email address.</p>
				}
				<p className="df tac fs1rem my1rem">You can edit your firm’s details or your own bio in your Investor Settings accessed via your Portfolio Pro dashboard  or booking a call with Mark Bernstein, the Diligentsia CEO </p>
				<a href="https://koalendar.com/e/meet-with-mark-bernstein" target="_blank" className="fs1rem c3 tdu mb1rem">https://koalendar.com/e/meet-with-mark-bernstein</a>
			</div>
		</Modal>

		{/* Company Contact Details */}
		<Modal open={modal['open'] == 'companyContactDetails'} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
			<div className="mah90 oya bg1 shadow w100 px3rem py2rem bsbb df fdc m1rem maw600px br5px pr">
				<button data-scroll-to="About the Company" className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
					<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
						<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
					</svg>
				</button>
				<div className="pr modal-bio">
					<h2>Company Contact Details</h2>
					<div className="user-area">
						<div className="user-img">
							<img className="h4rem w4rem br100" src={company?.['creatorId']?.['profile'] || require(`../../../../images/profile-photo.jpeg`)} alt="image" />
						</div>
						<div className="text-box">
							<h4 className="fs0-75rem df">
								<span>{company?.['creatorId']?.['firstName']} {company?.['creatorId']?.['lastName']}</span>
								<button onClick={() => handleLinkedIn(company['creatorId']['linkedIn'])} className="icon ml0-5rem bg1 cp" style={{ width: '22px' }}>
									<svg version="1.1" className="f3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310">
										<g>
											<path d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
													C77.16,101.969,74.922,99.73,72.16,99.73z"/>
											<path d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
													c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"/>
											<path d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
													c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
													c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
													C310,145.43,300.549,94.761,230.454,94.761z"/>
										</g>
									</svg>
								</button>
							</h4>
							<p className="fs0-75rem df aic">
								<span className="fw500 mr0-5rem">Email: </span>
								{company?.['creatorId']?.['email']
									? <>{company?.['creatorId']?.['email']} <button class="cp bg1" onClick={() => handleCopyClipboard(company?.['creatorId']?.['email'])}><i class="fa fa-clipboard c4" style={{ fontSize: '12px' }}></i></button></>
									: '-'}
							</p>
							<p className="fs0-75rem df aic">
								<span className="fw500 mr0-5rem">Phone: </span>
								{company?.['creatorId']?.['phone']
									? <>{company?.['creatorId']?.['phone']} <button class="cp bg1" onClick={() => handleCopyClipboard(company?.['creatorId']?.['phone'])}><i class="fa fa-clipboard c4" style={{ fontSize: '12px' }}></i></button></>
									: '-'}</p>
						</div>
					</div>
					{/* <div className="description-box mt1rem pr">
						<h4 className="fs1rem mb1rem">Description</h4>
						<p className="fs0-75rem">{company['companyDescription'] ? company['companyDescription'] : '-'}</p>
					</div> */}
				</div>

			</div>
		</Modal>

		{/* No LinkedIn Popup */}
		<Modal open={modal['open'] == 'noLinkedIn'} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
			<div className="mah90 oya bg1 shadow w100 px3rem py2rem bsbb df fdc m1rem maw600px br5px pr">
				<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="image" style={{ top: '15px' }} />
				<button data-scroll-to="over-content-btn" className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
					<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
						<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
					</svg>
				</button>
				<h1 className="text-gradient fs2rem fw600 tac py2rem">No LinkedIn Profile</h1>
				<p className="df tac fs1rem my1rem">We are unable to locate a LinkedIn profile for this person.</p>
				<button data-scroll-to="over-content-btn" onClick={() => dispatch(toggleModal({ open: '' }))} className="h100 mt1rem mxa shadow bg3 bg4-hover fs0-75rem c1 td250 br5px px2rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">Back</button>
			</div>
		</Modal>

		{/* Manage Files */}
		<Modal open={modal['open'] == 'manageFiles'} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
			<div className="mah90 pr oya bg1 shadow w100 p3rem pt5rem bsbb df fdc m1rem maw800px br5px pr">
				<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="image" style={{ top: '15px' }} />
				<button data-scroll-to="Fit Metrics" className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
					<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
						<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
					</svg>
				</button>
				<h1 className="text-gradient fw600 tac mb1rem fs1-5em">{company.cName}<br />Pitch Deck</h1>
				<div className='df jcc'>
				</div>
				{(fitMetrics['deckFiles'] && fitMetrics['deckFiles'].length > 0)
					? <>{fitMetrics['deckFiles'].map(file => {
						return <div className="df jcsb my1rem">
							<p className="fs1rem c4 cp">{file['originalname']}</p>
							<a href={file['location']} download>
								<svg data-tooltip={"Download"} className={`f3 h1rem cp mr1rem ${tab == 'repository' ? '' : 'mla'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
									<path className="fa-primary" d="M105.4 246.6c-12.49-12.5-12.49-32.75 0-45.25c12.5-12.5 32.76-12.5 45.25 0L224 274.8V32c0-17.67 14.33-32 32-32c17.67 0 32 14.33 32 32v242.8l73.38-73.38c12.49-12.5 32.75-12.5 45.25 0c12.49 12.5 12.49 32.75 0 45.25l-128 128C272.4 380.9 264.2 384 256 384s-16.38-3.125-22.62-9.375L105.4 246.6z" /><path className="fa-secondary" d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456z" />
								</svg>
							</a>
						</div>
					})}</>
					: <div className="dg gg1rem mb2rem fs1rem">
						<span className="fs1rem fw500 gcs1 gce5-md tac">No files have been added yet</span>
					</div>
				}
			</div>
		</Modal>

		{/* Show Directors */}
		<Modal open={modal['open'] == 'showDirectors'} className="backdrop showDirectors td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
			<div className="pr maw800px inner-showDirectors pr">
				<button data-scroll-to='Diligence Reports' className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
					<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
						<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
					</svg>
				</button>
				<div className='top-header'>
					<h1 className="text-gradient fw600 tac mb1rem">LIST OF QUESTIONNAIRES</h1>
				</div>
				<div className='table-area-showDirectors'>
					<table id="table" className="table-showDirectors">
						<thead className="ps t0">
							<tr>
								<th>Name</th>
								<th>Designation</th>
								<th>Progress</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{company['directors'] && company['directors'].length > 0 && <>
								{company['directors'].map((item, idx) => (
									<tr key={idx}>
										<td className={`${item['headShot'] && 'df aic '} c6 fw500 ttc`}>
											{item['headShot'] && <img src={item['headShot']['location']} className="w2rem h2rem df jcc aic br100 bg3 c1 mr0-5rem" />}
											{item['name']}
										</td>
										<td className='c6 fw500 ttc'>{item['designation']}</td>
										<td className='p1rem c6 fw500 ttc'>({showPopupPercent(company?.['modules']?.[`directors-questionnaire_${item['_id']}`]?.['stats']?.['completionPercent'] || 0, 'Completeness')} / {showPopupPercent(company?.['modules']?.[`directors-questionnaire_${item['_id']}`]?.['stats']?.['riskPercent'] || 0, 'Risk')})</td>
										<td><button onClick={() => { localStorage.setItem('isDiligenceReport', true); setTab(`directors-questionnaire_${item['_id']}-report`) }} className="cp bg4 bg3-hover c1 tac c1 shadow px1rem py0-5rem br5px fw500">Report</button></td>
									</tr>
								))}
							</>}
						</tbody>
					</table>
					{company['directors'] && company['directors'].length == 0 &&
						<div className='tac py1rem'>
							<h2 className="text-gradient fw600">No Directors by this Company</h2>
						</div>
					}
					<div className='logo-modal'>
						<img className="h2rem" src={require(`../../../../images/logo.svg`)} alt="TODO" />
					</div>
				</div>
			</div>
		</Modal>

		{/* Accept/Reject Client */}
		<Modal open={modal['open'] == 'clientAcceptReject'} className="backdrop td500 zi4 pf t0 l0 w100 h110vh df jcc aic">
			<div className="mah90 oya bg1 shadow w100 px2rem py3rem bsbb df fdc m1rem maw700px br5px pr">
				<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
				<button data-scroll-to="actions" className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
					<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
						<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
					</svg>
				</button>
				<h1 className="text-gradient fs2rem fw600 tac ttu mb1rem pb1rem">Access Request </h1>
				<div class="df fdc gg1rem px2rem">
					<p className="my1rem">Are you sure you want to {leadStatus.slice(0, -2).toLowerCase()} this lead request of <span className="fw500">{company['cName']}</span>?</p>
					<button data-scroll-to="actions" onClick={() => sendLeadEmail(leadStatus)} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp ttu w100 bsbb tac df jcc aic">{leadStatus.slice(0, -2)}</button>
					<button data-scroll-to="actions" onClick={() => dispatch(toggleModal({ open: '' }))} className="bg1 c3-hover td250 fs1-25rem cp">Cancel</button>
				</div>
			</div>
		</Modal>
	</>
}
