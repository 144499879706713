import { createSlice, createAction } from "@reduxjs/toolkit";

export default function getCompaniesReducer(companies=[]) {
	const companiesSlice = createSlice({
		name: "companies",
		initialState: companies,
		reducers: {
			refresh: (state, action) => {
				return [...action.payload];
			}
		}
	});

	return companiesSlice.reducer;
}

export const refreshCompanies = createAction("companies/refresh");