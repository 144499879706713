import AnimatedPage from "../components/AnimatedPage";
import CallToAction from "../components/CallToAction";
import Hero from "../components/Hero";
import FancyHeadingParagraph from "../components/FancyHeadingParagraph";
import HeadingParagraph from "../components/HeadingParagraph";
import HeadingSubheadingContent from "../components/HeadingSubheadingContent";
import TakeTheTest from "../components/TakeTheTest";
import IconTicksCard from "../components/IconTicksCard";
import Testimonials from "../components/Testimonials";

export default function ExitReadyCompanies() {
	const testimonials = [
		{
			title: "The Growth Manager",
			text: "Only when you work as part of a Senior Management Team around a central data repository, kept up to date, do you really realise the operational benefits of centralising key corporate data into one easy to access location.",
			author: "Katy Davidson",
			company: "CFO Wearable Technologies Limited",
		},
		{
			title: "The Early Stage CEO",
			text: "Having an investment ready data room and supporting documents has undoubtedly attracted more interested investors prepared to look at us seriously and accelerated our investment timetable.",
			author: "Louise Birritteri",
			company: "CEO Pikl Insurance Services Limited",
		},
		{
			title: "The Professional",
			text: "Getting a client into a position where they can provide a draft disclosure letter schedule at the commencement of a transaction will reduce costs, speed up the transaction, and lessen the chance of its falling over right at the end.",
			author: "Rik Pancholi",
			company: "Director, Pattersons Commercial Law",
		},
	];
	return (
		<AnimatedPage>
			<Hero h1Class="fs2-5rem" h1={["Exit More Quickly,", "At a Higher Valuation,", "With More Certainty."]} buttonText="Learn How" buttonId="scrollDown" videoSrc="diligentsia_for_companies.mp4" videoThumbnail="diligentsia-for-companies.jpg" />
			{/* <Hero h1Class="fs2-5rem" h1={["Exit More Quickly,", "At a Higher Valuation,", "With More Certainty."]} buttonText="Register as Investor" id='openRegisterPopup' dataShowPopup="registerInvestor" buttonId="scrollDown" videoSrc="ddiligentsia_for_companies.mp4" videoThumbnail="diligentsia-for-companies.jpg" /> */}

			<TakeTheTest readyFor="Exit" />
			<div className="bg1">
				<div className="container df fww py5rem">
					<HeadingSubheadingContent wrapperClass="w50"  heading="Is Your Company Exit Ready?" subheading="Meet Diligentsia" paragraph="Warranty disclosure schedules, Director’s questionnaires, up to date key corporate data - legal, IP, key contracts, HR, constitutional - all in a single intuitive and easy to access repository. These are all things that give purchasers confidence in your business. But what else do you need and how do you know if you’re ready to maximise the value of your business on exit?" />
					<IconTicksCard wrapperClass="w50 jcfe"  title="Exit Phase" ticks={['We make you "exit ready"', "More buyers will look at your company", "Better chance of auction", "Higher valuation", "Better terms", "Quicker transaction time", "Reduced risk of late collapse of deal"]}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path className="fa-primary" d="M502.6 278.6l-128 128c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L402.8 288H192C174.3 288 160 273.7 160 256s14.31-32 32-32h210.8l-73.38-73.38c-12.5-12.5-12.5-32.75 0-45.25c12.49-12.49 32.74-12.51 45.25 0l128 128C515.1 245.9 515.1 266.1 502.6 278.6z" />
							<path className="fa-secondary" d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416z" />
						</svg>
					</IconTicksCard>
				</div>
			</div>
			<div className="bg1">
				<div className="df fww container pb5rem">
					<HeadingParagraph wrapperClass="w45 mr2rem" heading="Does your repository deliver everything your purchasers expect and more?" paragraph="Have you self-diligenced through a business audit in advance of going out for funding? How do you benchmark yourselves? These are all questions that we can help you with. We help our business exit, more quickly, with more certainty, and at a higher valuation." />
					<HeadingParagraph wrapperClass="w45 mr2rem" heading="So how do we do it?" paragraph="Our ultra-low-cost SaaS based platform helps you easily use tailored online forms and checklists to systematically help you create an easily accessible central “brain”, a living breathing data repository. Why waste valuable time every day looking for key documents when we can help you centralise your key documents within your existing cloud-based provider. We get you organized!" />
				</div>
			</div>
			<FancyHeadingParagraph
				content={[
					["Helping You Help Yourselves", "You can pull together the repository yourself using our easy-to-use checklists, or we can assist you, or ask your lawyer or accountant to help – they also benefit from your key corporate data being better organised."],
					["Already have a data room?", "We’re data room agnostic. If you already have a data room, such as Seed Legals, or iDeals or DataSite, we help you organise it better to create more value. Or we simply create one within your existing cloud-based solution – Sharepoint/Drive. You tell us"],
					["Be Exit Ready", "We make you exit ready. We work with a number of accoutants, law firms and banks, who gain confidence if you are working with us. They know their time will not be wasted."],
				]}
			/>
			<Testimonials testimonials={testimonials} />
			<CallToAction heading="Ready to Maximise Your Exit Valuation for as Little as &pound; 5 a Month?"/>
		</AnimatedPage>
	);
}
