import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

export default function Footer() {
	return <>
		<footer className="w100 zi-1 pf b0 l0 bg5"></footer>
		<div className="pf b0 r0 mr1rem mb5rem">
			<svg className="w3rem h3rem cp f3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path className="fa-primary" d="M249.1 268C235.4 268 224 279.4 224 294S235.4 320 249.1 320C264.6 320 276 308.6 276 294S264.6 268 249.1 268zM277.7 96H234.3C201.8 96 176 121.6 176 153.8c0 10.74 9.166 19.83 20 19.83S216 164.6 216 153.8c0-9.914 8.334-18.17 18.33-18.17h43.33c10 0 18.33 8.26 18.33 18.17c0 6.607-3.334 12.39-9.166 15.7L239.3 197.6c-6.668 4.131-10 10.74-10 17.35v13.22c0 10.74 9.166 19.83 20 19.83c10.83 0 20-9.088 20-19.83V226.5l38.33-23.13C325.2 192.7 336 173.7 336 153.8C336 121.6 310.2 96 277.7 96z"/><path className="fa-secondary" d="M448 0H64C28.63 0 0 28.62 0 63.1v287.1C0 387.4 28.63 415.1 64 415.1h96v83.1c0 9.873 11.25 15.52 19.12 9.649L304 415.1H448c35.38 0 64-28.63 64-63.1V63.1C512 28.62 483.4 0 448 0zM249.1 320C235.4 320 224 308.6 224 294S235.4 268 249.1 268C264.6 268 276 279.4 276 294S264.6 320 249.1 320zM307.7 203.4l-38.33 23.13v1.652c0 10.74-9.168 19.83-20 19.83c-10.83 0-20-9.088-20-19.83V214.1c0-6.609 3.332-13.22 10-17.35l47.5-28.09C292.7 166.2 296 160.4 296 153.8c0-9.914-8.334-18.17-18.33-18.17H234.3c-10 0-18.33 8.26-18.33 18.17c0 10.74-9.166 19.83-20 19.83S176 164.6 176 153.8C176 121.6 201.8 96 234.3 96h43.33C310.2 96 336 121.6 336 153.8C336 173.7 325.2 192.7 307.7 203.4z"/></svg>
		</div>
	</>
}
