import ReactDOM from "react-dom";
import jwt_decode from 'jwt-decode';
import { Provider } from "react-redux";
import { useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import React, { Fragment, useEffect } from "react";

import storage from 'redux-persist/lib/storage';
import { combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter, useLocation } from "react-router-dom";

import getUserReducer from "./reducers/userReducer";
import getModalReducer from "./reducers/modalReducer";
import getLoginReducer from "./reducers/loginReducer";
import getModuleReducer from "./reducers/moduleReducer";
import getCompanyReducer from "./reducers/companyReducer";
import getCompaniesReducer from "./reducers/companiesReducer";
import socket, { token, getRequest, showMessage } from './config';

import Header from "./components/Header";
import NewHeader from "./components/NewHeader";
import Footer from "./components/Footer";
import NewFooter from "./components/NewFooter";
import Popups from "./components/Popups";
import AnimatedRoutes from "./components/AnimatedRoutes";
import LoggedInHeader from "./components/logged_in/Header";
import LoggedInFooter from "./components/logged_in/Footer";
import { LOGGED_IN_ROUTES, NO_FOOTER } from "./constants/routes";

import 'react-table-6/react-table.css';
import 'react-phone-number-input/style.css';

export default function App() {
	const location = useLocation();
	const modal = useSelector(state => state.modal);

	useEffect(() => {
		if (modal['open'] == '') {
			let inputs = document.getElementsByTagName('input');
			for (var i = 0; i < inputs.length; i++) {
				if (inputs[i]['id'] !== "userPermission" && (inputs[i]['type'] == "text" || inputs[i]['type'] == "email" || inputs[i]['type'] == "password")) inputs[i]['value'] = "";
			}
		}
	}, [modal]);

	const isFooter = NO_FOOTER.find(r => r.split("/").every((s, i) => s === location.pathname.split("/")[i] || s[0] === ":"));
	const isLoggedInPage = LOGGED_IN_ROUTES.find(r => r.split("/").every((s, i) => s === location.pathname.split("/")[i] || s[0] === ":"));

	return (
		<>
			{isLoggedInPage && <LoggedInHeader /> || <NewHeader />}
			<AnimatedRoutes />
			{(isFooter || isLoggedInPage) && <LoggedInFooter /> || <NewFooter />}
			<Popups />
		</>
	);
}

(async () => {
	if (typeof document !== "undefined") {
		let user = {};
		let module = {};
		let company = {};
		let companies = [];
		let modal = { open: '' };
		let loginType = { smsEnabled: '', twoFaEnabled: '', emailEnabled: '', hash: '' };
		if (!window.__PRELOADED_STATE__) {
			if (LOGGED_IN_ROUTES.find(r => r.split("/").every((s, i) => s === location.pathname.split("/")[i] || s[0] === ":"))) {
				let response = await getRequest(`/user`);
				if (!response || response['code'] != 200) return showMessage(response['message']);
				else {
					const { body } = response;
					user = body['user'];
					// companies = body['companies'];
				}
			}
		};

		const userReducer = getUserReducer(user);
		const modalReducer = getModalReducer(modal);
		const moduleReducer = getModuleReducer(module);
		const loginReducer = getLoginReducer(loginType);
		const companyReducer = getCompanyReducer(company);
		const companiesReducer = getCompaniesReducer(companies);

		const persistConfig = {
			key: 'root',
			version: 1,
			storage,
		};

		const reducer = combineReducers({
			user: userReducer,
			modal: modalReducer,
			module: moduleReducer,
			loginType: loginReducer,
			company: companyReducer,
			companies: companiesReducer,
		});

		const persistedReducer = persistReducer(persistConfig, reducer);
		const store = configureStore({
			reducer: {
				// persistedReducer,
				user: userReducer,
				modal: modalReducer,
				module: moduleReducer,
				company: companyReducer,
				loginType: loginReducer,
				companies: companiesReducer,
			},
			preloadedState: window.__PRELOADED_STATE__
		});

		// let persistor = persistStore(store);
		delete window.__PRELOADED_STATE__;

		ReactDOM.hydrate(
			<BrowserRouter>
				<Provider store={store}>
					{/* <PersistGate persistor={persistor}> */}
					<App />
					{/* </PersistGate> */}
				</Provider>
			</BrowserRouter>,
			document.getElementById("root")
		);
	};
})();