import AnimatedPage from "../components/AnimatedPage";
import CallToAction from "../components/CallToAction";
import Hero from "../components/Hero";
import FancyHeadingParagraph from "../components/FancyHeadingParagraph";
import HeadingParagraph from "../components/HeadingParagraph";
import HeadingSubheadingContent from "../components/HeadingSubheadingContent";
import TakeTheTest from "../components/TakeTheTest";
import IconTicksCard from "../components/IconTicksCard";
import Testimonials from "../components/Testimonials";

export default function GrowthReadyCompanies() {
	const testimonials = [
		{
			title: "The Growth Manager",
			text: "Only when you work as part of a Senior Management Team around a central data repository, kept up to date, do you really realise the operational benefits of centralising key corporate data into one easy to access location.",
			author: "Katy Davidson",
			company: "CFO Wearable Technologies Limited",
		},
		{
			title: "The Early Stage CEO",
			text: "Having an investment ready data room and supporting documents has undoubtedly attracted more interested investors prepared to look at us seriously and accelerated our investment timetable.",
			author: "Louise Birritteri",
			company: "CEO Pikl Insurance Services Limited",
		},
		{
			title: "The Professional",
			text: "Getting a client into a position where they can provide a draft disclosure letter schedule at the commencement of a transaction will reduce costs, speed up the transaction, and lessen the chance of its falling over right at the end.",
			author: "Rik Pancholi",
			company: "Director, Pattersons Commercial Law",
		},
	];
	return (
		<AnimatedPage>
			<Hero h1Class="fs2-5rem" h1={["Grow More Value,", "More Quickly,", "With More Certainty."]} buttonText="Learn How" buttonId="scrollDown" videoSrc="diligentsia_for_companies.mp4" videoThumbnail="diligentsia-for-companies.jpg" />
			<TakeTheTest readyFor="Growth" />
			<div className="bg1">
				<div className="container df fww py5rem">
					<HeadingSubheadingContent  wrapperClass="w50" heading="Is Your Company Growth Ready?" subheading="Meet Diligentsia" paragraph="Warranty disclosure schedules, Director’s questionnaires, up to date key corporate data - legal, IP, key contracts, HR, constitutional - all in a single intuitive and easy to access repository. These are all things that give your stakeholders confidence in your business. But what else do you need and how do you know if you’re ready to maximise the continuing value of your business?" />
					<IconTicksCard wrapperClass="w50 jcfe" title="Growth Phase" ticks={["We make your processes more efficient", "Spot problems earlier", 'Your company "grows up" faster', "Reduced professional fees", "Higher growth", 'More time to focus on the "fun stuff"']}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
							<path className="fa-primary" d="M544 320c-17.69 0-32-14.33-32-32V160h-128c-17.69 0-32-14.33-32-32s14.31-32 32-32h160c17.69 0 32 14.33 32 32v160C576 305.7 561.7 320 544 320z" />
							<path className="fa-secondary" d="M512 205.3l-169.4 169.4C336.4 380.9 328.2 384 320 384s-16.38-3.125-22.62-9.375L192 269.3l-137.4 137.4C48.38 412.9 40.19 416 32 416c-18.28 0-32-14.95-32-32c0-8.188 3.125-16.38 9.375-22.62l160-160C175.6 195.1 183.8 192 192 192s16.38 3.125 22.62 9.375L320 306.8L466.8 160H512V205.3z" />
						</svg>
					</IconTicksCard>
				</div>
			</div>
			<div className="bg1">
				<div className="df fww container pb5rem">
					<HeadingParagraph wrapperClass="w45 mr2rem"  heading="Does your repository deliver everything your purchasers expect and more?" paragraph="Have you self-diligenced through a business audit in advance of going out for funding? How do you benchmark yourselves? These are all questions that we can help you with. We help your business grow or raise investment or exit, more quickly, at a higher valuation, and with more certainty." />
					<HeadingParagraph wrapperClass="w45 mr2rem"  heading="So how do we do it?" paragraph="Our ultra-low-cost SaaS based platform helps you easily use tailored online forms and checklists to systematically help you create an easily accessible central “brain”, a living breathing data repository. Why waste valuable time every day looking for key documents when we can help you centralise your key documents within your existing cloud-based provider. We get you organized!" />
				</div>
			</div>
			<FancyHeadingParagraph
				content={[
					["Helping You Help Yourselves", "As you grow, are you self-diligencing your business regularly?  How do you benchmark yourself against your competition?  These are questions we can help you with.  We can help you grow your business more quickly and present itself in a more sophisticated manner to those around you."],
					["Already have a data room?", "We’re data room agnostic. If you already have a data room, such as Seed Legals, or iDeals or DataSite, we help you organise it better to create more value. Or we simply create one within your existing cloud-based solution – Sharepoint/Drive/Dropbox. You tell us"],
					["Be Growth Ready", "By enhancing your growth, we help you position your business better for either investment or exit, where you will be able to undertake transactions more quickly, at a higher valuation and with more certainty."],
				]}
			/>
			<Testimonials testimonials={testimonials} />
			<CallToAction heading="Ready to Maximise Your Growth for as Little as &pound; 5 a Month?"/>
		</AnimatedPage>
	);
}
