import React from "react"

const ContentCard = ({ description, title, icon }) => {
    return (
        <div className="col-sm bg1 br60px p2rem cp oh new-shadow shadowContainer bsbb" style={{ width: '47%' }}>
            <div className='shadowPaperCrasouel'>
                <div className="df aic">
                    <div className="br10px bg19 p0-5rem">
                        {icon}
                    </div>
                    <p className="fw500 fs1-5rem ml1rem c15 df tas df jcsb w100" style={{ lineHeight: '39.81px' }}>{title}</p>
                </div>
                <p className="fw400 fs1-25rem mt1rem c14 tas px1rem pr3rem" style={{ lineHeight: '2rem' }}>
                    {description}
                </p>
            </div>
        </div>
    )

}

export default ContentCard