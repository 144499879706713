import { useEffect } from "react";
import * as ROUTES from "../constants/routes";
import { setLogout, getRequest } from '../config';
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { updateUser } from "../reducers/userReducer";
import { toggleModal } from "../reducers/modalReducer";


export default function Header() {

	const dispatch = useDispatch();
	const navigation = useNavigate();
	let user = useSelector(state => state.user);

	useEffect(() => {
		// getUser();
	}, []);

	const getUser = async () => {
		let response = await getRequest(`/user`);
		if (response['code'] == 200 && response['body']) {
			user = response['body']['user']
			dispatch(updateUser(user));
		};
	};

	const openMenu = () => {
		document.getElementById("menu").classList.add("showing");
		document.getElementById("menuOverlay").classList.remove("op0", "pen");
	}

	const closeMenu = () => {
		document.getElementById("menu").classList.remove("showing");
		document.getElementById("menuOverlay").classList.add("op0", "pen");
	}

	useEffect(() => {
		if (localStorage.getItem("token")) checkLogin();
	}, []);

	const checkLogin = async () => {
		await getRequest("/user/checkLogin");
	}

	const logout = () => {
		setLogout();
		// setTimeout(()=> navigation('/'), 500);
	}

	//TODO: move to redux
	let loggedIn = localStorage.getItem("token") || localStorage.getItem("userId");

	return (
		<header className="bw0px bbw5px bss bo3 pf t0 l0 zi2 w100 shadow bg1">
			<nav className="container wsn df w100 jcsb">
				<div className="df jcsb w100">
					<Link className="df" to={ROUTES.HOME}>
						<img className="py0-5rem h3rem pr1rem" src={require(`../../images/logo.svg`)} alt="TODO" />
					</Link>
					<svg onClick={() => openMenu()} className="cp p1rem w2rem h2rem dn-xl f3" viewBox="0 0 448 512">
						<path d="M16 288h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16z" className="pen td250 fa-secondary" />
						<path className="pen td250 fa-primary" d="M432 384H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H16A16 16 0 0 0 0 80v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16z" />
					</svg>
					<div onClick={() => closeMenu()} id="menuOverlay" className="backdrop zi4 cp pen op0 pf t0 l0 w100 h100 td500"></div>
					<div id="menu" className="menu-safari c1 c6-xl w100 jcsb-xl td500 df fdc fdr-xl jcc pf t0 r0 pr-xl wsn">
						<div className="df fdc fdr-xl">
							<div className="mxa menuHover pr df py0-5rem px1rem">
								<span className="usn df aic">
									For Companies
									<svg className="ml0-5rem w1rem h1rem f1 f6-xl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
										<path className="fa-secondary" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
									</svg>
								</span>
								<div className="miw100 bsbb menu bw0px bbw5px bss bo3 zi1 bg3 bg1-xl shadow td250 op0 pen p0-5rem pa t100 l0 df fdc">
									<Link onClick={() => closeMenu()} className="bsbb w100 td250 c4-hover p0-5rem ass" to={ROUTES.INVESTMENT_READY}>
										Investment Ready
									</Link>
									<Link onClick={() => closeMenu()} className="bsbb w100 td250 c4-hover p0-5rem ass" to={ROUTES.GROWTH_READY}>
										Growth Ready
									</Link>
									<Link onClick={() => closeMenu()} className="bsbb w100 td250 c4-hover p0-5rem ass" to={ROUTES.EXIT_READY}>
										Exit Ready
									</Link>
								</div>
							</div>
							<div className="mxa menuHover pr df py0-5rem px1rem">
								<span className="usn df aic">
									For Advisors
									<svg className="ml0-5rem w1rem h1rem f1 f6-xl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
										<path className="fa-secondary" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
									</svg>
								</span>
								<div className="miw100 bsbb menu bw0px bbw5px bss bo3 zi1 bg3 bg1-xl shadow td250 op0 pen p0-5rem pa t100 l0 df fdc">
									<Link onClick={() => closeMenu()} className="bsbb w100 td250 c4-hover p0-5rem ass" to={ROUTES.ACCOUNTANTS}>
										Accountants
									</Link>
									<Link onClick={() => closeMenu()} className="bsbb w100 td250 c4-hover p0-5rem ass" to={ROUTES.LAWYERS}>
										Lawyers
									</Link>
								</div>
							</div>
							<div className="mxa menuHover pr df py0-5rem px1rem">
								<span className="usn df aic">
									For Investors
									<svg className="ml0-5rem w1rem h1rem f1 f6-xl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
										<path className="fa-secondary" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
									</svg>
								</span>
								<div className="miw100 bsbb menu bw0px bbw5px bss bo3 zi1 bg3 bg1-xl shadow td250 op0 pen p0-5rem pa t100 l0 df fdc">
									<Link onClick={() => closeMenu()} className="bsbb w100 td250 c4-hover p0-5rem ass" to={ROUTES.ANGEL_NETWORKS}>
										Angel Networks
									</Link>
									<Link onClick={() => closeMenu()} className="bsbb w100 td250 c4-hover p0-5rem ass" to={ROUTES.ANGEL_INVESTORS}>
										Angel Investors
									</Link>
									<Link onClick={() => closeMenu()} className="bsbb w100 td250 c4-hover p0-5rem ass" to={ROUTES.VENTURE_CAPITAL}>
										Venture Capital Firms
									</Link>
									<Link onClick={() => closeMenu()} className="bsbb w100 td250 c4-hover p0-5rem ass" to={ROUTES.PRIVATE_EQUITY}>
										Private Equity Firms
									</Link>
								</div>
							</div>
							<Link onClick={() => closeMenu()} className="td250 c4-hover py0-5rem px1rem" to={ROUTES.PRICING_PLAN}>
								Pricing Plans
							</Link>
							<Link onClick={() => closeMenu()} className="td250 c4-hover py0-5rem px1rem" to={ROUTES.ABOUT}>
								About Us
							</Link>
						</div>
						<div className="df fdc fdr-xl">
							{loggedIn
								? <>
									<Link onClick={() => closeMenu()} to={user['role'] === "Advisor" ? ROUTES.ADVISOR : user['role'] == "Investor" ? ROUTES.INVESTOR : ROUTES.DASHBOARD} style={{ textDecoration: 'none' }} className="bsbb jcc w200px wa-xl df aic shadow fw500 py0-5rem px1rem c1 bg3 br5px my1rem my0-xl mr1rem-xl cp">
										Dashboard
										<svg className="pen ml0-5rem h1rem f1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
											<path className="fa-primary" d="M342.6 278.6l-128 128c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 288H32C14.31 288 0 273.7 0 256s14.31-32 32-32h210.8L169.4 150.6c-12.5-12.5-12.5-32.75 0-45.25c12.49-12.49 32.74-12.51 45.25 0l128 128C355.1 245.9 355.1 266.1 342.6 278.6z" />
											<path className="fa-secondary" d="M416 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c17.67 0 32 14.33 32 32v256c0 17.67-14.33 32-32 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c53.02 0 96-42.98 96-96V128C512 74.98 469 32 416 32z" />
										</svg>
									</Link>
									<Link onClick={() => logout()} className="bsbb jcc w200px wa-xl df aic shadow fw500 py0-5rem px1rem c1 bg3 br5px my1rem my0-xl mr1rem-xl cp" to='#'>
										Logout
									</Link>
								</>
								: <>
									<span id='openLoginPopup' onClick={() => dispatch(toggleModal({ open: 'login' }))} className="bsbb jcc w200px wa-xl df aic shadow fw500 py0-5rem px1rem c1 bg3 br5px my1rem my0-xl mr1rem-xl cp">
										Login
										<svg className="pen ml0-5rem h1rem f1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
											<path className="fa-primary" d="M342.6 278.6l-128 128c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 288H32C14.31 288 0 273.7 0 256s14.31-32 32-32h210.8L169.4 150.6c-12.5-12.5-12.5-32.75 0-45.25c12.49-12.49 32.74-12.51 45.25 0l128 128C355.1 245.9 355.1 266.1 342.6 278.6z" />
											<path className="fa-secondary" d="M416 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c17.67 0 32 14.33 32 32v256c0 17.67-14.33 32-32 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c53.02 0 96-42.98 96-96V128C512 74.98 469 32 416 32z" />
										</svg>
									</span>
									<span id='openRegisterPopup' onClick={() => dispatch(toggleModal({ open: 'register' }))} className="bsbb jcc w200px wa-xl df aic shadow fw500 py0-5rem px1rem c1 bg4 br5px cp">
										Register
										<svg className="pen ml0-5rem h1rem f1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
											<path className="fa-primary" d="M616 200h-48v-48C568 138.8 557.3 128 544 128s-24 10.75-24 24v48h-48C458.8 200 448 210.8 448 224s10.75 24 24 24h48v48C520 309.3 530.8 320 544 320s24-10.75 24-24v-48h48C629.3 248 640 237.3 640 224S629.3 200 616 200z" />
											<path className="fa-secondary" d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3C0 496.5 15.52 512 34.66 512h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" />
										</svg>
									</span>
								</>
							}
						</div>
					</div>
				</div>
			</nav>
		</header >
	);
}
