import Cropper from "react-cropper";
import Modal from "@mui/material/Modal";
import React, { useState, useEffect } from "react";
import Input from "react-phone-number-input/input";
import { useSelector, useDispatch } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import "cropperjs/dist/cropper.css";
import { UserIcon } from "./Advisor/Advisors.js";
import { updateUser } from "../../reducers/userReducer";
import AnimatedPage from "../../components/AnimatedPage";
import { toggleModal } from "../../reducers/modalReducer";
import { hasValidAdvisor } from "./Advisor/BusinessCards.js";
import { getRequest, showMessage, postRequest } from "../../config";
import HeadingSubheadingContent from "../../components/HeadingSubheadingContent";

const VALID_EMAIL_REGX = /^\S+@\S+\.\S+$/
const cropper = React.createRef(null);

export default function Profile() {
  const [firstNameError, setFirstNameError] = useState('');
const [lastNameError, setLastNameError] = useState('');
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  const reduxUser = useSelector((state) => state.user);

  const [user, setUser] = useState({});
  const [verify, setVerify] = useState();
  const [userProfile, setUserProfile] = useState("");
  const [profileClass, setProfileClass] = useState("client-profile");
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getUser();
    document.getElementById("title").autocomplete = "off";
  }, []);

  useEffect(() => {
    setUser({ ...reduxUser });
    if (reduxUser)
      setProfileClass(
        reduxUser["role"] == "Advisor"
          ? "advisor-profile"
          : reduxUser["role"] == "Investor"
            ? "investor-profile"
            : "client-profile"
      );
  }, [reduxUser]);

  const getUser = async () => {
    const res = await getRequest("/user");
    if (!res || res["code"] != 200) return;
    dispatch(updateUser(res["body"]["user"]));
  };

  const changePassword = async () => {
    if (!user.password) return showMessage("Enter your current password");
    if (!user.newPassword) return showMessage("Enter a new password");
    if (!user.confirmNewPassword)
      return showMessage("Confirm your new password");
    if (user.newPassword !== user.confirmNewPassword)
      return showMessage("New and confirmed passwords don't match");

    const oldPassword = user.password;
    const newPassword = user.newPassword;

    const res = await postRequest(`/user/changePassword`, { oldPassword, newPassword });
    if (!res || res["code"] != 200) return;
    if (res["code"] == 200) setUser({ ...user, password: '', newPassword: '', confirmNewPassword: '' });
    showMessage(res["message"]);
  };

  // const inputChanged = (e) => {
  //   const el = e.target;
  //   setUser({ ...user, [el.name]: el.type === "checkbox" ? el.checked : el.value, });

  //   if (el.name === "twoFaEnabled" && !user["twoFaEnabled"]) enableAuthenticator();
  //   else if (el.name === "smsEnabled" && !user["smsEnabled"]) sendCode();
  // };


  const inputChanged = (e) => {
    const { name, type } = e.target;
    const value = type === "checkbox" ? e.target.checked : e.target.value;

    if (name === "firstName") setFirstNameError('');
    if (name === "lastName") setLastNameError('');
    if ((name === "firstName" || name === "lastName") && !/^[a-zA-Z\s]+$/.test(value)) {const errorMessage = `${name === "firstName" ? "First name" : "Last name"} should not contain numbers or special characters`;
        if (name === "firstName") setFirstNameError(errorMessage);
        if (name === "lastName") setLastNameError(errorMessage);
        
    }

    setUser({ ...user, [name]: value });

    if (name === "twoFaEnabled" && !user["twoFaEnabled"]) enableAuthenticator();
    else if (name === "smsEnabled" && !user["smsEnabled"]) sendCode();
};

  const enableAuthenticator = async () => {
    const res = await getRequest("/user/enable2Factor");
    if (!res || res["code"] != 200) {
      return showMessage(res["message"]);
    }

    const { qrData, secret, backupCodes } = res.body;
    dispatch(toggleModal({ open: "authenticator" }));
    document.getElementById("qrCode").src = qrData;
    // document.getElementById("secret").innerText = secret;
    // document.getElementById("backupCodes").innerText = backupCodes;
  };

  const sendCode = async () => {
    const phone = user["phone"];
    if (!phone) {
      showMessage("Please provide phone number");
      return;
    }
    if (!isValidPhoneNumber(phone)) {
      showMessage("Please provide valid phone number");
      return;
    }

    const response = await postRequest("/user/sendSMS", { phone });
    showMessage(response["message"]);
    if (!response || response['code'] !== 200) return;

    setVerify("Phone");
    dispatch(toggleModal({ open: "verify" }));
  };

  const verifySMSCode = async (e) => {
    const authKey = e.target.value;
    if (authKey.length < 6) return;

    const response = await postRequest("/user/enableSMS", { authKey });
    if (!response) return;
    showMessage(response["message"]);
    getUser();
    dispatch(toggleModal({ open: "" }));
  };

  const verifyAuthenticatorCode = async (e) => {
    const tokenCode = e.target.value;
    if (tokenCode.length < 6) return;

    const response = await postRequest("/user/verify2Factor", { tokenCode });
    if (!response) return;
    showMessage(response["message"]);

    getUser();
    dispatch(toggleModal({ open: "" }));
  };

  const handleProfile = async (e) => {
    let profile = e.target.files[0];
    if (profile && profile.size > 5 * 1024 * 1024)
      return showMessage("Photo Size must be less than 5MB");
    profile = await URL.createObjectURL(profile);
    setUserProfile(profile);
  };

  const updateProfile = async () => {
    let attachment = [];
    if (cropper.current && userProfile) {
      setLoading(true)
      let imageToUpload = cropper?.current?.getCroppedCanvas()?.toDataURL();
      let res = await fetch(imageToUpload);
      let blob = await res.blob();
      const coverFile = new File([blob], "profile image", {
        type: "image/png",
      });
      attachment.push(coverFile);
      setUserProfile(imageToUpload);
      setUser((prevUser) => ({ ...prevUser, profile: imageToUpload }));
      const response = await postRequest(`/user/updateProfile`, { attachment });
      if (response || response["code"] == 200) {
        getUser();
        dispatch(toggleModal({ open: "" }));
        setLoading(false)
      } else { setLoading(false) }
      showMessage(response.message);
    }
    else {
      showMessage("Please upload image first")
    }
  };

  const saveProfile = async () => {
    //Validate fisrtname and email not to be empty
    if (user?.firstName == '') { return showMessage("First name can not be empty") }
    else if (user?.lastName === '') { return showMessage("Last name can not be empty") }
    else if (user?.email === '') { return showMessage('Email can not be empty') }
    else if (user?.title === '') { return showMessage('Title can not be empty') }
    else if (!VALID_EMAIL_REGX.test(user?.email)) { return showMessage('Enter valid email') }
    setLoading(true)
    const userChanges = {};
    for (const key of Object.keys(user))
      if (user[key] !== reduxUser[key]) userChanges[key] = user[key];
    const response = await postRequest(`/user/updateProfile`, userChanges);
    if (response || response["code"] == 200) {
      setUser({ ...user })
      getUser();
      dispatch(toggleModal({ open: "" }));
      setLoading(false)
    }
    else { setLoading(false) }
    showMessage(response.message);

    // TODO: if successful run: dispatch(updateUser(user))
  };


  return (
    <AnimatedPage>
      <div className="container py5rem df fdc">
        <HeadingSubheadingContent
          wrapperClass="mb2rem tac"
          heading="Profile"
          subheading="Edit Your Diligentsia User Profile"
        />
        <div className="maw600px mxa w100 df fdc gg1rem">
          <form autoComplete="off" className="maw600px mxa w100 df fdc gg1rem">
            {user["role"] == "Advisor" && <div className="df jce"><UserIcon color={hasValidAdvisor(user) ? '#008000' : '#F44336'} /></div>}
            <div className="form-group df jsc">
              <div className="img-upload-area pr">
                <div className="img-box pr shadow">
                  <img src={user["profile"] || require("../../../images/profile-photo.jpeg")} alt="Profile"
                    className={`profile-img w7rem h7rem ${profileClass}`} />
                  <div className="input-file">
                    <span
                      className="add-btn bg5"
                      onClick={() => { dispatch(toggleModal({ open: "uploadProfile" })), setUserProfile("") }}>
                      <svg viewBox="0 0 487 487" className="zi3">
                        <g>
                          <path
                            d="M308.1,277.95c0,35.7-28.9,64.6-64.6,64.6s-64.6-28.9-64.6-64.6s28.9-64.6,64.6-64.6S308.1,242.25,308.1,277.95z
													M440.3,116.05c25.8,0,46.7,20.9,46.7,46.7v122.4v103.8c0,27.5-22.3,49.8-49.8,49.8H49.8c-27.5,0-49.8-22.3-49.8-49.8v-103.9
													v-122.3l0,0c0-25.8,20.9-46.7,46.7-46.7h93.4l4.4-18.6c6.7-28.8,32.4-49.2,62-49.2h74.1c29.6,0,55.3,20.4,62,49.2l4.3,18.6H440.3z
													M97.4,183.45c0-12.9-10.5-23.4-23.4-23.4c-13,0-23.5,10.5-23.5,23.4s10.5,23.4,23.4,23.4C86.9,206.95,97.4,196.45,97.4,183.45z
													M358.7,277.95c0-63.6-51.6-115.2-115.2-115.2s-115.2,51.6-115.2,115.2s51.6,115.2,115.2,115.2S358.7,341.55,358.7,277.95z"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="dg gtcr2-md gg1rem">
              <div className="pr">
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  className="bg1 pl4rem shadow w100 br5px p1rem"
                  value={user.firstName}
                  onChange={inputChanged}
                />
                    {firstNameError && <div style={{ color: 'red', fontSize: '0.6rem', marginTop: '4px' }} className="error-message">{firstNameError}</div>}
                <svg
                  className="center-vertically f3 pa l0 ml1rem w2rem"
                  viewBox="0 0 640 512"
                >
                  <path
                    d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z"
                    className="fa-secondary"
                  ></path>
                </svg>
              </div>
              <div className="pr">
                <input
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  className="bg1 pl4rem shadow w100 br5px p1rem"
                  value={user.lastName}
                  onChange={inputChanged}
                />
               {lastNameError && <div style={{ color: 'red', fontSize: '0.6rem', marginTop: '4px' }} className="error-message">{lastNameError}</div>}
                <svg
                  className="center-vertically f3 pa l0 ml1rem w2rem"
                  viewBox="0 0 640 512"
                >
                  <path
                    d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z"
                    className="fa-secondary"
                  ></path>
                </svg>
              </div>
            </div>
            <div className="pr">
              <input
                disabled
                type="email"
                name="email"
                placeholder="Email"
                className="bg1 pl4rem shadow w100 br5px p1rem"
                value={user.email}
                onChange={inputChanged}
              />
              <svg
                className="center-vertically f3 pa l0 ml1rem w2rem"
                viewBox="0 0 512 512"
              >
                <path
                  d="M256.47 352h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V400a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V183.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52zM464 64H48a48 48 0 0 0-48 48v19a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4 23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131v-19a48 48 0 0 0-48-48z"
                  className="fa-secondary"
                ></path>
                <path
                  d="M512 131v52.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V131a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.69 12.12 49.75 41.4 72.93 41.4h.94c23.18 0 56.24-29.28 72.93-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131z"
                  className="fa-primary"
                ></path>
              </svg>
              {(user.emailVerified && (
                <span className="pa r0 center-vertically bg3 c1 h100 px1rem bbrr5px btrr5px df aic">
                  Verified
                </span>
              )) || (
                  <span
                    onClick={() => {
                      setVerify("Email"),
                        dispatch(toggleModal({ open: "verify" }));
                    }}
                    className="pa r0 center-vertically bg4 c1 h100 px1rem bbrr5px btrr5px df cp aic"
                  >
                    Click to Verify
                  </span>
                )}
            </div>
            <div className="pr">
            <Input
                className="bg1 w100 pl4rem shadow br5px p1rem"
                name="phone"
                country="GB"
                international
                withCountryCallingCode
                value={user.phone}
                onChange={(value) => setUser({ ...user, phone: value })}
                maxLength={16} // Add this line to limit the input to 15 characters
            />
              <svg
                className="center-vertically f3 pa l0 ml1rem w2rem"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  className="fa-primary"
                  d="M352 48V384H32V48C32 21.5 53.5 0 80 0h224C330.5 0 352 21.5 352 48z"
                />
                <path
                  className="fa-secondary"
                  d="M32 384v80C32 490.5 53.5 512 80 512h224c26.5 0 48-21.5 48-48V384H32zM240 447.1C240 456.8 232.8 464 224 464H159.1C151.2 464 144 456.8 144 448S151.2 432 160 432h64C232.8 432 240 439.2 240 447.1z"
                />
              </svg>
              {(reduxUser.smsEnabled && (
                <span className="pa r0 center-vertically bg3 c1 h100 px1rem bbrr5px btrr5px df aic">
                  Verified
                </span>
              )) || (
                  <span onClick={() => sendCode()} className="pa r0 center-vertically bg4 c1 h100 px1rem bbrr5px btrr5px df cp aic">
                    Click to Verify
                  </span>
                )}
            </div>
            <div className="pr">
              <input
                name="linkedIn"
                type="text"
                placeholder="LinkedIn"
                className="bg1 pl4rem shadow w100 br5px p1rem"
                value={user.linkedIn}
                onChange={inputChanged}
              />
              <svg
                className="center-vertically f3 pa l0 ml1rem w2rem"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 310 310"
              >
                <g id="XMLID_801_">
                  <path
                    className="fa-primary"
                    id="XMLID_802_"
                    d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
									C77.16,101.969,74.922,99.73,72.16,99.73z"
                  />
                  <path
                    className="fa-secondary"
                    id="XMLID_803_"
                    d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
									c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"
                  />
                  <path
                    className="fa-primary"
                    id="XMLID_804_"
                    d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
									c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
									c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
									C310,145.43,300.549,94.761,230.454,94.761z"
                  />
                </g>
              </svg>
            </div>
            <div className="pr">
              <input
                id="title"
                name="title"
                type="text"
                placeholder="Job Title"
                className="bg1 pl4rem shadow w100 br5px p1rem"
                value={user.title}
                onChange={inputChanged}
              />
              <svg
                className="center-vertically f3 pa l0 ml1rem w2rem"
                viewBox="0 0 640 512"
              >
                <path
                  d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z"
                  className="fa-secondary"
                ></path>
              </svg>
            </div>
          </form>
          <span className="fs1-5rem fw600 mt1rem mb0-5rem">
            Choose how to login securely
          </span>
          <div className="dg gtcr3-md gg1rem tac">
            <label className={(reduxUser.phone ? "" : "op40 pen") + " shadow br5px p1rem bg1 df fdc jcc aic cp bss td250 bw5px " + (user.smsEnabled ? "bo3" : "bo1")}>
              <input
                name="smsEnabled"
                className="dn"
                type="checkbox"
                onChange={inputChanged}
              />
              <svg
                className="f3 h3rem"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  className="fa-primary"
                  d="M352 48V384H32V48C32 21.5 53.5 0 80 0h224C330.5 0 352 21.5 352 48z"
                />
                <path
                  className="fa-secondary"
                  d="M32 384v80C32 490.5 53.5 512 80 512h224c26.5 0 48-21.5 48-48V384H32zM240 447.1C240 456.8 232.8 464 224 464H159.1C151.2 464 144 456.8 144 448S151.2 432 160 432h64C232.8 432 240 439.2 240 447.1z"
                />
              </svg>
              <span className="mt0-5rem w100 fs1rem lh3 usn">
                Use SMS Authentication
              </span>
            </label>
            <label
              className={
                "shadow br5px p1rem bg1 df fdc jcc aic cp bss td250 bw5px " +
                (user.twoFaEnabled ? "bo3" : "bo1")
              }
            >
              <input
                name="twoFaEnabled"
                className="dn"
                type="checkbox"
                onChange={inputChanged}
              />
              <img
                className="h3rem"
                src={require("../../../images/google_authenticator.svg")}
              />
              <span className="mt0-5rem w100 fs1rem lh3 usn">
                Use App e.g. Google Authenticator
              </span>
            </label>
            <label
              className={
                "shadow br5px p1rem bg1 df fdc jcc aic cp bss td250 bw5px " +
                (user.emailEnabled ? "bo3" : "bo1")
              }
            >
              <input
                id="email"
                name="emailEnabled"
                className="dn"
                type="checkbox"
                onChange={inputChanged}
              />
              <svg className="f3 h3rem" viewBox="0 0 512 512">
                <path
                  d="M256.47 352h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V400a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V183.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52zM464 64H48a48 48 0 0 0-48 48v19a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4 23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131v-19a48 48 0 0 0-48-48z"
                  className="fa-secondary"
                ></path>
                <path
                  d="M512 131v52.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V131a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.69 12.12 49.75 41.4 72.93 41.4h.94c23.18 0 56.24-29.28 72.93-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131z"
                  className="fa-primary"
                ></path>
              </svg>
              <span className="mt0-5rem w100 fs1rem lh3 usn">
                Use Email Authentication
              </span>
            </label>
          </div>
          <span
            onClick={() => { if (!loading) { saveProfile() } }}
            className={`shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp ttu w100 bsbb tac mb1rem df jcc aic ${loading && 'button-disabled'}`}
          >
            Save
            <svg
              className="pen ml0-5rem h1rem f1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                className="fa-primary"
                d="M215 255l88-88C305.6 164.5 311.5 160 320 160s14.4 4.467 16.97 7.031l88 88c9.375 9.375 9.375 24.56 0 33.94s-24.56 9.375-33.94 0L344 241.9V392c0 13.25-10.75 24-24 24s-24-10.75-24-24V241.9L248.1 288.1c-9.375 9.375-24.56 9.375-33.94 0S205.7 264.4 215 255z"
              />
              <path
                className="fa-secondary"
                d="M537.6 226.6C541.7 215.9 544 204.2 544 192c0-53-43-96-96-96c-19.69 0-38.09 6-53.31 16.2C367 64.2 315.3 32 256 32C167.6 32 96 103.6 96 192c0 2.703 .1094 5.445 .2031 8.133C40.2 219.8 0 273.2 0 336C0 415.5 64.5 480 144 480H512c70.69 0 128-57.3 128-128C640 290.1 596 238.4 537.6 226.6zM215 255l88-88C305.6 164.5 311.5 160 320 160s14.4 4.467 16.97 7.031l88 88c9.375 9.375 9.375 24.56 0 33.94s-24.56 9.375-33.94 0L344 241.9V392c0 13.25-10.75 24-24 24s-24-10.75-24-24V241.9L248.1 288.1c-9.375 9.375-24.56 9.375-33.94 0S205.7 264.4 215 255z"
              />
            </svg>
          </span>

          <h2 className="c4 fs1-5rem fs2rem-sm ttu w100 tac mt1rem">Change Password</h2>
          <div className="pr">
            <input
              name="password"
              type="password"
              placeholder="Current Password"
              className="bg1 pl4rem shadow w100 br5px p1rem"
              value={user.password}
              onChange={inputChanged}
            />
            <svg
              className="center-vertically f3 pa l0 ml1rem w2rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                className="fa-primary"
                d="M336 0c-97.2 0-176 78.8-176 176c0 14.71 2.004 28.93 5.406 42.59l128 127.1C307.1 349.1 321.3 351.1 336 351.1c97.2 0 176-78.8 176-176S433.2 0 336 0zM376 176c-22.09 0-40-17.91-40-40S353.9 96 376 96S416 113.9 416 136S398.1 176 376 176z"
              />
              <path
                className="fa-secondary"
                d="M165.4 218.6l-156 156C3.371 380.6 0 388.8 0 397.3V496C0 504.8 7.164 512 16 512l96 0c8.836 0 16-7.164 16-16v-48h48c8.836 0 16-7.164 16-16v-48h57.37c4.242 0 8.312-1.688 11.31-4.688l32.72-32.72L165.4 218.6z"
              />
            </svg>
          </div>
          <div className="pr">
            <input
              name="newPassword"
              type="password"
              placeholder="New Password"
              className="bg1 pl4rem shadow w100 br5px p1rem"
              value={user.newPassword}
              onChange={inputChanged}
            />
            <svg
              className="center-vertically f3 pa l0 ml1rem w2rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                className="fa-primary"
                d="M64 223.1h320c35.35 0 64 28.65 64 64v160c0 35.34-28.65 64-64 64H64c-35.35 0-64-28.66-64-64v-160C0 252.7 28.65 223.1 64 223.1z"
              />
              <path
                className="fa-secondary"
                d="M368 144V224h-64V144C304 99.88 268.1 64 224 64S144 99.88 144 144V224h-64V144c0-79.41 64.59-144 144-144S368 64.59 368 144z"
              />
            </svg>
          </div>
          <div className="pr">
            <input
              name="confirmNewPassword"
              type="password"
              placeholder="Confirm New Password"
              className="bg1 pl4rem shadow w100 br5px p1rem"
              value={user.confirmNewPassword}
              onChange={inputChanged}
            />
            <svg
              className="center-vertically f3 pa l0 ml1rem w2rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                className="fa-primary"
                d="M64 223.1h320c35.35 0 64 28.65 64 64v160c0 35.34-28.65 64-64 64H64c-35.35 0-64-28.66-64-64v-160C0 252.7 28.65 223.1 64 223.1z"
              />
              <path
                className="fa-secondary"
                d="M368 144V224h-64V144C304 99.88 268.1 64 224 64S144 99.88 144 144V224h-64V144c0-79.41 64.59-144 144-144S368 64.59 368 144z"
              />
            </svg>
          </div>
          <span onClick={() => changePassword()} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp ttu w100 bsbb tac mb1rem df jcc aic">
            Change Password
            <svg
              className="pen ml0-5rem h1rem f1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                className="fa-primary"
                d="M215 255l88-88C305.6 164.5 311.5 160 320 160s14.4 4.467 16.97 7.031l88 88c9.375 9.375 9.375 24.56 0 33.94s-24.56 9.375-33.94 0L344 241.9V392c0 13.25-10.75 24-24 24s-24-10.75-24-24V241.9L248.1 288.1c-9.375 9.375-24.56 9.375-33.94 0S205.7 264.4 215 255z"
              />
              <path
                className="fa-secondary"
                d="M537.6 226.6C541.7 215.9 544 204.2 544 192c0-53-43-96-96-96c-19.69 0-38.09 6-53.31 16.2C367 64.2 315.3 32 256 32C167.6 32 96 103.6 96 192c0 2.703 .1094 5.445 .2031 8.133C40.2 219.8 0 273.2 0 336C0 415.5 64.5 480 144 480H512c70.69 0 128-57.3 128-128C640 290.1 596 238.4 537.6 226.6zM215 255l88-88C305.6 164.5 311.5 160 320 160s14.4 4.467 16.97 7.031l88 88c9.375 9.375 9.375 24.56 0 33.94s-24.56 9.375-33.94 0L344 241.9V392c0 13.25-10.75 24-24 24s-24-10.75-24-24V241.9L248.1 288.1c-9.375 9.375-24.56 9.375-33.94 0S205.7 264.4 215 255z"
              />
            </svg>
          </span>
        </div>
      </div>

      {/* Authenticator */}
      <Modal
        open={modal["open"] == "authenticator"}
        onClose={() => dispatch(toggleModal({ open: "" }))}
        className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic"
      >
        <div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
          <button
            className="close-popup"
            onClick={() => dispatch(toggleModal({ open: "" }))}
          >
            <svg
              style={{ position: "relative", top: "4px" }}
              className="pen fds h1rem f1 mr0-5rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                className="pen fa-primary"
                d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
              />
            </svg>
          </button>
          <h1 className="text-gradient fs2rem tac lh1 fw600 mb1rem">
            Authenticator Setup
          </h1>
          <h2 className="c4 fs1rem ttu w100 tac mb2rem">
            Scan the QR Code in your Authenticator App
          </h2>
          <img className="maw400px mxa db" id="qrCode" />
          <p>
            <span id="secret"></span>
          </p>
          <p>
            <span id="backupCodes"></span>
          </p>
          <div className="df fdc gg1rem maw400px mxa">
            <p>Once scanned, enter the 6 digit code generated by the app</p>
            <div className="pr">
              <input
                type="text"
                placeholder="Enter 6 Digit Code"
                className="bg1 pl4rem shadow w100 br5px p1rem"
                onChange={verifyAuthenticatorCode}
              />
              <svg
                className="center-vertically f3 pa l0 ml1rem w2rem"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  className="fa-primary"
                  d="M416 127.1h-58.23l-10.67 64H416c17.67 0 32-14.32 32-31.1C448 142.3 433.7 127.1 416 127.1zM0 352c0 17.67 14.33 31.1 32 31.1h58.23l10.67-64H32C14.33 319.1 0 334.3 0 352zM384 319.1H165.8l-10.67 64H384c17.67 0 32-14.33 32-31.1C416 334.3 401.7 319.1 384 319.1zM64 191.1h218.2l10.67-64H64c-17.67 0-32 14.33-32 32C32 177.7 46.33 191.1 64 191.1z"
                />
                <path
                  className="fa-secondary"
                  d="M341.3 32.43c-17.53-2.875-33.92 8.891-36.83 26.3l-43.54 261.3h64.88l41.79-250.7C370.5 51.83 358.7 35.34 341.3 32.43zM240.4 442.7c-2.906 17.44 8.875 33.92 26.3 36.83C268.5 479.9 270.3 480 272 480c15.36 0 28.92-11.09 31.53-26.73l11.54-69.27h-64.88L240.4 442.7zM80.44 442.7c-2.906 17.44 8.875 33.92 26.3 36.83C108.5 479.9 110.3 480 112 480c15.36 0 28.92-11.09 31.53-26.73L187.1 191.1H122.2L80.44 442.7zM181.3 32.43c-17.53-2.875-33.92 8.891-36.83 26.3L132.9 127.1H197.8l9.789-58.74C210.5 51.83 198.7 35.34 181.3 32.43z"
                />
              </svg>
            </div>
          </div>
        </div>
      </Modal>

      {/* Verify */}
      <Modal
        open={modal["open"] == "verify"}
        onClose={() => dispatch(toggleModal({ open: "" }))}
        className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic"
      >
        <div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
          <button
            className="close-popup"
            onClick={() => dispatch(toggleModal({ open: "" }))}
          >
            <svg
              style={{ position: "relative", top: "4px" }}
              className="pen fds h1rem f1 mr0-5rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                className="pen fa-primary"
                d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
              />
            </svg>
          </button>
          <h1 className="text-gradient fs3rem tac lh1 fw600 mb2rem">
            {verify} Verification
          </h1>
          <h2 className="c4 fs1-5rem fs2rem-sm ttu w100 tac mb2rem">
            Check your {verify} for a code
          </h2>
          <div className="df fdc gg1rem maw400px mxa">
            <div className="pr">
              <input
                type="text"
                placeholder="Enter 6 Digit Code"
                className="bg1 pl4rem shadow w100 br5px p1rem"
                onChange={verifySMSCode}
              />
              <svg
                className="center-vertically f3 pa l0 ml1rem w2rem"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  className="fa-primary"
                  d="M416 127.1h-58.23l-10.67 64H416c17.67 0 32-14.32 32-31.1C448 142.3 433.7 127.1 416 127.1zM0 352c0 17.67 14.33 31.1 32 31.1h58.23l10.67-64H32C14.33 319.1 0 334.3 0 352zM384 319.1H165.8l-10.67 64H384c17.67 0 32-14.33 32-31.1C416 334.3 401.7 319.1 384 319.1zM64 191.1h218.2l10.67-64H64c-17.67 0-32 14.33-32 32C32 177.7 46.33 191.1 64 191.1z"
                />
                <path
                  className="fa-secondary"
                  d="M341.3 32.43c-17.53-2.875-33.92 8.891-36.83 26.3l-43.54 261.3h64.88l41.79-250.7C370.5 51.83 358.7 35.34 341.3 32.43zM240.4 442.7c-2.906 17.44 8.875 33.92 26.3 36.83C268.5 479.9 270.3 480 272 480c15.36 0 28.92-11.09 31.53-26.73l11.54-69.27h-64.88L240.4 442.7zM80.44 442.7c-2.906 17.44 8.875 33.92 26.3 36.83C108.5 479.9 110.3 480 112 480c15.36 0 28.92-11.09 31.53-26.73L187.1 191.1H122.2L80.44 442.7zM181.3 32.43c-17.53-2.875-33.92 8.891-36.83 26.3L132.9 127.1H197.8l9.789-58.74C210.5 51.83 198.7 35.34 181.3 32.43z"
                />
              </svg>
            </div>
          </div>
        </div>
      </Modal>

      {/* Upload Profile */}
      <Modal
        open={modal["open"] == "uploadProfile"}
        onClose={() => dispatch(toggleModal({ open: "" }))}
        className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic"
      >
        <div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
          <button
            className="close-popup"
            onClick={() => {
              setUserProfile(""), dispatch(toggleModal({ open: "" }));
            }}
          >
            <svg
              style={{ position: "relative", top: "4px" }}
              className="pen fds h1rem f1 mr0-5rem"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                className="pen fa-primary"
                d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
              />
            </svg>
          </button>

          <h1 className="text-gradient fs2rem tac lh1 fw600 mb2rem">
            Upload Profile Picture
          </h1>
          {userProfile == "" ? (
            <label
              for="profile"
              className="w100 bsbb bg5 br5px tac p1rem bsd bo6 bw2px cp pr upload-pic-area"
            >
              <span className="pen fs1rem fw500 tac">Upload picture</span>
              <br />
              <div className="">
                <input
                  className=""
                  onChange={(e) => handleProfile(e)}
                  id="profile"
                  type="file"
                  accept="image/jpeg, image/jpg, image/png"
                />
              </div>
              <label>Max file size 5MB</label>
            </label>
          ) : (
            <div className="img-box pr">
              <Cropper
                style={{ maxHeight: "500px" }}
                ref={cropper}
                guides={false}
                aspectRatio={1 / 1}
                className="cropper-settings"
                src={userProfile}
              />
            </div>
          )}
          <div className="df jcse">
            <span
              onClick={() => {
                setUserProfile(""), dispatch(toggleModal({ open: "" }));
              }}
              className="shadow bg4 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem"
            >
              Cancel
            </span>
            <span
              onClick={() => { if (!loading) updateProfile() }}
              className={`shadow bg3 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem ${loading && "button-disabled"}`}
            >
              {Object.keys(userProfile).length == 0
                ? "Upload"
                : "Crop & Upload"}
            </span>
          </div>
        </div>
      </Modal>
    </AnimatedPage>
  );
}
