import React, { useState } from 'react'
import AnimatedPage from '../components/AnimatedPage'
import SmoothScrollingButton from '../components/SmoothScrollingButton'
import { useSelector, useDispatch } from 'react-redux'
import { toggleModal } from '../reducers/modalReducer'
import Modal from "@mui/material/Modal";
import { showMessage, postRequest, } from '../config'


const OPTIONS_SECTION = [
    { icon: require('../../images/landingpage/contact-us-support.png'), identifier: 'support' },
    { icon: require('../../images/landingpage/contact-us-sales.png'), identifier: 'sales' },
    { icon: require('../../images/landingpage/contact-us-patner.png'), identifier: 'patner' },
    { icon: require('../../images/landingpage/contact-us-bugs.png'), identifier: 'bugs' }
]

const SECTION_CONTENT = [
    {
        title: <div className="fw500 c15" style={{ fontSize: '39px', lineHeight: '48.52px' }}>I have a <span className="c4">customer support</span> issue ...</div>,
        subTitle: 'Please first ask our customer support bot link, FAQs troubleshooting - ',
        subTitle1: 'you can ask support team at Diligentsia by asking a question in your own words',
        image: require('../../images/landingpage/contact-us-support-img.png'),
        imageStyle: { width: '50%' },
        arrowClass: 'bottom-arrow-support',
        email: 'Support@diligentsia.co.uk',
        identifier: 'support'
    },
    {
        title: <div className="fw500 c15" style={{ fontSize: '39px', lineHeight: '48.52px' }}>I want to talk to someone in <span className="c4">sales</span> ...</div>,
        subTitle: 'Please first ask our customer support bot link, FAQs, troubleshooting. If no joy, please feel free to call.',
        image: require('../../images/landingpage/book-call.png'),
        imageStyle: { width: '50%' },
        arrowClass: 'bottom-arrow-sales',
        email: 'sales@diligentsia.co.uk',
        identifier: 'sales'
    },
    {
        title: <div className="fw500 c15" style={{ fontSize: '39px', lineHeight: '48.52px' }}>I want to <span className="c4">partner with Diligentsia</span> ...</div>,
        subTitle: 'If you are talking strategic partnering we would love to hear from you...',
        image: require('../../images/landingpage/book-call.png'),
        imageStyle: { width: '50%' },
        arrowClass: 'bottom-arrow-patner',
        email: 'sales@diligentsia.co.uk',
        identifier: 'patner'
    },
    {
        title: <div className="fw500 c15" style={{ fontSize: '39px', lineHeight: '48.52px' }}>I want to <span className="c4">report a bug</span> ...</div>,
        subTitle: 'We are still in beta, so bugs are to be expected. Please help us to help by providing details',
        subTitle1: <>(and ideally a screenshot) using this form. Any suggestions for improvement/new features also gratefully received.<br />
            <div className="pt3rem">We appreciate your help as we create our ecosystem community.</div>
        </>,
        image: require('../../images/landingpage/contact-us-bug-img.png'),
        imageStyle: { width: '50%' },
        arrowClass: 'bottom-arrow-bugs',
        email: '',
        identifier: 'bugs'
    }
]

const ContactUs = () => {

    const dispatch = useDispatch()
    let modal = useSelector((state) => state.modal);

    let [user, setUser] = useState();
    let [bugDescription, setBugDescription] = useState();
    let [attachment, setAttachment] = useState([]);
    let [onEmailHover, setOnEmailHover] = useState(false)
    let [selectOption, setSelectOption] = useState(SECTION_CONTENT?.[0])


    const onDragOver = (e) => e.preventDefault();
    const handleOptionSelect = (obj) => {
        const selectedObj = SECTION_CONTENT?.find((sectionObj, i, arr) => sectionObj?.identifier === obj?.identifier)
        setSelectOption(selectedObj)
    }

    const onImageClick = (identifier) => {
        if (identifier === "support") {
            window.Intercom('show')
        }
        else if (identifier === "bugs") {
            window.location = 'mailto:support@diligentsia.co.uk'
        }
        else {
            window.open('https://koalendar.com/e/meet-with-mark-bernstein-koaSc6kO?embed=true', '_blank')
        }
    }

    // attachment file handler
    const handleAttachmentFile = async (e) => {
        if (e.target.files) {
            let newAttachment = [];
            for (const file of e.target.files) {
                await newAttachment.push(file);
            }
            setAttachment(newAttachment);
        }
    };

    //Delete image handler
    const handleDelete = async (targetFile) => {
        let updateAttachments = await attachment.filter(
            (file) => file.name !== targetFile.name
        );
        setAttachment(updateAttachments);
    };

    //submit handler
    const handleSubmitBug = async () => {
        if (!bugDescription) return showMessage("Please add bug description!");
        const wordCount = bugDescription?.split(/\s+/).length;
        if (wordCount < 5)
            return showMessage("Please elaborate bug in minimum five words");
        let data;
        if (attachment) data = { attachment, bugDescription };
        else data = { bugDescription };
        let res = await postRequest("/user/reportBug", data);
        if (res || res["code"] == 200) {
            showMessage(res["message"]);
            dispatch(toggleModal({ open: "" }));
        }
    };


    return (
        <>
            <AnimatedPage>
                <div className="poppins-font-style bg1 pr contact-us-page oh">
                    <SmoothScrollingButton />
                    {/* Banner Section */}
                    <div className="df w100" >

                        <div className='w60 fs2-5rem fw500 c15 df aic' style={{ lineHeight: "3.45rem", paddingLeft: '11.5rem' }} >
                            How can we best help you?
                        </div>
                        <div className='w35 pr df ais bscon brnr pr8rem' >
                            <img src={require('../../images/landingpage/contact-us-bg.png')} />
                        </div>
                    </div>

                    {/* Options Section */}
                    <div className="pl10rem pr10rem pt5rem pb5rem w100" style={{ maxWidth: '100%' }}>
                        {OPTIONS_SECTION?.map((obj, i, arr) => {
                            return (
                                <>
                                    <img src={obj?.icon} className={`w20 cp pr`}
                                        style={{
                                            transform: selectOption?.identifier === obj?.identifier ? "scale(1.2)" : "scale(1)",
                                            transition: "transform 0.3s ease",
                                        }}
                                        onClick={() => handleOptionSelect(obj)}
                                    />
                                </>)
                        })}
                    </div>

                    {/* Active Section */}
                    <div className="bg20 pt3rem pb8rem px10rem w100 df pr" style={{ height: "40vh", minHeight: "40vh", background: `url(${require('../../images/landingpage/contact-us-option-bg.png')})` }}>
                        <div className={selectOption?.arrowClass} ></div>
                        <div className="w60 df fdc jcc">
                            {selectOption?.title}
                            <div className="fw400 c14 mt2rem" style={{ fontSize: '16px', lineHeight: '32px' }}>{selectOption?.subTitle}</div>
                            {selectOption?.subTitle1 ?
                                <div className="fw400 c14" style={{ fontSize: '16px', lineHeight: '32px' }}>{selectOption?.subTitle1}</div> :
                                <div className="fw400 c14" style={{ fontSize: '16px', lineHeight: '32px', visibility: 'hidden' }}>NO Text</div>
                            }

                            <div className={`df aic mt5rem ${selectOption?.identifier === "bugs" && 'vh'}`}>
                                <div className="cp" onClick={() => window.location = `mailto:${selectOption?.email}`}
                                    onMouseLeave={() => setOnEmailHover(false)}
                                    onMouseEnter={() => setOnEmailHover(true)}
                                >
                                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.25 0.5H2.75C2.15326 0.5 1.58097 0.737053 1.15901 1.15901C0.737053 1.58097 0.5 2.15326 0.5 2.75V13.25C0.5 13.8467 0.737053 14.419 1.15901 14.841C1.58097 15.2629 2.15326 15.5 2.75 15.5H19.25C19.8467 15.5 20.419 15.2629 20.841 14.841C21.2629 14.419 21.5 13.8467 21.5 13.25V2.75C21.5 2.15326 21.2629 1.58097 20.841 1.15901C20.419 0.737053 19.8467 0.5 19.25 0.5ZM18.9275 2L11.3225 8.06C11.105 8.2625 10.895 8.2625 10.6325 8.06L3.0725 2H18.9275ZM19.25 14H2.75C2.55109 14 2.36032 13.921 2.21967 13.7803C2.07902 13.6397 2 13.4489 2 13.25V3.065L9.6575 9.1625C10.0214 9.51576 10.5078 9.71462 11.015 9.7175C11.493 9.71548 11.952 9.53027 12.2975 9.2L20 3.065V13.25C20 13.4489 19.921 13.6397 19.7803 13.7803C19.6397 13.921 19.4489 14 19.25 14Z" fill={onEmailHover ? "#FC7785" : "#505050"} />
                                    </svg>
                                    <span className={`${onEmailHover ? "c4" : 'c14'} fw600 pl0-5rem`} style={{ fontSize: '18px', lineHeight: '32px' }}>
                                        {selectOption?.email}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="w40">
                            <img src={selectOption?.image} className="cp" style={{ ...selectOption?.imageStyle }} onClick={() => onImageClick(selectOption?.identifier)} />
                        </div>
                    </div>
                    <img src={require('../../images/landingpage/sphere.png')} alt='img' className="pa ar"
                        style={{ maxWidth: "5rem", left: '-30px', bottom: '-40px' }} />
                </div>
            </AnimatedPage>

            {/* REPORT A BUG  - POPUP */}
            <Modal open={modal["open"] == "report-bug"} onClose={() => dispatch(toggleModal({ open: "" }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic">
                <div className="mah90 oya bg1 shadow w100 p3rem bsbb df fdc m1rem maw600px br5px pr">
                    <button className="close-popup" onClick={() => hideBugPopup()}>
                        <svg
                            style={{ position: "relative", top: "4px" }}
                            className="pen fds h1rem f1 mr0-5rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                        >
                            <path
                                className="pen fa-primary"
                                d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                            />
                        </svg>
                    </button>
                    <h1 className="text-gradient fs2rem tac lh1 fw600 mb2rem">
                        {" "}
                        Report a Bug
                    </h1>
                    <div className="pr mb1rem">
                        <label>
                            Enter a description of the bug or suggested improvement below....{" "}
                            <br />
                            It is always useful if you are able to attach a screenshot...
                        </label>
                        <textarea
                            onChange={(e) => setBugDescription(e.target.value)}
                            rows="4"
                            value={bugDescription}
                            placeholder="Please add bug description or suggestion for improvement here"
                            className="bss bw2px bo5 w100 br5px p1rem mt1rem"
                        ></textarea>
                    </div>
                    <label
                        onDragOver={onDragOver}
                        onDrop={(e) => handleAttachmentFile(e)}
                        for="attachment"
                        className="w100 bsbb bg5 br5px df jcc aic p1rem bsd bo6 bw2px cp"
                    >
                        <span className="pen fs1rem fw500 tac">
                            Click here or drag and drop attachment (max 5MB)
                        </span>
                        <input
                            onChange={(e) => handleAttachmentFile(e)}
                            className="dn"
                            id="attachment"
                            type="file"
                            accept="image/jpeg, image/jpg, image/png"
                            multiple
                        />
                    </label>
                    <div className="df fdc aic my1rem">
                        {attachment &&
                            attachment.map((item, idx) => (
                                <div
                                    className="df jcc br25px lh1 bg3 c1 px0-5rem fw600 w50 mt0-5rem"
                                    key={item["name"]}
                                >
                                    <a href={item} target="_blank">
                                        <span className="py0-5rem pl0-5rem">
                                            {item["name"].substring(0, 20) + "..."}
                                        </span>
                                    </a>
                                    <svg
                                        onClick={() => handleDelete(item)}
                                        className="p0-5rem f9 w1rem h1rem cp"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 320 512"
                                    >
                                        <path
                                            className="pen fa-primary"
                                            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32.0 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                                        />
                                    </svg>
                                </div>
                            ))}
                    </div>
                    <div className="df jcse">
                        <span
                            onClick={() => dispatch(toggleModal({ open: "" }))}
                            className="shadow bg4 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem"
                        >
                            Cancel
                        </span>
                        <span
                            onClick={() => handleSubmitBug()}
                            className="shadow bg3 bg4-hover c1 td250 br5px px2rem py0-5rem cp ttu mr1rem bsbb tac df jcc aic mt1rem"
                        >
                            Submit
                        </span>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default ContactUs

