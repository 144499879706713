import { useEffect } from "react";

export default function Testimonials({ testimonials }) {
	function changeTestimonial(e) {
		if (e?.target?.classList.contains("op100")) return;
		if (e?.target) {
			e.target.closest("[data-scroll-testimonials]").dataset.scrollTestimonials = "";
			setTestimonial(e.target);
		}
	}

	useEffect(() => {
		const interval = setInterval(() => {
			if (document.querySelector("[data-scroll-testimonials]").dataset.scrollTestimonials)
				setTestimonial(document.querySelector(".testimonialDot.op100 + .testimonialDot.op20") || document.querySelector(".testimonialDot"));
		}, 10000);
		return () => clearInterval(interval);
	}, []);

	function setTestimonial(el) {
		const testimonialContainer = document.getElementById("testimonialContainer");
		const currentDot = document.querySelector(".testimonialDot.op100");
		const index = el.getAttribute("index");

		testimonialContainer.style.left = "calc(-" + index * 100 + "%)";

		currentDot.classList.remove("op100");
		currentDot.classList.add("op20");

		el.classList.add("op100");
		el.classList.remove("op20");
	}

	return (
		<div className="bg5">
			<div data-scroll-testimonials="true" className="container df fdc jcc py5rem">
				<h2 className="text-gradient fs2rem tac">Don't just take our word for it&hellip;</h2>
				<div id="testimonialWrapper" className="pr oxh w600px maw100 mxa">
					<div
						id="testimonialContainer"
						style={{
							width: testimonials.length + "00%",
							gridTemplateColumns: "repeat(" + testimonials.length + ", 1fr)",
						}}
						className="df pr t0 l0 td500"
					>
						{testimonials.map(i => (
							<div className="w100 df fdc p2rem jcc">
								<h4 className="c3 tac fs1-5rem mb1rem w100">{i.title}</h4>
								<p className="fs1rem mb2rem lh3">"{i.text}"</p>
								{i.author == "Katy Davidson"
									? <img className="mb0-5rem w4rem h4rem br100 obcov mla mra" src={require(`../../images/testimonials/katy-davidson.png`)} />
									: i.author == "Louise Birritteri"
										? <img className="mb0-5rem w4rem h4rem br100 obcov mla mra" src={require(`../../images/testimonials/louise-birritteri.png`)} />
										: i.author == "Rik Pancholi"
											? <img className="mb0-5rem w4rem h4rem br100 obcov mla mra" src={require(`../../images/testimonials/rik-pancholi.png`)} />
											: i.author == "Richard Whitwell"
												? <img className="mb0-5rem w4rem h4rem br100 obcov mla mra" src={require(`../../images/testimonials/richard-whitwell.png`)} />
												: <img className="mb0-5rem w4rem h4rem br100 obcov mla mra" src={require(`../../images/testimonials/barry-williams.png`)} />
								}
								<p className="tac w100 fw400 mb0-5rem">{i.author}</p>
								<p className="tac w100 fw600">{i.company}</p>
							</div>
						))}
					</div>
				</div>
				<div className="df jcc mb2-5rem">
					{testimonials.map((i, index) => (
						<svg key={index} index={index} onClick={() => changeTestimonial()} className={"f4 testimonialDot pl0-5rem pr0-5rem cp w1rem td300 op" + (index === 0 ? 100 : 20)} viewBox="0 0 512 512">
							<path className="pen" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" />
						</svg>
					))}
				</div>
			</div>
		</div>
	);
}
