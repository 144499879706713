import React from 'react'
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; 


export default function TeamPermissions({}) {

  const dispatch = useDispatch();

  let user = useSelector((state) => state.user);
  let modal = useSelector((state) => state.modal);
  

  return (
    <>
		<div class="sectionWrapper p1rem fs0-75rem pr mr2rem ml1rem pr mb2rem">
			<div className="table-permission">
				<table id="table" className="table-userPermissions table-clientPermissions">
					<thead className="ps t0">
                		<tr>
							<th className="index-table">Modules</th>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							{/* Map Sections */}
							<td className="c15 bg0" style={{ background: '#fafbfe' }}><span className="fw600 bg0">001 </span>Key Shareholder/Investor Documents</td>
							<td className="c15 cp">
								<div class="w100 df jcsb bg1 p0-5rem br30px" style={{ border: '1px solid #DBDFEE' }}>
									<div className="df">
										<img src={require("../../../../images/profile-photo.png")}
                                            className={`w3rem h3rem df jcc aic br100 mr0-5rem pr bss bow1px bo7`}
                                          />
										  <img src={require("../../../../images/profile-photo.png")}
                                            className={`w3rem h3rem df jcc aic br100 mr0-5rem pr bss bow1px bo31`}
                                          />
										  <img src={require("../../../../images/profile-photo.png")}
                                            className={`w3rem h3rem df jcc aic br100 mr0-5rem pr bss bow1px bo31`}
                                          />
										  {/* <span
											data-tooltip={advisor["firstName"] ? advisor["firstName"] + " " + advisor["lastName"] : "Register to show your name here"}
											className={`w3rem h3rem df jcc aic br100 mr0-5rem pr ${profileClass}`}>
											{advisor["firstName"]
											? advisor["firstName"][0] + advisor["lastName"][0] : "?"}
										</span> */}
									</div>
									<Select
										multiple
										className="select-advisor w10"
										id="demo-multiple-name"
										sx={{ "& .MuiSvgIcon-root": { color: "#4A67FF" } }}
										IconComponent={ExpandMoreIcon}
										value={[]}
										MenuProps={{
											anchorOrigin: {
											vertical: "bottom",
											horizontal: "right",
											},
											transformOrigin: {
											vertical: "top",
											horizontal: "right",
											},
											getContentAnchorEl: null,
										}}
										>
											<MenuItem
											// disabled={!advisor?.createdBy}
											// key={idx}
											// value={advisor}
											className="select-advisor-list ttc">
											<Checkbox
												// checked={section?.["permission"]?.[company["_id"]]?.[advisor["_id"]]}
												// onChange={(e) => handlePermissions(e, advisor, section["sectionNo"], company)}
											/>
											<ListItemText
												
												// primary={advisor["firstName"] + " " + advisor["lastName"] + " - " + (advisor["title"] ? advisor["title"] : advisor["job"])
												// }
												
											/>
											</MenuItem>
										</Select>
								</div>
							</td>
							<td></td>
						</tr>
						<tr>
							{/* Map Sections */}
							<td className="c15 bg0" style={{ background: '#fafbfe' }}><span className="fw600 bg0">002 </span>Commercial Activities</td>
							<td className="c15 cp">
								<div class="w100 df jcsb bg1 p0-5rem br30px" style={{ border: '1px solid #DBDFEE' }}>
									<div className="df">
										<img src={require("../../../../images/profile-photo.png")}
                                            className={`w3rem h3rem df jcc aic br100 mr0-5rem pr bss bow1px bo7`}
                                          />
										  <img src={require("../../../../images/profile-photo.png")}
                                            className={`w3rem h3rem df jcc aic br100 mr0-5rem pr bss bow1px bo31`}
                                          />
										  {/* <span
											data-tooltip={advisor["firstName"] ? advisor["firstName"] + " " + advisor["lastName"] : "Register to show your name here"}
											className={`w3rem h3rem df jcc aic br100 mr0-5rem pr ${profileClass}`}>
											{advisor["firstName"]
											? advisor["firstName"][0] + advisor["lastName"][0] : "?"}
										</span> */}
									</div>
									<Select
										multiple
										className="select-advisor w10"
										id="demo-multiple-name"
										sx={{ "& .MuiSvgIcon-root": { color: "#4A67FF" } }}
										IconComponent={ExpandMoreIcon}
										value={[]}
										MenuProps={{
											anchorOrigin: {
											vertical: "bottom",
											horizontal: "right",
											},
											transformOrigin: {
											vertical: "top",
											horizontal: "right",
											},
											getContentAnchorEl: null,
										}}
										>
											<MenuItem
											// disabled={!advisor?.createdBy}
											// key={idx}
											// value={advisor}
											className="select-advisor-list ttc">
											<Checkbox
												// checked={section?.["permission"]?.[company["_id"]]?.[advisor["_id"]]}
												// onChange={(e) => handlePermissions(e, advisor, section["sectionNo"], company)}
											/>
											<ListItemText
												
												// primary={advisor["firstName"] + " " + advisor["lastName"] + " - " + (advisor["title"] ? advisor["title"] : advisor["job"])
												// }
												
											/>
											</MenuItem>
										</Select>
								</div>
							</td>
							<td></td>
						</tr>
						<tr>
							{/* Map Sections */}
							<td className="c15 bg0" style={{ background: '#fafbfe' }}><span className="fw600 bg0">003 </span>Legal</td>
							<td className="c15 cp">
								<div class="w100 df jcsb bg1 p0-5rem br30px" style={{ border: '1px solid #DBDFEE' }}>
									<div className="df">
										<img src={require("../../../../images/profile-photo.png")}
                                            className={`w3rem h3rem df jcc aic br100 mr0-5rem pr bss bow1px bo7`}
                                          />
										  <img src={require("../../../../images/profile-photo.png")}
                                            className={`w3rem h3rem df jcc aic br100 mr0-5rem pr bss bow1px bo31`}
                                          />
										  {/* <span
											data-tooltip={advisor["firstName"] ? advisor["firstName"] + " " + advisor["lastName"] : "Register to show your name here"}
											className={`w3rem h3rem df jcc aic br100 mr0-5rem pr ${profileClass}`}>
											{advisor["firstName"]
											? advisor["firstName"][0] + advisor["lastName"][0] : "?"}
										</span> */}
									</div>
									<Select
										multiple
										className="select-advisor w10"
										id="demo-multiple-name"
										sx={{ "& .MuiSvgIcon-root": { color: "#4A67FF" } }}
										IconComponent={ExpandMoreIcon}
										value={[]}
										MenuProps={{
											anchorOrigin: {
											vertical: "bottom",
											horizontal: "right",
											},
											transformOrigin: {
											vertical: "top",
											horizontal: "right",
											},
											getContentAnchorEl: null,
										}}
										>
											<MenuItem
											// disabled={!advisor?.createdBy}
											// key={idx}
											// value={advisor}
											className="select-advisor-list ttc">
											<Checkbox
												// checked={section?.["permission"]?.[company["_id"]]?.[advisor["_id"]]}
												// onChange={(e) => handlePermissions(e, advisor, section["sectionNo"], company)}
											/>
											<ListItemText
												
												// primary={advisor["firstName"] + " " + advisor["lastName"] + " - " + (advisor["title"] ? advisor["title"] : advisor["job"])
												// }
												
											/>
											</MenuItem>
										</Select>
								</div>
							</td>
							<td></td>
						</tr>
						<tr>
							{/* Map Sections */}
							<td className="c15 bg0" style={{ background: '#fafbfe' }}><span className="fw600 bg0">004 </span>Property</td>
							<td className="c15 cp">
								<div class="w100 df jcsb bg1 p0-5rem br30px" style={{ border: '1px solid #DBDFEE' }}>
									<div className="df">
										<img src={require("../../../../images/profile-photo.png")}
                                            className={`w3rem h3rem df jcc aic br100 mr0-5rem pr bss bow1px bo7`}
                                          />
										  <img src={require("../../../../images/profile-photo.png")}
                                            className={`w3rem h3rem df jcc aic br100 mr0-5rem pr bss bow1px bo31`}
                                          />
										  <img src={require("../../../../images/profile-photo.png")}
                                            className={`w3rem h3rem df jcc aic br100 mr0-5rem pr bss bow1px bo31`}
                                          />
										  {/* <span
											data-tooltip={advisor["firstName"] ? advisor["firstName"] + " " + advisor["lastName"] : "Register to show your name here"}
											className={`w3rem h3rem df jcc aic br100 mr0-5rem pr ${profileClass}`}>
											{advisor["firstName"]
											? advisor["firstName"][0] + advisor["lastName"][0] : "?"}
										</span> */}
									</div>
									<Select
										multiple
										className="select-advisor w10"
										id="demo-multiple-name"
										sx={{ "& .MuiSvgIcon-root": { color: "#4A67FF" } }}
										IconComponent={ExpandMoreIcon}
										value={[]}
										MenuProps={{
											anchorOrigin: {
											vertical: "bottom",
											horizontal: "right",
											},
											transformOrigin: {
											vertical: "top",
											horizontal: "right",
											},
											getContentAnchorEl: null,
										}}
										>
											<MenuItem
											// disabled={!advisor?.createdBy}
											// key={idx}
											// value={advisor}
											className="select-advisor-list ttc">
											<Checkbox
												// checked={section?.["permission"]?.[company["_id"]]?.[advisor["_id"]]}
												// onChange={(e) => handlePermissions(e, advisor, section["sectionNo"], company)}
											/>
											<ListItemText
												
												// primary={advisor["firstName"] + " " + advisor["lastName"] + " - " + (advisor["title"] ? advisor["title"] : advisor["job"])
												// }
												
											/>
											</MenuItem>
										</Select>
								</div>
							</td>
							<td></td>
						</tr>
						<tr>
							{/* Map Sections */}
							<td className="c15 bg0" style={{ background: '#fafbfe' }}><span className="fw600 bg0">005 </span>Intellectual Property</td>
							<td className="c15 cp">
								<div class="w100 df jcsb bg1 p0-5rem br30px" style={{ border: '1px solid #DBDFEE' }}>
									<div className="df">
										<img src={require("../../../../images/profile-photo.png")}
                                            className={`w3rem h3rem df jcc aic br100 mr0-5rem pr bss bow1px bo7`}
                                          />
										  <img src={require("../../../../images/profile-photo.png")}
                                            className={`w3rem h3rem df jcc aic br100 mr0-5rem pr bss bow1px bo31`}
                                          />
										  {/* <span
											data-tooltip={advisor["firstName"] ? advisor["firstName"] + " " + advisor["lastName"] : "Register to show your name here"}
											className={`w3rem h3rem df jcc aic br100 mr0-5rem pr ${profileClass}`}>
											{advisor["firstName"]
											? advisor["firstName"][0] + advisor["lastName"][0] : "?"}
										</span> */}
									</div>
									<Select
										multiple
										className="select-advisor w10"
										id="demo-multiple-name"
										sx={{ "& .MuiSvgIcon-root": { color: "#4A67FF" } }}
										IconComponent={ExpandMoreIcon}
										value={[]}
										MenuProps={{
											anchorOrigin: {
											vertical: "bottom",
											horizontal: "right",
											},
											transformOrigin: {
											vertical: "top",
											horizontal: "right",
											},
											getContentAnchorEl: null,
										}}
										>
											<MenuItem
											// disabled={!advisor?.createdBy}
											// key={idx}
											// value={advisor}
											className="select-advisor-list ttc">
											<Checkbox
												// checked={section?.["permission"]?.[company["_id"]]?.[advisor["_id"]]}
												// onChange={(e) => handlePermissions(e, advisor, section["sectionNo"], company)}
											/>
											<ListItemText
												
												// primary={advisor["firstName"] + " " + advisor["lastName"] + " - " + (advisor["title"] ? advisor["title"] : advisor["job"])
												// }
												
											/>
											</MenuItem>
										</Select>
								</div>
							</td>
							<td></td>
						</tr>
						<tr>
							{/* Map Sections */}
							<td className="c15 bg0" style={{ background: '#fafbfe' }}><span className="fw600 bg0">006 </span>Insurance</td>
							<td className="c15 cp">
								<div class="w100 df jcsb bg1 p0-5rem br30px" style={{ border: '1px solid #DBDFEE' }}>
									<div className="df">
										<img src={require("../../../../images/profile-photo.png")}
                                            className={`w3rem h3rem df jcc aic br100 mr0-5rem pr bss bow1px bo7`}
                                          />
										  <img src={require("../../../../images/profile-photo.png")}
                                            className={`w3rem h3rem df jcc aic br100 mr0-5rem pr bss bow1px bo31`}
                                          />
										  {/* <span
											data-tooltip={advisor["firstName"] ? advisor["firstName"] + " " + advisor["lastName"] : "Register to show your name here"}
											className={`w3rem h3rem df jcc aic br100 mr0-5rem pr ${profileClass}`}>
											{advisor["firstName"]
											? advisor["firstName"][0] + advisor["lastName"][0] : "?"}
										</span> */}
									</div>
									<Select
										multiple
										className="select-advisor w10"
										id="demo-multiple-name"
										sx={{ "& .MuiSvgIcon-root": { color: "#4A67FF" } }}
										IconComponent={ExpandMoreIcon}
										value={[]}
										MenuProps={{
											anchorOrigin: {
											vertical: "bottom",
											horizontal: "right",
											},
											transformOrigin: {
											vertical: "top",
											horizontal: "right",
											},
											getContentAnchorEl: null,
										}}
										>
											<MenuItem
											// disabled={!advisor?.createdBy}
											// key={idx}
											// value={advisor}
											className="select-advisor-list ttc">
											<Checkbox
												// checked={section?.["permission"]?.[company["_id"]]?.[advisor["_id"]]}
												// onChange={(e) => handlePermissions(e, advisor, section["sectionNo"], company)}
											/>
											<ListItemText
												
												// primary={advisor["firstName"] + " " + advisor["lastName"] + " - " + (advisor["title"] ? advisor["title"] : advisor["job"])
												// }
												
											/>
											</MenuItem>
										</Select>
								</div>
							</td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
    </>
  );
}
