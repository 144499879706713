import React from 'react'
import SmoothScrollingButton from '../../components/SmoothScrollingButton';
import AnimatedPage from '../../components/AnimatedPage';
import CustomTab from '../../components/termsAndConditions/CustomTab';


const DMCA = () => {
    return (
        <AnimatedPage>
            <SmoothScrollingButton />
            <div className='poppins-font-style privacy-policy-page *'>
                <CustomTab type="policy" activeIdentifier="dmca-Policy" title={'Policies'} width="w60" />
                <div className="fw400 tal c14 fs1rem policy-box">
                    <p className="pt1rem pb1rem">Effective: June 30th 2022</p>
                    <p className="pb1rem">Diligentsia Limited (“Diligentsia”) respects the intellectual property rights of others and expects its users to do the same. In accordance with the Digital Millennium Copyright Act of 1998, the text of which may be found on the U.S. Copyright Office website at https://www.copyright.gov/legislation/dmca.pdf, Diligentsia will respond expeditiously to claims of copyright infringement committed using the Diligentsia platform and/or the Diligentsia website (the “Site”) if such claims are reported to Diligentsia’s Designated Copyright Agent identified in the sample notice below.</p>
                    <p className="pb1rem">If you are a copyright owner, authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements taking place on or through the Site by completing the following DMCA Notice of Alleged Infringement and delivering it to Diligentsia’s designated Data Privacy Officer at privacy@diligentsia.co.uk . Upon receipt of Notice as described below, Diligentsia will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Site.</p>
                    <p className="pb1rem">DMCA Notice of Alleged Infringement (“Notice”):</p>
                    <ol className="pb1rem">
                        <li>Identify the copyrighted work that you claim has been infringed, or - if multiple copyrighted works are covered by this Notice - you may provide a representative list of the copyrighted works that you claim have been infringed.</li>
                        <li>Identify the material or link you claim is infringing (or the subject of infringing activity) and to which access is to be disabled, including at a minimum, if applicable, the URL of the link shown on the Site or the exact location where such material may be found.</li>
                        <li>Provide your company affiliation (if applicable), mailing address, telephone number, and, if available, email address.</li>
                        <li>Include both of the following statements in the body of the Notice:</li>
                        <ol>
                            <li>“I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorised by the copyright owner, its agent or the law (e.g. as a fair use)”.</li>
                            <li>“I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of, the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed.”</li>
                        </ol>
                        <li>Provide your full legal name and your electronic or physical signature.</li>
                    </ol>
                    <p className="pb1-5rem">Deliver this Notice, with all items completed, to Diligentsia’s designated Data Privacy Officer at privacy@diligentsia.co.uk :</p>
                    <p className="pb0-5rem">Data Privacy Officer</p>
                    <p style={{ fontStyle: 'italic' }}>Diligentsia Limited</p>
                    <p style={{ fontStyle: 'italic' }}>The Old Rectory Nock Verges, Stoney Stanton,</p>
                    <p className="pb1rem" style={{ fontStyle: 'italic' }}>Leicestershire, LE9 4LR, UK</p>
                </div>
            </div>
        </AnimatedPage>
    )
}
export default DMCA;