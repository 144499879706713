import React, { useEffect, useState } from 'react'
import * as ROUTES from "../../constants/routes"
import { useNavigate } from 'react-router-dom'


const TC_MENU = [
    { title: 'Company Terms', indentifier: 'company-terms', link: ROUTES.TERMS_CONDITION_COMPANY },
    { title: 'Individuals Terms', indentifier: 'individual-terms', link: ROUTES.TERMS_CONDITION_INDIVIDUALS_TERMS },
    { title: 'Acceptable Use', indentifier: 'acceptable-use', link: ROUTES.TERMS_CONDITION_ACCEPTABLE_USE }
]


const POLICIES_MENU = [
    { title: 'Cookie Policy', indentifier: 'cookie-policy', link: ROUTES.POLICY_COOKIE },
    { title: 'DMCA Policy', indentifier: 'dmca-Policy', link: ROUTES.POLICY_DMCA },
    { title: 'Privacy Policy', indentifier: 'privacy-policy', link: ROUTES.POLICY_PRIVACY },
    { title: 'Data Privacy & Ethics Policy', indentifier: 'data-ethics', link: ROUTES.POLICY_DATA_ETHICS },
    { title: 'AI Use Policy', indentifier: 'all-use', link: ROUTES.POLICY_ALL_USE }
]

const CustomTab = ({ type, activeIdentifier, title, width }) => {
    const navigate = useNavigate()
    const [barMenus, setBarMenu] = useState([])


    useEffect(() => {
        if (type === "terms-and-conditions") {
            setBarMenu(TC_MENU)
        } else if (type === "policy") {
            setBarMenu(POLICIES_MENU)
        }
    }, [type])
    // backgroundImage: `url(${require('../../../images/landingpage/terms-background.png')})`

    return (
        <div className="w100 df jcc">
            <div className={`w60 df fdc jcc aic bscov brnr`} style={{ gap: "90px", backgroundPosition: 'center' }}>
                <img clasName="pr" src={require('../../../images/landingpage/terms-background.png')} style={{ width: '100%', height: '355px', objectFit: 'contain' }} />
                <h1 className="pa fw500 c15 fs3-5rem" style={{ lineHeight: "66.24px", top: '162px' }}>{title}</h1>
                <div className={`${width} pa br br35px df jcsa aic zi1  p1rem mb4rem`} style={{ top: '320px', backgroundColor: 'rgba(103, 117, 185, 0.584)', backdropFilter: 'blur(5px)', width }}>
                    {barMenus.map((obj, index, arr) =>
                        <div
                            onClick={() => navigate(obj?.link)}
                            key={obj?.title}
                            className={`cp c1 ${activeIdentifier === obj?.indentifier ? "fs1-25rem-screen4 fw700 fs1rem-screen1" : 'fs1-125rem-screen4 fw400 fs1rem-screen1'}`}
                            style={{ lineHeight: "33px" }}>
                            {obj?.title}
                        </div>)
                    }
                </div>
            </div>
        </div >
    )
}
export default CustomTab
