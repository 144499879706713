import React from "react"

const dummyIcon = <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.96204 74.0005H47.1957C40.4123 70.3005 35.7872 63.2088 35.7872 54.8837C35.7872 42.8586 45.654 32.9919 57.6791 32.9919H57.9874V8.01673C57.9874 3.70003 54.2874 0 49.9707 0H8.96204C4.64534 0 0.945312 3.70003 0.945312 8.01673V65.9838C0.945312 70.3005 4.337 74.0005 8.96204 74.0005ZM16.0538 12.3334H42.5706C44.4206 12.3334 45.654 13.8751 45.654 15.4168C45.654 17.2668 44.4206 18.5001 42.5706 18.5001H16.0538C14.2037 18.5001 12.9704 17.2668 12.9704 15.4168C12.9704 13.8751 14.5121 12.3334 16.0538 12.3334ZM16.0538 26.8252H33.3206C35.1706 26.8252 36.4039 28.3669 36.4039 29.9086C36.4039 31.7586 35.1706 32.9919 33.3206 32.9919H16.0538C14.2037 32.9919 12.9704 31.7586 12.9704 29.9086C12.9704 28.0585 14.5121 26.8252 16.0538 26.8252Z" fill="#4A67FF" />
    <path d="M57.6821 39.4668C49.0488 39.4668 41.957 46.5585 41.957 55.1919C41.957 63.8253 49.0488 70.917 57.6821 70.917C66.3155 70.917 73.4073 63.8253 73.4073 55.1919C73.4073 46.5585 66.3155 39.4668 57.6821 39.4668ZM65.3905 53.6502L57.6821 61.0503C57.0655 61.667 56.4488 61.9753 55.5238 61.9753C54.5988 61.9753 53.9821 61.667 53.3654 61.0503L49.6654 57.0419C48.4321 55.8086 48.4321 53.9586 49.9738 52.7252C51.2071 51.4919 53.0571 51.4919 54.2905 52.7252L55.8321 54.5752L61.3822 49.3335C62.6155 48.1002 64.4655 48.1002 65.6989 49.3335C66.6239 50.2585 66.6239 52.4169 65.3905 53.6502Z" fill="#4A67FF" />
</svg>


const WorkCards = ({ isAnimationVisible, refs, data }) => {
    return (
        <div ref={refs?.current?.[4]} className={`pt2rem pl2rem pr2rem pb0-5rem br30px bg1 bsflp pr work-card-box  ${isAnimationVisible?.[4] && 'scaleAnimation'}`} >
            <div className="pb1rem df jcsb" style={{ alignItems: 'end', minHeight: '45px', height: '45px', borderBottom: '3px solid #15131380' }}>
                <div className="pr w80">
                    <div className="fw600 fs1-25rem c15 w100" style={{ lineHeight: '22.81px' }}>
                        {data?.title1}
                    </div>
                    {data?.title2 && <div className="fw600 fs1-25rem c15 w100">
                        {data?.title2}
                    </div>}
                </div>
                <div className="df jce pr" style={{ top: '-20px', height: '100%', width: "56px", visibility: (data?.icon || data?.image) ? 'visible' : 'hidden', marginBottom: !data?.icon && '10px' }}>
                    {data?.icon ? data['icon'] : data?.image ? data['image'] : dummyIcon}
                </div>
            </div>
            <div className="fs1rem pt1-5rem pb1rem c14" style={{ lineHeight: '27px' }}>
                {data?.desc}
            </div>
            <div className="pa df jcc aic bg1 how-its-work-circle fw500 c15 fs1-25rem" style={{
                top: '-20px', left: '-20px', lineHeight: '47.27px'
            }} >{data?.serialNumber}</div>
        </div >
    )

}
export default WorkCards