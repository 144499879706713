import { useState, useEffect } from "react";

import AnimatedPage from "../../../components/AnimatedPage";
import HeadingSubheadingContent from "../../../components/HeadingSubheadingContent";
import { getRequest, postRequest, showMessage } from '../../../config';
import moment from "moment";

export default function AiTwoHundredsHistoryComponent(props) {

    const { onBack } = props;
    const [twoHundredWordsTestLogs, setTwoHundredWordsTestLogs] = useState([]);
    const [selectedLog, setSelectedLog] = useState();

    const fetch = async () => {
        let res = await getRequest(`/aiTest/getTHWTestLogs`);
        console.log(res)
        if (res?.code === 200) {
            setTwoHundredWordsTestLogs(res['body']);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    const getAiResponseString = (aiResp) => {
        if (!aiResp || aiResp.length == 0)
            return "";

        let stringResult = "";
        for (const dup of aiResp) {
            if (Array.isArray(dup)) {
                stringResult += "<p>- " + dup.join(", ") + "</p>";
                // stringResult += "<br>";
            }

        }

        return stringResult;
    }

    const selectCurrentTwoHundredWordsLog = async () => {
        let result = confirm("Do you want to update this instruction as default?");
        if (result === false) {
            return;
        }
        if (!selectedLog) return;
        if (selectedLog.isSelected) return;
        let res = await postRequest(`/aiTest/selectTwoHundredWords`, { thwId: selectedLog['_id'] });
        if (!res || res['code'] != 200) return;

        let currentLogs = twoHundredWordsTestLogs;
        for (const currentLog of currentLogs) {
            currentLog.isSelected = false;
            if (currentLog['_id'] == selectedLog['_id'])
                currentLog.isSelected = true;
        }
        setTwoHundredWordsTestLogs(currentLogs);
        showMessage("Instruction is selected as default");
    }

    return (
        <AnimatedPage>
            <div className="container py5rem df fdc">
                <HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Admin Panel - Ai 200 Words Test History" />
                <span onClick={() => { selectedLog ? setSelectedLog(null) : onBack() }} class="mra shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
                    <svg class="pen f1 h2rem mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path class="fa-primary pen" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
                        <path class="fa-secondary pen" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l-32-32l32-32H416C433.7 224 447.1 238.3 447.1 256z" />
                    </svg>
                    Back to Menu
                </span>
                {
                    !selectedLog ? twoHundredWordsTestLogs.map((twoHundredWordsTestLog, logIndex) => {
                        console.log("twoHundredWordsTestLog; ", twoHundredWordsTestLog);
                        return <div className={`shadow mt1rem py1rem px2rem br5px cp bss bw2px bo5 ${twoHundredWordsTestLog.isSelected ? "bg7" : "bg1"}`} onClick={() => { setSelectedLog(twoHundredWordsTestLog); }}>
                            <p className="usn pen fs1-5rem">
                                Tested by {twoHundredWordsTestLog?.['tester']?.['profile'] && <img className="ass mx0-5rem br100 w2rem h2rem" src={twoHundredWordsTestLog?.['tester']?.['profile']} />} {twoHundredWordsTestLog?.['tester']?.['firstName']},&nbsp;&nbsp;
                                {moment(twoHundredWordsTestLog['createdAt']).format('lll')}<br /><br />
                                <b>Instructions:</b> {twoHundredWordsTestLog.instructions.substr(0, 100) + "  ... "}
                            </p>
                        </div>
                    }) :
                        <div class="fs1rem bss bw2px bo5 br10px p1rem jcsb">
                            <div class="sectionWrapper jcsb px2rem py0-5rem my0-5rem bss bw2px bo5 br10px">
                                <div class="df jcsb">
                                    <span className="fs1rem ">
                                        <b>Company Name :</b> {selectedLog.companyName} <br /> <br />
                                        <b>Company URL :</b> {selectedLog.companyURL} <br /> <br />
                                        <b>Instructions :</b> <p>{selectedLog.instructions}</p> <br /> <br />
                                        <b>Company Description :</b> <p>{selectedLog.companyDescription}</p> <br /> <br />
                                        <div>
                                            {selectedLog.response && selectedLog.response.length > 0 && selectedLog.response.map(resp => {
                                                let respString = "";
                                                if (Array.isArray(resp)) {
                                                    respString = "- " + resp.join(", ");
                                                }
                                                return <p>
                                                    {respString}
                                                </p>
                                            })}
                                        </div>
                                    </span>
                                </div>
                            </div>
                            {
                                <div className="w100 df jcc aic">
                                    <button onClick={() => selectCurrentTwoHundredWordsLog()} className={`c6 br5px px1rem py0-5rem fw600 fs1-25rem bsbb tac df jcc aic ${selectedLog.isSelected ? "bg7" : "cp bg4"}`}>
                                        {selectedLog.isSelected ? 'This instruction is currently used' : 'Go Live this instruction'}
                                    </button>
                                </div>
                            }
                        </div>
                }
            </div>
        </AnimatedPage>

    );
}

