import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import ExpPackPayment from "./Company/ExpPackPayment";
import { getRequest, showMessage } from "../../config";
import AnimatedPage from "../../components/AnimatedPage";
import { toggleModal } from "../../reducers/modalReducer";
import HeadingSubheadingContent from "../../components/HeadingSubheadingContent";

export default function ESGPack() {
	const dispatch = useDispatch();

	let loggedIn = localStorage.getItem("token") || localStorage.getItem("userId");
	const user = useSelector(state => state.user);
	const companies = useSelector(state => state.companies);
	let [company, setCompany] = useState(companies[location.pathname.split("/").slice(-1)[0]] || {});

	useEffect(() => {
		getCompany();
	}, []);

	const getCompany = async () => {
		const companyId = location.pathname.split("/").slice(-1)[0];
		if (companyId) {
			const res = await getRequest(`/company/get/${companyId}`);
			setCompany(res['body']);
		}
	}

	return (
		<AnimatedPage>
			<div className="expansionpack-page py4rem">
				<div className="container df fdc">
					<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Function Expansion Pack" subheading="ESG​​" />
				</div>
				<div className="container df">
					<div className="ma lef-img-area">
						<div className='img-box'>
							<img className="h10rem" src={require('../../../images/left-person.png')} alt="" />
						</div>
					</div>
					<div className="ma w100 right-text-area">
						<p>Any company needing to demonstrate that they  are well-prepared and score highly on an ESG ​</p>

						<div className="number-sec">
							<ul className="bg5 br5px shadow">
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>Number of Sections: </span> 20</li>
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>Number of Questions: </span> 100</li>
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>AI Generated Company Specific Questions included? </span> Yes/No</li>
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>Benefit: </span> Working though these questions will strengthen the business, identify weaknesses, and giving you an opportunity to plug them prior to being subject to due diligence if you have stressed your ESG credentials </li>
							</ul>
						</div>

						<div className="about-img">
							{/* <div className="img-box">
								<img className="br5px" src={require('../../../images/about-img.png')} alt="" />
							</div> */}
							<div className="text-box bg5 br5px shadow">
								<p>“Not having clear ESG credentials is today seen as a weakness.  How can we demonstrate that we are a responsible company in this complex area?”  Graham Jones,, Non-Exec Chair​</p>
							</div>
						</div>
					</div>


				</div>
			</div>

			<div className="boost-sec bg-gradient c1">
				<div className="container py5rem">
					<div className="dg gg2rem gtcr3">
						<div className="h100 df fdc jcsb aic">
							<div className="img-box">
								<img className="h10rem" src={require('../../../images/speed-img.png')} alt="" />
							</div>
						</div>
						<div className="h100 df fdc jcsb py4rem">
							<h2 className="fds c1 fs2rem tac">Highlight your responsible credentials…<br /><span className="c3 fds ">more clearly identify your company’s weaknesses​</span></h2>
						</div>
						<div className="h100 df fdc jcsb aic py4rem">
							{loggedIn
								? <>
									{!company?.['expensions']?.['DEPF005'] &&
										<button onClick={() => dispatch(toggleModal({ open: 'payment' }))} style={{ padding: '15px', position: 'relative' }} className="br5px shadow cp c1 px1rem my0-5rem fw600 m1rem bg4 bg3-hover td250">
											One off purchase – BUY NOW!
											<div className="tooltip-btn">
												<span>&pound; 500</span>
											</div>
										</button>
									}
								</>
								: <button onClick={() => showMessage("LogIn to - BUY NOW!")} style={{ padding: '15px', position: 'relative' }} className="br5px shadow cp c1 px1rem my0-5rem fw600 m1rem bg4 bg3-hover td250">
									One off purchase – BUY NOW!
									<div className="tooltip-btn">
										<span>&pound; 500</span>
									</div>
								</button>
							}
						</div>
					</div>
				</div>
			</div>

			<div className="benfits-sec py4rem">
				<div className="container df fdc">
					<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Benefits" />
				</div>
				<div className="container">
					<div className="dg gg2rem gtcr3">
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-1.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Enhanced reputation and brand value:</b></p>
								<p>By completing a comprehensive ESG (Environmental, Social, and Governance) evaluation checklist, a company can showcase its commitment to responsible business practices. This can lead to a stronger reputation and increased brand value, helping the company attract customers, partners, and investors who prioritize sustainability and ethical practices.</p>
							</div>
						</div>
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-2.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Improved risk management:</b></p>
								<p>A thorough ESG evaluation can help a company identify and address potential risks related to environmental, social, and governance factors. Proactively managing these risks can reduce the likelihood of regulatory fines, reputational damage, or other negative consequences.​</p>
							</div>
						</div>
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-3.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Increased operational efficiency:</b></p>
								<p>Evaluating and optimizing ESG processes and procedures can lead to more efficient use of resources, such as energy and water, and a reduction in waste generation. This can result in cost savings and improved profitability for the company.</p>
							</div>
						</div>

						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-4.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Attractiveness to investors:</b></p>
								<p>Companies that demonstrate strong ESG performance are increasingly attractive to investors who prioritize responsible investment strategies. By completing a 100-question ESG evaluation checklist, a company can signal its commitment to ESG best practices and potentially access a wider pool of investors.​</p>
							</div>
						</div>

						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-5.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Employee engagement and retention:</b></p>
								<p>A strong ESG focus can help a company attract and retain top talent. Employees who feel that their company is committed to positive environmental, social, and governance practices are more likely to be engaged, motivated, and loyal, leading to a more productive and stable workforce.​</p>
							</div>
						</div>

						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-6.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Competitive advantage:</b></p>
								<p>Companies that prioritize ESG factors can differentiate themselves from competitors and position themselves as leaders in their industry. By proactively addressing ESG issues and implementing responsible practices, a company can gain a competitive edge in the market and enhance its long-term growth prospects.​</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="modal-sec py4rem">
				<div className="container df fdc">
					<div className="heading">
						<img className="h5rem" src={require('../../../images/modle-title.png')} alt="" />
					</div>
				</div>
				<div className="container">
					<div className="dg gg2rem gtcr2">
						<div className="df tac  fdc" style={{ padding: '0 25px' }}>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Share Capital and Authority</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Business Plan</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Accounts</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Management Accounts</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Properties</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Assets,Debt & Stock</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Employment and Consultancy Arrangements</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Records & Registers</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Group Structure</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Borrowings & Facilities</p>
						</div>

						<div className="df tac  fdc" style={{ padding: '0 25px' }}>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Information</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Accounts</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Events Since The Accounts Date</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Litigation</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Intellectual Property</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Contracts with Connected Persons</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Statutory & Legal Requirements</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Insurance</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Agreements & Captial Commitments</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Social Obligations</p>
						</div>
					</div>
				</div>
			</div>
			<ExpPackPayment {...{ user, company, showExpension: 'DEPF005' }} />
		</AnimatedPage>
	);
};
