import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from '@mui/material/Modal';
import AnimatedPage from "../../../components/AnimatedPage";
import HeadingSubheadingContent from "../../../components/HeadingSubheadingContent";
import { getRequest, postRequest, showMessage } from '../../../config';
import { toggleModal } from "../../../reducers/modalReducer";

export default function AiDeduperTestComponent(props) {

	let dispatch = useDispatch();

	let modal = useSelector(state => state.modal);

	const defaultFileNames = `CFO Job Description V2 2022.docx, Consultancy Agreement - FINAL - MB Signed 24.01.21.docx, \
	Consultancy Agreement - Final Redline MB edits 24.01.21.docx, Consultancy Agreement - Final Redline MB Signed 24.01.21.docx, \
	Consultancy Agreement - MB edits 14.01.21.docx, Consultancy Agreement - redline.docx, Electric Rider_Investor_Deck (1).pdf, \
	Electric  Rider_Investor_Deck (1).pptx, Electric  Rider_Investor_Deck MB.pptx, Electric Rider - MB Chairman  Consultancy Agreement - draft 1 - 14.12.21 (1).docx, \
	Electric Rider - MB Chairman  Consultancy Agreement - draft 1 - 14.12.21 (2).docx, Electric Rider - MB Chairman  Consultancy Agreement - draft 1 - 14.12.21.docx, \
	Electric Rider - MB Chairman Consultancy Agreement - draft 1 - 14.12.21.docx, Electric Rider - MB Chairman Consultancy Agreement - draft 2 - 23.12.21.docx, \
	Electric Rider Logo .png, ER CD Terms - MB Suggested Edits 25.01.22.docx, ER MC Propoded Terms - 25.01.22.docx, \
	ERCDtermsv2210122 - MB proposed Edits.docx, EV Product Manager Job Description V1 2022.docx, EV Product Manager Job Description V2 2022.docx, \
	Growthink Capabilities, Electric Rider.pdf, MarkedAmendedSettlementAgreementMB10Jan-V1 (1).doc, MB - Consultancy Agreement -  (HJS 13.01.22) (1).pdf, \
	MB - Consultancy Agreement -  (HJS 13.01.22).pdf, MB Electric Rider Proposal V1 09.12.21.docx, MB Slides.pptx, \
	MB Subscription &  Option Agreement FINAL - MB Signed 24.01.21.docx, MB Subscription & Buyback Agreement - draft 1 - 14.12.21.doc, \
	MB Subscription & Buyback Agreement - draft 2 - 23.12.21.doc, MB Subscription & Option  Agreement (HJS 13.01.22) (1).pdf, \
	MB Subscription & Option  Agreement (HJS 13.01.22) (2).pdf, MB Subscription & Option  Agreement (HJS 13.01.22).pdf, \
	MB Subscription & Option Agreement MB Edits 14.01.21.docx, NED Job Description V2 2022.docx, Passport Letter 11.12.21.docx, \
	Platform Manager Job Description 2022.docx, Platform Manager Job Description V1 2022.docx, Proposed Agreements & Side Letter - CD 26.01.2022.docx, \
	Proposed Agreements & Side Letter - MC 26.01.2022.docx, Screenshot Motorpoint 1.png, ServiceAgreement - MC 25.01.2022.docx, \
	Subscription Agreement Redline - MB Edits14.01.21.docx, Subscription Agreement Redline 14.01.21.docx, teamsp-2.webp, Teamsport2.jfif, \
	Teamsport3.jfif, Teamsport4.png, Teamsport5.png`;

	const defaultInstruction = `Identify duplicated files based on filenames excluding extension. Generate the output in JSON data. The key is summarized filename and value is array of duplicated files.`;
	const { onBack } = props;
	const [answers, setAnswers] = useState([]);

	const [fileNames, setFileNames] = useState('');
	const [instructions, setInstructions] = useState('');
	const [companyName, setCompanyName] = useState("ASTRAZENECA PLC");
	const [description, setDescription] = useState(`The company AstraZeneca PLC is a global biopharmaceutical company that discovers, develops, and commercializes prescription medicines for a wide range of therapy areas, including oncology, cardiovascular, renal and metabolic diseases, infection, respiratory, and rare diseases.**
	AstraZeneca's business model is based on the research and development of innovative new medicines, which it then sells through its own commercial organization and through partners. The company is regulated by a number of different authorities, including the UK Medicines and Healthcare products Regulatory Agency (MHRA) and the US Food and Drug Administration (FDA).
	**AstraZeneca's main business activity is the development and commercialization of prescription medicines.** The company has a portfolio of over 50 marketed medicines, and a pipeline of over 100 new drugs in development. AstraZeneca's medicines are used to treat a wide range of diseases, including cancer, heart disease, stroke, diabetes, asthma, and arthritis.
	**AstraZeneca's business model is based on the following key activities:**
	* **Research and development:** AstraZeneca invests heavily in research and development, with the aim of discovering new medicines that can improve the lives of patients. The company has a global network of research and development centers, and employs over 16,000 scientists and researchers.
	* **Manufacturing:** AstraZeneca manufactures its own medicines at its own facilities around the world. The company also has a number of partnerships with other manufacturers.
	* **Commercialization:** AstraZeneca sells its medicines through its own commercial organization and through partners. The company has a global sales and marketing team, and operates in over 100 countries.
	**AstraZeneca is a major player in the global pharmaceutical industry. The company's medicines are used by millions of people around the world. AstraZeneca is committed to developing new medicines that can improve the lives of patients and transform the future of healthcare.**`);
	const [aiResp, setAiResp] = useState([]);
	const [deDuperId, setDeduperId] = useState("");
	let [refresh, setRefresh] = useState(false);

	useEffect(() => {
		const fetch = async () => {
			let res = await getRequest(`/aiTest/currentDeduperInfo`);
			if (res?.code === 200) {
				let fileNames = res.body['fileNames'];
				let instructions = res.body['instructions'];
				let companyName = res.body['companyName'];
				let companyDescription = res.body['companyDescription'];
				let response = res.body['response'];
				let id = res.body['_id'];

				if (id) {
					setDeduperId(id);
				}

				if (fileNames) {
					setFileNames(fileNames);
				} else {
					setFileNames(defaultFileNames);
				}

				if (instructions) {
					setInstructions(instructions);
				} else {
					setInstructions(defaultInstruction);
				}
				companyName && setCompanyName(companyName);
				companyDescription && setDescription(companyDescription);
				response && setAiResp(response);
			}
		};
		fetch();
	}, []);

	const getAiResponseString = () => {
		if (!aiResp || aiResp.length == 0)
			return "";

		let stringResult = "";
		for (const dup of aiResp) {
			if (Array.isArray(dup)) {
				stringResult += "- " + dup.join(", ");
				stringResult += "\n";
			}
		}

		return stringResult;
	}

	const getAIResponse = async () => {
		dispatch(toggleModal({ open: 'checkingDuplication' }));
		let res = await postRequest(`/aiTest/testDeduper`, { fileNames, instructions, companyName, description });
		if (!res || res['code'] != 200) return;

		let { aiResponse, id } = res['body'];
		setDeduperId(id);
		setAiResp(aiResponse);
		setRefresh(!refresh);
		if (!aiResponse) {
			showMessage("No duplicated files");
		}
		dispatch(toggleModal({ open: '' }));
	}

	const selectDeduper = async () => {
		let result = confirm("Do you want to update this instruction as default?");
		if (result === false) {
			return;
		}
		if (deDuperId == "") return;
		let res = await postRequest(`/aiTest/selectDeduper`, { deDuperId });
		if (!res || res['code'] != 200) return;
		showMessage("Instruction is selected as default");
	}

	return (
		<AnimatedPage>
			<div className="container py5rem df fdc">
				<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Admin Panel - AI Test" subheading="DeDuper" />
				<span onClick={onBack} className="mra shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
					<svg className="pen f1 h2rem mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
						<path className="fa-primary pen" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
						<path className="fa-secondary pen" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l-32-32l32-32H416C433.7 224 447.1 238.3 447.1 256z" />
					</svg>
					Back to Menu
				</span>

				<div className="dg gg1rem mx1rem p1rem py0-5rem my0-5rem bss bw2px bo5 br10px">
					<h3 className="usn pen fs1-25rem fw600">Ai Deduper Test</h3>
					<div className="pr">
						<b className="w100 tac fs1-25rem">Company Name</b>
						<textarea onChange={(e) => setCompanyName(e.target.value)} defaultValue={companyName} className="bss bw2px bo5 px4rem py2rem bg1 w100 br5px rv" />
					</div>
					<div className="pr">
						<b className="w100 tac fs1-25rem">Company Description</b>
						<textarea onChange={(e) => setDescription(e.target.value)} defaultValue={description} className="bss bw2px bo5 px4rem py2rem bg1 w100 br5px rv" />
					</div>
					<div className="pr">
						<b className="w100 tac fs1-25rem">Filenames</b>
						<textarea onChange={(e) => setFileNames(e.target.value)} defaultValue={fileNames} className="bss bw2px bo5 px4rem py2rem bg1 w100 br5px rv" />
					</div>

					<div className="pr">
						<b className="w100 tac fs1-25rem">Instructions</b>
						<textarea onChange={(e) => setInstructions(e.target.value)} defaultValue={instructions} className="bss bw2px bo5 px4rem py2rem bg1 w100 br5px rv" />
					</div>

					<div className="pr">
						<b className="w100 tac fs1-25rem">AI Response</b>
						<textarea defaultValue={getAiResponseString(aiResp)} className="bss bw2px bo5 px4rem py2rem bg1 w100 br5px rv" />
					</div>

					{/* <div className="pr">
						{ aiResp && aiResp.map((v) => {
							return (<div className="pr">
								{
									Object.keys(v).map(key=>{
										return <span className="pen fs1rem fw500 tac">{v[key]}<br/></span>
									})
								}
							</div>);
						})}
					</div> */}

					<div className="w100 df jcc aic">
						<button onClick={() => getAIResponse()} className={`bg5 br5px px1rem py0-5rem fw600 cp fs1-25rem bsbb tac df jcc aic mr1rem ${fileNames == "" || instructions == "" ? "c12 disabled" : "c6"}`}>Ask ChatGPT</button>
						<button onClick={() => selectDeduper()} className={`bg5 br5px px1rem py0-5rem fw600 cp fs1-25rem bsbb tac df jcc aic ${deDuperId == "" ? "disabled c12" : "c6"}`}>Go Live</button>
					</div>

				</div>

				{/* Duplication Check Waiting  */}
				<Modal open={modal['open'] == 'checkingDuplication'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
					<div className="pr oya bg1 shadow w100 p3rem pt5rem bsbb df fdc m1rem maw1000px br5px pr">
						<span className="pa t0 l0 m1rem ttu c7 fs1-5rem fw500">PRO</span>
						<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
						<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
							<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
								<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
							</svg>
						</button>
						<h1 className="text-gradient fw600 tac mb1rem fs1-5em">{companyName}</h1>
						<h2 className="c4 fs1-25rem-sm ttu w100 tac mb2rem">Diligentsia Admin Deduper Assistant</h2>

						<div className="tac">
							<p>🔮 <b>Deduper Magic:</b> We're now summoning the power of AI to checking duplication of files! <br />Please stay with us during this magical incantation…</p>
							<img className="h9rem" src={require(`../../../../images/wizard.gif`)} alt="" />
						</div>
					</div>
				</Modal>

			</div>
		</AnimatedPage>

	);
}
