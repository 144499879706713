import { useSearchParams, useLocation } from "react-router-dom";
import Company from './index';
import base64 from 'base-64';

export default function CompanyWrapper() {

	const location = useLocation();

	const urlSearchData = searchString => {
		if (!searchString) return {};
		return searchString.substring(1).split('&').reduce((result, next) => {
			let pair = next.split('=');
			result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
			return result;
		}, {});
	};

	const parseState = state => {
		if ( state.length > 25) {
			const decode = base64.decode(state);
			return decode.split("==");
		}
		return [state, ""];
	}

	// useEffect(() => {
	let { code, state, client_info } = urlSearchData(location['search']);
	let [companyId, verifier] = parseState(state);

	const newProps = {};
	newProps['isCallback'] = true;
	if (location.pathname.includes('gDriveCallBack')) {
		newProps['storageId'] = 'GDrive';
		newProps['storageName'] = 'Google Drive';
	}
	else if (location.pathname.includes('dropBoxCallBack')) {
		newProps['storageId'] = 'dropbox';
		newProps['storageName'] = 'Dropbox';
	}
	else if (location.pathname.includes('sharePointCallBack')) {
		newProps['storageId'] = 'sharepoint';
		newProps['storageName'] = 'SharePoint';
	} else newProps['isCallback'] = false;

	newProps['code'] = code;
	newProps['companyId'] = companyId;
	newProps['client_info'] = client_info;

	return <Company {...newProps} />;
};