import Hero from "../components/Hero";
import Testimonials from "../components/Testimonials";
import TakeTheTest from "../components/TakeTheTest";
import AnimatedPage from "../components/AnimatedPage";
import HeadingSubheadingContent from "../components/HeadingSubheadingContent";

export default function Home() {
	const testimonials = [
		{
			title: "The Growth Manager",
			text: "Only when you work as part of a Senior Management Team around a central data repository, kept up to date, do you really realise the operational benefits of centralising key corporate data into one easy to access location.",
			author: "Katy Davidson",
			company: "CFO Wearable Technologies Limited",
		},
		{
			title: "The Early Stage CEO",
			text: "Having an investment ready data room and supporting documents has undoubtedly attracted more interested investors prepared to look at us seriously and accelerated our investment timetable.",
			author: "Louise Birritteri",
			company: "CEO Pikl Insurance Services Limited",
		},
		{
			title: "The Professional",
			text: "Getting a client into a position where they can provide a draft disclosure letter schedule at the commencement of a transaction will reduce costs, speed up the transaction, and lessen the chance of its falling over right at the end.",
			author: "Rik Pancholi",
			company: "Director, Pattersons Commercial Law",
		},
		{
			title: "The Investor",
			text: "I can see the benefit of setting up a standard data repository structure as part of an immediate post-investment 100-day plan for an investee company, to facilitate subsequent cross-portfolio monitoring.",
			author: "Richard Whitwell",
			company: "Private Equity Investor",
		},
		{
			title: "The Corporate Financier",
			text: "As a corporate financier, when seeing a company with a comprehensive data room that is clearly up to date always gives us early confidence as a professional advisors that that the management are going to work with us in a time-efficient manner – saving everyone cost in the long run on a transaction.",
			author: "Barry Williams",
			company: "CEO Capital Advisors Limted",
		},
	];

	return (
		<AnimatedPage>
			<Hero buttonScrollTo="takeTheTest" h1Class="fs2rem" h1={["For Companies", "Do you want to grow faster?", "Raise investment more quickly?", "Exit for a higher valuation?"]} buttonText="Take the 2 Minute Test" belowButtonText="No signup or credit card required" videoSrc="diligentsia_for_companies.mp4" videoThumbnail="diligentsia-for-companies.jpg" />
			<TakeTheTest />
			<div className="bg1">
				<div className="container df fww gtcr2-lg gg2rem py5rem">
					<div className="w50">
					<HeadingSubheadingContent wrapperClass="mb1rem" heading="Key Corporate Information" subheading="The Problem" paragraph="Your organisation’s key commercial documents are scattered around your organisation, making them difficult to locate, update and weed out when superceded." />
					<HeadingSubheadingContent wrapperClass="mb1rem mb0-lg" subheading="The Solution" paragraph="We provide tools to help you get organised. We can also help you and your advisers and investors get your company fighting fit. And be recognised for it. Everyone Wins." />
					</div>
					
					<div className="mxa mr0-lg w50 df fdc maw400px fs1-25rem">
						<span className="bsbb fs1rem asfs mb2rem c6 pr bg5 btlr10px btrr10px bbrr10px bblr0 tal w300px mb1rem py0-5rem px1rem fds speech-bubble">I can find the draft contract, where is the signed contract?</span>
						<span className="bsbb fs1rem asfe mb2rem c1 pr bg3 btlr10px btrr10px bblr10px bbrr0 tal w300px mb1rem py0-5rem px1rem fds speech-bubble">The CFO knows where it is, but he is on holiday this week.</span>
						<span className="bsbb fs1rem asfs mb2rem c6 pr bg5 btlr10px btrr10px bbrr10px bblr0 tal w300px mb1rem py0-5rem px1rem fds speech-bubble">I can now find the contract signed by us, but where is the countersigned version?</span>
						<span className="bsbb fs1rem asfe mb2rem c1 pr bg3 btlr10px btrr10px bblr10px bbrr0 tal w300px mb1rem py0-5rem px1rem fds speech-bubble">Do all contractor contracts contain an IP assignment clause?</span>
						<span className="bsbb fs1rem asfs mb2rem c6 pr bg5 btlr10px btrr10px bbrr10px bblr0 tal w300px mb1rem py0-5rem px1rem fds speech-bubble">Are our Companies House fillings up to date?</span>
						<span className="bsbb fs1rem asfe mb2rem c1 pr bg3 btlr10px btrr10px bblr10px bbrr0 tal w300px mb1rem py0-5rem px1rem fds speech-bubble">Which SharePoint folder is it in?</span>
						<span className="bsbb fs1rem asfs c6 pr bg5 btlr10px btrr10px bbrr10px bblr0 tal w300px mb1rem py0-5rem px1rem fds speech-bubble">Is the GDPR ICO certificate still valid? Where can I find it?</span>
					</div>
				</div>
			</div>
			<div className="bg1">
				<div className="df fww container py5rem">
					<div className="df fww w50 aic">
						<div className="svg-left w50">
							<svg className="fds" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7.70076 0.320312L0.478516 4.91332L7.70076 9.50633L14.9242 4.91332L22.1465 9.50633L29.3687 4.91332L22.1465 0.320312L14.9242 4.91332L7.70076 0.320312Z" fill="#0061FE"></path>
								<path d="M7.70076 18.6925L0.478516 14.0994L7.70076 9.50633L14.9242 14.0994L7.70076 18.6925Z" fill="#0061FE"></path>
								<path d="M14.9242 14.0994L22.1465 9.50633L29.3687 14.0994L22.1465 18.6925L14.9242 14.0994Z" fill="#0061FE"></path>
								<path d="M14.9242 24.8164L7.70077 20.2234L14.9242 15.6304L22.1465 20.2234L14.9242 24.8164Z" fill="#0061FE"></path>
							</svg>
							<svg className="fds grs1-lg gre3-lg gcs2-lg gce3-lg mya" viewBox="0 0 87.3 78" xmlns="http://www.w3.org/2000/svg">
								<path d="m6.6 66.85 3.85 6.65c.8 1.4 1.95 2.5 3.3 3.3l13.75-23.8h-27.5c0 1.55.4 3.1 1.2 4.5z" fill="#0066da" />
								<path d="m43.65 25-13.75-23.8c-1.35.8-2.5 1.9-3.3 3.3l-25.4 44a9.06 9.06 0 0 0 -1.2 4.5h27.5z" fill="#00ac47" />
								<path d="m73.55 76.8c1.35-.8 2.5-1.9 3.3-3.3l1.6-2.75 7.65-13.25c.8-1.4 1.2-2.95 1.2-4.5h-27.502l5.852 11.5z" fill="#ea4335" />
								<path d="m43.65 25 13.75-23.8c-1.35-.8-2.9-1.2-4.5-1.2h-18.5c-1.6 0-3.15.45-4.5 1.2z" fill="#00832d" />
								<path d="m59.8 53h-32.3l-13.75 23.8c1.35.8 2.9 1.2 4.5 1.2h50.8c1.6 0 3.15-.45 4.5-1.2z" fill="#2684fc" />
								<path d="m73.4 26.5-12.7-22c-.8-1.4-1.95-2.5-3.3-3.3l-13.75 23.8 16.15 28h27.45c0-1.55-.4-3.1-1.2-4.5z" fill="#ffba00" />
							</svg>
						</div>
						<svg className="fds w50" version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1992.333 1946" enableBackground="new 0 0 1992.333 1946">
							<metadata>
								<sfw>
									<slices></slices>
									<sliceSourceBounds bottomleftorigin="true" height="1946" width="1992.333" x="-995.333" y="-949"></sliceSourceBounds>
								</sfw>
							</metadata>
							<circle fill="#036C70" cx="1019.333" cy="556" r="556" />
							<circle fill="#1A9BA1" cx="1482.667" cy="1065.667" r="509.667" />
							<circle fill="#37C6D0" cx="1088.833" cy="1552.167" r="393.833" />
							<path
								opacity="0.1"
								enableBackground="new    "
								d="M1112,501.79v988.753c-0.23,34.357-21.05,65.222-52.82,78.303
						c-10.116,4.279-20.987,6.484-31.97,6.487H695.463c-0.463-7.877-0.463-15.29-0.463-23.167c-0.154-7.734,0.155-15.47,0.927-23.167
						c8.48-148.106,99.721-278.782,235.837-337.77v-86.18c-302.932-48.005-509.592-332.495-461.587-635.427
						c0.333-2.098,0.677-4.195,1.034-6.289c2.306-15.626,5.556-31.099,9.73-46.333h546.27C1073.964,417.178,1111.822,455.036,1112,501.79
						z"
							/>
							<path
								opacity="0.2"
								enableBackground="new    "
								d="M980.877,463.333H471.21c-51.486,302.386,151.908,589.256,454.293,640.742
						c9.156,1.559,18.35,2.888,27.573,3.986c-143.633,68.11-248.3,261.552-257.196,420.938c-0.771,7.697-1.081,15.433-0.927,23.167
						c0,7.877,0,15.29,0.463,23.167c0.836,15.574,2.85,31.063,6.023,46.333h279.39c34.357-0.23,65.222-21.05,78.303-52.82
						c4.279-10.115,6.485-20.987,6.487-31.97V548.123C1065.443,501.387,1027.613,463.537,980.877,463.333z"
							/>
							<path
								opacity="0.2"
								enableBackground="new    "
								d="M980.877,463.333H471.21c-51.475,302.414,151.95,589.297,454.364,640.773
						c6.186,1.053,12.389,2.001,18.607,2.844c-139,73.021-239.543,266-248.254,422.05h284.95c46.681-0.353,84.437-38.109,84.79-84.79
						V548.123C1065.489,501.369,1027.631,463.511,980.877,463.333z"
							/>
							<path
								opacity="0.2"
								enableBackground="new    "
								d="M934.543,463.333H471.21c-48.606,285.482,130.279,560.404,410.977,631.616
						C775.901,1216.384,710.711,1368.301,695.927,1529h238.617c46.754-0.178,84.612-38.036,84.79-84.79V548.123
						C1019.308,501.306,981.361,463.359,934.543,463.333z"
							/>
							<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="177.0788" y1="1551.0284" x2="842.2545" y2="398.9716" gradientTransform="matrix(1 0 0 -1 0 1948)">
								<stop offset="0" style={{ stopColor: "#058F92" }} />
								<stop offset="0.5" style={{ stopColor: "#038489" }} />
								<stop offset="1" style={{ stopColor: "#026D71" }} />
							</linearGradient>
							<path
								fill="url(#SVGID_1_)"
								d="M84.929,463.333h849.475c46.905,0,84.929,38.024,84.929,84.929v849.475
						c0,46.905-38.024,84.929-84.929,84.929H84.929c-46.905,0-84.929-38.024-84.929-84.929V548.262
						C0,501.357,38.024,463.333,84.929,463.333z"
							/>
							<path
								fill="#FFFFFF"
								d="M379.331,962.621c-19.903-13.202-36.528-30.777-48.604-51.384c-11.701-21.542-17.533-45.781-16.912-70.288
						c-1.042-33.181,10.155-65.586,31.46-91.045c22.388-25.49,51.326-44.366,83.678-54.581c36.871-12.136,75.49-18.116,114.304-17.699
						c51.043-1.865,102.015,5.272,150.583,21.082v106.567c-21.103-12.784-44.088-22.166-68.11-27.8
						c-26.065-6.392-52.81-9.597-79.647-9.545c-28.3-1.039-56.419,4.913-81.871,17.329c-19.65,8.475-32.392,27.807-32.433,49.206
						c-0.08,12.981,4.907,25.481,13.9,34.843c10.622,11.037,23.187,20.021,37.067,26.503c15.444,7.691,38.611,17.916,69.5,30.673
						c3.401,1.075,6.716,2.407,9.915,3.985c30.401,11.881,59.729,26.344,87.663,43.229c21.154,13.043,38.908,30.924,51.801,52.171
						c13.218,24.085,19.625,51.315,18.533,78.767c1.509,34.066-8.913,67.591-29.468,94.798c-20.488,25.012-47.88,43.446-78.767,53.005
						c-36.329,11.387-74.245,16.892-112.312,16.309c-34.154,0.155-68.258-2.635-101.933-8.34c-28.434-4.653-56.182-12.807-82.612-24.279
						v-112.358c25.264,18.043,53.489,31.529,83.4,39.847c29.81,9.289,60.798,14.251,92.018,14.734c28.895,1.83,57.739-4.291,83.4-17.699
						c17.976-10.144,28.909-29.358,28.449-49.994c0.12-14.359-5.56-28.158-15.753-38.271c-12.676-12.444-27.352-22.671-43.414-30.256
						c-18.533-9.267-45.824-21.483-81.871-36.65C432.618,993.951,405.161,979.594,379.331,962.621z"
							/>
						</svg>
					</div>
					<div className="w50 tar-lg">
						<HeadingSubheadingContent wrapperClass="tar-lg right-text" listClass="fdrr-lg fdrr-sf" heading="3 Problems with Cloud Drives" subheading="Use Your Preferred Data Room" list={["Silos organized by function", "Key documents buried within voluminous operational folders", "No systematic reviewing & weeding"]} />
						<p className="mt2rem fw600 fs1-25rem tar-lg w100 right-text">Does this apply to your organisation?</p>
					</div>
				</div>
			</div>
			<Testimonials testimonials={testimonials} />
			<div className="bw0px btw5px bss bo3 bg1 py5rem bg6">
				<div className="container df fdc">
					<h2 className="fds c1 fs2rem fs4rem-sm tac mb2rem mb3rem-lg">How it all Works</h2>
					<div className="df fww bg6 c1 rg2rem rg5rem-lg">
						<div className="mih200px pr w50 h100 df jcc aic">
							<svg className="fds pa t0 center-horizontally h100 wa f3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
								<path className="fa-secondary" d="M256 448c0 17.67-14.33 32-32 32H32c-17.67 0-32-14.33-32-32s14.33-32 32-32h64V123.8L49.75 154.6C35.02 164.5 15.19 160.4 5.375 145.8C-4.422 131.1-.4531 111.2 14.25 101.4l96-64c9.828-6.547 22.45-7.187 32.84-1.594C153.5 41.37 160 52.22 160 64.01v352h64C241.7 416 256 430.3 256 448z" />
							</svg>
							<h3 className="fds zi1 lh1 fs2rem fs3rem-sm">Setup</h3>
						</div>
						<div className="fs1rem df w50 fdc jcc">
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Setup your account.</span>
							</div>
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Set up your first administrator user.</span>
							</div>
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Enter your company details You will need its name and/or UK Companies House registration number.</span>
							</div>
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Allocate your company against your account.</span>
							</div>
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Select the best package for your organisation.</span>
							</div>
							<div className="df aifs">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Sign up for your monthly subscription.</span>
							</div>
						</div>
						<div className="grs2-lg gcs1-lg w50 fs1rem df fdc jcc">
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Populate your Data Repository</span>
							</div>
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">We take you through tailored online checklists and forms to assist you to systematically upload your corporate files and other information into our Secure Data Repository.</span>
							</div>
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">When you are ready, we create a replica folder structure in a Diligentsia Data Room folder in your existing cloud storage provider (Sharepoint/Drive/Dropbox), and you securely sync the files between them.</span>
							</div>
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">You see the degree of completeness etc on your dashboard</span>
							</div>
						</div>
						<div className="gcs2-lg gce3-lg mih200px pr w50 h100 df jcc aic">
							<svg className="fds pa t0 center-horizontally h100 wa f3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
								<path className="fa-secondary" d="M320 448c0 17.67-14.33 32-32 32H32c-13.08 0-24.83-7.953-29.7-20.09c-4.859-12.12-1.859-26 7.594-35.03l193.6-185.1c31.36-30.17 33.95-80 5.812-113.4c-14.91-17.69-35.86-28.12-58.97-29.38C127.4 95.83 105.3 103.9 88.53 119.9L53.52 151.7c-13.08 11.91-33.33 10.89-45.2-2.172C-3.563 136.5-2.594 116.2 10.48 104.3l34.45-31.3c28.67-27.34 68.39-42.11 108.9-39.88c40.33 2.188 78.39 21.16 104.4 52.03c49.8 59.05 45.2 147.3-10.45 200.8l-136 130H288C305.7 416 320 430.3 320 448z" />
							</svg>
							<h3 className="fds zi1 lh1 fs2rem fs3rem-sm">Get Organised</h3>
						</div>
						<div className="mih200px pr w50 h100 df jcc aic">
							<svg className="fds pa t0 center-horizontally h100 wa f3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
								<path className="fa-secondary" d="M320 344c0 74.98-61.02 136-136 136H103.6c-46.34 0-87.31-29.53-101.1-73.48c-5.594-16.77 3.484-34.88 20.25-40.47c16.75-5.609 34.89 3.484 40.47 20.25c5.922 17.77 22.48 29.7 41.23 29.7H184c39.7 0 72-32.3 72-72s-32.3-72-72-72H80c-13.2 0-25.05-8.094-29.83-20.41C45.39 239.3 48.66 225.3 58.38 216.4l131.4-120.4H32c-17.67 0-32-14.33-32-32s14.33-32 32-32h240c13.2 0 25.05 8.094 29.83 20.41c4.781 12.3 1.516 26.27-8.203 35.19l-131.4 120.4H184C258.1 208 320 269 320 344z" />
							</svg>
							<h3 className="fds zi1 lh1 fs2rem fs3rem-sm">Get Fighting Fit</h3>
						</div>
						<div className="fs1rem w50 df fdc jcc">
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Select for each area of your business our business audit checklists.</span>
							</div>
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">We use AI to tailor questions to your particular cicumstances</span>
							</div>
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Complete the checklists with the help of our Top Tips and Toolbox Offers.</span>
							</div>
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Receive reports on each area of your business, risk reports etc.</span>
							</div>
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">If raising investment, complete our checklists to make you "investment ready".</span>
							</div>
						</div>
						<div className="grs4-lg w50 gcs1-lg fs1rem df fdc jcc">
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Third party validation</span>
							</div>
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Optional</span>
							</div>
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Apply for Diligentsia's kitemark certifcation of your business to demonstrate to potential investors/purchasers that you are investment/exit ready</span>
							</div>
							<div className="mb1rem df aic">
								<svg className="fds w1rem ha f3 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
									<path className="fa-primary" d="M310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
									<path className="fa-secondary" d="M384 32H64C28.65 32 0 60.66 0 96v320c0 35.34 28.65 64 64 64h320c35.35 0 64-28.66 64-64V96C448 60.66 419.3 32 384 32zM310.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L242.8 256L153.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C316.9 239.6 320 247.8 320 256S316.9 272.4 310.6 278.6z" />
								</svg>
								<span className="w100">Your lawyer of accountant may be able to undertake the kitemark work – ask them</span>
							</div>
						</div>
						<div className="gcs2-lg gce3-lg mih200px pr w50 h100 df jcc aic">
							<svg className="fds pa t0 center-horizontally h100 wa f3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
								<path className="fa-secondary" d="M384 334.2c0 17.67-14.33 32-32 32h-32v81.78c0 17.67-14.33 32-32 32s-32-14.33-32-32v-81.78H32c-10.97 0-21.17-5.625-27.05-14.89c-5.859-9.266-6.562-20.89-1.875-30.81l128-270.2C138.6 34.33 157.8 27.56 173.7 35.09c15.97 7.562 22.78 26.66 15.22 42.63L82.56 302.2H256V160c0-17.67 14.33-32 32-32s32 14.33 32 32v142.2h32C369.7 302.2 384 316.6 384 334.2z" />
							</svg>
							<h3 className="fds zi1 lh1 fs2rem fs3rem-sm">Certification</h3>
						</div>
					</div>
				</div>
			</div>
		</AnimatedPage>
	);
}
