import AnimatedPage from "../components/AnimatedPage";
import CallToAction from "../components/CallToAction";
import Hero from "../components/Hero";
import FancyHeadingParagraph from "../components/FancyHeadingParagraph";
import HeadingParagraph from "../components/HeadingParagraph";
import HeadingSubheadingContent from "../components/HeadingSubheadingContent";
import Testimonials from "../components/Testimonials";

export default function AngelNetworks() {
	const testimonials = [
		{
			title: "The Growth Manager",
			text: "Only when you work as part of a Senior Management Team around a central data repository, kept up to date, do you really realise the operational benefits of centralising key corporate data into one easy to access location.",
			author: "Katy Davidson",
			company: "CFO Wearable Technologies Limited",
		},
		{
			title: "The Investment Manager",
			text: "Cost-effective diligencing of angel-stage investments, along with an associated kitemark validation would be of great benefit to the angel market – both the networks and prospective angel investors.",
			author: "[Need to get approval]",
			company: "Investment Manager, [xxx]",
		},
		{
			title: "The Professional",
			text: "Getting a client into a position where they can provide a draft disclosure letter schedule at the commencement of a transaction will reduce costs, speed up the transaction, and lessen the chance of its falling over right at the end.",
			author: "Rik Pancholi",
			company: "Director, Pattersons Commercial Law",
		},
	];
	return (
		<AnimatedPage>
			<Hero h1Class="fs2-5rem" h1={["Better Quality Companies,", "Happier Investors,", "More Deals Closed."]} buttonText="Register as Investor" id='openRegisterPopup' dataShowPopup="registerInvestor" buttonId="scrollDown" videoSrc="diligentsia_for_angel_networks.mp4" videoThumbnail="diligentsia-for-angel-networks.jpg" />

			<div className="bg1">
				<div className="container dg gtcr1-lg gg2rem py5rem">
					<HeadingSubheadingContent heading="Can We Help Your Network?" subheading="Meet Diligentsia" paragraph="Would you like to demonstrably improve investor returns across your network? Earlier qualification? Quicker deals? Higher funding completion %?<br>Would you like to better assess investment risk? It is currently difficult for investors and you to truly assess risk and stage of development of the companies that are presented to you." />
				</div>
			</div>
			<div className="bg1">
				<div className="dg gtcr2-lg gg2rem container pb5rem">
					<HeadingParagraph heading="Would you like to offer cost effective due diligence to your companies?" paragraph="Not currently cost effective for you to undertake thorough due diligence. The companies do not have the funds available to pay the networks." />
					<HeadingParagraph heading="Would you like to improve standards across across your portfolio companies?" paragraph="And save investors time when assessing investment opportunities?" />
					<HeadingParagraph heading="Would you like to Increase interest amongst your investor base?" paragraph="Kitemarking will enable investors immediately to see which of your companies is investment ready." />
					<HeadingParagraph heading="Flywheel effect." paragraph="Your network will gain competitive advantage through offering better quality companies that have been pre-vetted; your angels will make better returns, and more angels will flock to your platform." />
				</div>
			</div>
			<FancyHeadingParagraph
				content={[
					["Investment Ready Kitemarks", "Data room already? Seed Legals, or iDeals or DataSite? We organise it better to create more value.  No data room?  We simply create one within their existing cloud-based solution – Sharepoint/Drive/Dropbox."],
					["We are Data Room Agnostic", "Diligentsia has developed an ultra low-cost SaaS solution to act as a filter for the angel network, and get the companies “investment ready”, along with a kitemark (bronze, silver gold) to display to potential investors."],
				]}
			/>
			<Testimonials testimonials={testimonials} />
			<CallToAction callUsNow heading="Ready to make your portfolio companies more investable for as little as &pound; 5 per month, payable by the companies?"/>
		</AnimatedPage>
	);
}
