import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import AnimatedPage from "../../components/AnimatedPage";
import { BILLING, COMPANY } from "../../constants/routes";
import PricingColumn from "../../components/PricingColumn";
import CheckoutForm from "../../components/CheckoutForm";
import { toggleModal } from "../../reducers/modalReducer";
import { getRequest, postRequest, showMessage } from "../../config";
import HeadingSubheadingContent from "../../components/HeadingSubheadingContent";

export default function Billing() {
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const modal = useSelector(state => state.modal);
	const companies = useSelector(state => state.companies);

	let [plan, setPlan] = useState();
	let [loading, setLoading] = useState(false);
	let [stripeHistory, setStripeHistory] = useState();
	let [upcomingInvoice, setUpcomingInvoice] = useState();
	let [companyId] = useState(location.href.split("/").slice(-1)[0]);
	let [company, setCompany] = useState({});

	useEffect(() => {
		company = companies.filter(company => companyId == company['_id'])?.[0];
		if (company) setCompany(company);
		else getCompany();
	}, []);

	const getCompany = async () => {
		let res = await getRequest(`/company/get/${companyId}`);
		if (res?.['code'] == 200) setCompany(res?.['body']);
	};

	useEffect(() => {
		(async () => {
			if (companyId !== "all") {
				res = await getRequest(`/payment/stripeHistory/${companyId}`, true);
				if (res && res.code == 200 && res.body) setStripeHistory(res.body);

				res = await getRequest(`/payment/upcomingInvoice/${companyId}`);
				if (res && res.code == 200 && res.body) setUpcomingInvoice(res.body);

				//res = await getRequest(`/payment/subscriptionStatus/${companyId}`);
				//if (res && res.code == 200 && res.body)
				//	setSubscriptionStatus(res.body);
			}
		})();
	}, [companyId]);

	const handlePaymentPopup = (paymentPlan) => {
		let activePlans = ['Free', 'Lite', 'Pro'];
		if (activePlans.includes(paymentPlan)) {
			setPlan(paymentPlan);
			dispatch(toggleModal({ open: 'subscriptionPayment' }));
		}
	};

	const freeSubscription = async () => {
		try {
			setLoading(true);
			const response = await getRequest(`/payment/freeSubscription/${companyId}`);
			setLoading(false);
			if (!response || response['code'] != 200) return showMessage(response['message']);
			setTimeout(() => location.href = COMPANY.replace(":id", companyId), 2500);
		} catch (e) { console.log('********* e = ', e) };
	}

	return (
		<AnimatedPage>
			<div className="container py3rem df aic fdc">
				<HeadingSubheadingContent wrapperClass="mb1rem tac" heading="Billing" subheading="View and Manage Your Diligentsia Purchases" />
				{companyId !== "all" && <>
					{company && Object.keys(company).length > 0 && <>
						{company?.['creatorId']?.['createdBy']
							? <div className="df jcc fdc aic py3rem c1">
								<div className="pt10rem pb1-5rem c1 dg gtcr4-xl  gtcr2-md rg5rem cg2rem gg4rem-xl pricing-table-saf pricing-advisor-table ">
									<PricingColumn company={company} plan="Portfolio Pro" isAdvisor={true} isInvestor={true} component="Billing" handlePaymentPopup={handlePaymentPopup} />
									<div className='pricing-box pricing-box-mobile'></div>
									<PricingColumn company={company} plan="Pro" component="Billing" handlePaymentPopup={handlePaymentPopup}>
										<label for="proOption" className="df w100 jcc mt1rem">
											<input onClick={() => dispatch(toggleModal({ open: 'payment' }))} onChange={() => setPlan("Pro")} name="pricingPlan" id="proOption" className="op0 pen" type="radio" />
											<span data-testid="purchasePro" className="fs1rem br5px shadow cp c1 py0-5rem px1rem fw550 bg4 bg3-hover td250">Purchase Now</span>
										</label>
									</PricingColumn>
									<PricingColumn company={company} plan="Pro PLUS" component="Billing" handlePaymentPopup={handlePaymentPopup} />
								</div>
							</div>
							: <div className={`gtcr4-xl mt8rem py5rem c1 dg gtcr2-md rg5rem cg2rem gg1rem-xl pricing-table-saf`}>
								<PricingColumn company={company} plan="Free" component="Billing" handlePaymentPopup={freeSubscription}>
									{(user['role'] !== 'Advisor' && user['role'] !== 'Investor') &&
										<label for="partnerOption" className="df w100 jcc mt1rem">
											<input onClick={freeSubscription} onChange={() => setPlan("Free")} name="pricingPlan" id="partnerOption" className="op0 pen" type="radio" />
											<span data-testid="purchaseFree" className="fs1rem br5px shadow cp c1 py0-5rem px1rem fw550 bg4 bg3-hover td250">Purchase Now</span>
										</label>
									}
								</PricingColumn>
								<PricingColumn company={company} plan="Lite" component="Billing" handlePaymentPopup={handlePaymentPopup}>
									{(user['role'] !== 'Advisor' && user['role'] !== 'Investor') &&
										<label for="liteOption" className="df w100 jcc mt1rem">
											<input onClick={() => dispatch(toggleModal({ open: 'payment' }))} onChange={() => setPlan("Lite")} name="pricingPlan" id="liteOption" className="op0 pen" type="radio" />
											<span data-testid="purchaseLite" className="fs1rem br5px shadow cp c1 py0-5rem px1rem fw550 bg4 bg3-hover td250">Purchase Now</span>
										</label>
									}
								</PricingColumn>
								<PricingColumn company={company} plan="Pro" component="Billing" handlePaymentPopup={handlePaymentPopup}>
									<label for="proOption" className="df w100 jcc mt1rem">
										<input onClick={() => dispatch(toggleModal({ open: 'payment' }))} onChange={() => setPlan("Pro")} name="pricingPlan" id="proOption" className="op0 pen" type="radio" />
										<span data-testid="purchasePro" className="fs1rem br5px shadow cp c1 py0-5rem px1rem fw550 bg4 bg3-hover td250">Purchase Now</span>
									</label>
								</PricingColumn>
								<PricingColumn company={company} plan="Pro PLUS" component="Billing" handlePaymentPopup={handlePaymentPopup} />
							</div>
						}
					</>
					}
					{/* For Company Subscription */}
					<CheckoutForm plan={plan} companyId={companyId} />
					{upcomingInvoice && <>
						<h4 className="fs2rem mb1rem">Upcoming Invoice</h4>
						<div className="tac p1rem bg1 br5px dg gtcr2-md gg1rem mb2rem">
							<h3 className="fs1-5rem p1rem">Total</h3>
							<h3 className="fs1-5rem p1rem">Date of Payment</h3>
							<span className="p1rem fs1-25rem">&pound;{upcomingInvoice.amount_due / 100}</span>
							<span className="p1rem fs1-25rem">{new Date(upcomingInvoice.next_payment_attempt * 1000).toLocaleDateString("en-GB")}</span>
						</div>
					</>}
					{stripeHistory && <>
						<h4 className="fs2rem mb1rem">Billing History</h4>
						<div className="tac p1rem bg1 br5px dg gtcr4-md gg1rem">
							<h3 className="fs1-5rem p1rem">Total</h3>
							<h3 className="fs1-5rem p1rem">Amount Paid</h3>
							<h3 className="fs1-5rem p1rem">Invoice</h3>
							<h3 className="fs1-5rem p1rem">Status</h3>
							{Object.values(stripeHistory.data).map((data, idx) =>
								<Fragment key={idx}>
									<span className="p1rem fs1-25rem">&pound;{data.amount_due / 100}</span>
									<span className="p1rem fs1-25rem">&pound;{data.amount_paid / 100}</span>
									<Link className="p1rem c3-hover td250 c6" to={data.hosted_invoice_url} target="_blank">View Invoice</Link>
									<span className="p1rem ttu fw600 fs1rem">{data.status}</span>
								</Fragment>
							)}
						</div>
					</>}
				</> || companies.map((company, idx) => {
					return <>
						<div key={idx} className="dg gtcr3-md gg1rem p1rem br5px shadow bg1 fs1-25rem">
							<h4>{company['cName']}</h4>
							<span>{company['subscription'] || 'Free'} - {company['role']}</span>
							<Link to={BILLING.replace(":id", company['_id'])} className="cp tac c1 bg3 br5px shadow px1rem py0-5rem fw500">Manage Billing</Link>
						</div>
						{company?.['childrenCompanies'] && company['childrenCompanies'].map(childCompany => {
							return (
								<div key={idx} className="dg gtcr3-md gg1rem p1rem br5px shadow bg1 fs1-25rem mx3rem mt0-5rem">
									<h4>{childCompany['cName']}</h4>
									<span>{childCompany['subscription'] || 'Free'} - {childCompany['role'] || 'Group Company'}</span>
									<Link to={BILLING.replace(":id", childCompany['_id'])} className="cp tac c1 bg3 br5px shadow px1rem py0-5rem fw500">Manage Billing</Link>
								</div>
							)
						})}
					</>
				}
				)}
			</div>
		</AnimatedPage>
	);
};