import { createAction, createSlice } from "@reduxjs/toolkit";

export default function getModuleReducer(module={}) {
	const moduleSlice = createSlice({
		name: "module",
		initialState: module,
		reducers: {
			update: (state, action) => {
				return { ...state, ...action.payload };
			}
		}
	});

	return moduleSlice.reducer;
}

export const updateModule = createAction("module/update");