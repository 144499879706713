import { createSlice, createAction } from "@reduxjs/toolkit";

export default function getCompanyReducer(company={}) {
	const companySlice = createSlice({
		name: "company",
		initialState: company,
		reducers: {
			update: (state, action) => {
				return { ...state, ...action.payload };
			}
		}
	});

	return companySlice.reducer;
}

export const updateCompany = createAction("company/update");