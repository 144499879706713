import AnimatedPage from "../components/AnimatedPage";
import HeadingSubheadingContent from "../components/HeadingSubheadingContent";
import OfferBanner from "../components/OfferBanner";

export default function Terms() {
	return (
		<AnimatedPage>
			<OfferBanner>
				<span className="c1 cp px0-5rem" data-scroll-to="forCompanies">Company Terms</span>
				<span className="c1 cp px0-5rem" data-scroll-to="forIndividuals">Individuals Terms</span>
				<span className="c1 cp px0-5rem" data-scroll-to="acceptableUse">Acceptable Use</span>
			</OfferBanner>
			<div className="container mxa py5rem dg gg1rem lh3">
				<HeadingSubheadingContent id="forCompanies" wrapperClass="tac mb2rem" heading="Terms & Conditions" />
				<div className="br5px bg5 w50 shadow p2rem table-of-content style-two">
					<h3 className="mb1rem">List of Terms & Conditions:</h3>
					<ul className="list-table">
						<li className="df jcsb"><span className="c4 cp px0-5rem" data-scroll-to="forCompanies">Company Terms</span><i>Page 1</i></li>
						<li className="df jcsb"><span className="c4 cp px0-5rem" data-scroll-to="forIndividuals">Individuals Terms</span><i>Page 2</i></li>
						<li className="df jcsb"><span className="c4 cp px0-5rem" data-scroll-to="acceptableUse">Acceptable Use</span><i>Page 3</i></li>
					</ul>
				</div>
				<div className="pr bg1 p2rem shadow my2rem policy-page-data">
					<HeadingSubheadingContent id="forCompanies" wrapperClass="tac" subheading="For Companies" />
					<p>Effective: October 25, 2022</p>
					<p>This agreement only applies to customers that purchase a Diligentsia service for corporate organisations and is not applicable for Diligentsia services purchased by consumers or individuals. </p>
					<p>This Diligentsia Services Agreement (the "DSA") is between the applicable Diligentsia entity set forth in Appendix 1 ("Diligentsia") and the organization agreeing to these terms ("Customer"). Diligentsia and Customer are each referred to as a “Party” and collectively as the “Parties.” This DSA governs access to and use of the Services and any Beta Services. This DSA, any Service-Specific Terms, each applicable Order Form, the Data Processing Agreement and, if applicable, the HIPAA Business Associate Agreement are collectively referred to as the "Agreement." By clicking "I agree," signing your Order Form for the Services, or using the Services, you agree to the Agreement as a Customer.</p>
					<p>If you are agreeing to this Agreement for use of the Services by an organization, you are agreeing on behalf of that organization. You must have the authority to bind that organization to these terms, otherwise you must not sign up for the Services.</p>
					<ol>
						<li>Services.</li>
						<ol>
							<li>Provision. The Agreement governs access to, and use of the Services, and any associated Software. Customer may access and use the Services in accordance with the Agreement.</li>
							<li>Service-Specific Terms. Certain Services, or portions thereof, may be subject to additional terms, including third party terms and conditions, that are specific to the particular Services and are set forth in the Service-Specific Terms. By accessing or using Services covered by any Service-Specific Terms, you agree to the applicable Service-Specific Terms. If there is a conflict between these Terms and the Service-Specific Terms, the Service-Specific Terms will control with respect to the applicable Services or portions thereof.</li>
							<li>Modifications. Diligentsia may update the Services from time to time. If Diligentsia changes the Services in a manner that materially reduces their functionality, Diligentsia will notify Customer at the email address associated with the account, and Customer may provide notice within thirty days of the change to terminate the Agreement. This termination right will not apply to updates made to features provided on a beta or evaluation basis. Without limiting the foregoing, from time to time, Diligentsia may add additional features to enhance the user experience of the Services at no additional charge. However, these free features may be withdrawn without further notices.</li>
							<li>Software.</li>
							<ol>
								<li>Generally. Some of the Services may allow Customer and End Users to download Software that may update automatically. Diligentsia hereby grants to Customer during the Term a limited non-exclusive license to use the Software solely in connection with the Services and in accordance with the Agreement. This license is non-transferable (subject to Section 14.8), irrevocable (except as set forth in Section 9), non-sublicensable, and will be fully paid up upon Customer’s payment of the Fees.</li>
								<li>Open Source. If any component of the Software is offered under an open source license, Diligentsia will make the license available to Customer and to the extent the provisions of that license grant Customer additional rights, those provisions will expressly override some terms of the Agreement solely with respect to that component of the Software.</li>
							</ol>
							<li>Add-Ons. Customer may purchase Add-Ons for use in conjunction with certain Services. Add-Ons may not be purchased on a standalone basis and must be purchased in connection with a new or existing subscription to the applicable Services for which they are offered. Add-Ons may be subject to additional Service-Specific Terms. Without limiting the foregoing, Add-Ons that are identified as being offered by third parties are not part of the Services and are subject to Section 3.9 of the Agreement.</li>
						</ol>
						<li>Subscriptions.</li>
						<ol>
							<li>Services Term. Unless otherwise set forth on the Order Form, the Services are sold on a subscription basis. Diligentsia will deliver the Services to Customer for the Services Term. Unless the parties agree otherwise in writing, any increases in quantities of Services purchased during any Services Term will have a prorated term ending on the last day of the pre-existing Services Term.</li>
							<li>Automatic Renewals. Unless otherwise specified on the Order Form, following the Initial Services Term or a Renewal Term, Customer’s subscription to the Services will automatically renew for a Renewal Term at the then-current quantity or subscription tier, unless either Party gives the other written notice of termination (including any partial termination to reduce quantities) at least thirty days prior to the expiration of the then-current Services term. If Customer has purchased the Services online via a self-serve mechanism and provided a payment method to Diligentsia for recurring charges, Customer may elect to terminate the Agreement via the Admin Console prior to the day a Renewal Term begins.</li>
							<li>Additional Subscriptions. The Services may be configured to allow Administrators or End Users to purchase additional subscriptions or quantities of Services or Add-Ons. Customer is responsible for understanding the settings and controls of the Services for purchasing additional Services. Diligentsia will charge Customer the applicable pro-rated amount for additional Services or Add-Ons for the remainder of the then-current Services Term based on Customer’s then-current price unless otherwise set forth on the Order Form.</li>
							<li>Usage-based Subscriptions. If Customer elects to purchase any Services based on usage, Customer acknowledges that Diligentsia will charge Customer the Fees for the Services as set forth in the Order Form or Service-Specific Terms based on the usage calculated by Diligentsia.</li>
						</ol>
						<li>Customer Obligations.</li>
						<ol>
							<li>Registration. Customer and its End Users may need to register for an End User Account to place orders or to access or use the Services. Account information must be accurate, current, and complete, and Customer agrees to keep this information up-to-date.</li>
							<li>Customer Administration of the Services. Customer may specify End Users as Administrators, including through multiple tiers of Administrators. Administrators may be able to: (a) access, disclose, restrict access to, or remove Customer Data; (b) purchase additional licenses, capacity, or other applicable quantity for the Services; and (c) provision, monitor, restrict, or terminate access to the Services by End Users. Customer is responsible for maintaining the confidentiality of passwords and Admin Accounts and managing access to Admin Accounts. Diligentsia’s responsibilities do not extend to the internal management or administration of the Services for Customer.</li>
							<li>Third Party Administration. Customer acknowledges that, if Customer purchases the Services through a reseller and designates any of the reseller's personnel as Administrators of Customer's Services account, the reseller may be able to control account information, including Customer Data, and access Customer's Services account as described above.</li>
							<li>End Users.</li>
							<ol>
								<li>Provisioning. For services sold based on End User Licenses, Customer may provision End User Accounts up to the number of End User Licenses purchased through one or more Order Forms. Each End User Account requires a paid End User License, and End User Accounts may not be shared by multiple individuals. End User Accounts may only be provisioned to, registered for, and used by a single End User. End User Accounts that are set as suspended by Customer require a paid End User License. If Customer deletes an End User Account, the End User License may be reallocated to provision a new End User Account.</li>
								<li>Responsibility. Customer is responsible for use of the Services by its End Users. Customer will obtain and maintain from End Users any consents necessary to allow Administrators to engage in the activities described in the Agreement and to allow Diligentsia to deliver the Services.</li>
							</ol>
							<li>Unauthorized Use or Access. Customer will prevent unauthorized use of the Services by its End Users and terminate any unauthorized use of or access to the Services. The Services are not intended for End Users under the age of 18. If the law where the End User resides requires that the End User must be older in order for Diligentsia to lawfully provide the Services to the End User without parental consent, the End User must be that older age. Customer will ensure that it does not allow any person under 18 to use the Services. Customer will promptly notify Diligentsia of any unauthorized use of or access to the Services.</li>
							<li>Restrictions. Customer will not: (a) sell, resell, or lease the Services, Software, or End User Licenses; (b) use the Services or Software for activities where use or failure of the Services or Software could lead to physical damage, death, or personal injury; (c) reverse engineer the Services or Software, or attempt or assist anyone else to do so, unless this restriction is prohibited by law; (d) use the Services or Software, including the export or re-export of Customer Data, in violation of Export Control Laws; (e) violate or circumvent any Service Limits of the Services or otherwise configure the Services to avoid Service Limits; (f) access the Services for the purpose of building a competitive product or service or copying its features or user interface; (g) use the Services for evaluation, benchmarking, or other comparative analysis intended for publication without Diligentsia’s prior written consent; (h) remove or obscure any proprietary or other notices contained in the Services, including in any reports or output obtained from the Services; (i) use or permit the Services to be used for any illegal or misleading purpose; or (j) establish an account on the Services as an individual for personal, family, or household purposes.</li>
							<li>Compliance. Customer and its End Users must use the Services in compliance with the Acceptable Use Policy. Customer will comply with laws and regulations applicable to Customer's use of the Services. Customer will not take any action that would cause Diligentsia to violate EU Data Protection Laws, the U.S. Foreign Corrupt Practices Act of 1977, as amended, the U.K. Bribery Act of 2010, or any other applicable data protection, anti-bribery, anti-corruption, or anti-money laundering law. Customer must satisfy itself that: (i) the Services are appropriate for its purposes, taking into account the nature of the Customer Data; and (ii) the technical and organizational requirements applicable to Diligentsia under EU Data Protection Laws or other data protection laws, if applicable, are satisfied by the Security Measures and the Agreement.</li>
							<li>HIPAA. If Customer will store, transmit or otherwise process any information via the Services that falls within the definition of “Protected Health Information” under the HIPAA Privacy Rule (45 C.F.R. Section 164.051), Customer must separately enter into a HIPAA Business Associate Agreement with Diligentsia prior to storing, transmitting, or otherwise processing this information. NOTWITHSTANDING THE FOREGOING, NOT ALL SERVICES OFFERED BY DILIGENTSIA ARE DESIGNED FOR PROCESSING PROTECTED HEALTH INFORMATION. IF CUSTOMER USES A SERVICE THAT IS NOT DESIGNED FOR PROCESSING PROTECTED HEALTH INFORMATION OR HAS NOT ENTERED INTO THE HIPAA BUSINESS ASSOCIATE AGREEMENT, CUSTOMER MAY NOT USE THE SERVICES TO STORE, TRANSMIT, OR PROCESS THIS INFORMATION.</li>
							<li>Third-Party Apps and Integrations. If Customer uses any third-party service or applications, such as a service that uses a Diligentsia API, with the Services: (a) Diligentsia will not be responsible for any act or omission of the third-party, including the third-party’s access to or use of Customer Data; and (b) Diligentsia does not warrant or support any service provided by the third-party. Customer will comply with any API limits associated with the Services plan purchased by Customer.</li>
						</ol>
						<li>Customer Data.</li>
						<ol>
							<li>Customer Data Use. This Agreement constitutes Customer’s instructions to Diligentsia to Process Customer Data. Diligentsia, Diligentsia personnel and its Subcontractors will only Process, access, use, store, and transfer Customer Data as Customer instructs in order to deliver the Services and to fulfill Diligentsia’s obligations in the Agreement. If Customer accesses or uses multiple Services, Customer acknowledges and agrees that Diligentsia may transfer Customer Data between those Services and third parties where the Customer has agreed to do so. The categories of Personal Data to be processed by Diligentsia and the processing activities to be performed under this Agreement are set out in the Data Processing Agreement. Diligentsia will inform Customer of any legal requirement which prevents it from complying with Customer’s instructions, unless prohibited from doing so by applicable law or on important grounds of public interest. Any Diligentsia personnel who have access to Customer Data will be bound by appropriate confidentiality obligations.</li>
							<li>Security Measures. Diligentsia will use industry standard technical and organizational Security Measures to transfer, store, and Process Customer Data. Customers can find additional information regarding Diligentsia’s security practices for the applicable Services by reviewing any applicable Security Resources. Diligentsia may update the Security Measures from time to time. Diligentsia will provide Customer with at least thirty days prior notice if Diligentsia updates the Security Measures in a manner that materially diminishes the administrative, technical, or physical security features of the Services taken as a whole. Within five business days of receipt of this notice, Customer may elect to terminate the Agreement and associated Order Forms by providing written notice to Diligentsia.</li>
							<li>Third-Party Requests.</li>
							<ol>
								<li>Customer Responsibility. Customer is responsible for responding to Third-Party Requests via its own access to information. Customer will seek to obtain information required to respond to Third-Party Requests and will contact Diligentsia only if it cannot comply with the Third-Party Request despite diligent efforts.</li>
								<li>Diligentsia Responsibility. If Diligentsia receives a Third-Party Request, Diligentsia will make commercially reasonable efforts, to the extent allowed by law and by the terms of the Third-Party Request, to: (i) promptly notify Customer of Diligentsia's receipt of a Third-Party Request; (ii) comply with Customer's commercially reasonable requests regarding its efforts to oppose a Third-Party Request; and (iii) provide Customer with information or tools required for Customer to respond to the Third-Party Request, if Customer is otherwise unable to respond to the Third-Party Request. If Diligentsia is prohibited from notifying Customer of a Third-Party Request or Customer fails to promptly respond to any Third-Party Request, then Diligentsia may, but will not be obligated to do so, to the extent permitted by applicable law.</li>
							</ol>
							<li>Customer Data Sharing. The Services may enable End Users to share Customer Data, including to other Customer End Users and to third parties. Recipients of shared Customer Data may access, view, download, and share this Customer Data, including in and through their own Services accounts. Customer understands: (a) it is solely Customer’s, and its End Users’, choice to share Customer Data; (b) Diligentsia cannot control third parties with whom Customer has shared Customer Data; and (c) Customer and its End Users are solely responsible for their use of the Services, including any sharing of Customer Data through the Services. Certain Account Data such as End Users’ names, profile pictures, or contact information may be viewable by other customers and users with whom End Users collaborate and by the entity that has authority to control a corporate email domain if you purchase the Services using an email address on that domain.</li>
							<li>We need Customer’s permission to do things like hosting Customer Data, backing it up and sharing it when Customer asks us to. It includes our setting up a Diligentsia corporate folder and sub-folders within one or more of Customer’s third party cloud repositories, such as Sharepoint, Google Drive or Diligentsia.  Customer authorises us to upload Customer Data to our Diligentsia databases and platform more generally.  Diligentsia may also provide Customer with features like commenting, sharing, searching, image thumbnails, document previews, optical character recognition (OCR), easy sorting and organisation and personalisation to help reduce busywork. To provide these and other features, Diligentsia accesses, uploads, stores and scans Customer Data within its own databases. Customer gives us permission to do those things, and this permission extends to Diligentsia affiliates and trusted third parties Diligentsia works with.  In particular, unless Customer opts out, Diligentsia is free to share Customer’s Customer Data with Customer’s accountants, lawyers, other advisors and investors where Customer has been introduced by one or more of those organisations as their client or portfolio company.  Where Customer enters additional information directly into the Diligentsia folder within Customer’s cloud storage, Customer authorise us to synch that folder with its corresponding copy held within the Diligentsia databases in Customer’s name.</li>
							<li>Data Transfer. Customer agrees that Diligentsia and its Subcontractors may transfer Customer Data to and access, use, and store Customer Data in locations other than Customer’s country.</li>
							<li>Aggregate/Anonymous Data. Customer agrees that Diligentsia will have the right to generate aggregate and anonymous data based on End Users’ use of the Services and this data is owned by Diligentsia. Diligentsia may use this data for its business purposes during or after the term of this Agreement (including without limitation to develop and improve Diligentsia’s products and services and to create and distribute reports and other materials, including anonymised benchmarking). For clarity, Diligentsia will not disclose any aggregate or anonymous data externally in a manner that could reasonably identify Customer or its End Users. Customer is not responsible for Diligentsia’s use of aggregate or anonymous data.</li>
							<li>Takedown Requests. If Customer receives any take down requests or infringement notices related to Customer Data, it must promptly: (i) stop using the related item with the Services; and (ii) notify Diligentsia. If Diligentsia receives any take down requests or infringement notices related to Customer Data, Diligentsia may respond in accordance with its policies, and will notify and consult with the Customer on next steps.</li>
							<li>Data Processing Agreement. To the extent Customer Data comprises Personal Data that is processed by Diligentsia on Customer’s behalf, Customer and Diligentsia agree to the Data Processing Agreement. The Data Processing Agreement applies only to the Servic and does not apply to Beta Services.</li>
						</ol>
						<li>Confidential Information.</li>
						<ol>
							<li>Use and Non-Disclosure. Except as expressly authorized herein, the Receiving Party will hold in confidence and not use or disclose any Confidential Information. Each Party, as the Receiving Party, will: (i) take reasonable measures to protect the Disclosing Party’s Confidential Information including at least those measures it takes to protect its own confidential information of a similar nature; and (ii) not disclose Confidential Information to any third parties. A Party may disclose Confidential Information to its employees, advisors and consultants who have a need to know the Confidential Information, if that employee, advisor or consultant is bound to restrictions at least as protective of the other Party’s Confidential Information as those set forth in this Agreement.</li>
							<li>Exceptions. Confidential Information does not include information that: (i) is or becomes generally known or available to the public, through no act or omission of the Receiving Party; (ii) was known, without restriction, prior to receiving it from the disclosing Party; (iii) is rightfully acquired from a third party who has the right to disclose it and who provides it without restriction as to use or disclosure; (iv) or is independently developed without access to any Confidential Information of the Disclosing Party.</li>
							<li>Permitted Disclosure. A Party may disclose Confidential Information to the extent required by any law or regulation if it gives the other Party reasonable advance notice, to the extent permitted, so that the other Party can seek to prevent or limit such disclosure. Notwithstanding the foregoing, the Parties’ obligations with respect to Third Party Requests are specifically addressed in Section 4.4.</li>
							<li>Remedies. The Receiving Party acknowledges that a disclosure of Confidential Information in violation of these terms would cause substantial harm for which damages alone would not be a sufficient remedy, and therefore that upon any such disclosure by the Receiving Party the Disclosing Party will be entitled to seek appropriate equitable relief in addition to whatever other remedies it might have at law.</li>
						</ol>
						<li>Payment.</li>
						<ol>
							<li>Fees. Customer will pay Diligentsia or Customer's reseller or Diligentsia’s partner all applicable Fees, in the currency and pursuant to the payment terms indicated on the Order Form, or in the applicable agreement between Customer and Customer’s reseller/Diligentsia’s partner. Customer authorizes Diligentsia, or Customer's reseller/Diligentsia’s Partner, to charge Customer for all applicable Fees using Customer's selected payment method, and Customer will issue the required purchasing documentation. Fees are non-refundable except as required by law or as otherwise specifically permitted in the Agreement.</li>
							<li>Payment. Customer will pay Diligentsia invoices on the payment interval set forth in the Order Form. Diligentsia may suspend or terminate the Services if Fees are past due. Customer will provide complete and accurate billing and contact information to Diligentsia or to Customer's reseller/Diligentsia partner.</li>
							<li>Taxes. Fees are exclusive of taxes and Customer is responsible for all Taxes. Diligentsia, or Customer's reseller/Diligentsia partner, will charge Taxes when required to do so. If Customer provides Diligentsia or its reseller or Diligentsia partner with a valid exemption certificate, Diligentsia will not collect the taxes covered by that certificate.</li>
							<li>Withholding Taxes. Customer will pay Diligentsia or its reseller/Diligentsia partner net of any applicable Withholding Taxes. Customer and Diligentsia, or Customer's reseller/Diligentsia partner if applicable, will work together to avoid any Withholding Tax if exemptions, or a reduced treaty withholding rate, are available. If Diligentsia or Customer's reseller/ Diligentsia partner qualifies for a tax exemption, or a reduced treaty withholding rate, Diligentsia or Customer's reseller/Diligentsia partner will provide Customer with reasonable documentary proof. Customer will provide Diligentsia or Customer's reseller/Diligentsia partner reasonable evidence that it has paid the relevant authority for the sum withheld or deducted.</li>
							<li>Auto-renewals and Trials. IF CUSTOMER HAS ALREADY PROVIDED A PAYMENT METHOD TO DILIGENTSIA OR CUSTOMER’S RESELLER/DILIGENTSIA PARTNER FOR RECURRING CHARGES AND CUSTOMER’S ACCOUNT IS SET TO AUTO-RENEWAL OR IS IN A TRIAL PERIOD, DILIGENTSIA (OR CUSTOMER’S RESELLER/DILIGENTSIA PARTNER) MAY CHARGE AUTOMATICALLY AT THE END OF THE TRIAL OR FOR THE RENEWAL, UNLESS CUSTOMER NOTIFIES DILIGENTSIA (OR CUSTOMER’S RESELLER/DILIGENTSIA PARTNER, AS APPLICABLE) THAT CUSTOMER WANTS TO DISABLE AUTO-RENEWAL OR CANCEL THE SERVICES IN ACCORDANCE WITH SECTION 2.2. Diligentsia may revise Services rates by providing the Customer at least thirty days' notice prior to the next charge.</li>
							<li>Purchase Orders. If Customer requires the use of a purchase order or purchase order number, Customer: (i) must provide the purchase order number at the time of purchase; and (ii) agrees that any terms and conditions on a Customer purchase order will not apply to this Agreement and are null and void. If the Customer is purchasing via a reseller/Diligentsia partner, any terms and conditions from the Customer's reseller/Diligentsia partner or in a purchase order between the Customer and its reseller/Diligentsia partner that conflict with the Agreement are null and void.</li>
							<li>No Refunds. Subscription and usage or overage Fees are non-refundable and non-creditable, except where required by law. Diligentsia subscriptions may be cancelled, and such cancellations take effect at the end of your then-current subscription term (for example, if Customer is on a paid monthly subscription the cancellation will take effect the following month, but if Customer is on a paid yearly subscription the cancellation will take effect the following year).</li>
						</ol>
						<li>Suspension.</li>
						<ol>
							<li>Of End User Accounts by Diligentsia. If an End User: (a) violates the Agreement; or (b) uses the Services in a manner that Diligentsia reasonably believes will cause it liability, then Diligentsia may request that Customer suspend or terminate the applicable End User account. If Customer fails to promptly suspend or terminate the End User account, then Diligentsia may do so.</li>
							<li>Of the Services. Diligentsia may suspend Customer’s access to the Services if: (i) Customer’s account is overdue; or (ii) Customer has exceeded any Service Limits. Diligentsia may also suspend Customer’s access to the Services or remove Customer Data if it determines that: (a) Customer has breached any portion of this Agreement, or (b) suspension is necessary to prevent a Security Emergency, provided that Diligentsia will make commercially reasonable efforts to narrowly tailor the suspension as needed to prevent or terminate the Security Emergency. Diligentsia will have no liability for taking action as permitted above. For the avoidance of doubt, Customer will remain responsible for payment of fees during any suspension period under this Section 7.2. However, unless this Agreement has been terminated, Diligentsia will cooperate with Customer to promptly restore access to the Services once it verifies that Customer has resolved the condition requiring suspension.</li>
						</ol>
						<li>Intellectual Property Rights.</li>
						<ol>
							<li>Reservation of Rights. Except as expressly set forth herein, the Agreement does not grant: (a) Diligentsia any Intellectual Property Rights in Customer Data; or (b) Customer any Intellectual Property Rights in the Services or Diligentsia trademarks and brand features. Customer acknowledges that it is obtaining only a limited right to use the Services and that irrespective of any use of the words “purchase”, “sale” or similar terms, no ownership rights are transferred to Customer (or its End Users) under this Agreement.</li>
							<li>Limited Permission. Customer grants Diligentsia only the limited rights that are reasonably necessary for Diligentsia to deliver the Services. This limited permission also extends to Subcontractors or Sub-processors and Diligentsia Partners.</li>
							<li>Feedback. Diligentsia may use, modify, and incorporate into its products and services, license and sublicense, any Feedback that Customer or End Users may provide without any obligation to Customer. Customer agrees to: (i) and hereby does, assign to Diligentsia all right, title, and interest in any Feedback; and (ii) provide Diligentsia any reasonable assistance necessary to document and maintain Diligentsia’s rights in the Feedback.</li>
						</ol>
						<li>Term & Termination.</li>
						<ol>
							<li>Agreement Term. The Agreement will remain in effect for the Term.</li>
							<li>Termination. Either Party may terminate the Agreement, including all Order Forms, if: (i) the other Party is in material breach of the Agreement and fails to cure that breach within thirty days after receipt of written notice; or (ii) the other Party ceases its business operations or becomes subject to insolvency proceedings and the proceedings are not dismissed within ninety days. Diligentsia may terminate this Agreement and suspend Customer’s access to the Services if required to do so by law or for an egregious violation by Customer of the Acceptable Use Policy.</li>
							<li>Effects of Termination. If the Agreement terminates: (a) except as set forth in this Section, the rights and licenses granted by Diligentsia to Customer will cease immediately; (b) Customer will delete (or, at Diligentsia’s request, return) any and all copies of any Diligentsia code, documentation, passwords or access codes, and any other Diligentsia Confidential Information in Customer’s possession, custody, or control; (c) Customer may, prior to termination, request reasonable additional time to export its Stored Data, provided that Diligentsia may charge Customer for this extended access based on Diligentsia’s then-current standard fees; and (d) Diligentsia will delete any End User Accounts and Stored Data in Customer’s account in a commercially reasonable period of time following receipt of an Administrator’s request to do so prior to termination of the Services. Diligentsia may make instructions available to Customer regarding how to submit the Administrator request described in clause (d) of the previous sentence and Customer is responsible for following these instructions to initiate a deletion.</li>
							<li>Survival. The following sections will survive expiration or termination of the Agreement: 4.4 (Third Party Requests), 6.2 (Payment), 8 (Intellectual Property Rights), 9.3 (Effects of Termination), 9.4 (Survival), 10 (Indemnification), 11 (Disclaimers), 12 (Limitation of Liability), 13 (Disputes), and 14 (Miscellaneous). Notwithstanding the foregoing, Section 4.4 (Third-Party Requests) shall not survive termination if Diligentsia has exercised a right to terminate the Agreement.</li>
						</ol>
						<li>Indemnification.</li>
						<ol>
							<li>By Customer. Customer will indemnify, defend, and hold harmless Diligentsia from and against all liabilities, damages, and costs (including settlement costs and reasonable attorneys’ fees) arising out of any Claim against Diligentsia and its Affiliates regarding: (a) Customer Data; (b) Customer Domains; (c) Customer’s or Customer’s End Users’ use of the Services in violation of the Agreement; (d) Customer’s professional advisors or investors or similar third parties.</li>
							<li>By Diligentsia. Diligentsia will indemnify, defend, and hold harmless Customer from and against all liabilities, damages, and costs (including settlement costs and reasonable attorneys' fees) arising out of any Claim against Customer to the extent based on an allegation that Diligentsia's technology used to deliver the Services to the Customer infringes or misappropriates any copyright, trade secret, UK patent, or trademark right of the third party. In no event will Diligentsia have any obligations or liability under this section arising from: (a) use of any Services in a modified form or in combination with materials not furnished by Diligentsia; and (b) any content, information, or data provided by Customer, End Users, or other third parties.</li>
							<li>Possible Infringement. If Diligentsia believes the Services or Software infringe or may be alleged to infringe a third party's Intellectual Property Rights, then Diligentsia may: (a) obtain the right for Customer, at Diligentsia's expense, to continue using the Services or Software; (b) provide a non-infringing functionally equivalent replacement; or (c) modify the Services or Software so that they no longer infringe. If Diligentsia does not believe the options described in this section are commercially reasonable, then Diligentsia may suspend or terminate Customer's use of the affected Services or Software, with a pro-rata refund of prepaid fees for the Services or Software.</li>
							<li>General. The Party seeking indemnification will promptly notify the other Party of the claim and cooperate with the other Party in defending the claim. The indemnifying Party will have full control and authority over the defence, except that: (a) any settlement requiring the Party seeking indemnification to admit liability requires prior written consent, not to be unreasonably withheld or delayed; and (b) the other Party may join in the defence with its own counsel at its own expense. THE INDEMNITIES ABOVE ARE DILIGENTSIA AND CUSTOMER'S ONLY REMEDY UNDER THE AGREEMENT FOR VIOLATION BY THE OTHER PARTY OF A THIRD PARTY'S INTELLECTUAL PROPERTY RIGHTS.</li>
						</ol>
						<li>Disclaimers.</li>
						<ol>
							<li>Generally. THE SERVICES, SOFTWARE, AND ANY RELATED DOCUMENTATION ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED BY LAW, EXCEPT AS EXPRESSLY STATED IN THE AGREEMENT, DILIGENTSIA AND ITS PARTNERS,AFFILIATES, SUPPLIERS, AND DISTRIBUTORS MAKE NO WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE, OR NON-INFRINGEMENT. DILIGENTSIA MAKES NO REPRESENTATION, WARRANTY OR GUARANTEE THAT SERVICES WILL MEET CUSTOMER’S REQUIREMENTS OR EXPECTATIONS, THAT CUSTOMER DATA WILL BE ACCURATE, COMPLETE, OR PRESERVED WITHOUT LOSS, OR THAT THE SERVICES WILL BE TIMELY, UNINTERRUPTED OR ERROR-FREE. DILIGENTSIA WILL NOT BE RESPONSIBLE OR LIABLE IN ANY MANNER FOR ANY CUSTOMER PROPERTIES, CUSTOMER DATA, THIRD-PARTY PRODUCTS, THIRD-PARTY CONTENT, OR NON-DILIGENTSIA SERVICES (INCLUDING FOR ANY DELAYS, INTERRUPTIONS, TRANSMISSION ERRORS, SECURITY FAILURES, AND OTHER PROBLEMS CAUSED BY THESE ITEMS). CUSTOMER IS RESPONSIBLE FOR USING THE SERVICES OR SOFTWARE IN ACCORDANCE WITH THE TERMS SET FORTH HEREIN AND BACKING UP ANY STORED DATA ON THE SERVICES.</li>
							<li>Beta Services.</li>
							<ol>
								<li>Use In Customer's Discretion. Despite anything to the contrary in the Agreement: (a) Customer may choose to use Beta Services in its sole discretion; (b) Beta Services may not be supported and may be changed at any time without notice; (c) Beta Services may not be as reliable or available as the Services; (d) Beta Services have not been subjected to the same Security Measures and auditing to which the Services have been subjected; and (e) DILIGENTSIA WILL HAVE NO LIABILITY ARISING OUT OF OR IN CONNECTION WITH BETA SERVICES - USE AT YOUR OWN RISK.</li>
								<li>Feedback. Diligentsia offers Beta Services in order to get user feedback. In exchange for using Beta Services, Customer agrees that Diligentsia may contact Customer and its End Users to obtain Feedback regarding Beta Services.</li>
								<li>Confidential. Beta Services are confidential until officially launched by Diligentsia and Customer will treat the Beta Services, and any documentation, specifications, or instructions related the Beta Services, as Confidential Information of Diligentsia.</li>
							</ol>
						</ol>
						<li>Limitation of Liability.</li>
						<ol>
							<li>Limitation on Indirect Liability. TO THE FULLEST EXTENT PERMITTED BY LAW, EXCEPT FOR DILIGENTSIA OR CUSTOMER'S INDEMNIFICATION OBLIGATIONS, NEITHER CUSTOMER NOR DILIGENTSIA AND ITS AFFILIATES, SUPPLIERS, AND DISTRIBUTORS WILL BE LIABLE UNDER THE AGREEMENT FOR (I) INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, OR (II) LOSS OF USE, DATA, BUSINESS, REVENUES, OR PROFITS (IN EACH CASE WHETHER DIRECT OR INDIRECT), EVEN IF THE PARTY KNEW OR SHOULD HAVE KNOWN THAT SUCH DAMAGES WERE POSSIBLE AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</li>
							<li>Limitation on Amount of Liability. TO THE FULLEST EXTENT PERMITTED BY LAW, DILIGENTSIA’S AGGREGATE LIABILITY UNDER THE AGREEMENT WILL NOT EXCEED THE LESSER OF $100,000 OR THE AMOUNT PAID BY CUSTOMER TO DILIGENTSIA HEREUNDER DURING THE TWELVE MONTHS PRIOR TO THE EVENT GIVING RISE TO LIABILITY. FOR FREE ACCESS SUBSCRIPTIONS, TRIALS, OR BETA SERVICES, DILIGENTSIA’S TOTAL LIABILITY WILL NOT EXCEED, IN AGGREGATE, FIFTY POUNDS STERLING (£50).</li>
							<li>Failure of Essential Purpose. EACH PARTY ACKNOWLEDGES AND AGREES THAT THIS SECTION 12 IS A FUNDAMENTAL BASIS OF THE BARGAIN AND A REASONABLE ALLOCATION OF RISK BETWEEN THE PARTIES AND WILL SURVIVE AND APPLY TO ANY CLAIMS ARISING OUT OF OR RELATED TO THIS AGREEMENT, ANY DILIGENTSIA SERVICES OR ANY RELATED SERVICES, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE), EVEN IF ANY LIMITED REMEDY IN THIS AGREEMENT IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</li>
						</ol>
						<li>Disputes.</li>
						<ol>
							<li>Informal Resolution. Before filing a claim, each Party agrees to try to resolve the dispute by contacting the other Party through the notice procedures in Section 14.6. If a dispute is not resolved within thirty days of notice, Customer or Diligentsia may bring a formal proceeding.</li>
							<li>Arbitration. Customer and Diligentsia agree to resolve any claims relating to the Agreement or the Services through final and binding arbitration, except as set forth below. This includes disputes arising out of or relating to the interpretation or application of this “Arbitration” subsection, including its scope, enforceability, revocability, or validity. The UK Chartered Institute of Arbitrators (CIArb) will administer the arbitration under its Commercial Arbitration Rules. The arbitration will be held in London, UK, or any other location both parties agree to in writing. The arbitrator may award relief only individually and only to the extent necessary to redress Customer’s or Diligentsia’s individual claim(s); the arbitrator may not award relief on behalf of others or the general public. Diligentsia’s past, present and future affiliates, partners and agents may invoke their rights under this “Disputes” Section in the event they become involved in a dispute with Customer; otherwise, these Terms do not give rights to any third parties.</li>
							<li>Exception to Arbitration. Either Party may bring a lawsuit in courts of England & Wales solely for injunctive relief to stop unauthorized use or abuse of the Services or infringement of Intellectual Property Rights without first engaging in the informal dispute notice process described above. Both Customer and Diligentsia consent to venue and personal jurisdiction in the UK.</li>
							<li>NO CLASS ACTIONS. Customer may only resolve disputes with Diligentsia on an individual basis and will not bring a claim in a class, consolidated or representative action. Class arbitrations, class actions, private attorney general actions and consolidation with other arbitrations are not allowed.</li>
							<li>Severability. If any part of this “Disputes” section is found to be illegal or unenforceable, the remainder will remain in effect, except that if a finding of partial illegality or unenforceability would allow class or representative arbitration, this “Disputes” section will be unenforceable in its entirety. If Customer is found to have a non-waivable right to bring a particular claim or to request a particular form of relief that the arbitrator lacks authority to redress or award according to this “Disputes” section, then only that respective claim or request for relief may be brought in court, and Customer and Diligentsia agree that litigation of any such claim or request for relief shall be stayed pending the resolution of any individual claim(s) or request(s) for relief in arbitration.</li>
						</ol>
						<li>Miscellaneous.</li>
						<ol>
							<li>Terms Modification. Diligentsia may revise this Agreement from time to time and the most current version will always be posted on the Diligentsia website. If a revision, in Diligentsia's sole discretion, is material, Diligentsia will notify Customer (by, for example, sending an email to the email address associated with the applicable account). Other revisions may be posted to Diligentsia's blog or terms page, and Customer is responsible for checking these postings regularly. By continuing to access or use the Services after revisions become effective, Customer agrees to be bound by the revised Agreement. If Customer does not agree to the revised Agreement terms, Customer may terminate the Services within thirty days of receiving notice of the change.</li>
							<li>Entire Agreement. The Agreement supersedes any prior agreements or understandings between the Parties and constitutes the entire agreement between the Parties related to this subject matter. The Service-Specific Terms, the Data Processing Agreement, Customer invoices, and the Order Forms executed by the Parties, are hereby incorporated into the Agreement by this reference.</li>
							<li>Interpretation of Conflicting Terms. If there is a conflict between the documents that make up the Agreement, the documents will control in the following order: the invoice, the Service-Specific Terms, Order Form, the DSA. The terms and conditions of the Agreement will be considered Confidential Information of Diligentsia. Customer agrees that any terms and conditions on a Customer purchase order will not apply to the Agreement and are null and void. If End Users are required to click through terms of service in order to use the Services, those click through terms are subordinate to this Agreement and the Agreement will control if there is a conflict.</li>
							<li>Governing Law. THE AGREEMENT WILL BE GOVERNED BY UK LAW.</li>
							<li>Severability. Unenforceable provisions will be modified to reflect the parties' intention and only to the extent necessary to make them enforceable, and the remaining provisions of the Agreement will remain in full effect.</li>
							<li>Notice. Notices must be sent via email, first class, airmail, or overnight courier and are deemed given when received. Notices to Customer may also be sent to the applicable account email address and are deemed given when sent. Notices to Diligentsia must be sent to Diligentsia Legal at privacy@Diligentsia.co.uk, with a copy to Diligentsia Limited, The Old Rectory, Nock Verges, Stoney Stanton, Leicestershire, LE9 4LR, UKP.O. Box 77767, attn.: Legal Department.</li>
							<li>Waiver. A waiver of any default is not a waiver of any subsequent default.</li>
							<li>Assignment. Customer may not assign or transfer the Agreement or any rights or obligations under the Agreement without the written consent of Diligentsia, except that Customer may assign the Agreement to the surviving entity in connection with a merger, acquisition, or sale of all or substantially all of its assets by providing written notice to Diligentsia. Diligentsia may not assign the Agreement without providing notice to Customer, except Diligentsia may assign the Agreement or any rights or obligations under the Agreement to an Affiliate or in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of its assets without providing notice. Any other attempt to transfer or assign is void.</li>
							<li>No Agency. Diligentsia and Customer are not legal partners or agents but are independent contractors.</li>
							<li>Subcontracting. Customer consents to Diligentsia’s appointment of Subcontractors, including Sub-processors, to perform the Services. Diligentsia will remain liable for all acts or omissions of its Subcontractors or Sub-processors, and for any subcontracted obligations.</li>
							<li>Force Majeure. Except for payment obligations, neither Diligentsia nor Customer will be liable for inadequate performance to the extent caused by a condition that was beyond the Party's reasonable control (for example, natural disaster, act of war or terrorism, riot, labour condition, governmental action, and Internet disturbance).</li>
							<li>No Third-Party Beneficiaries. There are no third-party beneficiaries to the Agreement. Without limiting this section, a Customer's End Users are not third-party beneficiaries to Customer's rights under the Agreement.</li>
						</ol>
						<li>Definitions.</li>
					</ol>
					<ol>
						<li>"Acceptable Use Policy" means the acceptable use policy set forth at the following link, or other link Diligentsia may provide: https://www.Diligentsia.co.uk/acceptable_use.</li>
						<li>"Account Data" means the account and contact information submitted to the Services by Customer or End Users.</li>
						<li>"Add-On(s)" means an additional feature, component, service, or functionality that can be separately purchased or provisioned for use with certain Services.</li>
						<li>"Administrator" means a Customer-designated technical End User who administers the Services to End Users on Customer's behalf, through multiple tiers.</li>
						<li>"Admin Account" means an administrative account provided to Customer by Diligentsia for the purpose of administering the Services.</li>
						<li>"Admin Console" means the online tool provided by Diligentsia to Customer for use in administering the Services.</li>
						<li>"Affiliate" means any entity that controls, is controlled by or is under common control with a Party, where "control" means the ability to direct the management and policies of an entity.</li>
						<li>"Agreement" means, collectively, this DSA, each applicable Order Form, the Data Processing Agreement and, if applicable, the HIPAA Business Associate Agreement and any Service-Specific Terms entered into by the Parties.</li>
						<li>"Audit Reports" means the audit reports for the applicable Services that are set forth in the Service-Specific Terms.</li>
						<li>"Beta Services" means services or features identified as alpha, beta, preview, early access, MVP, or evaluation, or words or phrases with similar meanings.</li>
						<li>"Claim" means a claim by a third party, including a regulatory penalty.</li>
						<li>"Confidential Information" means information disclosed by one Party to the other Party that is identified as confidential at the time of disclosure or should be reasonably known by the Receiving Party to be Confidential Information due to the nature of the information disclosed and the circumstances surrounding the disclosure. Any performance information relating to the Service and the terms and conditions of this Agreement shall be deemed Confidential Information of Diligentsia without any marking or further designation.</li>
						<li>"Customer Data" means Stored Data, Account Data, and messages, comments, structured data, images, and other content submitted to the Services by Customer or End Users.</li>
						<li>"Customer Domains" means Customer's Internet domain names.</li>
						<li>"Data Processing Agreement" means the data processing agreement set forth at the following link: https://assets.Diligentsia.co.uk/documents/en/legal/dfb-data-processing-agreement.pdf or other link that Diligentsia may provide.</li>
						<li>"Disclosing Party" means the Party disclosing Confidential Information to the other Party.</li>
						<li>"EEA" means European Economic Area.</li>
						<li>"Effective Date" means the date this DSA is entered into by the Parties, either by acceptance online or by the signing of an Order Form.</li>
						<li>"End Users" means users of Customer’s Services account. End Users may include Customer’s and its Affiliate’s employees, consultants, agents, representatives, students or any other person authorized by Customer to use the Services through Customer’s account.  This also includes the Customer’s lawyers, accountants, investors and other similar advisors.</li>
						<li>"End User Account" means a Diligentsia hosted account provisioned by Customer through the Services for an End User or advisor.</li>
						<li>"End User License" means a user license purchased by Customer which enables Customer to provision an End User Account.</li>
						<li>"EU Data Protection Laws" means, to the extent in force and applicable from time to time, those laws implementing the EU General Data Protection Regulation (2016/679) and any implementing laws in each EU member state.</li>
						<li>"Export Control Laws" means all applicable export and re-export control laws and regulations, including the Export Administration Regulations ("EAR") maintained by the U.S. Department of Commerce, trade and economic sanctions maintained by the Treasury Department's Office of Foreign Assets Control, and the International Traffic in Arms Regulations ("ITAR") maintained by the Department of State.</li>
						<li>"Feedback" means any feedback, comments, or suggestions on the Services or Beta Services that Customer or End Users may send Diligentsia or post in Diligentsia’s forums. Feedback may include oral or written comments, suggestions, error reports, and analysis.</li>
						<li>"Fees" means the amounts invoiced to Customer by Diligentsia.</li>
						<li>"HIPAA Business Associate Agreement" means an agreement between Customer and Diligentsia detailing each Party's obligations regarding "Protected Health Information" under the HIPAA Privacy Rule (45 C.F.R. Section 164.051).</li>
						<li>"Initial Services Term" means the term for the applicable Services beginning on the Provisioning Date and continuing for the duration set forth on the Order Form.</li>
						<li>"Intellectual Property Rights" means current and future worldwide rights under patent, copyright, trade secret, trademark, moral rights, and other similar rights.</li>
						<li>"Order Form" means an ordering document, order page, or user interface through which Customer purchases a subscription to the Services or enables access to the Services.</li>
						<li>"Personal Data, "Process," and "Processing"" have the meaning given to those terms in the EU Data Protection Laws.</li>
						<li>"Provisioning Date" is the date upon which Diligentsia makes the Services available to Customer.</li>
						<li>"Receiving Party" means the Party receiving Confidential Information from the other Party.</li>
						<li>"Renewal Term" means, unless otherwise agreed to in writing by the Parties, the renewal term of the same duration as the Initial Services Term or preceding Renewal Term.</li>
						<li>"Security Emergency" means: (i) use of the Services that does or could disrupt the Services, other customers' use of the Services, or the infrastructure used to deliver the Services; or (ii) unauthorized third-party access to the Services.</li>
						<li>"Security Measures" means the technical and organizational security measures implemented by Diligentsia for the applicable Services, as may be further described in the Service-Specific Terms.</li>
						<li>"Security Resources" means any webpages, resources, guides, and whitepapers regarding technical or organizational measures that Diligentsia has implemented for the applicable Services that are described in the Service-Specific Terms.</li>
						<li>"Services" means the services ordered by Customer on the Order Form, excluding any third-party Add-Ons (as indicated at the time of purchase or enablement).</li>
						<li>"Service-Specific Terms" means additional terms that apply to certain Services or Add-Ons as set forth in Appendix 2 or otherwise attached to an Order Form.</li>
						<li>"Services Term" means the Initial Services Term and all Renewal Terms for the applicable Services.</li>
						<li>"Service Limits" means rate, storage, End User or other limits on Customers use of the Services as described in the applicable Order Form or product description page.</li>
						<li>"Software" means the client software provided as part of the Services, either directly by Diligentsia or through third party distribution channels such as app stores.</li>
						<li>"Stored Data" means the files uploaded to the Services by Customer or End Users.</li>
						<li>"Subcontractor" means an entity to whom Diligentsia subcontracts any of its obligations under the Agreement.</li>
						<li>"Sub-processor" means an entity who agrees to Process Customer Data on Diligentsia's behalf, or on behalf of another Diligentsia sub-processor, in order to deliver the Services.</li>
						<li>"Taxes" means any sales, use, value added, goods and services, consumption, excise, local stamp, or other tax, (including but not limited to ISS, CIDE, PIS, CONFINS), duty or other charge of any kind or nature excluding tax that is based on Diligentsia's net income, associated with the Services or Software, including any related penalties or interest.</li>
						<li>"Term" means the term of the Agreement, which will begin on the Effective Date and continue until the earlier of: (i) the end of all applicable Services Terms; or (ii) the Agreement is terminated as set forth herein.</li>
						<li>"Third-Party Request" means a request from a third-party for records relating to an End User's use of the Services including information in or from an End User Account, or from Customer's Services account. Third-Party Requests may include valid search warrants, court orders, or subpoenas, or any other request for which there is written consent from End Users, or an End User's authorized representative, permitting a disclosure.</li>
						<li>"Withholding Taxes" mean any income taxes that are imposed on Diligentsia or Customer's reseller in which Customer is required by law to withhold or deduct on the payment to Diligentsia or Customer's reseller.</li>
					</ol>
					<p>Appendix 1:</p>
					<p>Diligentsia Entities</p>
					<p>The Agreement will be between Customer and the following Diligentsia entities, as applicable:</p>
					<p>Diligentsia Limited, a company registered in England & Wales under company number 14256673</p>
					<p>Appendix 2:</p>
					<p>Service Specific Terms</p>
					<p>Posted: October 25, 2022</p>
					<p>Effective: October 25, 2022</p>
					<p>These Service-Specific Terms set forth additional terms and conditions that are specific to the Services listed below.</p>
					<p>Diligentsia may update these Service-Specific Terms from time to time, including to add additional Service-Specific Terms for new Services made available by Diligentsia, by posting an updated version. If an update materially changes any Service-Specific Terms for any Services already purchased by Customer, Diligentsia will notify Customer in accordance with Section 14.1 of the DSA.</p>
					<p>Diligentsia Free, Lite, Pro, Pro PLUS, Portfolio Pro & Expansion Packs</p>
					<ol>
						<li>Service Description. The Diligentsia Free, Lite, Pro, Pro PLUS, Portfolio Pro & Expansion Packs services are described at https://www.Diligentsia.co.uk/plans or other link that Diligentsia may provide. The Diligentsia Education services are described at https://www.Diligentsia.com/education.</li>
						<li>Security & Audits.</li>
						<li>Security Measures. The Security Measures for these Services are described at https://assets.Diligentsia.co.uk/documents/en/legal/security-measures.pdf or other link that Diligentsia may provide.</li>
						<li>Customer Domains. Prior to providing the Services, Diligentsia may require Customer to verify that Customer owns or controls the Customer Domains. If Customer does not own or control the Customer Domains, then Diligentsia will have no obligation to provide Customer with the Services.</li>
					</ol>
					<p className="c4 df jcc mt1rem">Page 1</p>
				</div>
				
				<div className="pr bg1 p2rem shadow my2rem policy-page-data">
					<HeadingSubheadingContent id="forIndividuals" wrapperClass="tac" subheading="For Individuals" />
					<p>Diligentsia Terms & Conditions of Service</p>
					<p>Effective: 30th June 2022</p>
					<p>Thanks for using Diligentsia! Our mission is to create a more enlightened way of working by providing an intuitive, unified platform to keep your content safe and accessible while helping you and those you work with stay coordinated and in sync. These terms of service (‘Terms’) cover your use and access to our services, client software and websites (‘Services’). Your agreement is with Diligentsia Limited, a UK company number registered in England & Wales under registration number 14256673. Our Privacy Policy explains how we collect and use your information while our Acceptable Use Policy outlines your responsibilities when using our Services. By using our Services, you’re agreeing to be bound by these Terms, our Privacy Policy and Acceptable Use Policy.</p>
					<p>Your Stuff & Your Permissions</p>
					<p>When you use our Services, your organisation provide us with things like your files, content, messages, contacts and so on (“Your Stuff”). Your Stuff belongs to your business. These Terms don’t give us any rights to Your Stuff except for the limited rights that enable us to offer the Services.  </p>
					<p>We need your permission to do things like hosting Your Stuff, backing it up and sharing it when you ask us to. It includes our setting up a Diligentsia corporate folder and sub-folders within one or more of your organisation’s third party cloud repositories, such as Sharepoint, Google Drive or Diligentsia.  You authorise us to upload Our Services to our Diligentsia databases and platform more generally.  We may also provide you with features like commenting, sharing, searching, image thumbnails, document previews, optical character recognition (OCR), easy sorting and organisation and personalisation to help reduce busywork. To provide these and other features, Diligentsia accesses, uploads, stores and scans Your Stuff within its own databases. You give us permission to do those things, and this permission extends to our affiliates and trusted third parties we work with.  In particular, unless you opt out, we are free to share your information with your accountants, lawyers, investors where you have been introduced by one or more of those organisations as their client or portfolio company.  Where you enter additional information directly into the Diligentsia folder within your cloud storage, you authorise us to synch that folder with its corresponding copy held within the Diligentsia databases in your organisation’s name.</p>
					<p>You authorise us to undertake data analytics on the information your organisation uploads to, and synch’s with, the Diligentsia folder.  We may utilise that information on an anonymised basis both to improve our and to provide additional services to you, either directly or through trusted third parties we work with, provided that you will authorise those third parties.</p>
					<p>Your Responsibilities</p>
					<p>Your organisation’s use of our Services must comply with our Acceptable Use Policy. Content in the Services may be protected by others’ intellectual property rights. Please don’t copy, upload, download or share content unless you have the right to do so.</p>
					<p>Diligentsia may review your organisation’s conduct and content for compliance with these Terms and our Acceptable Use Policy. We are not responsible for the content people post and share via the Services.</p>
					<p>Help us keep Your Stuff protected. Safeguard your organisation’s employees’ passwords to the Services, and keep your organisation’s account information current. Don’t share your account credentials or give others access to your account.</p>
					<p>You may use our Services only as permitted by applicable law, including export control laws and regulations. Finally, to use our Services you must be aged over 18. If the law where you reside requires that you must be older in order for Diligentsia to lawfully provide the Services to you without parental consent (including use of your personal data), then you must be that older age.</p>
					<p>Software</p>
					<p>Some of our Services and those provided by our partners allow you to download client software (“Software”) which may be updated automatically. As long as you comply with these Terms, we and they give you a limited, non-exclusive, non-transferable, revocable licence to use the Software, solely to access the Services. To the extent any component of the Software may be offered under an open source licence, we’ll make that licence available to you and the provisions of that licence may expressly override some of these Terms. Unless the following restrictions are prohibited by law, you agree not to reverse engineer or decompile the Services, attempt to do so or assist anyone to do so.</p>
					<p>Beta Services</p>
					<p>We sometimes release products and features that we’re still testing and evaluating (‘Beta Services’). Beta Services are labelled ‘alpha’, ‘beta’, ‘preview’, ‘early access’, ‘evaluation’ or “MVP” (or with words or phrases with similar meanings) and may not be as reliable as Diligentsia’s other services. Beta Services are made available so that we can collect user feedback, and by using our Beta Services, you agree that we may contact you to collect such feedback.</p>
					<p>Beta Services are confidential until official launch. If you use any Beta Services, you agree not to disclose any information about those Services to anyone else without our permission.</p>
					<p>Additional Features</p>
					<p>From time to time, Diligentsia will add additional features to enhance the user experience of our services at no additional charge. However, these free features may be withdrawn without further notice.</p>
					<p>Our Stuff</p>
					<p>The Services are protected by copyright, trademark, and other UK and foreign laws. These Terms don’t grant you any right, title or interest in the Services, others’ content in the Services, Diligentsia trademarks, logos and other brand features. We welcome feedback, but please note that we may use comments or suggestions without any obligation to you.</p>
					<p>Copyright</p>
					<p>We respond to notices of alleged copyright infringement if they comply with the law, and such notices should be reported using our Copyright Policy. We reserve the right to delete or disable content alleged to be infringing and terminate accounts of repeat infringers. Our designated agent for notice of alleged copyright infringement on the Services is:</p>
					<p>The CEO</p>
					<p>Diligentsia Limited</p>
					<p>The Old Rectory</p>
					<p>Nock Verges, Stoney Stanton LE9 $LR UK</p>
					copyright@Diligentsia.com
					<p>Paid Accounts</p>
					<p>Billing. You can change plans and/or add paid features to your account (turning your account into a ‘Paid Account’). We’ll automatically bill you from the date you convert to a Paid Account and on each periodic renewal until cancellation. If you’re on an annual plan, we’ll send you a notice email reminding you that your plan is about to renew within a reasonable period of time prior to the renewal date. You’re responsible for all applicable taxes, and we’ll charge tax when required to do so. Some countries have mandatory local laws regarding your cancellation rights, and this paragraph doesn’t override these laws.</p>
					<p>Cancellation. You may cancel your account at any time. Refunds are only issued if required by law. For example, users living in the European Union have the right to cancel their Paid Account subscriptions within 14 days of signing up for, upgrading to or renewing a Paid Account by clicking here.  Upon your account being cancelled, Diligentsia has the right to suspend the provision of its Services with immediate effect.  This may mean that some or all of your Stuff may be unavailable within the Diligentsia platform or Diligentsia folder within your cloud platform.  You will be warned of this via an email prior to our taking such action. </p>
					<p>Downgrades. Your Paid Account will remain in effect until cancelled or terminated under these Terms If you don’t pay for your Paid Account on time, we reserve the right to suspend it or remove Paid Account features.</p>
					<p>Changes. We may change the fees in effect on renewal of your subscription, to reflect factors such as changes to our product offerings, changes to our business or changes in economic conditions. We’ll give you no less than 30 days’ advance notice of these changes via a message to the email address associated with your account and you’ll have the opportunity to cancel your subscription before the new fee comes into effect.</p>
					<p>Diligentsia Business Teams</p>
					<p>Using Diligentsia Business Teams. If your organisation signs up to Diligentsia and uses our Services, you must use them in compliance with your organisation’s terms and policies. Please note that Diligentsia accounts are subject to your organisation's control. Your administrators may be able to access, disclose, restrict or remove information in or from your Diligentsia account. They may also be able to restrict or terminate your access to a Diligentsia account. </p>
					<p>Termination</p>
					<p>Your organisation is free to stop using our Services at any time. We reserve the right to suspend or terminate your access to the Services with notice to you if:</p>
					<ol>
						<li>Your organisation or any employee is in breach of these Terms,</li>
						<li>Your organisation’s use of the Services would cause a real risk of harm or loss to us or other users, or</li>
						<li>Your organisation hasn't accessed our Services for 12 consecutive months.</li>
						<li>Your organisation has ceased to pay for the relevant Services.</li>
					</ol>
					<p>We’ll provide you with reasonable advance notice via the email address associated with your account to remedy the activity that prompted us to contact you and give you the opportunity to export Your Stuff from our Services. If after such notice you fail to take the steps we ask of you, we’ll terminate or suspend your access to the Services.</p>
					<p>We won’t provide notice or an opportunity to export Your Stuff before termination or suspension of access to the Services where:</p>
					<ol>
						<li>you’re in material breach of these Terms,</li>
						<li>doing so would cause us legal liability or compromise our ability to provide the Services to our other users, or</li>
						<li>we're prohibited from doing so by law.</li>
					</ol>
					<p>Discontinuation of Services</p>
					<p>We may decide to discontinue the Services in response to exceptional unforeseen circumstances, events beyond Diligentsia’s control (for example a natural disaster, fire or explosion) or to comply with a legal requirement. If we do so, we’ll give you reasonable prior notice so that you can export Your Stuff from our systems (we will give you no less than 30 days’ notice where possible under the circumstances). If we discontinue the Services in this way before the end of any fixed or minimum term you have paid us for, we’ll refund the portion of the fees you have pre-paid but haven't received Services for.</p>
					<p>Services “AS IS”</p>
					<p>We strive to provide great Services, but there are certain things that we can't guarantee. TO THE FULLEST EXTENT PERMITTED BY LAW, DILIGENTSIA AND ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES. THE SERVICES ARE PROVIDED ‘AS IS’. WE ALSO DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. Some places don’t allow the disclaimers in this paragraph, so they may not apply to you. For example, these disclaimers do not override the legal protections, including statutory warranties granted to consumers by EU law.</p>
					<p>Limitation of Liability</p>
					<p>WE DON’T EXCLUDE OR LIMIT OUR LIABILITY TO YOU WHERE IT WOULD BE ILLEGAL TO DO SO – THIS INCLUDES ANY LIABILITY FOR DILIGENTSIA’S OR ITS AFFILIATES’ FRAUD OR FRAUDULENT MISREPRESENTATION IN PROVIDING THE SERVICES. IN COUNTRIES WHERE THE FOLLOWING TYPES OF EXCLUSIONS AREN’T ALLOWED, WE'RE RESPONSIBLE TO YOU ONLY FOR LOSSES AND DAMAGES THAT ARE A REASONABLY FORESEEABLE RESULT OF OUR FAILURE TO USE REASONABLE CARE AND SKILL OR OUR BREACH OF OUR CONTRACT WITH YOU. THIS PARAGRAPH DOESN’T AFFECT CONSUMER RIGHTS THAT CAN'T BE WAIVED OR LIMITED BY ANY CONTRACT OR AGREEMENT. IF YOU ARE AN EU OR UK CONSUMER, THESE TERMS DO NOT EXCLUDE DILIGENTSIA’S LIABILITY FOR LOSSES AND DAMAGES THAT ARE A RESULT OF OUR FAILURE TO USE REASONABLE CARE AND SKILL IN PROVIDING THE SERVICES OR OF OUR BREACH OF OUR CONTRACT WITH YOU, AS LONG AS THOSE LOSSES AND DAMAGES ARE REASONABLY FORESEEABLE.</p>
					<p>IN COUNTRIES WHERE EXCLUSIONS OR LIMITATIONS OF LIABILITY ARE ALLOWED, DILIGENTSIA, ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS WON’T BE LIABLE FOR:</p>
					<ol>
						<li>ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR</li>
						<li>ANY LOSS OF USE, DATA, BUSINESS OR PROFITS, REGARDLESS OF LEGAL THEORY.</li>
					</ol>
					<p>THESE EXCLUSIONS OR LIMITATIONS WILL APPLY REGARDLESS OF WHETHER OR NOT DILIGENTSIA OR ANY OF ITS AFFILIATES HAVE BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
					<p>IF YOU USE THE SERVICES FOR ANY COMMERCIAL, BUSINESS OR RE-SALE PURPOSE, DILIGENTSIA, ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS WILL HAVE NO LIABILITY TO YOU FOR ANY LOSS OF PROFIT, LOSS OF BUSINESS, BUSINESS INTERRUPTION OR LOSS OF BUSINESS OPPORTUNITY. DILIGENTSIA AND ITS AFFILIATES AREN’T RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USERS OF THE SERVICES.</p>
					<p>OTHER THAN FOR THE TYPES OF LIABILITY WE CANNOT LIMIT BY LAW (AS DESCRIBED IN THIS SECTION), WE LIMIT OUR LIABILITY TO YOU TO THE GREATER OF £20 OR 100% OF ANY AMOUNT YOU'VE PAID UNDER YOUR CURRENT SERVICE PLAN WITH DILIGENTSIA. THIS PROVISION DOES NOT APPLY TO EU CONSUMERS WHERE PROHIBITED BY APPLICABLE LAW.</p>
					<p>Resolving Disputes</p>
					<p>Let’s try to sort things out first. We want to address your concerns without needing a formal legal case. Before filing a claim against Diligentsia, you agree to try to resolve the dispute informally by sending us a written Notice of Dispute at dispute-notice@Diligentsia.co.uk that includes your name, a detailed description of the dispute and the relief you seek. We’ll try to resolve the dispute informally by contacting you via email. If a dispute is not resolved within 60 days after submission, you or Diligentsia may bring a formal proceeding. If you reside in the EU, the European Commission provides for an online dispute resolution platform, which you can access here: https://ec.europa.eu/consumers/odr.</p>
					<p>Judicial Forum for Disputes. You and Diligentsia agree that any judicial proceeding to resolve claims relating to these Terms or the Services will be brought in the UK courts of England and Wales, and will be subject to UK law, subject to the mandatory arbitration provisions below. Both your organsation and Diligentsia consent to venue and personal jurisdiction in such courts. If you reside in a country (for example, a member state of the European Union) with laws that give consumers the right to bring disputes in their local courts, this paragraph doesn’t affect those requirements.</p>
					<p>IF YOUR ORGANISATION IS US RESIDENT, IT ALSO AGREES TO THE FOLLOWING MANDATORY ARBITRATION PROVISIONS:</p>
					<p>We both agree to arbitrate. You and Diligentsia agree to resolve any claims relating to these Terms or the Services through final and binding individual arbitration by a single arbitrator, except as set forth under the ‘Exceptions to Agreement to Arbitrate’ below. This includes disputes arising out of or relating to the interpretation or application of this ‘Mandatory Arbitration Provisions’ section, including its scope, enforceability, revocability or validity. The arbitrator may award relief only individually and only to the extent necessary to redress your individual claim(s); the arbitrator may not award relief on behalf of others or the general public.</p>
					<p>Opt out of Agreement to Arbitrate. You can decline this agreement to arbitrate by clicking here and submitting the opt-out form within 30 days of first registering your account or agreeing to these Terms. However, if you agreed to a previous version of these Terms that allowed you to opt out of arbitration, your previous choice to opt out or not opt out remains binding.</p>
					<p>Arbitration Procedures. The American Arbitration Association (AAA) will administer the arbitration under its Consumer Arbitration Rules. The AAA’s rules and filing instructions are available at www.adr.org or by calling 1-800-778-7879. The arbitration will be held in the United States county where you live or work, San Francisco (CA) or any other location we agree to.</p>
					<p>Arbitration Fees and Incentives. The AAA rules will govern payment of all arbitration fees. For individual arbitration of non-frivolous claims less than US$75,000 for which you timely provided Diligentsia with a Notice of Dispute, Diligentsia will reimburse arbitration filing fees at the conclusion of the arbitration and will pay other arbitration fees. For all other claims, the costs and fees of arbitration shall be allocated in accordance with the arbitration provider’s rules, including rules regarding frivolous or improper claims. If you receive an arbitration award that is more favourable than any offer we make to resolve the claim, we will pay you US$1,000 in addition to the award. Diligentsia will not seek its lawyers’ fees and costs in arbitration unless the arbitrator determines that your claim is frivolous or brought for an improper purpose.</p>
					<p>Exceptions to agreement to arbitrate. Either you or Diligentsia may assert claims, if they qualify, in a small claims court in San Francisco (CA) or any United States county where you live or work. Either party may bring a lawsuit solely for injunctive relief to stop unauthorised use or abuse of the Services, or intellectual property infringement (for example, trademark, trade secret, copyright or patent rights) without first engaging in arbitration or the informal dispute resolution process described above. If the agreement to arbitrate is found not to apply to you or your claim, you agree to the exclusive jurisdiction of the state and federal courts in San Francisco County, California, USA to resolve your claim.</p>
					<p>NO CLASS OR REPRESENTATIVE ACTIONS. You may only resolve disputes with us on an individual basis and may not bring a claim as a plaintiff or a class member in a class, consolidated or representative action. Class arbitrations, class actions, private lawyer general actions and consolidation with other arbitrations aren’t allowed.</p>
					<p>Severability. If any part of this ‘Mandatory Arbitration Provisions’ section is found to be illegal or unenforceable, the remainder will remain in effect, except that if a finding of partial illegality or unenforceability would allow class or representative arbitration, this ‘Mandatory Arbitration Provisions’ section will be unenforceable in its entirety. If you are found to have a non-waivable right to bring a particular claim or to request a particular form of relief that the arbitrator lacks authority to redress or award according to this ‘Mandatory Arbitration Provisions’ section, including public injunctive relief, then only that respective claim or request for relief may be brought in court, and you and we agree that litigation of any such claim or request for relief shall be stayed pending the resolution of any individual claim(s) or request(s) for relief in arbitration.</p>
					<p>Controlling Law</p>
					<p>These Terms will be governed by UK law. However, some countries (including those in the European Union) have laws that require agreements to be governed by the local laws of the consumer's country. This paragraph doesn’t override those laws.</p>
					<p>Entire Agreement</p>
					<p>These Terms constitute the entire agreement between you and Diligentsia with respect to the subject matter of these Terms and supersede and replace any other prior or contemporaneous agreements or terms and conditions applicable to the subject matter of these Terms. Our past, present and future affiliates and agents can invoke our rights under this agreement in the event they become involved in a dispute with you. Otherwise, these Terms do not give rights to any third parties.</p>
					<p>Waiver, Severability & Assignment</p>
					<p>Diligentsia’s failure to enforce a provision is not a waiver of its right to do so later. If a provision is found to be unenforceable, the remaining provisions of the Terms will remain in full effect and an enforceable term will be substituted reflecting our intent as closely as possible. You may not assign any of your rights under these Terms, and any such attempt will be void. Diligentsia may assign its rights to any of its affiliates or subsidiaries, or to any successor in the interest of any business associated with the Services.</p>
					<p>Modifications</p>
					<p>We may revise these Terms from time to time to better reflect:</p>
					<ol>
						<li>changes to the law,</li>
						<li>new regulatory requirements, or</li>
						<li>improvements or enhancements made to our Services.</li>
					</ol>
					<p>If an update affects your use of the Services or your legal rights as a user of our Services, we’ll notify you prior to the update's effective date by sending an email to the email address associated with your organisation’s account or via an in-product notification. These updated terms will be effective no less than 30 days from when we notify you.</p>
					<p>If you do not agree to the updates we make, please cancel your account and stop using the Services before the updated Terms become effective. Where applicable, we’ll offer you a pro-rata refund based on the amounts you have prepaid for Services and your account cancellation date. By continuing to use or access the Services after the updates come into effect, you agree to be bound by the revised Terms.</p>
					<p className="c4 df jcc mt1rem">Page 2</p>
				</div>

				<div className="pr bg1 p2rem shadow my2rem policy-page-data">
					<HeadingSubheadingContent id="acceptableUse" wrapperClass="tac" subheading="Acceptable Use" />
					<p>Effective: June 30th 2022</p>
					<p>Diligentsia is proud of the trust placed in us. In exchange, we trust you to use our services responsibly.</p>
					<p>You agree not to misuse the Diligentsia services ("Services") or help anyone else to do so. For example, you must not even try to do any of the following in connection with the Services:</p>
					<ol>
						<li>probe, scan, or test the vulnerability of any system or network, </li>
						<li>breach or otherwise circumvent any security or authentication measures;</li>
						<li>access, tamper with, or use non-public areas or parts of the Services, or shared areas of the Services you haven't been invited to;</li>
						<li>interfere with or disrupt any user, host, or network, for example by sending a virus, overloading, flooding, spamming, or mail-bombing any part of the Services;</li>
						<li>access, search, or create accounts for the Services by any means other than our publicly supported interfaces (for example, "scraping" or creating accounts in bulk);</li>
						<li>send unsolicited communications, promotions or advertisements, or spam;</li>
						<li>send altered, deceptive or false source-identifying information, including "spoofing" or "phishing";</li>
						<li>promote or advertise products or services other than your own without appropriate authorization;</li>
						<li>abuse referrals or promotions to get more storage space than deserved or to sell storage space received from referrals or promotions;</li>
						<li>circumvent storage space limits;</li>
						<li>sell the Services unless specifically authorized to do so;</li>
						<li>publish, share, or store materials that constitute child sexually exploitative material (including material which may not be illegal child sexual abuse material but which nonetheless sexually exploits or promotes the sexual exploitation of minors), unlawful pornography, or are otherwise indecent;</li>
						<li>publish, share, or store content that contains or promotes extreme acts of violence or terrorist activity, including terror propaganda;</li>
						<li>advocate bigotry or hatred against any person or group of people based on their race, religion, ethnicity, sex, gender identity, sexual orientation, disability, or impairment;</li>
						<li>harass or abuse Diligentsia personnel or representatives or agents performing services on behalf of Diligentsia;</li>
						<li>violate the law in any way, including storing, publishing or sharing material that’s fraudulent, defamatory, or misleading, or that violates the intellectual property rights of others;</li>
						<li>violate the privacy or infringe the rights of others;</li>
						<li>use the Services to back up, or as infrastructure for, your own cloud services;</li>
						<ol>
							<li>use the storage space provided by the Services as the basis for cryptographic proof-of-space or proof-of-storage, or any similar proof system.</li>
						</ol>
					</ol>
					<p>We reserve the right to take appropriate action in response to violations of this policy, which could include removing or disabling access to content, suspending a user’s access to the Services, or terminating an account.</p>
					<p className="c4 df jcc mt1rem">Page 3</p>
				</div>

			</div>
		</AnimatedPage>
	);
}
