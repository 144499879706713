import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import ExpPackPayment from "./Company/ExpPackPayment";
import { getRequest, showMessage } from "../../config";
import AnimatedPage from "../../components/AnimatedPage";
import { toggleModal } from "../../reducers/modalReducer";
import HeadingSubheadingContent from "../../components/HeadingSubheadingContent";

export default function DirectorsPack() {
	const dispatch = useDispatch();

	let loggedIn = localStorage.getItem("token") || localStorage.getItem("userId");
	const user = useSelector(state => state.user);
	const companies = useSelector(state => state.companies);
	let [company, setCompany] = useState(companies[location.pathname.split("/").slice(-1)[0]] || {});

	useEffect(() => {
		getCompany();
	}, []);

	const getCompany = async () => {
		const companyId = location.pathname.split("/").slice(-1)[0];
		if (companyId) {
			const res = await getRequest(`/company/get/${companyId}`);
			setCompany(res['body']);
		}
	}

	return (
		<AnimatedPage>
			<div className="expansionpack-page py4rem">
				<div className="container df fdc">
					<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Expansion Pack – DD –" subheading="Director's Questionnaire" />
				</div>
				<div className="container df">
					<div className="ma lef-img-area">
						<div className='img-box'>
							<img className="h10rem" src={require('../../../images/left-person.png')} alt="" />
						</div>
					</div>
					<div className="ma w100 right-text-area">
						<p>Any company needing to demonstrate that they  are well-prepared for third party referencing in due diligence on their Directors on investment or exit. ​</p>

						<div className="number-sec">
							<ul className="bg5 br5px shadow">
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>Number of Sections: </span> 4</li>
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>Number of Questions: </span> 20</li>
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>AI Generated Company Specific Questions included? </span> No</li>
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>Benefit: </span> This module automates the process of obtaining and collating and storing questionnaires via an automated process, and automatically adds the reports to the Data Repository by sending to Directors (and senior mangers if required) and shareholders,  a quick questionnaire to fill in in relation to their dealings with your business.  This enables you to identify blocking issues early, and once completed you can show at the start of the diligence process to demonstrate your company’s good standing amongst its stakeholders.</li>
							</ul>
						</div>

						<div className="about-img">
							<div className="img-box">
								<img className="br5px" src={require('../../../images/about-img.png')} alt="" />
							</div>
							<div className="text-box bg5 br5px shadow">
								<p>““Investors invest in people.  Having Directors’ Questionnaires completed early in the diligence process saves time and ticks off another uncertainty”.  James Ross, CEO​</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="boost-sec bg-gradient c1">
				<div className="container py5rem">
					<div className="dg gg2rem gtcr3">
						<div className="h100 df fdc jcsb aic">
							<div className="img-box">
								<img className="h10rem" src={require('../../../images/speed-img.png')} alt="" />
							</div>
						</div>
						<div className="h100 df fdc jcsb py4rem">
							<h2 className="fds c1 fs2rem tac">Boost your Diligentsia<br /><span className="c3 fds ">certification score…​</span></h2>
						</div>
						<div className="h100 df fdc jcsb aic py4rem">
							{loggedIn
								? <>
									{!company?.['expensions']?.['DEPF007'] &&
										<button onClick={() => dispatch(toggleModal({ open: 'payment' }))} style={{ padding: '15px', position: 'relative' }} className="br5px shadow cp c1 px1rem my0-5rem fw600 m1rem bg4 bg3-hover td250">
											One off purchase – BUY NOW!
											<div className="tooltip-btn">
												<span>&pound; 500</span>
											</div>
										</button>
									}
								</>
								: <button onClick={() => showMessage("LogIn to - BUY NOW!")} style={{ padding: '15px', position: 'relative' }} className="br5px shadow cp c1 px1rem my0-5rem fw600 m1rem bg4 bg3-hover td250">
									One off purchase – BUY NOW!
									<div className="tooltip-btn">
										<span>&pound; 500</span>
									</div>
								</button>
							}
						</div>
					</div>
				</div>
			</div>

			<div className="benfits-sec py4rem">
				<div className="container df fdc">
					<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Benefits" />
				</div>
				<div className="container">
					<div className="dg gg2rem gtcr3">
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-1.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Identification of Potential Conflicts of Interest:</b></p>
								<p>Directors Questionnaires can help identify any potential conflicts of interest at the beginning of the due diligence process. This allows time for the company to evaluate and mitigate these risks before they become more significant issues.​</p>
							</div>
						</div>
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-2.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Clear Understanding of Roles and Responsibilities:</b></p>
								<p>The questionnaire can ensure that each director understands their role and responsibilities in the company. This understanding helps align the management team and promotes a shared vision for the company.​</p>
							</div>
						</div>
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-3.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Promotes Transparency:</b></p>
								<p>Having directors fill out the questionnaire at the beginning of the due diligence process promotes transparency and trust. It shows potential investors that the company has nothing to hide and is confident in its operations and management.​</p>
							</div>
						</div>
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-4.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Prevents Delays:</b></p>
								<p>Having this information at the beginning of the process can help prevent delays later on. For instance, if a potential issue is discovered towards the end of due diligence, it could cause a significant delay or even derail the entire process. Completing the questionnaire early can help identify such issues and provide time to address them.</p>
							</div>
						</div>
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-5.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Regulatory Compliance:</b></p>
								<p>Depending on the jurisdiction, directors may be legally required to disclose certain information. Filling out the questionnaire at the start of due diligence helps ensure that the company is in compliance with these regulations.</p>
							</div>
						</div>
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-6.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Better Preparation for Potential Challenges:</b></p>
								<p>The information collected from the questionnaire can help the company better prepare for potential challenges or difficult questions that may arise during the due diligence process. This preparation can result in a smoother, more efficient process and increase the likelihood of a successful outcome.</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="modal-sec py4rem">
				<div className="container df fdc">
					<div className="heading">
						<img className="h5rem" src={require('../../../images/modle-title.png')} alt="" />
					</div>
				</div>
				<div className="container">
					<div className="dg gg2rem gtcr">
						<div className="df tac  fdc" style={{ padding: '0 25px' }}>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Personal</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Occupational</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Personal Financial</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Litigation, Disputes & Proceedings</p>
						</div>
					</div>
				</div>
			</div>
			<ExpPackPayment {...{ user, company, showExpension: 'DEPF007' }} />
		</AnimatedPage>
	);
};
