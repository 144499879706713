'use client';
import _extends from "@babel/runtime/helpers/esm/extends";
import { useContext, useCallback } from 'react';
import { format, parse, isValid } from "../utils/date/index.js";
import defaultLocale from "../../locales/default.js";
import { CustomContext } from "../../CustomProvider/CustomProvider.js";
var mergeObject = function mergeObject(list) {
  return list.reduce(function (a, b) {
    a = _extends({}, a, b);
    return a;
  }, {});
};
var getDefaultRTL = function getDefaultRTL() {
  return typeof document !== 'undefined' && (document.body.getAttribute('dir') || document.dir) === 'rtl';
};

/**
 * A hook to get custom configuration of `<CustomProvider>`
 * @param keys
 */
export function useCustom(keys, overrideLocale) {
  var _locale$Calendar2, _locale$Calendar4;
  var _useContext = useContext(CustomContext),
    _useContext$locale = _useContext.locale,
    locale = _useContext$locale === void 0 ? defaultLocale : _useContext$locale,
    _useContext$rtl = _useContext.rtl,
    rtl = _useContext$rtl === void 0 ? getDefaultRTL() : _useContext$rtl,
    formatDate = _useContext.formatDate,
    parseDate = _useContext.parseDate,
    toasters = _useContext.toasters,
    disableRipple = _useContext.disableRipple;
  var componentLocale = _extends({}, locale === null || locale === void 0 ? void 0 : locale.common, typeof keys === 'string' ? locale === null || locale === void 0 ? void 0 : locale[keys] : typeof keys === 'object' ? mergeObject(keys.map(function (key) {
    return locale === null || locale === void 0 ? void 0 : locale[key];
  })) : {});

  // Component custom locale
  if (overrideLocale) {
    componentLocale = mergeObject([componentLocale, overrideLocale]);
  }
  var _formatDate = useCallback(function (date, formatStr, options) {
    try {
      var _locale$Calendar;
      if (formatDate) {
        return formatDate(date, formatStr, options);
      }
      return format(isValid(date) ? date : new Date(), formatStr, _extends({
        locale: locale === null || locale === void 0 ? void 0 : (_locale$Calendar = locale.Calendar) === null || _locale$Calendar === void 0 ? void 0 : _locale$Calendar.dateLocale
      }, options));
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Error: Invalid date format', error);
      }
      return 'Error: Invalid date format';
    }
  }, [formatDate, locale === null || locale === void 0 ? void 0 : (_locale$Calendar2 = locale.Calendar) === null || _locale$Calendar2 === void 0 ? void 0 : _locale$Calendar2.dateLocale]);
  var _parseDate = useCallback(function (dateString, formatString, referenceDate, options) {
    var _locale$Calendar3;
    if (parseDate) {
      return parseDate(dateString, formatString, referenceDate, options);
    }
    return parse(dateString, formatString, referenceDate || new Date(), _extends({
      locale: locale === null || locale === void 0 ? void 0 : (_locale$Calendar3 = locale.Calendar) === null || _locale$Calendar3 === void 0 ? void 0 : _locale$Calendar3.dateLocale
    }, options));
  }, [parseDate, locale === null || locale === void 0 ? void 0 : (_locale$Calendar4 = locale.Calendar) === null || _locale$Calendar4 === void 0 ? void 0 : _locale$Calendar4.dateLocale]);
  return {
    locale: componentLocale,
    rtl: rtl,
    toasters: toasters,
    disableRipple: disableRipple,
    formatDate: _formatDate,
    parseDate: _parseDate
  };
}
export default useCustom;