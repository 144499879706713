export default function DataSite({ className = "w2rem ma" }) {
	return <svg className={className} version="1.1" id="Layer_1" x="0px" y="0px"
		viewBox="0 0 800 172" style={{ enableBackground: "new 0 0 800 172" }}>
		<rect style={{ fill: "none" }} />
		<path d="M201.62,33.58c33.22,0,46.66,17.31,46.66,52.53c0,35.23-13.44,52.53-46.66,52.53h-35.54V33.58H201.62z
	 M186.17,50.58v71.07h15.45c16.53,0,25.8-6.49,25.8-20.39V70.97c0-13.91-9.27-20.39-25.8-20.39H186.17z M308.69,84.72
	c0-8.19-4.79-11.74-12.36-11.74c-7.73,0-13.29,3.86-13.29,11.12h-19.31c0.62-17.15,14.52-26.11,33.37-26.11
	c18.85,0,30.59,9.43,30.59,27.04v38.63h10.97v14.99h-28.12l-0.16-12.21h-0.62c-4.48,8.65-11.74,14.06-23.8,14.06
	c-14.21,0-24.88-10.04-24.88-24.26c0-12.67,9.27-23.18,25.8-24.57l21.78-1.86V84.72z M308.69,107.59v-3.09l-23.18,2.01
	c-3.09,0.31-4.32,2.01-4.32,5.1v6.8c0,4.63,4.79,7.11,11.59,7.11C302.67,125.51,308.69,117.47,308.69,107.59 M377.29,74.83h19.62
	V59.85h-19.62V33.58h-19.31v26.27h-15.61v14.99h15.61v63.81h38.94v-14.99h-19.62V74.83z M456.55,84.72
	c0-8.19-4.79-11.74-12.36-11.74c-7.73,0-13.29,3.86-13.29,11.12h-19.31c0.62-17.15,14.52-26.11,33.37-26.11
	c18.85,0,30.59,9.43,30.59,27.04v38.63h10.97v14.99h-28.12l-0.16-12.21h-0.62c-4.48,8.65-11.74,14.06-23.79,14.06
	c-14.21,0-24.88-10.04-24.88-24.26c0-12.67,9.27-23.18,25.8-24.57l21.78-1.86V84.72z M456.55,107.59v-3.09l-23.18,2.01
	c-3.09,0.31-4.32,2.01-4.32,5.1v6.8c0,4.63,4.79,7.11,11.59,7.11C450.53,125.51,456.55,117.47,456.55,107.59 M524.69,89.51
	c-5.1-1.08-8.65-3.24-8.65-8.34c0-5.1,4.63-8.19,11.28-8.19c6.8,0,11.59,3.71,11.89,11.12h19.31
	c-0.77-17.46-13.44-26.11-31.21-26.11c-19.31,0-30.59,10.66-30.59,24.1c0,12.36,6.49,19.62,19.78,22.56l13.6,2.94
	c6.95,1.39,11.74,3.55,11.74,9.27c0,5.87-6.02,8.65-13.44,8.65c-8.5,0-13.44-4.17-14.21-11.9h-19.31
	c0.77,17.15,12.67,26.88,33.53,26.88c21.01,0,32.75-10.81,32.75-24.57c0-13.9-9.11-20.39-20.86-23.02L524.69,89.51z M595.45,50.73
	h-19.93V33.58h19.93V50.73z M575.83,59.84h19.31v78.8h-19.31V59.84z M644.89,74.83h19.62V59.85h-19.62V33.58h-19.31v26.27h-15.61
	v14.99h15.61v63.81h38.93v-14.99h-19.62V74.83z M710.55,57.99c21.32,0,35.23,16.07,35.23,37.7v9.73H695.1v5.26
	c0,9.42,7.26,14.83,15.6,14.83c8.34,0,14.22-4.63,15.14-12.67h19.31c-2.47,17.46-15.61,27.66-34.61,27.66
	c-22.4,0-35.54-16.69-35.54-41.41C675.02,74.37,689.23,57.99,710.55,57.99 M695.1,90.44H726v-2.78c0-8.5-6.64-14.68-15.45-14.68
	c-8.81,0-15.45,6.18-15.45,14.68V90.44z"/>
		<path style={{ fill: "#FF9F27" }} d="M20.1,20.61h71.06v29.97H20.1V20.61z M20.1,151.62h71.06v-29.97H20.1V151.62z M91.15,121.65h29.97V50.59H91.15
	V121.65z"/>
		<path style={{ fill: "#575559" }} d="M766.15,29.38c9.91,0,17.95,8.04,17.95,17.95c0,9.91-8.04,17.94-17.95,17.94c-9.91,0-17.94-8.04-17.94-17.94
	C748.21,37.42,756.24,29.38,766.15,29.38 M766.15,61.98c8.19,0,14.66-6.47,14.66-14.66c0-8.19-6.47-14.66-14.66-14.66
	c-8.19,0-14.66,6.47-14.66,14.66C751.49,55.51,757.96,61.98,766.15,61.98 M763.02,49.6v6.32h-3.29V38.73h7.89
	c3.49,0,5.46,1.97,5.46,5.46c0,3.03-1.67,4.7-3.74,5.26l3.79,6.47h-3.64l-3.54-6.32H763.02z M767.82,46.97
	c1.26,0,1.87-0.91,1.87-1.97v-1.72c0-1.16-0.66-1.87-1.87-1.87h-4.8v5.56H767.82z"/>
	</svg>
}
