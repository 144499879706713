import { useState } from "react";
import { useDispatch } from "react-redux";

import { toggleModal } from "../reducers/modalReducer";

export default function Hero({ buttonScrollTo, dataShowPopup, h1Class = "fs2rem fs3rem-sm", h1, buttonText, belowButtonText, videoSrc, videoThumbnail }) {
	const dispatch = useDispatch();
	const [isPlay, setIsPlay] = useState(false);


	function playVideo(e) {
		let video = document.getElementById("video");
		if (video.paused == true) {
			setIsPlay(true);
			video.play();
		}
		e.target.controls = true;
		if (e?.target?.nextElementSibling) e.target.nextElementSibling.remove();
	}


	return (
		<div id="hero" className="bg1 mih100vh df">
			<div className="container py4rem w100 df fww aic gg5rem hero-saf">
				<div className="ma w60">
					<h1 className={h1Class + " text-gradient ass lh2"}>
						{h1.map((s, i) => (
							<p key={i} className="mb1rem">{s}</p>
						))}
					</h1>
					<span data-scroll-to={buttonScrollTo} onClick={() => dispatch(toggleModal({ open: dataShowPopup }))} className="dif jcc aic shadow fs1-5rem cp td250 bg1 c3 bss bw5px bo6 fw600 px1rem py0-5rem button-text">
						{buttonText}{belowButtonText && <p style={{ fontSize: '13px', color: '#000' }} className="fw400 mt1rem fs1-25rem px1rem py0-5rem bw0px blw5px bss bo6 button-below-text">{belowButtonText}</p>}
						<svg className="pen ml1rem h3rem f3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
							<path className="fa-primary" d="M256 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L370.8 256l-137.4-137.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C272.4 444.9 264.2 448 256 448z" />
							<path className="fa-secondary" d="M338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l32 32L338.8 288z" />
						</svg>
					</span>
				</div>
				<div className="pr ma w40 gradient-shadow cp">
					<video id="video" onClick={(e) => playVideo(e)} className="bg1 bsbb pr bss bw5px bo5 w100" poster={"/images/thumbnails" + videoThumbnail} preload="auto">
						<source src={`https://diligentsia-website-assets.s3.us-east-2.amazonaws.com/diligentsia_for_companies.mp4`} type="video/mp4" />
					</video>
					<svg className={`${isPlay && 'dn'} pen br100 shadow bss bw5px bo4 w5rem h5rem f4 bg1 pa center`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
						<path className="fa-primary" d="M216 368c-13.69 0-24-11.2-24-24V168C192 155.3 202.2 144 216 144c4.343 0 8.677 1.176 12.51 3.516l144 88C379.6 239.9 384 247.6 384 256c0 8.359-4.352 16.12-11.48 20.48l-144 88C224.7 366.8 220.3 368 216 368z" />
						<path className="fa-secondary" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM372.5 276.5l-144 88C224.7 366.8 220.3 368 216 368c-13.69 0-24-11.2-24-24V168C192 155.3 202.2 144 216 144c4.344 0 8.678 1.176 12.51 3.516l144 88C379.6 239.9 384 247.6 384 256C384 264.4 379.6 272.1 372.5 276.5z" />
					</svg>
				</div>
			</div>
		</div>
	);
}
