import React from "react"

const ContentCompaniesCard = ({ description, title, icon }) => {
    return (
        <div className="col-sm bg1 br40px p2rem new-shadow cp oh shadowContainer bsbb" style={{ width: '47%' }}>
            <div className='shadowPaperCrasouel w100'>
                <div className="df fww aic jcc">
                    <div className="w70">
                        <p className="fw400 mt1rem c14 tas fsi" style={{ fontSize: '18px', lineHeight: '1.4rem' }}>{description}</p>
                        <p className="fw500 fs1-5rem c15 df tas" style={{ lineHeight: '39.81px' }}>{title}</p>
                    </div>
                    <div className="df jce w30">
                        <div className="br10px mt1rem mr2rem">
                            {icon}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ContentCompaniesCard