import React from 'react'
import AnimatedPage from '../../components/AnimatedPage'
import SmoothScrollingButton from '../../components/SmoothScrollingButton'
import CustomTab from '../../components/termsAndConditions/CustomTab'


const DataEthics = () => {
    return (
        <AnimatedPage>
            <SmoothScrollingButton />
            <div className='poppins-font-style privacy-policy-page *'>
                <CustomTab type="policy" activeIdentifier="data-ethics" title={'Policies'} width="w60" />
                <div className="fw400 tal c14 fs1rem policy-box">
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>Scope and Acceptance of Policy:</p>
                    <p className="pt1rem pb1rem">This Data Privacy and Ethics Policy ('Policy') applies to the use of your data by Diligentsia Limited ('Company', 'we', 'us', 'our') in relation to the use of an artificial intelligence tool, Chat GPT, developed by OpenAI. Your acceptance of this Policy, along with our Terms and Conditions, is indicated by your continued use of our services and/or by expressly clicking to accept where that option is presented to you.</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Data Usage:</p>
                    <p className="pt1rem pb1rem">We use your data for the sole purpose of enhancing the quality of reports we generate for you through the application of AI technology. Your data may be used to create comprehensive reports via Chat GPT, but will not be used for any other purpose unless we obtain your explicit permission.​</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Data Protection:</p>
                    <p className="pt1rem pb1rem">Your data is important and private to you, and we respect that. We ensure that your data is not shared with third parties, except with Chat GPT as outlined in this policy. We do not disseminate or transfer ownership of your data. Any reports we generate using your data and AI are digitally watermarked to ensure their authenticity and traceability.</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Data Retention:</p>
                    <p className="pt1rem pb1rem">We will not retain your data for longer than is necessary for our services or as required by law. The data you supply to us that is processed through Chat GPT is not stored or reused by the AI system.</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Non-disclosure of Files:</p>
                    <p className="pt1rem pb1rem">The contents of any data files you provide to us will not be shared with AI systems. We will not disclose the contents of these files outside our organisation unless we have obtained your prior express permission.</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Your Rights:</p>
                    <p className="pt1rem pb1rem"> ​You have the right to access, rectify, delete or restrict the use of your personal data. You can exercise these rights at any time by contacting us using the contact details provided in our Terms and Conditions.</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Changes to the Policy:</p>
                    <p className="pt1rem pb1rem">We reserve the right to update this Policy at any time to reflect changes in our practices, service offerings, or the latest laws and regulations. Any changes will be posted on this page, and we recommend that you check this page regularly to stay informed.</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Contact Us:</p>
                    <p className="pt1rem pb1rem">If you have any questions or require further information about our data handling practices, please don't hesitate to contact our Data Privacy Officer at dpo@diligentsia.co.uk. We're committed to working with you to obtain a fair resolution of any privacy concerns."</p>
                </div >
            </div>
        </AnimatedPage>
    )

}
export default DataEthics