import moment from "moment";
import { useEffect, useState } from "react";

export default function Certification({ className, level, expiryTimestamp, subscription, company, component }) {

	let certificates = {
		None: {
			title: "Not Yet Certified",
		},
		Bronze: {
			title: "Bronze - Basic",
		},
		Silver: {
			title: "Silver - Investible",
		},
		Gold: {
			title: "Gold - Sophisticated",
		},
	};
	const [cLevel, setCLevel] = useState(level || 'None'); // Bronze, Silver, Gold, For 2 Minute Test (Pass, Fail)
	const [title, setTitle] = useState(certificates[level || 'None']['title']);

	useEffect(() => {
		if (subscription !== 'Pro') {
			setTitle('PRO ONLY');
			return;
		}

		setCLevel(level)
		setTitle(certificates[level]['title']);
	}, [company]);


	let expires = "";
	if (expiryTimestamp && cLevel !== 'None') {
		let date = new Date(expiryTimestamp);
		expires = date.setMonth(date.getMonth() + 3)
	}

	return (
		<>
			{cLevel && <div className={className + ` pr df aic ${cLevel == "None" ? 'c6' : 'c1'}`}>
				{cLevel == 'None' && <img style={cLevel == "None" ? { filter: "" } : null} src={require(`../../images/white.png`)} className={"w70px"} alt={title} />}
				{cLevel == 'Bronze' && <img style={cLevel == "None" ? { filter: "" } : null} src={require(`../../images/bronze.png`)} className={"w70px"} alt={title} />}
				{cLevel == 'Silver' && <img style={cLevel == "None" ? { filter: "" } : null} src={require(`../../images/silver.png`)} className={"w70px"} alt={title} />}
				{cLevel == 'Gold' && <img style={cLevel == "None" ? { filter: "" } : null} src={require(`../../images/gold.png`)} className={"w70px"} alt={title} />}

				<div className="certificate-audited pa l0 w100 tac mt1rem">
					{/* <div>
						{cLevel == "None"
							? <img className="db w7rem" src={require(`../../images/Dg-logo.png`)} alt="Diligentsia Logo" />
							: <img className="db w7rem" src={require(`../../images/logo_white.png`)} alt="Diligentsia Logo" />
						}
						<p className={`tac ttc fs0-75rem`}>{title}</p>
					</div> */}
					<p className={`tac fs0-5rem c2 ${component == 'Dashboard' ? ' mt0-5rem' : subscription == 'Pro' ? ' mt1rem' : ' mt1-5rem'}`}>
						{subscription == 'Pro' ? 'Audited Certificate' : 'Upgrade to Pro for certification'}
					</p>
				</div>
				<div className={`tac valid-date pa l0 b6px w100`}>
					<p className="tac fs0-5rem">Valid Until {expires ? moment(expires).format('DD/MMM/YY') : "-"}</p>
				</div>
			</div>
			}
		</>
	);
}
