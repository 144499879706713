import React from "react"
import CustomTab from "../../components/termsAndConditions/CustomTab"
import AnimatedPage from "../../components/AnimatedPage"
import SmoothScrollingButton from "../../components/SmoothScrollingButton"

const CookiePolicy = () => {
    return (
        <AnimatedPage>
            <SmoothScrollingButton />
            <div className='poppins-font-style privacy-policy-page *'>
                <CustomTab type="policy" activeIdentifier="cookie-policy" title={'Policies'} width="w60" />
                <div className="fw400 tal c14 fs1rem policy-box" >
                    <p className="pt1rem fw500 tal c14 fs1rem" style={{ fontStyle: "italic" }}>Effective: June 30th 2022</p>
                    <p className="pt1rem pb1rem fw500 tal c14 fs1-25rem">Use of cookies and similar technologies</p>
                    <p className="pt1rem pb1rem">Diligentsia uses cookies and similar technologies, such as pixels, to provide, improve, protect, and promote our services. For the purposes of this policy we will refer to all of these technologies as “cookies.” This page includes information on the what, who, and how of these technologies in connection with the Diligentsia website and services.</p>
                    <p className="pt2rem pb1rem fw500 tal c14 fs1-25rem">What are cookies?</p>
                    <p className="pt1rem pb1rem">A cookie is small data file sent to your browser when you visit a website. A pixel tag ("pixel") is a small piece of code belonging to a third-party that can be embedded on websites and in emails. We use both our own cookies as well as third-party cookies. See the “How does Diligentsia use cookies” section below for further information on third-party cookies we use.</p>
                    <p className="pt1rem pb1rem">We also use local storage, including HTML5 local storage and browser cache. Local storage allows us to store information locally on your browser or device to improve your experience, such as to remember your settings for whether you prefer to view your folders in a list or as thumbnails. </p>
                    <p className="pt2rem pb1rem fw500 tal c14 fs1-25rem">How does Diligentsia use cookies?</p>
                    <p className="pt1rem pb1rem">Diligentsia uses different categories of cookies to provide, protect, improve, and promote our website and services. Diligentsia only retains personal data for as long as is needed for the purposes for which it was collected. Information on these categories and their purposes are described below.</p>
                    <p className="pt2rem pb1rem fw500 tal c14 fs1-25rem">Strictly necessary cookies</p>
                    <p className="pt1rem pb1rem">These are cookies that are essential in order to operate the website and services.</p>
                    <p>Category</p>
                    <p>Purpose</p>
                    <p>Data elements</p>
                    <p>Expiration</p>
                    <p>Third parties</p>
                    <p>Strictly necessary</p>
                    <p className="pt1rem pb1rem">Cookies that are used to operate the website and services, such as to log you in and keep your account secure.</p>
                    <p className="pt1rem pb1rem">Categories of data collected for these purposes include authentication data, unique ID, browser information, and language settings.</p>
                    <p className="pt1rem pb1rem">These cookies are set to expire after times ranging from 30 days to 4 years.</p>
                    <p className="pt1rem pb1rem">Third party providers used include Google.</p>
                    <p className="pt2rem pb1rem fw500 tal c14 fs1-25rem">Non-essential cookies</p>
                    <p className="pt1rem pb1rem">These are all other cookies that are not considered strictly necessary but are used for a number of purposes when you interact with our website and services. These purposes include:</p>
                    <p>Category</p>
                    <p>Purpose</p>
                    <p>Data elements</p>
                    <p>Expiration</p>
                    <p>Third parties</p>
                    <p>Analytics</p>
                    <p className="pt1rem pb1rem">Cookies that help us and our partners understand how our websites are engaged with, such as usage statistics, in order to improve and customize our services.</p>
                    <p className="pt1rem pb1rem">Categories of data collected for these purposes include website usage data, number of visits, browser and/or device information, and unique ID.</p>
                    <p className="pt1rem pb1rem">These cookies are set to expire after times ranging from session (~20 minutes) to 2 years.</p>
                    <p className="pt1rem pb1rem">Third party providers used include Google, Salesforce, Coveo, and Tealium.</p>
                    <p className="pt2rem pb1rem fw500 tal c14 fs1-25rem">General marketing and advertising</p>
                    <p className="pt1rem pb1rem">Cookies that are used by Diligentsia and our partners to better understand the types of individuals who are interested in our products and services and how we can promote those services based on that information.</p>
                    <p className="pt1rem pb1rem">Categories of data collected for these purposes include cross website tracking data, browser information, version data, and advertisement conversion data.</p>
                    <p className="pt1rem pb1rem">These cookies are set to expire after times ranging from session (~20 minutes) to 2 years.</p>
                    <p className="pt1rem pb1rem">Third party providers used include Adobe, Marketo, Yahoo, and Google.</p>
                    <p className="pt2rem pb1rem fw500 tal c14 fs1-25rem">Performance and functionality</p>
                    <p className="pt1rem pb1rem">Cookies which help us remember your preferences and settings in order to improve your experience when interacting with our sites and services.</p>
                    <p className="pt1rem pb1rem">Categories of data collected for these purposes include website usage data, previous help center or chat history, email address, and unique ID.</p>
                    <p className="pt1rem pb1rem">These cookies are set to expire after times ranging from session (~20 minutes) to 3 years.</p>
                    <p className="pt1rem pb1rem">Third party providers used include SnapEngage.</p>
                    <p className="pt2rem pb1rem fw500 tal c14 fs1-25rem">Social media advertising</p>
                    <p className="pt1rem pb1rem">Cookies that are used by Diligentsia and our partners to understand the effectiveness of our advertisements on social media services. These cookies also help them understand your advertising preferences so they can more effectively show you our advertisements which are relevant to your interests.</p>
                    <p className="pt1rem pb1rem">Categories of data collected for these purposes include social media account details, browser and/or device data, advertisement conversion data, website usage and/or activity data, and unique ID.</p>
                    <p className="pt1rem pb1rem">These cookies are set to expire after times ranging from session (~20 minutes) to 2 years.</p>
                    <p className="pt1rem pb1rem">Third party providers used include Facebook, Twitter, and LinkedIn.</p>
                    <p className="pt1rem pb1rem">How can I control the data collected through cookies?</p>
                    <p className="pt2rem pb1rem fw500 tal c14 fs1-25rem">Browser settings:</p>
                    <p className="pt1rem pb1rem">You can set your browser to not accept cookies, but this may limit your ability to use our services. Due to a lack of an agreed upon standard for interpreting Do Not Track (DNT) signals, our systems currently don’t respond to DNT:1 signals from browsers visiting our websites.</p>
                    <p className="pt2rem pb1rem fw500 tal c14 fs1-25rem">Cookie preferences:</p>
                    <p className="pt1rem pb1rem">When you use Diligentsia’s services, you can select which cookies you consent for Diligentsia to use in by clicking Cookies & CCPA Preferences in the footer of this page under Support.</p>
                    <p className="pt2rem pb1rem fw500 tal c14 fs1-25rem">Self-Regulatory:</p>
                    <p className="pt1rem pb1rem">Diligentsia adheres to the Digital Advertising Alliance’s Self-Regulatory Principles for Online Behavioral Advertising ("Principles"). You can opt out of collection of information by third-parties that adhere to the Principles, and that we use to help us promote our services, by visiting www.aboutads.info/choices.</p>
                    <p className="pt1rem pb1rem">Diligentsia also adheres to the European Interactive Digital Advertising Alliance’s self-regulatory program for data-driven advertising. You can opt out of collection of information used for online behavior advertising by third-parties that adhere to the program by visiting http://www.youronlinechoices.eu/.</p>
                    <p className="pt1rem pb1rem">For more information on how to control and access your personal data, please see our Privacy Policy. If you’d like to reach out to Diligentsia regarding the use of data collected from cookies, contact our Data Protection Officer at privacy@Diligentsia.co.uk .</p>
                </div>
            </div>
        </AnimatedPage>
    )
}
export default CookiePolicy