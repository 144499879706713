
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Modal from '@mui/material/Modal';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { toggleModal } from "../../../reducers/modalReducer";
import { showMessage, getRequest, postRequest } from '../../../config';


export default function ChecklistReport({ profileClass, user, company, checklistTask, getChecklistTask }) {

	const dispatch = useDispatch();
	const modal = useSelector(state => state.modal);

	let [action, setAction] = useState('create'); // create, update, delete, complete
	let [auditLogs, setAuditLogs] = useState([]);
	let [checklistTaskState, setChecklistTaskState] = useState({});

	const downloadPDF = async () => {
		const input = document.getElementById('rootElementId');
		html2canvas(input)
			.then((canvas) => {
				const imgData = canvas.toDataURL('image/png');
				const imgWidth = 600;
				const pageHeight = 841.88976378;
				const imgHeight = (canvas.height * imgWidth) / canvas.width;
				let heightLeft = imgHeight;
				let position = 0;
				heightLeft -= pageHeight;
				const pdf = new jsPDF({ orientation: 'p', unit: 'pt', format: [600, 841.88976378] });
				pdf.addImage(canvas, 'JPEG', 0, position, imgWidth, imgHeight, '', 'FAST');
				while (heightLeft >= 0) {
					position = heightLeft - imgHeight;
					pdf.addPage();
					pdf.addImage(canvas, 'JPEG', 0, position, imgWidth, imgHeight, '', 'FAST');
					heightLeft -= pageHeight;
				}
				pdf.save(`Checklist-Report.pdf`);
			});
	};

	const handleChange = (e) => setChecklistTaskState({ ...checklistTaskState, [e.target.name]: e.target.value });

	const createChecklistTask = async (action) => {
		await setAction(action);
		await setChecklistTaskState({});
		dispatch(toggleModal({ open: 'actionTaskPopup' }));
	};

	const handleChecklistTask = async (task, action) => {
		await setAction(action);
		await setChecklistTaskState(task);
		if (action == 'delete') dispatch(toggleModal({ open: 'deleteTaskPopup' }));
		else dispatch(toggleModal({ open: 'actionTaskPopup' }));
	};

	const submitChecklistTask = async () => {
		if (checklistTaskState['title'] == '') return showMessage("Please provide title");
		if (checklistTaskState['description'] == '') return showMessage("Please provide Description");
		if (checklistTaskState['module'] == '') return showMessage("Please provide module");
		checklistTaskState = { ...checklistTaskState, action };

		const res = await postRequest(`/task/checklistTask/${company['_id']}`, checklistTaskState);
		if (res && res['code'] == 200) {
			showMessage(res['message']);
			dispatch(toggleModal({ open: '' }));
			await getChecklistTask('checklist-tasks');
			setChecklistTaskState({});
		}
	};

	const getAuditLogs = async () => {
		const res = await postRequest('/log', { endpoint: `/task/checklistTask/${company['_id']}` });
		if (res['code'] == 200) {
			await setAuditLogs(res['body']);
			dispatch(toggleModal({ open: 'taskAuditLogs' }));
		}
	};

	return <>
		<div className="checklist-page company-audit-page">
			<div className="table-area-company-log" id="rootElementId">
				<h2 className="w200 tac c6 lh2 fs1-75rem">Checklist Report - <span className="ttu">{company['cName']}</span></h2>
				<div className="h100 df fdc jcsb aic">
					<div className="logo-report">
						<img className="h2rem" src={require(`../../../../images/Dg-logo.png`)} alt="TODO" />
					</div>
				</div>
				<div className="report-start">
					<div className="logo-report mb1rem df jcsb aic w100">
						<img className="h2rem" src={require(`../../../../images/Dg-logo.png`)} alt="TODO" />
						<button onClick={() => downloadPDF()} className="h100 my0-5rem shadow bg3 bg4-hover c1 td250 br5px px1rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							<svg className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path className="fa-primary" d="M424.1 320.1l-88 88C334.4 411.5 328.5 416 320 416s-14.4-4.467-16.97-7.031l-88-88c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L296 334.1V184C296 170.8 306.8 160 320 160s24 10.75 24 24v150.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0S434.3 311.6 424.1 320.1z" /><path className="fa-secondary" d="M537.6 226.6C541.7 215.9 544 204.2 544 192c0-53-43-96-96-96c-19.69 0-38.09 6-53.31 16.2C367 64.2 315.3 32 256 32C167.6 32 96 103.6 96 192c0 2.703 .1094 5.445 .2031 8.133C40.2 219.8 0 273.2 0 336C0 415.5 64.5 480 144 480H512c70.69 0 128-57.3 128-128C640 290.1 596 238.4 537.6 226.6zM424.1 320.1l-88 88C334.4 411.5 328.5 416 320 416s-14.4-4.467-16.97-7.031l-88-88c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L296 334.1V184C296 170.8 306.8 160 320 160s24 10.75 24 24v150.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0S434.3 311.6 424.1 320.1z" /></svg>
							Download PDF
						</button>
					</div>
					<div className="dg gtcr2a gg2rem mb1-3rem">
						<div className="h100 df fdc jcsb">
							<div>
								<p className="ass fs1rem mb0-5rem">Company Name</p>
								<h2 className="fs1rem fw700 lh1 ttu usn c6">{company['cName']}</h2>
								<p className="ass fs1rem mt0-5rem mb0-5rem">Test Type</p>
								<h2 className="fs1rem fw700 lh1 ttu usn c6">{company['testType']}</h2>
							</div>
						</div>
						<div>
							<ul className="right-list">
								<li>Subscription <span>{((company?.['creatorId']?.['createdBy']?.['role'] == 'Advisor' || company?.['creatorId']?.['createdBy']?.['role'] == 'Investor') && company['subscription'] == 'Pro')
									? 'Portfolio PRO'
									: <>{company['subscription']?.toUpperCase()}</>}
								</span></li>
								<li>Company Reg <span>{company['ukRegNo']}</span></li>
								<li>Issue Date <span>{moment(company['updatedAt']).format("MMM Do YYYY")}</span></li>
								<li>Generated By <span>{company['creatorId']['email']}</span></li>
							</ul>
						</div>
					</div>
					<div className="logo-report mb1rem df jcfe aic w100">
						<button onClick={() => createChecklistTask('create')} className="h100 my0-5rem shadow bg3 bg4-hover c1 td250 br5px px1rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic mr1rem">Create Task</button>
						<button onClick={() => getAuditLogs()} className="h100 my0-5rem shadow bg3 bg4-hover c1 td250 br5px px1rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">Audit Log</button>
					</div>
				</div>

				<table id="table" className="table-company-log">
					<thead className="ps t0">
						<tr>
							<th>Task Created By</th>
							<th>Task Title</th>
							<th>Description</th>
							<th>Module</th>
							<th>Status</th>
							<th>Notes</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{checklistTask.length > 0
							? <>{checklistTask.map(task => {
								return <tr>
									<td className="">
										<div className="df tac aic jcc w100">
											{task?.['createdBy']?.['profile']
												? <img className={`w2rem h2rem df jcc aic br100 mr0-5rem ${profileClass}`} src={task['createdBy']['profile']} data-tooltip={task?.['createdBy']?.['firstName'] ? (task['createdBy']['firstName'] + " " + task['createdBy']['lastName']) : "Register to show your name here"} />
												: <span data-tooltip={task?.['createdBy']?.['firstName'] ? (task['createdBy']['firstName'] + " " + task['createdBy']['lastName']) : "Register to show your name here"} className={`w2rem h2rem df jcc aic br100 mr0-5rem fs0-75rem ${profileClass}`}>{task?.['createdBy']?.['firstName'] ? (task['createdBy']['firstName'][0] + task['createdBy']['lastName'][0]) : "?"}</span>
											}
										</div>
									</td>
									<td className="ttc">{task['title'] ? task['title'] : '-'}</td>
									<td className=""><span className="w100 df jcc fs0-75rem">{task['description'] ? task['description'] : '-'}</span></td>
									<td className="ttc">{task['module'] ? task['module'] : '-'}</td>
									<td className="">{task['status'] ? task['status'] : '-'}</td>
									<td className="">{task['notes'] ? task['notes'] : '-'}</td>
									{user['role'] == "Advisor"
										? <td>
											<div className="df jcsb aic">
												<button onClick={() => handleChecklistTask(task, 'update')} className="c3 bo3 cp df jcc aic px0-5rem py0-5rem pr bw2px bss br5px fs0-75rem mr0-5rem">Update</button>
												<button onClick={() => handleChecklistTask(task, 'delete')} className="c4 bo4 cp df jcc aic px0-5rem py0-5rem pr bw2px bss br5px fs0-75rem">Delete</button>
											</div>
										</td>
										: <td className="df jcc aic">
											<button onClick={() => handleChecklistTask(task, 'complete')} className="c7 cp df jcc aic px0-5rem py0-5rem pr bo7 bw2px br5px fs0-75rem bss">Complete</button>
										</td>
									}
								</tr>
							})}</>
							: <tr className="pa fs1-25rem fw500" style={{ left: '45%' }}>No Task Created!</tr>
						}
					</tbody>
				</table>
			</div>

			{/* Create/Update/Complete Checklist Task */}
			<Modal open={modal['open'] == 'actionTaskPopup'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100 h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 px2rem py3rem bsbb df fdc m1rem maw700px br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs2rem fw600 tac ttu mb1rem pb1rem">{action} Task</h1>
					<div class="df fdc gg1rem px2rem">
						{action == 'complete'
							? <>
								<div className="pr">
									<textarea value={checklistTaskState['notes']} name="notes" type="text" rows="4" placeholder="Add Notes" onChange={handleChange} className="pl4rem shadow bg5 w100 br5px p1rem" />
									<svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512" style={{ top: '20%' }}>
										<path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
									</svg>
								</div>
								<div className="pr">
									<select value={checklistTaskState['status']} onChange={handleChange} name='status' className="pl4rem shadow bg5 w100 br5px p1rem">
										<option value="" className="pen usn ass mr0-5rem tal">Select Status</option>
										<option value="Not Started" className="pen usn ass mr0-5rem tal">Not Started</option>
										<option value="Not Applicable" className="pen usn ass mr0-5rem tal">Not Applicable</option>
										<option value="In Progress" className="pen usn ass mr0-5rem tal">In Progress</option>
										<option value="Completed" className="pen usn ass mr0-5rem tal">Completed</option>
									</select>
									<svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
										<path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
									</svg>
								</div>
							</>
							: <>
								<div class="pr">
									<input value={checklistTaskState['title']} name="title" type="text" placeholder="Title" onChange={handleChange} className="pl4rem shadow bg5 w100 br5px p1rem" />
									<svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
										<path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
									</svg>
								</div>
								<div className="pr">
									<input value={checklistTaskState['description']} name="description" type="text" placeholder="Description" onChange={handleChange} className="pl4rem shadow bg5 w100 br5px p1rem" />
									<svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
										<path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
									</svg>
								</div>
								<div className="pr">
									<select value={checklistTaskState['module']} onChange={handleChange} name='module' className="pl4rem shadow bg5 w100 br5px p1rem">
										<option value="" className="pen usn ass mr0-5rem tal">Select Module</option>
										<option value="repository" className="pen usn ass mr0-5rem tal">Repository</option>
										<option value="business-audit-investment" className="pen usn ass mr0-5rem tal">Business Audit Investment</option>
										<option value="business-audit-growth" className="pen usn ass mr0-5rem tal">Business Audit Growth</option>
										<option value="business-audit-exit" className="pen usn ass mr0-5rem tal">Business Audit Exit</option>
										<option value="warranty-disclosure" className="pen usn ass mr0-5rem tal">Warranty Disclosure</option>
										<option value="directors-questionnaire" className="pen usn ass mr0-5rem tal">Directors Questionnaire</option>
										<option value="esg" className="pen usn ass mr0-5rem tal">ESG</option>
										<option value="software-development" className="pen usn ass mr0-5rem tal">Software Development</option>
										<option value="two-minute-test-investment" className="pen usn ass mr0-5rem tal">Two Minute Test Investment</option>
										<option value="two-minute-test-growth" className="pen usn ass mr0-5rem tal">Two Minute Test Growth</option>
										<option value="two-minute-test-exit" className="pen usn ass mr0-5rem tal">Two Minute Test Exit</option>
									</select>
									<svg className="center-vertically f3 pa l0 ml1rem w2rem" viewBox="0 0 640 512">
										<path d="M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z" className="fa-secondary"></path>
									</svg>
								</div>
							</>

						}
						<span onClick={() => submitChecklistTask()} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp ttu w100 bsbb tac df jcc aic">{action}</span>
						<span onClick={() => dispatch(toggleModal({ open: '' }))} className="c3-hover td250 fs1-25rem cp">Cancel</span>
					</div>
				</div>
			</Modal>

			{/* Delete Checklist Task */}
			<Modal open={modal['open'] == 'deleteTaskPopup'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100 h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 px2rem py3rem bsbb df fdc m1rem maw700px br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs2rem fw600 tac ttu mb1rem pb1rem">Delete Task</h1>
					<div class="df fdc gg1rem px2rem">
						<p>Are you sure you want to <span className="c4 fw500">DELETE</span> task - ({checklistTaskState['title']})</p>
						<span onClick={() => submitChecklistTask()} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp ttu w100 bsbb tac df jcc aic">Delete</span>
						<span onClick={() => dispatch(toggleModal({ open: '' }))} className="c3-hover td250 fs1-25rem cp">Cancel</span>
					</div>
				</div>
			</Modal>

			{/* Advisor Audit Logs */}
			<Modal open={modal['open'] == 'taskAuditLogs'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs1-5rem fw600 tac ttu">Checklist Tasks Audit Logs</h1>
					<h2 className="c4 fs0-75rem ttu w100 tac mb1rem">See the history of checklist tasks</h2>
					<div className="ma oya dg gg0-5rem mb2rem w100">
						{(auditLogs && auditLogs.length > 0)
							? auditLogs.map(log => {
								return (
									<div className="df my0-5rem">
										{log?.['userId']?.['profile']
											? <img className={`ass ml0-5rem br100 w2rem h2rem ${profileClass}`} src={log?.['userId']?.['profile']} data-tooltip={log?.['userId']?.['firstName'] ? (log?.['userId']?.['firstName'] + " " + log?.['userId']?.['lastName']) : "Register to show your name here"} />
											: <span className={`ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-75rem ${profileClass}`} data-tooltip={log?.['userId']?.['firstName'] ? (log?.['userId']?.['firstName'] + " " + log?.['userId']?.['lastName']) : "Register to show your name here"}>{log?.['userId']?.['firstName'][0] || "?"} {log?.['userId']?.['lastName'][0] || "?"}</span>
										}
										<div className="w100">
											<span className='fs0-75rem ml1rem'><b>{log['message']}</b> at <b>{new Date(log.timestamp).toLocaleString()}</b> by <b>{log?.['userId']?.['firstName']} {log?.['userId']?.['lastName']}</b></span>
											<div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
												<p className="df fw500 fs1rem ttc">Task {log['payload']['action']}:</p>
												<p className="df fs0-75rem ttc"><span className="fw500 mr0-5rem">Title : </span> {log?.['payload']?.['title'] ? log['payload']['title'] : '-'}</p>
												<p className="df fs0-75rem ttc"><span className="fw500 mr0-5rem">Module : </span> {log?.['payload']?.['module'] ? log['payload']['module'] : '-'}</p>
												<p className="df fs0-75rem ttc"><span className="fw500 mr0-5rem">Status : </span> {log?.['payload']?.['status'] ? log['payload']['status'] : '-'}</p>
											</div>
										</div>
									</div>
								)
							})
							: <h2 className="text-gradient fs1-75rem ttu w100 tac mb1rem">No Logs Found!</h2>
						}
					</div>
				</div>
			</Modal>

		</div>
	</>
}
