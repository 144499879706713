import React from 'react'
import { Link } from 'react-router-dom';

const PricingSection = () => {

    return (
        <div className="pricing-container bg1 pr bscon brne pr">
            {/* Heading container */}
            <div className={`pb2rem df jcc fdc tslp ttx20`}>
                <p className="fw500 fs2-125rem c15 tac w60" style={{ lineHeight: '62.2px' }}>
                    Our <span className="c4">Pricing</span> Plan
                </p>
                <p className='fw400 fs1rem c15 mt0-5rem mb1rem tac' style={{ lineHeight: '#151313' }}>
                    We offer a range of pricing plans tailored to fit your needs. To explore the details of each plan <br />
                    and find the one that's right for you, please see the complete list.
                </p>
            </div>
            <div className='cp fw400 df jcc w100'>
                <Link to="/pricing-plan" className="bsbb jcc w300px df fdc aic fw500 py0-5rem c1 bg3 br19px cp">See Details</Link>
            </div>
        </div >
    )

}
export default PricingSection


