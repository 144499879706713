import { Link } from "react-router-dom";
import AnimatedPage from "../../components/AnimatedPage";
import HeadingSubheadingContent from "../../components/HeadingSubheadingContent";

export default function Group() {
	return (
		<AnimatedPage>
			<div className="container py5rem df fdc">
				<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Group" subheading="Manage a group" />
			</div>
		</AnimatedPage>
	);
}