import { useState, useEffect } from "react";

import AnimatedPage from "../../../components/AnimatedPage";
import HeadingSubheadingContent from "../../../components/HeadingSubheadingContent";
import { getRequest, postRequest, showMessage } from '../../../config';
import moment from "moment";

export default function AiHistoryComponent(props) {

	const { onBack } = props;
	const [auditTestLogs, setAuditTestLogs] = useState([]);
	const [selectedLog, setSelectedLog] = useState();

	const fetch = async () => {
		let res = await getRequest(`/audit/audittestlogs`);
		console.log("res: ", res['body'])
		if (res?.code === 200) {
			setAuditTestLogs(res['body']);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	const selectCurrentAuditLog = async () => {
		let result = confirm("Do you want to update this instruction as default?");
		if (result === false) {
			return;
		}
		if (!selectedLog) {
			return;
		}
		let res = await postRequest(`/audit/selectaudit`, { auditTestLogId: selectedLog._id });
		if (!res || res['code'] != 200) return;
		// await fetch();
		let currentLogs = auditTestLogs;
		for (const currentLog of currentLogs) {
			currentLog.isSelected = false;
			if (currentLog['_id'] == selectedLog['_id'])
				currentLog.isSelected = true;
		}
		setAuditTestLogs(currentLogs);
		showMessage("Instruction is selected as default");
	}

	return (
		<AnimatedPage>
			<div className="container py5rem df fdc">
				<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Admin Panel - AI Test History" />
				<span onClick={() => { selectedLog ? setSelectedLog(null) : onBack() }} class="mra shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
					<svg class="pen f1 h2rem mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
						<path class="fa-primary pen" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
						<path class="fa-secondary pen" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l-32-32l32-32H416C433.7 224 447.1 238.3 447.1 256z" />
					</svg>
					Back to Menu
				</span>
				{
					!selectedLog ? auditTestLogs.map((auditTestLog, logIndex) => {
						return <div className={"shadow mt1rem py1rem px2rem br5px cp bss bw2px bo5 " + (auditTestLog.isSelected ? "bg7" : "bg1")} onClick={() => { setSelectedLog(auditTestLog); }}>
							<p className="usn pen fs1-5rem">
								Tested by {auditTestLog?.['tester']?.['profile'] && <img className="ass mx0-5rem br100 w2rem h2rem" src={auditTestLog?.['tester']?.['profile']} />} {auditTestLog?.['tester']?.['firstName']},&nbsp;&nbsp;
								{moment(auditTestLog['createdAt']).format('lll')}<br />
								{auditTestLog.intro} [COPMANY] <br /><br />
								{auditTestLog.instruction.substr(0, 100) + "  ... "}
							</p>
						</div>
					}) :
						<div class="fs1rem bss bw2px bo5 br10px p1rem jcsb">
							{selectedLog.questions.map((question, questionIndex) => {
								const risk = selectedLog.risks[questionIndex];
								const recommendation = selectedLog.recommendations[questionIndex];
								return (
									<>
										<div class="sectionWrapper jcsb px2rem py0-5rem my0-5rem bss bw2px bo5 br10px">
											<div className="op20 pen dg gtcr2-lg">
												<p className="fw500 fs1rem">{question}</p>
											</div>
										</div>
										<div class="sectionWrapper jcsb px2rem py0-5rem my0-5rem bss bw2px bo5 br10px">
											<div class="df jcsb">
												<span className="fs1rem ">
													Risk : {risk} <br /> <br />
													Recommendation : {recommendation} <br />
												</span>
											</div>
										</div>
									</>
								)
							})}

							<div class="sectionWrapper jcsb px2rem py0-5rem my0-5rem bss bw2px bo5 br10px">
								<div class="df jcsb">
									<span className="fs1rem ">
										Company Name : {selectedLog.companyName} <br /> <br />
										Company Description : <p>{selectedLog.companyDescription}</p> <br /> <br />
										Intro : {selectedLog.intro} <br /> <br />
										Instructions : <p>{selectedLog.instruction}</p>
									</span>
								</div>
							</div>
							<div className="w100 df jcc aic">
								<button onClick={() => selectCurrentAuditLog()} class="bg4 c6 br5px px1rem py0-5rem fw600 cp fs1-25rem bsbb tac df jcc aic">
									{selectedLog.isSelected ? 'This instruction is currently used' : 'Go Live this instruction'}
								</button>
							</div>

						</div>
				}
			</div>
		</AnimatedPage>

	);
}
