import { useState, useEffect } from "react";

import AnimatedPage from "../../../components/AnimatedPage";
import HeadingSubheadingContent from "../../../components/HeadingSubheadingContent";
import { getRequest, postRequest, showMessage } from '../../../config';
import moment from "moment";
import ReactTable from 'react-table-6';

export default function AiChecklistHistory(props) {

    const { onBack } = props;
    const [checklistTestLogs, setChecklistTestLogs] = useState([]);
    const [selectedLog, setSelectedLog] = useState();

    const [tData, setTData] = useState([]);
    //     {
    //         no: "S001",
    //         questions: "T collaborations with industry experts for innovation?	",
    //         space: "",
    //         risk: "Lack of external input can limit innovation.",
    //         recommendation: "Establish a dedicated product development team.",
    //     },

    
    const tColumns = [
        {
            Header: 'Section',
            accessor: 'section',
            minWidth: 100,
            style: { whiteSpace: 'normal', wordWrap: 'break-word' }
        },
        {
            Header: 'No',
            accessor: 'no',
            width: 80,
        },
        {
            Header: 'Questions',
            accessor: 'questions',
            minWidth: 200,
            style: { whiteSpace: 'normal', wordWrap: 'break-word' }
        },
        {
            Header: 'Space for Answer',
            accessor: 'space',
            width: 50,
            style: { whiteSpace: 'normal', wordWrap: 'break-word' }
        },
        {
            Header: 'Risk/Rationale',
            accessor: 'risk',
            minWidth: 200,
            style: { whiteSpace: 'normal', wordWrap: 'break-word' }
        },
        {
            Header: 'Recommendation',
            accessor: 'recommendation',
            minWidth: 200,
            style: { whiteSpace: 'normal', wordWrap: 'break-word' }
        },
    ];

    const parseData = (text) => {
        const lines = text.split('\n');
        const data = [];
        let currentSection = '';
    
        let sectionNo = 0;
        let showSection = true;
        lines.forEach(line => {
          if (line.startsWith('**Section') || line.startsWith('Section')) {
            // currentSection = line.replace('**', '').trim();
            currentSection = line.split(':')[1].replace('**', '').trim();
            sectionNo++;
            showSection = true;
          } else if (line.startsWith('|') && !line.startsWith('| ---') && !line.startsWith('| No')) {
            const [, no, questions, space, risk, recommendation] = line.split('|').map(item => item.trim());
            data.push({
              section: showSection ? `S${padNumber( sectionNo, 3)}. ` + currentSection : "",
              no: `S${padNumber( sectionNo, 3)}.${no}`,
              questions,
              space,
              risk : risk !== "-" ? risk : "",
              recommendation : recommendation !== "-" ? recommendation : ""
            });
            showSection = false;
          }
        });

        setTData(data);
    };


    const fetch = async () => {
        let res = await getRequest(`/aiTest/getChecklistLogs`);
        console.log(res)
        if (res?.code === 200) {
            setChecklistTestLogs(res['body']);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    const padNumber = (num, size) => String(num).padStart(size, '0');
    const processResponse = (response) => {
        const data = JSON.parse(response);

        let formattedString = '';
        data.module.forEach((section, sectionIndex) => {
            const sectionNumber = padNumber(sectionIndex + 1, 3);
            formattedString += `S${sectionNumber}. "${section.sectionName}"\n`;
            
            section.questions.forEach((q, questionIndex) => {
                formattedString += `S${sectionNumber}.${questionIndex + 1}: "${q.question}"\n`;
            });
            formattedString += '\n'; // Add an extra newline between sections
        });
        // setAiResp(formattedString);
        return formattedString;
    }

    const getAiResponseString = (aiResp) => {
        if (!aiResp || aiResp.length == 0)
            return "";

        let stringResult = "";
        for (const dup of aiResp) {
            if (Array.isArray(dup)) {
                stringResult += "<p>- " + dup.join(", ") + "</p>";
                // stringResult += "<br>";
            }

        }

        return stringResult;
    }

    const selectCurrentChecklistLog = async () => {
        let result = confirm("Do you want to update this instruction as default?");
        if (result === false) {
            return;
        }
        if (!selectedLog) return;
        if (selectedLog.isSelected) return;
        let res = await postRequest(`/aiTest/selectChecklists`, { chId: selectedLog['_id'] });
        if (!res || res['code'] != 200) return;

        let currentLogs = checklistTestLogs;
        for (const currentLog of currentLogs) {
            currentLog.isSelected = false;
            if (currentLog['_id'] == selectedLog['_id'])
                currentLog.isSelected = true;
        }
        setChecklistTestLogs(currentLogs);
        // parseData(currentLogs.checklistDescription);
        showMessage("Instruction is selected as default");
    }

    return (
        <AnimatedPage>
            <div className="container py5rem df fdc">
                <HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Admin Panel - Ai Checklist Module Test History" />
                <span onClick={() => { selectedLog ? setSelectedLog(null) : onBack() }} class="mra shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
                    <svg class="pen f1 h2rem mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path class="fa-primary pen" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
                        <path class="fa-secondary pen" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l-32-32l32-32H416C433.7 224 447.1 238.3 447.1 256z" />
                    </svg>
                    Back to Menu
                </span>
                {
                    !selectedLog ? checklistTestLogs.map((checklistTestLog, logIndex) => {
                        console.log("checklistTestLog; ", checklistTestLog);
                        return <div className={`shadow mt1rem py1rem px2rem br5px cp bss bw2px bo5 ${checklistTestLog.isSelected ? "bg7" : "bg1"}`} onClick={() => { setSelectedLog(checklistTestLog);
                            parseData(checklistTestLog.checklistDescription)
                         }}>
                            <p className="usn pen fs1-5rem">
                                Tested by {checklistTestLog?.['tester']?.['profile'] && <img className="ass mx0-5rem br100 w2rem h2rem" src={checklistTestLog?.['tester']?.['profile']} />} {checklistTestLog?.['tester']?.['firstName']},&nbsp;&nbsp;
                                {moment(checklistTestLog['createdAt']).format('lll')}<br /><br />
                                <b>Instructions:</b> {checklistTestLog.instructions.substr(0, 100) + "  ... "}
                            </p>
                        </div>
                    }) :
                        <div class="fs1rem bss bw2px bo5 br10px p1rem jcsb">
                            <div class="sectionWrapper jcsb px2rem py0-5rem my0-5rem bss bw2px bo5 br10px">
                                <div class="df jcsb">
                                    <span className="fs1rem ">
                                        <b>Name :</b> {selectedLog.moduleName} <br /> <br />
                                        <b>Description :</b> {selectedLog.moduleDescription} <br /> <br />
                                        <b>Instructions :</b> <p>{selectedLog.instructions}</p> <br /> <br />
                                        {/* <b>Answers :</b> <p>{processResponse( selectedLog.checklistDescription)}</p> <br /> <br /> */}
                                        <b>Answers :</b> 
                                        { tData && tData.length > 0 &&
                                            <ReactTable
                                                data={tData}
                                                columns={tColumns}
                                                defaultPageSize={10}
                                                className="-striped -highlight"
                                            />
                                        }
                                         <br /> <br />
                                    </span>
                                </div>
                            </div>
                            {
                                <div className="w100 df jcc aic">
                                    <button onClick={() => selectCurrentChecklistLog()} className={`c6 br5px px1rem py0-5rem fw600 fs1-25rem bsbb tac df jcc aic ${selectedLog.isSelected ? "bg7" : "cp bg4"}`}>
                                        {selectedLog.isSelected ? 'This instruction is currently used' : 'Go Live this instruction'}
                                    </button>
                                </div>
                            }
                        </div>
                }
            </div>
        </AnimatedPage>

    );
}

