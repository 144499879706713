import React from "react"
import AnimatedPage from "../../components/AnimatedPage";
import SmoothScrollingButton from "../../components/SmoothScrollingButton";
import CustomTab from "../../components/termsAndConditions/CustomTab";

const Privacy = () => {

    return (
        <AnimatedPage>
            <SmoothScrollingButton />
            <div className='poppins-font-style privacy-policy-page *'>
                <CustomTab type="policy" activeIdentifier="privacy-policy" title={'Policies'} width="w60" />
                <div className="fw400 tal c14 fs1rem policy-box">
                    <p className="pt1rem pb1rem">Effective: 30th June, 2022</p>
                    <p >Thanks for using Diligentsia! Here we describe how we collect, use, and handle your personal data when you use our websites, software, and services (“Services”). </p>
                    <p className='fw500 tal c14 fs1-5rem pt1rem pb1rem'>What & Why</p>
                    <p className="pb1rem">We collect and use the following information to provide, improve, protect, and promote our Services.</p>
                    <p className="pb1rem">Account information. We collect, and associate with your organisation’s account, the information you and your organisation provide to us when you do things such as sign up for your account, upgrade to a paid plan, and set up two-factor authentication (like your name, email address, phone number, payment info, and physical address).</p>
                    <p className="pb1rem">Your Stuff. Our Services are designed as a simple and personalized way for you to store your files, documents, photos, comments, messages, and so on (“Your Stuff”), collaborate with others, and work across multiple devices and services. To make that possible, we store, process, and transmit Your Stuff as well as information related to it. This related information includes your organisation’s account  information that makes it easier to collaborate and share Your Stuff with others, as well as things like the size of the file, the time it was uploaded, collaborators, and usage activity.</p>
                    <p className="pb1rem">Contacts. You may choose to give us access to your contacts to make it easy for you, and your organisation and its employees and contractors to do things like share and collaborate on Your Stuff, send messages, and invite others to use the Services. If you do, we’ll store those contacts on our servers.</p>
                    <p className="pb1rem">Usage information. We collect information related to how you use the Services, including actions you take in your account (like sharing, editing, viewing, creating and moving files or folders). We use this information to provide, improve, and promote our Services, and protect Diligentsia users. Please refer to our Diligentsia Terms & Conditions for more information about how we use this usage information.</p>
                    <p className="pb1rem">Device information. We also collect information from and about the devices you use to access the Services. This includes things like IP addresses, the type of browser and device you use, the web page you visited before coming to our sites, and identifiers associated with your devices. Your devices (depending on their settings) may also transmit location information to the Services. For example, we use device information to detect abuse and identify and troubleshoot bugs.</p>
                    <p className="pb1rem">Cookies and other technologies. We use technologies like cookies and pixel tags to provide, improve, protect, and promote our Services. For example, cookies help us with things like remembering your username for your next visit, understanding how you are interacting with our Services, and improving them based on that information. You can set your browser to not accept cookies, but this may limit your ability to use the Services. If our systems receive a DNT:1 signal from your browser, we’ll respond to that signal. We may also use third-party service providers that set cookies and similar technologies to promote Diligentsia services. You can learn more about how cookies and similar technologies work, as well as how to opt out of the use of them for advertising purposes by contacting customersupport@diligentsia.co.uk.</p>
                    <p className="pb1rem">Marketing. We give users the option to use some of our Services free of charge. These free Services are made possible by the fact that some users upgrade to one of our paid Services. If you register for our Services, we will, from time to time, send you information about upgrades when permissible. Users who receive these marketing materials can opt out at any time. If you don’t want to receive a particular type of marketing material from us, click the ‘unsubscribe’ link in the corresponding emails, or update your preferences in the Notifications section of your personal account.</p>
                    <p className="pb1rem">We sometimes contact people who don’t have a Diligentsia account. For recipients in the EU, we or a third party will obtain consent before reaching out. If you receive an email and no longer wish to be contacted by Diligentsia, you can unsubscribe and remove yourself from our contact list via the message itself.</p>
                    <p className="pb1rem">Bases for processing your data. We collect and use the personal data described above in order to provide you with the Services in a reliable and secure manner. We also collect and use personal data for our legitimate business needs. To the extent we process your personal data for other purposes, we ask for your consent in advance or require that our partners obtain such consent..</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>With Whom</p>
                    <p className="pb1rem">We may share information or your data uploaded via the Diligentsia platform, or received by it, as discussed below, but we won’t sell it to advertisers or other third parties.</p>
                    <p className="pb1rem">Others working for and with Diligentsia. Diligentsia uses certain trusted third parties (for example, providers of customer support and IT services) for the business purposes of helping us provide, improve, protect, and promote our Services. These third parties will access your information to perform tasks on our behalf, and we’ll remain responsible for their handling of your information per our instructions.  </p>
                    <p className="pb1rem">We need your permission to do things like hosting Your Stuff, backing it up and sharing it when you ask us to. It includes our setting up a Diligentsia corporate folder and sub-folders within one or more of your organisation’s third party cloud repositories, such as Sharepoint, Google Drive or Diligentsia.  You authorise us to upload Our Services to our Diligentsia databases and platform more generally.  We may also provide you with features like commenting, sharing, searching, image thumbnails, document previews, optical character recognition (OCR), easy sorting and organisation and personalisation to help reduce busywork. To provide these and other features, Diligentsia accesses, uploads, stores and scans Your Stuff within its own databases. You give us permission to do those things, and this permission extends to our affiliates and trusted third parties we work with.  In particular, unless you opt out, we are free to share your information with your accountants, lawyers, investors where you have been introduced by one or more of those organisations as their client or portfolio company.  Where you enter additional information directly into the Diligentsia folder within your cloud storage, you authorise us to synch that folder with its corresponding copy held within the Diligentsia databases in your organisation’s name.</p>
                    <p className="pb1rem">You authorise us to undertake data analytics on the information your organisation uploads to, and synch’s with, the Diligentsia folder.  We may utilise that information on an anonymised basis both to improve our and to provide additional services to you, either directly or through trusted third parties we work with, provided that you will authorise those third parties.</p>
                    <p className="pb1rem">Other Diligentsia Companies. Diligentsia may in the future share infrastructure, systems, and technology with other Diligentsia Companies to provide, improve, protect, and promote Diligentsia Company Services. We process your information across the Diligentsia Companies for these purposes, as permitted by applicable law and in accordance with their terms and policies. </p>
                    <p className="pb1rem">Other users. Our Services are designed to help you collaborate with others. If you register your Diligentsia account with an email address on a domain owned by your employer or organization or collaborate with other Diligentsia users, we may suggest you or your team as a potential collaborator to other users or teams. For example, if you interact with a person at a company, and that person frequently works with one of their coworkers, we may suggest you as a potential collaborator for that coworker. Collaborators and potential collaborators may see some of your basic information, like your name, profile picture, device, email address, and usage information. This helps you sync up with teams you can join and helps other users share files and folders with you, and your professional advisors/investors if your organisation has given them permission.</p>
                    <p className="pb1rem">Certain features let you make additional information available to others.</p>
                    <p className="pb1rem">Other applications. You can choose to connect your Diligentsia account with third-party services––for example, via Diligentsia APIs. By doing so, you’re enabling Diligentsia and those third parties to exchange information about you and data in your account so that Diligentsia and those third parties can provide, improve, protect, and promote their services. Please remember that third parties’ use of your information will be governed by their own privacy policies and terms of service.</p>
                    <p className="pb1rem">Business Team Admins. If you are the employee of an organisation using the Diligentsia platform, your organisation’s administrator may have the ability to access and control your account. Please refer to your organization’s internal policies if you have questions about this. If you aren’t a Diligentsia user but interact with a Diligentsia customer account or its contents (for example, by joining a shared folder or accessing stuff shared by that user), members of that organization may be able to view information about you (such as you or your organisation’s name, email address, and profile picture) and your interaction with the Diligentsia user or the user’s organisation (such as your IP address). If you share Your Stuff with a Diligentsia user, the administrator of the team account may have the ability to access and edit what you share.</p>
                    <p className="pb1rem">Law & Order and the Public Interest. We may disclose your information to third parties if we determine that such disclosure is reasonably necessary to: (a) comply with any applicable law, regulation, legal process, or appropriate government request; (b) protect any person from death or serious bodily injury; (c) prevent fraud or abuse of Diligentsia or our users; (d) protect Diligentsia’s rights, property, safety, or interest; or (e) perform a task carried out in the public interest.</p>
                    <p className="pb1rem">Stewardship of your data is critical to us and a responsibility that we embrace. We believe that your data should receive the same legal protections regardless of whether it’s stored on our Services or on your organisation’s own servers. We’ll abide by the following Government Request Principles when receiving, scrutinizing, and responding to government requests (including national security requests) for your data:</p>
                    <ol className="pb1rem">
                        <li>Be transparent</li>
                        <li>Fight blanket requests</li>
                        <li>Protect all users, and</li>
                        <li>Provide trusted services.</li>
                    </ol>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>How</p>
                    <p className="pb1rem">Security. We are dedicated to keeping your information secure and testing for vulnerabilities. We continue to work on features to keep your information safe in addition to things like two-factor authentication.  </p>
                    <p className="pb1rem">User Controls. You can access, amend, download, and delete your organisation’s information by logging into your Diligentsia account and going to your account settings page. </p>
                    <p className="pb1rem">Retention. When you sign up for an account with us, we’ll retain information you store on our Services for as long as your account exists or as long as we need it to provide you the Services. If you delete your account, we’ll initiate deletion of this information after 30 days. But please note: (1) there might be some latency in deleting this information from our servers and back-up storage; and (2) we may retain this information if necessary to comply with our legal obligations, resolve disputes, or enforce our agreements.</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Where</p>
                    <p className="pb1rem">Around the world. To provide you with the Services, we may store, process, and transmit data in the United States and other locations around the world—including those outside your country. Data may also be stored locally on the devices you use to access the Services.</p>
                    <p className="pb1rem">Data Transfers. When transferring data from the European Union, the European Economic Area, the United Kingdom, and Switzerland, Diligentsia relies upon a variety of legal mechanisms, such as contracts with our customers and affiliates, Standard Contractual Clauses, and the European Commission's adequacy decisions about certain countries, as applicable.</p>
                    <p className="pb1rem">Your Control and Access of Your Data</p>
                    <p className="pb1rem">Your organisation has control over your personal data and how it’s collected, used, and shared. For example, you can:</p>
                    <ol className="pb1rem">
                        <li>Delete Your Stuff in your Diligentsia account. </li>
                        <li>Change or correct personal data. </li>
                        <li>Access and take your data elsewhere</li>
                        <li>Object to the processing of your personal data. </li>
                    </ol>
                    <p className="pb1rem">If you would like to submit a data access request or object to the processing of your personal data, please email us at privacy@Diligentsia.co.uk  </p>
                    <p className="pb1rem">Diligentsia as controller or processor. Diligentsia Limited acts as your service provider. </p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Changes</p>
                    <p className="pb1rem">If we’re involved in a reorganization, merger, acquisition, or sale of our assets, your data may be transferred as part of that deal. We’ll notify you (for example, via a message to the email address associated with your account) of any such deal and outline your choices in that event.</p>
                    <p className="pb1rem">We may revise this Privacy Policy from time to time and will post the most current version on our website. If a revision meaningfully reduces your rights, we will notify you.</p>
                    <p className='fw500 tal c14 fs1-5rem pb1rem'>Contact</p>
                    <p className="pb1rem">Have questions or concerns about Diligentsia, our Services, and privacy? Contact our Data Protection Officer at privacy@Diligentsia.co.uk . If they can’t answer your question, you have the right to contact your local data protection supervisory authority.</p>
                </div>
            </div >
        </AnimatedPage >
    )

}
export default Privacy;