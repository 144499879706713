export default function IDeals({ className = "w2rem ma" }) {
	return <svg className={className} viewBox="0 0 321 66" fill="none">
		<path
			d="M320 51.3082C320 43.1968 313.517 36.6191 305.523 36.6191C297.527 36.6191 291.041 43.1968 291.041 51.3082C291.041 59.4196 297.527 65.9997 305.522 65.9997C313.517 65.9997 320 59.4196 320 51.3082Z"
			fill="#3BAE5B" />
		<path
			d="M285.252 16.0562C285.252 7.94185 278.769 1.36719 270.774 1.36719C262.777 1.36719 256.297 7.94246 256.297 16.0562C256.297 24.17 262.778 30.7453 270.774 30.7453C278.769 30.7459 285.252 24.1706 285.252 16.0562Z"
			fill="#3BAE5B" />
		<path
			d="M291.201 15.9125C291.201 27.1561 282.085 36.2735 270.841 36.2735C262.804 36.2735 256.293 42.7868 256.293 50.8188C256.293 58.8508 262.804 65.3672 270.841 65.3672C278.873 65.3672 285.383 58.8514 285.383 50.8188C285.383 39.5728 294.5 30.4579 305.748 30.4579C313.778 30.4579 320.293 23.9469 320.293 15.9125C320.293 7.87813 313.777 1.36719 305.748 1.36719C297.715 1.36719 291.201 7.87753 291.201 15.9125Z"
			fill="#87999F" />
		<path
			d="M86.3531 30.9238V38.3596H117.479V30.9238C117.479 29.2151 116.421 25.9586 112.585 25.9586H91.2501C87.7518 25.9586 86.3531 29.2151 86.3531 30.9238ZM119.444 57.5382C121.128 57.5382 122.492 58.9192 122.492 60.628V61.8498C122.492 63.5555 121.128 64.9396 119.444 64.9396H91.2501C84.5181 64.9396 79.0586 59.4 79.0586 52.5723V30.9238C79.0586 24.0912 84.5181 18.5547 91.2501 18.5547H112.586C119.32 18.5547 124.777 24.0912 124.777 30.9238V42.6686C124.777 44.3774 123.414 45.7609 121.73 45.7609H86.3543V52.5723C86.3543 55.9574 89.5665 57.5376 91.2507 57.5376L119.444 57.5382Z"
			fill="#343A40" />
		<path
			d="M169.296 57.5377V45.761H143.066C140.009 45.761 138.173 47.4841 138.173 50.1044V53.1967C138.173 56.5544 141.382 57.5377 143.066 57.5377H169.296ZM136.208 25.958C134.526 25.958 133.16 24.5745 133.16 22.8682V21.6489C133.16 19.9376 134.526 18.5566 136.208 18.5566H164.402C171.136 18.5566 176.596 24.0932 176.596 30.9239V61.8499C176.596 63.5555 175.229 64.9422 173.548 64.9422H143.066C136.334 64.9422 130.875 59.4025 130.875 52.573V50.7294C130.875 43.8974 136.334 38.3603 143.066 38.3603H169.296V30.9245C169.296 29.2158 168.571 25.9593 164.402 25.9593L136.208 25.958Z"
			fill="#343A40" />
		<path
			d="M183.835 61.8501C183.835 63.5557 185.199 64.9399 186.885 64.9399H188.085C189.768 64.9399 191.132 63.5557 191.132 61.8501V3.09228C191.132 1.38351 189.768 0 188.085 0H186.885C185.199 0 183.835 1.38351 183.835 3.09228V61.8501Z"
			fill="#343A40" />
		<path
			d="M228.999 45.7616C232.204 45.7616 234.508 47.5921 234.508 50.7268V52.573C234.508 56.1216 232.634 57.5382 228.999 57.5382H201.421C199.737 57.5382 198.373 58.9192 198.373 60.6305V61.8498C198.373 63.5555 199.737 64.9396 201.421 64.9396H229.614C236.855 64.9396 241.809 59.9718 241.809 52.5723V49.4775C241.809 42.648 236.349 38.3596 229.614 38.3596H208.279C205.955 38.3596 203.385 37.405 203.385 33.3918V30.9238C203.385 28.0582 204.916 25.9586 208.279 25.9586H236.473C238.156 25.9586 239.52 24.5726 239.52 22.8663V21.6495C239.52 19.9357 238.156 18.5547 236.473 18.5547H208.279C201.544 18.5547 196.084 24.0912 196.084 30.9238V34.0161C196.084 40.8481 201.544 45.7609 208.279 45.7609H228.999V45.7616Z"
			fill="#343A40" />
		<path
			d="M18.0957 61.8494C18.0957 63.5551 19.4593 64.9392 21.1405 64.9392H50.0993C62.7204 64.9392 72.9575 55.3427 72.9575 41.748V23.1937C72.9575 10.3813 62.7204 0 50.0993 0H21.1405C19.4593 0 18.0957 1.38351 18.0957 3.09228V61.8494ZM25.3926 7.40388H50.0993C58.9408 7.40388 65.671 14.7909 65.6599 23.1937V41.748C65.6599 51.2347 57.9821 57.5378 50.0993 57.5378H25.3926V7.40388Z"
			fill="#343A40" />
		<path
			d="M1.80664 61.85C1.80664 63.5557 3.17028 64.9398 4.85452 64.9398H5.90248C7.58364 64.9398 8.95281 63.5557 8.95281 61.85V20.4004C8.95281 18.6867 7.58364 17.3057 5.90248 17.3057H4.85452C3.17028 17.3057 1.80664 18.6867 1.80664 20.4004V61.85Z"
			fill="#343A40" />
		<path
			d="M10.0057 5.08014C10.0057 2.27442 7.76584 0 5.00287 0C2.23991 0 0 2.27442 0 5.08014C0 7.88211 2.23991 10.154 5.00287 10.154C7.76584 10.154 10.0057 7.88211 10.0057 5.08014Z"
			fill="#3BAE5B" />
	</svg>
}
