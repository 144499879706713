import AnimatedPage from "../components/AnimatedPage";
import CallToAction from "../components/CallToAction";
import Hero from "../components/Hero";
import FancyHeadingParagraph from "../components/FancyHeadingParagraph";
import HeadingParagraph from "../components/HeadingParagraph";
import HeadingSubheadingContent from "../components/HeadingSubheadingContent";
import TakeTheTest from "../components/TakeTheTest";
import IconTicksCard from "../components/IconTicksCard";
import Testimonials from "../components/Testimonials";

export default function InvestmentReadyCompanies() {
	const testimonials = [
		{
			title: "The Growth Manager",
			text: "Only when you work as part of a Senior Management Team around a central data repository, kept up to date, do you really realise the operational benefits of centralising key corporate data into one easy to access location.",
			author: "Katy Davidson",
			company: "CFO Wearable Technologies Limited",
		},
		{
			title: "The Early Stage CEO",
			text: "Having an investment ready data room and supporting documents has undoubtedly attracted more interested investors prepared to look at us seriously and accelerated our investment timetable.",
			author: "Louise Birritteri",
			company: "CEO Pikl Insurance Services Limited",
		},
		{
			title: "The Professional",
			text: "Getting a client into a position where they can provide a draft disclosure letter schedule at the commencement of a transaction will reduce costs, speed up the transaction, and lessen the chance of its falling over right at the end.",
			author: "Rik Pancholi",
			company: "Director, Pattersons Commercial Law",
		},
	];
	return (
		<AnimatedPage>
			<Hero h1Class="fs2-5rem" h1={["Raise More Money,", "More Quickly,", "With More Certainty."]} buttonText="Learn How" buttonId="scrollDown" videoSrc="diligentsia_for_companies.mp4" videoThumbnail="diligentsia-for-companies.jpg" />
			<TakeTheTest readyFor="Investment"/>
			<div className="bg1">
				<div className="container df fww py5rem">
					<HeadingSubheadingContent wrapperClass="w50" heading="Is Your Company Investment Ready?" subheading="Meet Diligentsia" paragraph="Warranty disclosure schedules, Director’s questionnaires, up to date key corporate data - legal, IP, key contracts, HR, constitutional - all in a single intuitive and easy to access repository. These are all things that give investors confidence in your business. But what else do you need and how do you know if you’re investment ready?" />
					<IconTicksCard  wrapperClass="w50 jcfe" title="Investment Phase" ticks={['We make you "investment ready"', "More investors will look at your company", "Better chance of auction", "Higher valuation", "Better terms", "Quicker transaction time", "Reduced risk of late collapse of deal", "Lower professional fees"]}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="fa-primary" d="M288 480H32c-12.41 0-23.72-7.188-28.97-18.44c-5.281-11.23-3.562-24.52 4.375-34.05c28.25-33.86 43.78-76.55 43.78-120.2V157.3C51.19 88.22 108.3 32 178.6 32c13.69 0 27.25 2.156 40.25 6.422l79.09 25.89c16.81 5.484 25.97 23.56 20.47 40.36c-5.469 16.77-23.62 25.98-40.34 20.45l-79.13-25.89C192.3 97.09 185.5 96 178.6 96C143.6 96 115.2 123.5 115.2 157.3v149.1c0 37.73-8.625 74.91-24.88 108.7H288c17.69 0 32 14.33 32 32S305.7 480 288 480z" />
							<path className="fa-secondary" d="M0 256c0 17.67 14.31 32 32 32h19.19V224H32C14.31 224 0 238.3 0 256zM224 224H115.2v64H224c17.69 0 32-14.33 32-32S241.7 224 224 224z" />
						</svg>
					</IconTicksCard>
				</div>
			</div>
			<div className="bg1">
				<div className="df fww container pb5rem">
					<HeadingParagraph wrapperClass="w45 mr2rem" heading="Does your repository deliver everything your investors expect and more?" paragraph="Have you self-diligenced through a business audit in advance of going out for funding? How do you benchmark yourselves? These are all questions that we can help you with. We help your business grow or raise investment or exit, more quickly, at a higher valuation, and with more certainty." />
					<HeadingParagraph wrapperClass="w45 ml2rem" heading="So how do we do it?" paragraph="Our ultra-low-cost SaaS based platform helps you easily use tailored online forms and checklists to systematically help you create an easily accessible central “brain”, a living breathing data repository. Why waste valuable time every day looking for key documents when we can help you centralise your key documents within your existing cloud-based provider. We get you organized!" />
				</div>
			</div>
			<FancyHeadingParagraph
				content={[
					["Helping You Help Yourselves", "You can pull together the repository yourself using our easy-to-use checklists, or we can assist you, or ask your lawyer or accountant to help – they also benefit from your key corporate data being better organised."],
					["Already have a data room?", "We’re data room agnostic. If you already have a data room, such as Seed Legals, or iDeals or DataSite, we help you organise it better to create more value. Or we simply create one within your existing cloud-based solution – Sharepoint/Drive. You tell us"],
					["Be Investment Ready", "We make you investment ready. We work closely with a number of leading VCs, accountants and law firms, who gain confidence if you are working with us. They know their time will not be wasted"],
				]}
			/>
			<Testimonials testimonials={testimonials} />
			<CallToAction heading="Ready to Maximise Your Investability for as Little as &pound; 5 a Month?"/>
		</AnimatedPage>
	);
}
