import React from "react";
import { HashLink } from 'react-router-hash-link';

const style = { lineHeight: '30px', textAlign: "left" }

const ReviewSection = ({ data, refs, isAnimationVisible }) => {
    return (
        <div className="client-review-container bg20 pr" style={{ ...data?.containerStyle }}>
            {/* Page Heading */}
            <div ref={refs?.current?.[2]} className={`df jcc tslp mb2-5rem ${isAnimationVisible[2] ? 'op1 tty0' : 'op0 ttx20'}`}>
                <h1 className="c15 fw500 fs2-125rem" style={{ lineHeight: '55px' }}>
                    {data?.title}
                </h1>
            </div>

            {/* Section for those who have a div with full length of paragrapgh on top */}
            {data?.isExtandable ?
                <div className="bg1 br40px pr pt3rem px3rem mt4rem">
                    <p className="fw400 fs1-25rem c14 " style={{ lineHeight: '36px', textAlign: 'left' }}>
                        {data?.description?.desc1}
                    </p>
                    <p className="mt1rem fw400 fs1-25rem c14 " style={{ lineHeight: '36px', textAlign: 'left' }}>
                        {data?.description?.desc2}
                    </p>
                    <div className="df w100">
                        <div className="w60 mt1rem mb3rem pr3rem">
                            {data?.description?.desc3 &&
                                <p className="mt1rem fw400 fs1-25rem c14 " style={{ lineHeight: '36px', textAlign: 'left' }}>
                                    {data?.description?.desc3}
                                </p>
                            }
                            {data?.description?.desc4 &&
                                <p className="mt1rem fw400 fs1-25rem c14 " style={{ lineHeight: '36px', textAlign: 'left' }}>
                                    {data?.description?.desc4}
                                </p>
                            }
                            {data?.description?.desc5 &&
                                <p className="mt1rem fw400 fs1-25rem c14 " style={{ lineHeight: '36px', textAlign: 'left' }}>
                                    {data?.description?.desc5}
                                </p>
                            }
                            {data?.description?.desc6 &&
                                <p className="mt1rem fw400 fs1-25rem c14 " style={{ lineHeight: '24.88px', textAlign: 'left' }}>
                                    {data?.description?.desc6}
                                </p>
                            }
                            <p className="mt2rem fw500 fs1-25rem c15" style={{ lineHeight: '36px' }}>
                                {data?.description?.clientName}
                            </p>
                            <p className="fw300 fs1rem c14" style={{ lineHeight: '36px' }}>
                                {data?.description?.designation}
                            </p>
                            {data?.['isInvestorLookupBtn'] &&
                                <HashLink to="#investor-lookup" className='registerButtonAnimation mt2rem pt1rem pb1rem pl2rem pr2rem shadow c1 bg4 bg3-hover br35px mt1rem mb1rem mr2-5rem cp fw600 fs0-75rem pr zi0 br10px td250 registerButtonAnimation secend-sec-btn'>
                                    Investor Database Check
                                    <i className="icon">
                                        <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.39612 10.336H15.818L11.43 14.7241C10.1696 15.9845 12.0602 17.875 13.3206 16.6147L17.3169 12.6121L19.9847 9.94019C20.5017 9.42008 20.5017 8.58008 19.9847 8.05996L13.3206 1.38812C13.0671 1.12758 12.7181 0.981397 12.3545 0.984421C11.1542 0.984572 10.5652 2.4465 11.43 3.2788L15.8284 7.66688H1.32711C-0.521408 7.75859 -0.383387 10.4281 1.39612 10.336Z" fill="#FC7785" />
                                        </svg>
                                    </i>
                                </HashLink>
                            }
                        </div>
                        <div className="w40 df jce review-img">
                            <img src={data?.clientImage} style={{ width: '62%', ...data?.clientImageStyle }} />
                        </div>
                    </div>
                    <img src={require('../../../../images/landingpage/blue-circle.png')} className="ar pa" style={{ width: '4%', top: '-15px', right: '-14px' }} />
                </div>
                :
                //Section for those who have text only on left 
                <div className="bg1 br30px pr pt2rem  pr3rem pl3rem mt2rem">
                    <div className="df w100">
                        <div className="w60 mt1-5rem mb3rem pr3rem">
                            <p className="fw400 fs1-25rem c14" style={{ ...style }}>
                                {data?.description?.desc1}
                            </p>
                            {
                                data?.description?.desc2 &&
                                <p className="mt1rem fw400 fs1-25rem c14" style={{ ...style }}>
                                    {data?.description?.desc2}
                                </p>
                            }

                            {data?.description?.desc3 &&
                                <p className="mt1rem fw400 fs1-25rem c14" style={{ ...style }}>
                                    {data?.description?.desc3}
                                </p>
                            }
                            {data?.description?.desc4 &&
                                <p className="mt1rem fw400 fs1-25rem c14" style={{ ...style }}>
                                    {data?.description?.desc4}
                                </p>
                            }
                            <p className="mt2rem fw500 fs1-5rem c15" style={{ lineHeight: '36px' }}>
                                {data?.description?.clientName}
                            </p>
                            <p className="fw300 fs1-25rem c14" style={{ lineHeight: '36px' }}>
                                {data?.description?.designation}
                            </p>
                        </div>
                        <div className="w40 df jce review-img">
                            <img src={data?.clientImage} style={{ ...data?.imageStyle }} />
                        </div>
                    </div>
                    <img src={require('../../../../images/landingpage/blue-circle.png')} className="ar pa" style={{ width: '4%', top: '-15px', right: '-14px' }} />
                </div>
            }
            <img src={require('../../../../images/landingpage/half-curve.png')} className="pa" style={{ width: '7%', top: '-106px', left: '0px' }} />
        </div>
    )
}
export default ReviewSection