import { useDispatch } from "react-redux";

import AnimatedPage from "../../components/AnimatedPage";
import { toggleModal } from "../../reducers/modalReducer";
import HeadingSubheadingContent from "../../components/HeadingSubheadingContent";

export default function Support() {
	const dispatch = useDispatch();

	return (
		<AnimatedPage>
			<div className="container py5rem df fdc">
				<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Support" subheading="Get assistance from our friendly team" />
				<div className="tac df jcc fww">
					<div className="w100 fs1-25rem">
						<span>Email: <a className="c4-hover" href="mailto:support@diligentsia.co.uk">support@diligentsia.co.uk</a></span><br />
						<span>Telephone: <a className="c4-hover" href="tel:+441455271121">+44 (0)1455-271121</a></span><br /><br />
						<span className="w100 fw500 text-gradient fs1-75rem lh2">Found a Bug?</span><br />
						<span className="c4 fw500 fs1-25rem ttu">Please help us by Reporting</span><br />
					</div>
					<button onClick={() => dispatch(toggleModal({ open: 'report-bug' }))} className="w20 pr zi1 mt1rem shadow bg3 bg4-hover c1 td250 br5px py0-5rem px1rem fw600 usn cp ttu bsbb tac mb1rem df jcc aic" >Report Bug</button><br />
					<a href="https://intercom.help/diligentsia-limited/en" target="_blank" className="w100 c4 fw500 fs1-75rem ttu mt2rem tdu">Help Centre</a>

				</div>
			</div>
		</AnimatedPage>
	);
}
