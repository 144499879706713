
import { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';

import AnimatedPage from "../../components/AnimatedPage";
import HeadingSubheadingContent from "../../components/HeadingSubheadingContent";

export default function ESGPack() {
	
	let loggedIn = localStorage.getItem("token") || localStorage.getItem("userId");

	return (
		<AnimatedPage>
			<div className="expansionpack-page py4rem">
				<div className="container df fdc">
					<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Function Expansion Pack – DD" subheading="Referencing Module​" />
				</div>
				<div className="container df">
					<div className="ma lef-img-area">
						<div className='img-box'>
							<img className="h10rem" src={require('../../../images/left-person.png')} alt="" />
						</div>
					</div>
					<div className="ma w100 right-text-area">
						<p>Any company needing to demonstrate that they  are well-prepared for third party referencing in due diligence on their customers, suppliers, partners and shareholders on investment or exit.​ ​</p>

						<div className="number-sec">
							<ul className="bg5 br5px shadow">
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>Number of Sections: </span> 4</li>
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>Number of Questions: </span></li>
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>AI Generated Company Specific Questions included? </span> Yes/No</li>
								<li><i className="icon"><img className="h10rem" src={require('../../../images/left-angle-arrow.png')} alt="" /> </i><span>Benefit: </span> This module automates the process of obtaining third party references from your top (up to 5) customers, suppliers, partners and shareholders, by sending them a quick questionnaire to fill in in relation to their dealings with your business.  This enables you to identify blocking issues early, and once completed you can show at the start of the diligence process to demonstrate your company’s good standing amongst its stakeholders.</li>
							</ul>
						</div>

						<div className="about-img">
							{/* <div className="img-box">
								<img className="br5px" src={require('../../../images/about-img.png')} alt="" />
							</div> */}
							<div className="text-box bg5 br5px shadow">
								<p>“Getting references part way through an investment/sale process is stressful and time-consuming.  Getting ahead of any issues/reluctance saved  us time during our transaction.”  Steven Brough,, CEO​</p>
							</div>
						</div>
					</div>


				</div>
			</div>

			<div className="boost-sec bg-gradient c1">
				<div className="container py5rem">
					<div className="dg gg2rem gtcr3">
						<div className="h100 df fdc jcsb aic">
							<div className="img-box">
								<img className="h10rem" src={require('../../../images/speed-img.png')} alt="" />
							</div>
						</div>
						<div className="h100 df fdc jcsb py4rem">
							<h2 className="fds c1 fs2rem tac">Boost your Diligentsia<br /><span className="c3 fds ">certification score…​</span></h2>
						</div>
						<div className="h100 df fdc jcsb aic py4rem">
							{loggedIn
								? <Link to='/billing/64a5c8a5605162e8bf9ce86a' style={{ padding: '15px', position: 'relative' }} className="br5px shadow cp c1 px1rem my0-5rem fw600 m1rem bg4 bg3-hover td250">
									One off purchase – BUY NOW!
									<div className="tooltip-btn">
										<span>&pound; 500</span>
									</div>
								</Link>
								: <button onClick={() => showMessage("LogIn to - BUY NOW!")} style={{ padding: '15px', position: 'relative' }} className="br5px shadow cp c1 px1rem my0-5rem fw600 m1rem bg4 bg3-hover td250">
									One off purchase – BUY NOW!
									<div className="tooltip-btn">
										<span>&pound; 500</span>
									</div>
								</button>
							}
						</div>
					</div>
				</div>
			</div>

			<div className="benfits-sec py4rem">
				<div className="container df fdc">
					<HeadingSubheadingContent wrapperClass="mb2rem tac" heading="Benefits" />
				</div>
				<div className="container">
					<div className="dg gg2rem gtcr3">
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-1.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Enhanced credibility and trust:</b></p>
								<p>Providing a pre-collected set of business references from key stakeholders can demonstrate the company's strong relationships and proven track record. This helps build credibility and trust with potential investors, increasing the likelihood of securing investment.​</p>
							</div>
						</div>
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-2.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Faster due diligence process:</b></p>
								<p>By proactively gathering and presenting business references, the company can streamline the due diligence process for potential investors. This saves time for both parties and allows for more efficient investment discussions and decision-making.​</p>
							</div>
						</div>
						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-3.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Showcasing success stories:</b></p>
								<p>Business references can highlight the company's successful collaborations, projects, and partnerships, showcasing the company's ability to deliver results and work effectively with diverse stakeholders. This can make the company more attractive to investors seeking growth opportunities.​</p>
							</div>
						</div>

						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-4.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Positive word-of-mouth marketing:</b></p>
								<p>References from satisfied customers, suppliers, partners, and shareholders can serve as a powerful form of word-of-mouth marketing, potentially attracting additional investors and business opportunities. These positive testimonials can help reinforce the company's reputation and expand its network.</p>
							</div>
						</div>

						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-5.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Demonstrating adaptability and resilience:</b></p>
								<p>Business references can provide insight into how the company has navigated challenges and adapted to changing market conditions. This information can be valuable to potential investors, who may be more inclined to invest in a company that has proven its ability to overcome obstacles and adapt to change.​</p>
							</div>
						</div>

						<div className="df tac  fdc jcsb aic bg5 br5px shadow p2rem">
							<div className="img-box mb2rem">
								<img className="h8rem" src={require('../../../images/benifts-6.png')} alt="" />
							</div>
							<div className="text-box">
								<p><b>Strengthened negotiation position:</b></p>
								<p>By presenting a strong set of business references, the company can bolster its negotiating position during investment discussions. This can lead to more favorable investment terms, valuation, and potential partnerships, as investors will have greater confidence in the company's ability to deliver on its promises and drive growth.</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="modal-sec py4rem">
				<div className="container df fdc">
					<div className="heading">
						<img className="h5rem" src={require('../../../images/modle-title.png')} alt="" />
					</div>
				</div>
				<div className="container">
					<div className="dg gg2rem gtcr2">
						<div className="df tac  fdc" style={{ padding: '0 25px' }}>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Share Capital and Authority</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Business Plan</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Accounts</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Management Accounts</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Properties</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Assets,Debt & Stock</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Employment and Consultancy Arrangements</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Records & Registers</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Group Structure</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Borrowings & Facilities</p>
						</div>

						<div className="df tac  fdc" style={{ padding: '0 25px' }}>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Information</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Accounts</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Events Since The Accounts Date</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Litigation</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Intellectual Property</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Contracts with Connected Persons</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Statutory & Legal Requirements</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Insurance</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Agreements & Captial Commitments</p>
							<p className="w100 jcsb bg5 br5px shadow px1rem py0-5rem py0-5rem mb0-5rem">Social Obligations</p>
						</div>
					</div>
				</div>
			</div>
		</AnimatedPage>
	);
};
