import AnimatedPage from "../components/AnimatedPage";
import CallToAction from "../components/CallToAction";
import Hero from "../components/Hero";
import FancyHeadingParagraph from "../components/FancyHeadingParagraph";
import HeadingParagraph from "../components/HeadingParagraph";
import HeadingSubheadingContent from "../components/HeadingSubheadingContent";
import TakeTheTest from "../components/TakeTheTest";
import IconTicksCard from "../components/IconTicksCard";
import Testimonials from "../components/Testimonials";

export default function VentureCapitalFirms() {
	const testimonials = [
		{
			title: "The Investor",
			text: "I can see the benefit of setting up a standard data repository structure as part of an immediate post-investment 100-day plan for an investee company, to facilitate subsequent cross-portfolio monitoring.",
			author: "Richard Whitwell",
			company: "Private Equity Investor",
		},
		{
			title: "The Growth Manager",
			text: "Only when you work as part of a Senior Management Team around a central data repository, kept up to date, do you really realise the operational benefits of centralising key corporate data into one easy to access location.",
			author: "Katy Davidson",
			company: "CFO Wearable Technologies Limited",
		},
		{
			title: "[todo]",
			text: "As a corporate financier, when seeing a company with a comprehensive data room that is clearly up to date always gives us early confidence as a professional advisors that that the management are going to work with us in a time-efficient manner – saving everyone cost in the long run.",
			author: "[todo]",
			company: "[todo]",
		},
		{
			title: "The Professional",
			text: "Getting a client into a position where they can provide a draft disclosure letter schedule at the commencement of a transaction will reduce costs, speed up the transaction, and lessen the chance of its falling over right at the end.",
			author: "Rik Pancholi",
			company: "Director, Pattersons Commercial Law",
		},
	];
	return (
		<AnimatedPage>
			{/* <Hero h1Class="fs2-5rem fs2-5rem-sm" h1={["Streamlined Deal Process", "More Certain Outcomes,", "Higher Exit Valuations."]} buttonText="FREE Portfolio Monitoring and Benchmarking Dashboard" buttonId="scrollDown" videoSrc="diligentsia_for_venture_capital_firms.mp4" videoThumbnail="diligentsia-for-venture-capital-firms.jpg" /> */}
			<Hero h1Class="fs2-5rem" h1={["Streamlined Deal Process", "More Certain Outcomes,", "Higher Exit Valuations."]} buttonText="Register as Investor" id='openRegisterPopup' dataShowPopup="registerInvestor" buttonId="scrollDown" videoSrc="diligentsia_for_venture_capital_firms.mp4" videoThumbnail="diligentsia-for-venture-capital-firms.jpg" />

			<div className="bg1">
				<div className="container dg gtcr1-lg gg2rem py5rem">
					<HeadingSubheadingContent heading="Investment Phase" subheading="Meet Diligentsia" paragraph="Do you waste time seeing a company’s impressive deck and then find that the company is not investment ready? Warranty disclosure schedules, Director’s Questionnaires, up to date key corporate data - legal, IP, key contracts, HR, constitutional - all in a single intuitive and easy to access repository ready for your review? Diligentsia makes this happen." />
				</div>
			</div>
			<div className="bg1">
				<div className="dg gtcr2-lg gg2rem container pb5rem">
					<HeadingParagraph heading="Growth Phase" paragraph="Do you look to add value during first 100 days post investment? Why not let us help them put in place a standard structure living breathing repository across your investee companies? Would like time-saving tools to assist in investment monitoring? Would you like to benchmark companies across your portfolio?" />
					<HeadingParagraph heading="Exit Phase." paragraph="Your portfolio company, well organised in advance, with issues resolved, will exit at a higher valuation, more quickly, and with more certainty. Your own checklists? Not a problem. We can automate them." />
				</div>
			</div>
			<FancyHeadingParagraph
				content={[
					["Helping You Help Your Portfolio", "Our ultra-low-cost SaaS based platform has easy to use  tailored online forms and checklists to systematically help your portfolio  companies create an easily accessible central “brain”, a living breathing data repository. Why waste everyone’s valuable time every day looking for key documents when we can help you centralise your clients’ key documents.  We get your companies organized!"],
					["We are Data Room Agnostic", "Data room already? Seed Legals, or iDeals or DataSite? We organise it better to create more value.  No data room?  We simply create one within their existing cloud-based solution – Sharepoint/Drive/Dropbox.  You tell us."],
				]}
			/>
			<Testimonials testimonials={testimonials} />
			<CallToAction heading="Ready to Maximise Your Investability Portfolio Exit Valuation as Little as &pound; 5 a Month?"/>
		</AnimatedPage>
	);
}
