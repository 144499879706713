import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleModal } from '../../../reducers/modalReducer';


const KeyFeaturesModules = ({ data, refs, isAnimationVisible, subTitle, len }) => {
    const dispatch = useDispatch();
    const modal = useSelector(state => state.modal);

    const [kpiAnimationBox, setKPIAnimationBox] = useState(false);
    const [businessAnimationBox, setBusinessAnimationBox] = useState(false);

    const [selectedFeature, setSelectedFeature] = useState({});
    const [proPLusSelected, setProPLusSelected] = useState({});
    const [appStoreGrowthSelected, setAppStoreGrowthSelected] = useState({});
    const [coreModulesProSelected, setCoreModulesProSelected] = useState({});
    const [coreModulesFreeSelected, setCoreModulesFreeSelected] = useState({});
    const [coreModulesLiteSelected, setCoreModulesLiteSelected] = useState({});
    const [coreModulesPortfolioSelected, setCoreModulesPortfolioSelected] = useState({});
    const [appStoreInvestmentSelected, setAppStoreInvestmentSelected] = useState({});

    const handleCoreModulesPortfolioMore = async (item) => {
        setSelectedFeature(item);
        setCoreModulesFreeSelected(item);
        await dispatch(toggleModal({ open: 'openSeeMore' }));
    };

    const handleCoreModuleFreeSeeMore = async (item, scroll) => {
        if (scroll) {
            document.getElementById('not-sure-yet').scrollIntoView({ behavior: 'smooth' });
            dispatch(toggleModal({ open: '' }));
        }
        else {
            setSelectedFeature(item);
            setCoreModulesFreeSelected(item);
            await dispatch(toggleModal({ open: 'openSeeMore' }));
        }
    };

    const handleCoreModuleLiteSeeMore = async (item) => {
        setSelectedFeature(item);
        setCoreModulesLiteSelected(item);
        await dispatch(toggleModal({ open: 'openSeeMore' }));
    };

    const handleCoreModuleProSeeMore = async (item) => {
        setSelectedFeature(item);
        setCoreModulesProSelected(item);
        await dispatch(toggleModal({ open: 'openSeeMore' }));
    };

    const handleAppStoreInvestmentSeeMore = async (item) => {
        setSelectedFeature(item);
        setAppStoreInvestmentSelected(item);
        await dispatch(toggleModal({ open: 'openSeeMore' }));
    };

    const handleAppStoreGrowthSeeMore = async (item) => {
        setSelectedFeature(item);
        setAppStoreGrowthSelected(item);
        await dispatch(toggleModal({ open: 'openSeeMore' }));
    };

    const handleProPlusSeeMore = async (item) => {
        setSelectedFeature(item);
        setProPLusSelected(item);
        await dispatch(toggleModal({ open: 'openSeeMore' }));
    };

    const onModalClose = () => {
        dispatch(toggleModal({ open: '' }));
        setSelectedFeature({});
        setProPLusSelected({});
        setCoreModulesProSelected({});
        setAppStoreGrowthSelected({});
        setCoreModulesFreeSelected({});
        setCoreModulesLiteSelected({});
        setAppStoreInvestmentSelected({});
        setCoreModulesPortfolioSelected({});
    };

    const mouseOverCoreModulesPro = (item) => {
        setCoreModulesProSelected(item);

        // if (item['title'] == "20-Section Business Audit") {
        //     if (kpiAnimationBox) setKPIAnimationBox(false);
        //     setBusinessAnimationBox(true);

        // }
        // if (item['title'] == "Key Performance Indicators (KPI) Report") {
        //     if (businessAnimationBox) setBusinessAnimationBox(false);
        //     setKPIAnimationBox(true);
        // }
    };

    const mouseOutCoreModulesPro = (item) => {
        if (modal['open'] == '') setCoreModulesProSelected({});

        // if (item['title'] == "20-Section Business Audit") setBusinessAnimationBox(false);
        // if (item['title'] == "Key Performance Indicators (KPI) Report") setKPIAnimationBox(false);
    };

    return (
        <>
            <div id="homeMainBanner" className="bg1 py4rem pr">
                <div ref={refs?.current?.[5]} className={`tslp mt4rem ${isAnimationVisible[5] ? 'op1 tty0' : 'op0 ttx20'}`}>
                    <div className='fw500 df jcc fs2-5rem mb0-5rem'>
                        <span className='c4'>Key modules</span><span className='c15 ml0-5rem'>and features</span>
                    </div>
                    <h1 className='fw400 fs1rem df jcc c15'>
                        {subTitle ? subTitle : 'Reusable diligence - building your businesses stronger'}
                    </h1>
                    <img src={require('../../../../images/landingpage/blue-circle-cut.png')} className="pa blue-cricle-slider" style={{ width: '3%', top: '84px', right: '0' }} />
                </div>
                <div className="keyfeautre-container py3rem w100 df fww aic hero-saf pr">
                    {data?.['titleOne'] &&
                        <div className="for-yoy-box p1rem pl3rem pr3rem br10px c1 df jcc mla mra pr zi2">{data['titleOne']}</div>
                    }
                    {/* Portdolio Pro */}
                    {data?.['portfolioModules'] &&
                        <div className="bss p2rem br15px w100 keyfeature-box keyfeature-box-portfolio df fww mb3rem pr">
                            <h3 className="fs1-75rem c7 w100 mb2rem ml1rem">FREE</h3>
                            <div className="keyfeature-box-portfolio-innner pr">
                                <div className="keyfeature-box-portfolio-bg w100 df fww pr">
                                    <div className="w30 df fdc zi9 bsbb pr pt8rem pl4rem">
                                        <p className="fs1-5rem c15 fw500">Diligentsia<br />Porfolio Pro<br />FREE <br /></p>
                                        {data?.['subTiltle'] && <p className="fs1rem c12 pr3rem">{data?.['subTiltle']}</p>}
                                    </div>
                                    <div className="w70 df fww pt1rem">
                                        {data['portfolioModules'].map(item => {
                                            return (
                                                <div className="cp w25 keyfeature-wihte-item bg1 new-shadow br15px p2rem zi9 pr bsbb" style={{ width: item?.['isTwoMinuteTestBtn'] && '35%' }}
                                                    onMouseOver={() => setCoreModulesPortfolioSelected(item)} onMouseOut={() => (modal['open'] == '') && setCoreModulesPortfolioSelected({})} onClick={() => handleCoreModulesPortfolioMore(item)}>
                                                    {item?.['isStar'] &&
                                                        <img src={require('../../../../images/landingpage/gold-star-icon.png')} className="pa" alt="star-img" style={{ width: item?.['isTwoMinuteTestBtn'] ? '5%' : '8%', right: '8%' }} />
                                                    }
                                                    <div className='df aic features-icon'>
                                                        {item?.icon}
                                                    </div>
                                                    <div className='df fw500 fs1-25rem c15'>
                                                        {item?.title}
                                                    </div>
                                                    <div className="bottom-area"></div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            {Object.keys(coreModulesPortfolioSelected).length > 0 &&
                                <div className="seemore-box zi9">
                                    <div className="seemore-inner zi9">
                                        <div className='df w100'>
                                            <div className="pl2rem mt1rem" style={{ width: "50%", height: '100%' }}>
                                                <div className='df w100 mb1rem'>
                                                    <div className="w10 df aic">{coreModulesPortfolioSelected?.seeMoreSection?.icon}</div>
                                                    <div className="w90 fw500 c15 pl1rem df aic" style={{ fontSize: '26px', lineHeight: '37.32px' }}>{coreModulesPortfolioSelected?.seeMoreSection?.title}</div>
                                                </div>
                                                {/* custom-list */}
                                                <ul class=" mt0-5rem" style={{ paddingLeft: '22px' }}>
                                                    {coreModulesPortfolioSelected?.seeMoreSection?.points?.map((objpoints, index) => {
                                                        return (
                                                            <li style={{ lineHeight: '25px', paddingBottom: "3px", paddingTop: "3px", paddingLeft: "20px", borderLeft: '2px solid #D3D9FC', ...coreModulesPortfolioSelected?.seeMoreSection?.liStyle }} className="df fdc fs1rem c14 fs1rem fw400" key={`${objpoints}-${index}`}>
                                                                <div className="pr">
                                                                    <div className="circle"></div>
                                                                    {objpoints}
                                                                </div>
                                                                <div>{coreModulesPortfolioSelected?.seeMoreSection?.subMenu && coreModulesPortfolioSelected?.seeMoreSection?.subMenu?.index === index &&
                                                                    coreModulesPortfolioSelected?.seeMoreSection?.subMenu?.points?.map(str => {
                                                                        return (
                                                                            <div className="fs1rem c14 fs1rem fw400 df aic pl2rem" style={{ lineHeight: '30px' }}>{str}</div>
                                                                        )
                                                                    })
                                                                }
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul >
                                                {coreModulesPortfolioSelected?.seeMoreSection?.notePoint &&
                                                    <div className="mt1rem" style={{ border: '1px solid gray', padding: "10px", borderRadius: '10px' }}>{coreModulesPortfolioSelected?.seeMoreSection?.notePoint}</div>
                                                }
                                            </div>
                                            <div className="df aic jce" style={{ width: '45%' }}>
                                                <img src={coreModulesPortfolioSelected?.seeMoreSection?.image} className="w70" style={{ objectFit: 'fill' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {/* Free */}
                    {data?.['freeModules'] &&
                        <div className="bss p2rem br25px w100 keyfeature-box keyfeature-box-free df fww mb3rem pr">
                            <h3 className="fs1-75rem c7 w100 mb2rem ml1rem">FREE</h3>
                            {data?.['freeModules'].map(item => {
                                return (
                                    <div className="cp w25 keyfeature-wihte-item bg1 new-shadow br15px p2rem pr" style={{ width: item?.['isTwoMinuteTestBtn'] && '35%' }}
                                        onMouseOver={() => setCoreModulesFreeSelected(item)} onMouseOut={() => { modal['open'] == '' && setCoreModulesFreeSelected({}) }}>
                                        {item?.['isStar'] &&
                                            <img src={require('../../../../images/landingpage/gold-star-icon.png')} className="pa" alt="star-img" style={{ width: item?.['isTwoMinuteTestBtn'] ? '5%' : '8%', right: '8%' }} />
                                        }
                                        <div className='df aic features-icon' onClick={() => handleCoreModuleFreeSeeMore(item, false)}>
                                            {item?.icon}
                                        </div>
                                        <div className='df fw500 fs1-25rem c15'>
                                            <span onClick={() => handleCoreModuleFreeSeeMore(item, false)}>{item?.title}</span>
                                            {item?.['isTwoMinuteTestBtn'] &&
                                                <Link onClick={() => handleCoreModuleFreeSeeMore(item, true)}
                                                    className={`w250px fs0-75rem bg1 br35px pr cp c4 arrowBtnAnimation df jcc aic py0-5rem`} style={{ border: '1px solid #FC7785' }}>
                                                    2-Minute Test
                                                    <svg width='13' height='13' viewBox='0 0 21 18' fill='none' xmlns='http:www.w3.org/2000/svg' style={{ marginLeft: '10px', stroke: '#FC7785' }}>
                                                        <path d='M1.39612 10.331H15.818L11.43 14.7191C10.1696 15.9795 12.0602 17.87 13.3206 16.6096L17.3169 12.6071L19.9847 9.93519C20.5017 9.41507 20.5017 8.57507 19.9847 8.05496L13.3206 1.38311C13.0671 1.12258 12.7181 0.976392 12.3545 0.979416C11.1542 0.979567 10.5652 2.4415 11.43 3.2738L15.8284 7.66187H1.32711C-0.521408 7.75359 -0.383387 10.4231 1.39612 10.331Z' fill='#FC7785' />
                                                    </svg>
                                                </Link>
                                            }
                                        </div>
                                        <div className="bottom-area" onClick={() => handleCoreModuleFreeSeeMore(item, false)}></div>
                                    </div>
                                )
                            })}
                            {Object.keys(coreModulesFreeSelected).length > 0 &&
                                <div className="seemore-box">
                                    <div className="seemore-inner">
                                        <div className='df w100'>
                                            <div className="pl2rem mt1rem" style={{ width: "50%", height: '100%' }}>
                                                <div className='df w100 mb1rem'>
                                                    <div className="w10 df aic">{coreModulesFreeSelected?.seeMoreSection?.icon}</div>
                                                    <div className="w90 fw500 c15 pl1rem df aic" style={{ fontSize: '26px', lineHeight: '37.32px' }}>{coreModulesFreeSelected?.seeMoreSection?.title}</div>
                                                </div>
                                                {/* custom-list */}
                                                <ul class=" mt0-5rem" style={{ paddingLeft: '22px' }}>
                                                    {coreModulesFreeSelected?.seeMoreSection?.points?.map((objpoints, index, arrr) => {
                                                        return (
                                                            <li style={{ lineHeight: '25px', paddingBottom: "3px", paddingTop: "3px", paddingLeft: "20px", borderLeft: '2px solid #D3D9FC', ...coreModulesFreeSelected?.seeMoreSection?.liStyle }} className="df fdc fs1rem c14 fs1rem fw400" key={`${objpoints}-${index}`}>
                                                                <div className="pr">
                                                                    <div className="circle"></div>
                                                                    {objpoints}
                                                                </div>
                                                                <div>{coreModulesFreeSelected?.seeMoreSection?.subMenu && coreModulesFreeSelected?.seeMoreSection?.subMenu?.index === index &&
                                                                    coreModulesFreeSelected?.seeMoreSection?.subMenu?.points?.map(str => {
                                                                        return (
                                                                            <div className="fs1rem c14 fs1rem fw400 df aic pl2rem" style={{ lineHeight: '30px' }}>{str}</div>
                                                                        )
                                                                    })
                                                                }
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul >
                                                {coreModulesFreeSelected?.seeMoreSection?.notePoint &&
                                                    <div className="mt1rem" style={{ border: '1px solid gray', padding: "10px", borderRadius: '10px' }}>{coreModulesFreeSelected?.seeMoreSection?.notePoint}</div>
                                                }
                                            </div>
                                            <div className="df aic jce" style={{ width: '45%' }}>
                                                <img src={coreModulesFreeSelected?.seeMoreSection?.image} className="w70" style={{ objectFit: 'fill' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {/* Core Modules Lite */}
                    {data?.['coreModulesLite'] &&
                        <div className="bg20 bss bo5 bsbb p2rem br25px w100 keyfeature-box keyfeature-box-core df fww mb3rem pr ">
                            <h3 className="fs1-25rem c15 w100 mb2rem">Core Modules - <span className="c7">Lite</span></h3>
                            {data?.['coreModulesLite'].map(item => {
                                return (
                                    <div className="cp w25 keyfeature-wihte-item bg1 new-shadow br15px p2rem pr"
                                        onMouseOver={() => setCoreModulesLiteSelected(item)} onMouseOut={() => { modal['open'] == '' && setCoreModulesLiteSelected({}) }} onClick={() => handleCoreModuleLiteSeeMore(item)}>
                                        {item['isStar'] &&
                                            <img src={require('../../../../images/landingpage/gold-star-icon.png')} className="pa" alt="star-img" style={{ width: '8%', right: '8%' }} />
                                        }
                                        <div className='df aic features-icon'>
                                            {item?.icon}
                                        </div>
                                        <div className='fw500 fs1-25rem c15'>{item?.title}</div>
                                        <div className="bottom-area"></div>

                                    </div>
                                )
                            })}
                            {Object.keys(coreModulesLiteSelected).length > 0 &&
                                <div className="seemore-box">
                                    <div className="seemore-inner">
                                        <div className='df w100'>
                                            <div className="pl3rem" style={{ width: "55%", height: '100%' }}>
                                                <div className='df w100 mb1rem'>
                                                    <div className="w10 df aic">{coreModulesLiteSelected?.seeMoreSection?.icon}</div>
                                                    <div className="w90 fw500 c15 pl1rem df aic" style={{ fontSize: '26px', lineHeight: '37.32px' }}>{coreModulesLiteSelected?.seeMoreSection?.title}</div>
                                                </div>
                                                {/* custom-list */}
                                                <ul class=" mt0-5rem" style={{ paddingLeft: '22px' }}>
                                                    {coreModulesLiteSelected?.seeMoreSection?.points?.map((objpoints, index, arrr) => {
                                                        return (
                                                            <li style={{ lineHeight: '25px', paddingBottom: "3px", paddingTop: "3px", paddingLeft: "20px", borderLeft: '2px solid #D3D9FC', ...coreModulesLiteSelected?.seeMoreSection?.liStyle }} className="df fdc fs1rem c14 fs1rem fw400" key={`${objpoints}-${index}`}>
                                                                <div className="pr">
                                                                    <div className="circle"></div>
                                                                    {objpoints}
                                                                </div>
                                                                <div>{coreModulesLiteSelected?.seeMoreSection?.subMenu && coreModulesLiteSelected?.seeMoreSection?.subMenu?.index === index &&
                                                                    coreModulesLiteSelected?.seeMoreSection?.subMenu?.points?.map(str => {
                                                                        return (
                                                                            <div className="fs1rem c14 fs1rem fw400 df aic pl2rem" style={{ lineHeight: '30px' }}>{str}</div>
                                                                        )
                                                                    })
                                                                }
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul >
                                                {coreModulesLiteSelected?.seeMoreSection?.notePoint &&
                                                    <div className="mt1rem" style={{ border: '1px solid gray', padding: "10px", borderRadius: '10px' }}>{coreModulesLiteSelected?.seeMoreSection?.notePoint}</div>
                                                }
                                            </div>
                                            <div className="df aic jce" style={{ width: '45%' }}>
                                                <img src={coreModulesLiteSelected?.seeMoreSection?.image} className="w70" style={{ objectFit: 'fill' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {data?.['titleTwo'] &&
                        <div className="for-yoy-box p1rem pl3rem pr3rem br10px c1 df jcc mla mra pr zi2">{data['titleTwo']}</div>
                    }

                    {/* Core Pro & AppStore Modules */}
                    <div className="bg20 bss bo3 bw2px br50px p2rem bsbb">
                        {/* Core Modules Pro */}
                        {data?.['coreModulesPro'] &&
                            <div className="bg20 bss bo5 bsbb p2rem br25px w100 keyfeature-box keyfeature-box-core-pro df fww mb2rem pr ">
                                <h3 className="fs1-25rem c15 w100 mb2rem">Core Modules - <span className="c7">PRO</span></h3>
                                {data?.['coreModulesPro'].map(item => {
                                    return (
                                        <div className="cp w25 keyfeature-wihte-item bg1 new-shadow br15px p2rem pr"
                                            onMouseOver={() => mouseOverCoreModulesPro(item)} onMouseOut={() => mouseOutCoreModulesPro(item)} onClick={() => handleCoreModuleProSeeMore(item)}>
                                            {item['isStar'] &&
                                                <img src={require('../../../../images/landingpage/gold-star-icon.png')} className="pa" alt="star-img" style={{ width: '8%', right: '8%' }} />
                                            }
                                            {item['isCertificate'] &&
                                                <img src={require('../../../../images/landingpage/key-feature-certificates.png')} className="pa" alt="star-img" style={{ width: '50%', right: '8%' }} />
                                            }
                                            <div className='df aic features-icon'>
                                                {item?.icon}
                                            </div>
                                            <div className={`fw500 fs1-25rem c15 ${item?.['isCertificate'] && ' mt1rem'}`}>{item?.title}</div>
                                            <div className="bottom-area"></div>

                                        </div>
                                    )
                                })}
                                {Object.keys(coreModulesProSelected).length > 0 &&
                                    <div className="seemore-box">
                                        <div className="seemore-inner">
                                            <div className='df w100'>
                                                <div className="pl2rem mt1rem" style={{ width: "50%", height: '100%' }}>
                                                    <div className='df w100 mb1rem'>
                                                        <div className="w10 df aic">{coreModulesProSelected?.seeMoreSection?.icon}</div>
                                                        <div className="w90 fw500 c15 pl1rem df aic" style={{ fontSize: '26px', lineHeight: '37.32px' }}>{coreModulesProSelected?.seeMoreSection?.title}</div>
                                                    </div>
                                                    {/* custom-list */}
                                                    <ul class=" mt0-5rem" style={{ paddingLeft: '22px' }}>
                                                        {coreModulesProSelected?.seeMoreSection?.points?.map((objpoints, index, arrr) => {
                                                            return (
                                                                <li style={{ lineHeight: '25px', paddingBottom: "3px", paddingTop: "3px", paddingLeft: "20px", borderLeft: '2px solid #D3D9FC', ...coreModulesProSelected?.seeMoreSection?.liStyle }} className="df fdc fs1rem c14 fs1rem fw400" key={`${objpoints}-${index}`}>
                                                                    <div className="pr">
                                                                        <div className="circle"></div>
                                                                        {objpoints}
                                                                    </div>
                                                                    <div>{coreModulesProSelected?.seeMoreSection?.subMenu && coreModulesProSelected?.seeMoreSection?.subMenu?.index === index &&
                                                                        coreModulesProSelected?.seeMoreSection?.subMenu?.points?.map(str => {
                                                                            return (
                                                                                <div className="fs1rem c14 fs1rem fw400 df aic pl2rem" style={{ lineHeight: '30px' }}>{str}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul >
                                                    {coreModulesProSelected?.seeMoreSection?.notePoint &&
                                                        <div className="mt1rem" style={{ border: '1px solid gray', padding: "10px", borderRadius: '10px' }}>{coreModulesProSelected?.seeMoreSection?.notePoint}</div>
                                                    }
                                                </div>
                                                <div className="df aic jce" style={{ width: '45%' }}>
                                                    <img src={coreModulesProSelected?.seeMoreSection?.image} className="w70" style={{ objectFit: 'fill' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        {/* AppStore Modules */}
                        {(data?.['appStoreInvestment'] || data?.['appStoreGrowth']) &&
                            <div className="bg20 bss bsbb bo5 p2rem br25px w100 keyfeature-box keyfeature-box-core df fww keyfeature-box-two keyfeature-box-appstore pr">
                                <h3 className="fs1-25rem c15 w100">Diligentsia <span className="c4">AppStore</span></h3>
                                <p className="fs0-75rem mb1-5rem w100 c2 mt0-5rem">In-platform Expansion Pack purchases - requires Pro subscription</p>
                                {data?.['appStoreInvestment'] && <>
                                    <p className="fs1rem mb1rem w100 c15 fw600">Investment/Exit Ready</p>
                                    {data?.['appStoreInvestment'].map(item => {
                                        return (
                                            <div className="cp w25 keyfeature-wihte-item bg1 new-shadow br15px p2rem pr"
                                                onMouseOver={() => setAppStoreInvestmentSelected(item)} onMouseOut={() => { modal['open'] == '' && setAppStoreInvestmentSelected({}) }} onClick={() => handleAppStoreInvestmentSeeMore(item)}>
                                                {item['isStar'] &&
                                                    <img src={require('../../../../images/landingpage/gold-star-icon.png')} className="pa" alt="star-img" style={{ width: '8%', right: '8%' }} />
                                                }
                                                {item['isGoldCertificate'] &&
                                                    <img src={require('../../../../images/landingpage/key-feature-gold-certificate.png')} className="pa" alt="star-img" style={{ width: '20%', right: '8%' }} />
                                                }
                                                <div className='df aic features-icon'>
                                                    {item?.icon}
                                                </div>
                                                <div className='fw500 fs1-25rem c15'>{item?.title}</div>
                                                <div className="bottom-area"></div>

                                            </div>
                                        )
                                    })}
                                    {Object.keys(appStoreInvestmentSelected).length > 0 &&
                                        <div className={`seemore-box mb2rem pr ${appStoreInvestmentSelected['title'] == 'Diligentsia Pro PLUS' ? 'seemore-box-pro' : ''} `}>
                                            <div className="seemore-inner">
                                                <div className='df w100'>
                                                    <div className="pl2rem mt1rem" style={{ width: "50%", height: '100%' }}>
                                                        <div className='df w100 mb1rem'>
                                                            <div className="w10 df aic">{appStoreInvestmentSelected?.seeMoreSection?.icon}</div>
                                                            <div className="w90 fw500 c15 pl1rem df aic" style={{ fontSize: '26px', lineHeight: '37.32px' }}>{appStoreInvestmentSelected?.seeMoreSection?.title}</div>
                                                        </div>
                                                        {/* custom-list */}
                                                        <ul class=" mt0-5rem" style={{ paddingLeft: '22px' }}>
                                                            {appStoreInvestmentSelected?.seeMoreSection?.points?.map((objpoints, index, arrr) => {
                                                                return (
                                                                    <li style={{ lineHeight: '25px', paddingBottom: "3px", paddingTop: "3px", paddingLeft: "20px", borderLeft: '2px solid #D3D9FC', ...appStoreInvestmentSelected?.seeMoreSection?.liStyle }} className="df fdc fs1rem c14 fs1rem fw400" key={`${objpoints}-${index}`}>
                                                                        <div className="pr">
                                                                            <div className="circle"></div>
                                                                            {objpoints}
                                                                        </div>
                                                                        <div>{appStoreInvestmentSelected?.seeMoreSection?.subMenu && appStoreInvestmentSelected?.seeMoreSection?.subMenu?.index === index &&
                                                                            appStoreInvestmentSelected?.seeMoreSection?.subMenu?.points?.map((str, i, arr) => {
                                                                                return (
                                                                                    <div className="fs1rem c14 fs1rem fw400 df aic pl2rem" style={{ lineHeight: '30px' }}>{str}</div>
                                                                                )
                                                                            })
                                                                        }
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul >
                                                        {appStoreInvestmentSelected?.seeMoreSection?.notePoint &&
                                                            <div className="mt1rem" style={{ border: '1px solid gray', padding: "10px", borderRadius: '10px' }}>{appStoreInvestmentSelected?.seeMoreSection?.notePoint}</div>
                                                        }
                                                    </div>
                                                    <div className="df aic jce" style={{ width: '45%' }}>
                                                        <img src={appStoreInvestmentSelected?.seeMoreSection?.image} className="w70" style={{ objectFit: 'fill' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>}
                                {data?.['appStoreGrowth'] &&
                                    <div className="df fww pr w100 keyfeature-box-three">
                                        <p className="fs1rem mb1rem w100 c15 fw600">Growth Ready</p>
                                        {data?.['appStoreGrowth'].map(item => {
                                            return (
                                                <div className="cp w25 keyfeature-wihte-item bg1 new-shadow br15px p2rem pr"
                                                    onMouseOver={() => setAppStoreGrowthSelected(item)} onMouseOut={() => { modal['open'] == '' && setAppStoreGrowthSelected({}) }} onClick={() => handleAppStoreGrowthSeeMore(item)}>
                                                    {item['isStar'] &&
                                                        <img src={require('../../../../images/landingpage/gold-star-icon.png')} className="pa" alt="star-img" style={{ width: '8%', right: '8%' }} />
                                                    }
                                                    <div className='df aic features-icon'>
                                                        {item?.icon}
                                                    </div>
                                                    <div className='fw500 fs1-25rem c15'>{item?.title}</div>
                                                    <div className="bottom-area"></div>

                                                </div>
                                            )
                                        })}
                                        {Object.keys(appStoreGrowthSelected).length > 0 &&
                                            <div className="seemore-box">
                                                <div className="seemore-inner">
                                                    <div className='df w100'>
                                                        <div className="pl2rem mt1rem" style={{ width: "50%", height: '100%' }}>
                                                            <div className='df w100 mb1rem'>
                                                                <div className="w10 df aic">{appStoreGrowthSelected?.seeMoreSection?.icon}</div>
                                                                <div className="w90 fw500 c15 pl1rem df aic" style={{ fontSize: '26px', lineHeight: '37.32px' }}>{appStoreGrowthSelected?.seeMoreSection?.title}</div>
                                                            </div>
                                                            {/* custom-list */}
                                                            <ul class="mt0-5rem" style={{ paddingLeft: '22px' }}>
                                                                {appStoreGrowthSelected?.seeMoreSection?.points?.map((objpoints, index, arrr) => {
                                                                    return (
                                                                        <li style={{ lineHeight: '25px', paddingBottom: "3px", paddingTop: "3px", paddingLeft: "20px", borderLeft: '2px solid #D3D9FC', ...appStoreGrowthSelected?.seeMoreSection?.liStyle }} className="df fdc fs1rem c14 fs1rem fw400" key={`${objpoints}-${index}`}>
                                                                            <div className="pr">
                                                                                <div className="circle"></div>
                                                                                {objpoints}
                                                                            </div>
                                                                            <div>{appStoreGrowthSelected?.seeMoreSection?.subMenu && appStoreGrowthSelected?.seeMoreSection?.subMenu?.index === index &&
                                                                                appStoreGrowthSelected?.seeMoreSection?.subMenu?.points?.map((str, i, arr) => {
                                                                                    return (
                                                                                        <div className="fs1rem c14 fs1rem fw400 df aic pl2rem" style={{ lineHeight: '30px' }}>{str}</div>
                                                                                    )
                                                                                })
                                                                            }
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul >
                                                            {appStoreGrowthSelected?.seeMoreSection?.notePoint &&
                                                                <div className="mt1rem" style={{ border: '1px solid gray', padding: "10px", borderRadius: '10px' }}>{appStoreGrowthSelected?.seeMoreSection?.notePoint}</div>
                                                            }
                                                        </div>
                                                        <div className="df aic jce" style={{ width: '45%' }}>
                                                            <img src={appStoreGrowthSelected?.seeMoreSection?.image} className="w70" style={{ objectFit: 'fill' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }

                        {/* PRO PLUS */}
                        {data?.['proPlus'] && <>
                            <div className="bsbb pt2rem pl2rem w100 keyfeature-box df fww keyfeature-box-proplus pr">
                                {data?.['proPlus'].map(item => {
                                    return (
                                        <div className="cp w20 keyfeature-wihte-item bg1 new-shadow br15px px1rem py2-5rem pr bgyellow" style={{ background: '#fffbd3', border: '1px solid #f9e84b' }}
                                            onMouseOver={() => setProPLusSelected(item)} onMouseOut={() => { modal['open'] == '' && setProPLusSelected({}) }} onClick={() => handleProPlusSeeMore(item)}>
                                            {item?.['isStar'] &&
                                                <img src={require('../../../../images/landingpage/gold-star-icon.png')} className="pa" alt="star-img" style={{ width: item?.['isTwoMinuteTestBtn'] ? '5%' : '8%', right: '8%' }} />
                                            }
                                            <div className='df aic features-icon'>
                                                {item?.icon}
                                            </div>
                                            <div className='df fw500 fs1-25rem c15'>{item?.title}</div>
                                            <div className="bottom-area"></div>
                                        </div>
                                    )
                                })}
                                <div className="w20"></div>
                            </div>
                            {Object.keys(proPLusSelected).length > 0 &&
                                <div className="seemore-box shadow bg1 br30px px3rem py1rem">
                                    <div className="seemore-inner">
                                        <div className='df w100'>
                                            <div className="pl2rem mt1rem" style={{ width: "50%", height: '100%' }}>
                                                <div className='df w100 mb1rem'>
                                                    <div className="w10 df aic">{proPLusSelected?.seeMoreSection?.icon}</div>
                                                    <div className="w90 fw500 c15 pl1rem df aic" style={{ fontSize: '26px', lineHeight: '37.32px' }}>{proPLusSelected?.seeMoreSection?.title}</div>
                                                </div>
                                                {/* custom-list */}
                                                <ul class="mt0-5rem" style={{ paddingLeft: '22px' }}>
                                                    {proPLusSelected?.seeMoreSection?.points?.map((objpoints, index, arrr) => {
                                                        return (
                                                            <li style={{ lineHeight: '25px', paddingBottom: "3px", paddingTop: "3px", paddingLeft: "20px", borderLeft: '2px solid #D3D9FC', ...proPLusSelected?.seeMoreSection?.liStyle }} className="df fdc fs1rem c14 fs1rem fw400" key={`${objpoints}-${index}`}>
                                                                <div className="pr">
                                                                    <div className="circle"></div>
                                                                    {objpoints}
                                                                </div>
                                                                <div>{proPLusSelected?.seeMoreSection?.subMenu && proPLusSelected?.seeMoreSection?.subMenu?.index === index &&
                                                                    proPLusSelected?.seeMoreSection?.subMenu?.points?.map((str, i, arr) => {
                                                                        return (
                                                                            <div className="fs1rem c14 fs1rem fw400 df aic pl2rem" style={{ lineHeight: '30px' }}>{str}</div>
                                                                        )
                                                                    })
                                                                }
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul >
                                                {proPLusSelected?.seeMoreSection?.notePoint &&
                                                    <div className="mt1rem" style={{ border: '1px solid gray', padding: "10px", borderRadius: '10px' }}>{proPLusSelected?.seeMoreSection?.notePoint}</div>
                                                }
                                            </div>
                                            <div className="df aic jce" style={{ width: '45%' }}>
                                                {proPLusSelected?.seeMoreSection?.image &&
                                                    <img src={proPLusSelected?.seeMoreSection?.image} className="w60" style={{ objectFit: 'fill' }} />
                                                }
                                                {proPLusSelected?.seeMoreSection?.image_one &&
                                                    <img src={proPLusSelected?.seeMoreSection?.image_one} className="w60" style={{ objectFit: 'fill' }} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                        }
                    </div>

                    <div className="df pt2rem pl3rem">
                        <img src={require('../../../../images/landingpage/gold-star-icon.png')} className="w20px h20px mr0-5rem" alt="star-img" />
                        <p className="c12 fs0-75rem">=  completed modules required for Gold Certification</p>
                    </div>

                    {/* FREE Key Performance Indicators (KPI) Report Animation */}
                    {kpiAnimationBox &&
                        <img id="balloon-animation2" className={`pa animation-img-box animation-img-boxUP`} src={require('../../../../images/landingpage/accounts-lawrs-bloon-2.png')} alt="" />
                    }

                    {/* Bespoked Business Audit Animation */}
                    {businessAnimationBox &&
                        <img id="balloon-animation1" className={`pa animation-img-box animation-img-boxUP`} src={require('../../../../images/landingpage/accounts-lawrs-bloon-1.png')} alt="" />
                    }
                </div>
            </div>

            {/* Key Feature See More Popup */}
            <Modal id="keyFeatureSeeMore" open={modal['open'] == 'openSeeMore'} onClose={onModalClose} className="backdrop td500 zi4 pf t0 l0 w100vw  df jcc aic" >
                <div className="poppins-font-style mah100 bg1 shadow w100 p4rem df fdc m1rem pr br10px pr" style={{ outline: 'none', paddingBottom: '2rem', maxWidth: '1350px', ...selectedFeature?.seeMoreSection?.cardPaddingBottom, }}>
                    <div className='df w100' >
                        <div style={{ width: "55%", height: '100%' }}>
                            <div className='df w100 mb1rem'>
                                <div className="w10 df aic">{selectedFeature?.seeMoreSection?.icon}</div>
                                <div className="w90 fw500 c15 pl1rem df aic" style={{ fontSize: '26px', lineHeight: '37.32px' }}>{selectedFeature?.seeMoreSection?.title}</div>
                            </div>
                            {/* custom-list */}
                            <ul class=" mt0-5rem" style={{ paddingLeft: '22px' }}>
                                {selectedFeature?.seeMoreSection?.points?.map((objpoints, index, arrr) => {
                                    return (
                                        <li style={{ lineHeight: '25px', paddingBottom: "3px", paddingTop: "3px", paddingLeft: "20px", borderLeft: '2px solid #D3D9FC', ...selectedFeature?.seeMoreSection?.liStyle }} className="df fdc fs1rem c14 fs1rem fw400" key={`${objpoints}-${index}`}>
                                            <div className="pr">
                                                <div className="circle"></div>
                                                {objpoints}
                                            </div>
                                            <div>{selectedFeature?.seeMoreSection?.subMenu && selectedFeature?.seeMoreSection?.subMenu?.index === index &&
                                                selectedFeature?.seeMoreSection?.subMenu?.points?.map((str, i, arr) => {
                                                    return (
                                                        <div className="fs1rem c14 fs1rem fw400 df aic pl2rem" style={{ lineHeight: '30px' }}>{str}</div>
                                                    )
                                                })
                                            }
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul >
                            {selectedFeature?.seeMoreSection?.notePoint &&
                                <div className="mt1rem" style={{ border: '1px solid gray', padding: "10px", borderRadius: '10px' }}>{selectedFeature?.seeMoreSection?.notePoint}</div>
                            }
                        </div>
                        <div className="df aic jce" style={{ width: '45%' }}>
                            <img src={selectedFeature?.seeMoreSection?.image} className="w70" style={{ objectFit: 'fill' }} />
                            {/* {proPLusSelected?.seeMoreSection?.image &&
                                <img src={proPLusSelected?.seeMoreSection?.image} className="w60" style={{ objectFit: 'fill' }} />
                            }
                            {proPLusSelected?.seeMoreSection?.image_one &&
                                <img src={proPLusSelected?.seeMoreSection?.image_one} className="w60" style={{ objectFit: 'fill' }} />
                            } */}
                        </div>
                    </div>
                    {selectedFeature?.['isInvestorLookupBtn'] &&
                        <HashLink onClick={() => onModalClose()} to="#investor-lookup" className='my2rem p1rem px2rem shadow c1 bg4 bg3-hover br35px cp fw600 fs1rem pr zi0 br10px td250 registerButtonAnimation'>Investor Database Check</HashLink>
                    }
                    {selectedFeature?.['isTwoMinuteTestBtn'] &&
                        <HashLink onClick={() => onModalClose()} to="#not-sure-yet" className='my2rem p1rem px2rem shadow c1 bg4 bg3-hover br35px cp fw600 fs1rem pr zi0 br10px td250 registerButtonAnimation'>Take FREE Test</HashLink>
                    }
                    <svg className="pa cp" onClick={() => onModalClose()} style={{ left: '650px', bottom: '-80px' }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
                        <path d="M48.5626 11.4375C44.8913 7.76665 40.214 5.26685 35.1221 4.2542C30.0302 3.24156 24.7523 3.76155 19.9559 5.74843C15.1595 7.73531 11.06 11.0998 8.17569 15.4166C5.29142 19.7333 3.75195 24.8084 3.75195 30C3.75195 35.1917 5.29142 40.2667 8.17569 44.5835C11.06 48.9002 15.1595 52.2647 19.9559 54.2516C24.7523 56.2385 30.0302 56.7585 35.1221 55.7458C40.214 54.7332 44.8913 52.2334 48.5626 48.5625C51.0004 46.1249 52.9342 43.231 54.2535 40.0461C55.5729 36.8611 56.252 33.4474 56.252 30C56.252 26.5526 55.5729 23.1389 54.2535 19.954C52.9342 16.769 51.0004 13.8751 48.5626 11.4375ZM39.3751 36.6375C39.5624 36.8129 39.7117 37.0249 39.8138 37.2603C39.9159 37.4958 39.9686 37.7496 39.9686 38.0063C39.9686 38.2629 39.9159 38.5168 39.8138 38.7522C39.7117 38.9876 39.5624 39.1996 39.3751 39.375C39.1999 39.5488 38.9921 39.6863 38.7636 39.7796C38.5352 39.8729 38.2906 39.9202 38.0438 39.9188C37.5519 39.9167 37.0805 39.7214 36.7313 39.375L30.0001 32.6438L23.3626 39.375C23.0133 39.7214 22.5419 39.9167 22.0501 39.9188C21.8033 39.9202 21.5587 39.8729 21.3302 39.7796C21.1018 39.6863 20.894 39.5488 20.7188 39.375C20.3696 39.0237 20.1736 38.5485 20.1736 38.0531C20.1736 37.5578 20.3696 37.0826 20.7188 36.7313L27.3563 30L20.7188 23.3625C20.4116 23.0038 20.2511 22.5424 20.2694 22.0705C20.2876 21.5986 20.4832 21.151 20.8171 20.8171C21.1511 20.4832 21.5987 20.2875 22.0706 20.2693C22.5425 20.2511 23.0039 20.4116 23.3626 20.7188L30.0001 27.3563L36.6376 20.7188C36.8111 20.539 37.0184 20.3952 37.2476 20.2956C37.4768 20.196 37.7233 20.1424 37.9732 20.1381C38.223 20.1337 38.4713 20.1786 38.7038 20.2702C38.9363 20.3618 39.1484 20.4983 39.3282 20.6719C39.5079 20.8455 39.6517 21.0528 39.7514 21.2819C39.851 21.5111 39.9045 21.7576 39.9089 22.0075C39.9132 22.2573 39.8683 22.5056 39.7767 22.7381C39.6851 22.9706 39.5486 23.1828 39.3751 23.3625L32.6438 30L39.3751 36.6375Z" fill="white" />
                    </svg>
                </div>
            </Modal >
        </>
    )
}

export default KeyFeaturesModules