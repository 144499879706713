import AnimatedPage from "../components/AnimatedPage";
import CallToAction from "../components/CallToAction";
import Hero from "../components/Hero";
import FancyHeadingParagraph from "../components/FancyHeadingParagraph";
import HeadingParagraph from "../components/HeadingParagraph";
import HeadingSubheadingContent from "../components/HeadingSubheadingContent";
import OfferBanner from "../components/OfferBanner";
import Testimonials from "../components/Testimonials";

export default function Lawyers() {
	const testimonials = [
		{
			title: "The Professional",
			text: "Getting a client into a position where they can provide a draft disclosure letter schedule at the commencement of a transaction will reduce costs, speed up the transaction, and lessen the chance of its falling over right at the end.",
			author: "Rik Pancholi",
			company: "Director, Pattersons Commercial Law",
		},
		{
			title: "The Growth Manager",
			text: "Only when you work as part of a Senior Management Team around a central data repository, kept up to date, do you really realise the operational benefits of centralising key corporate data into one easy to access location.",
			author: "Katy Davidson",
			company: "CFO Wearable Technologies Limited",
		},
		{
			title: "The Early Stage CEO",
			text: "Having an investment ready data room and supporting documents has undoubtedly attracted more interested investors prepared to look at us seriously and accelerated our investment timetable.",
			author: "Louise Birritteri",
			company: "CEO Pikl Insurance Services Limited",
		},
	];
	return (
		<AnimatedPage>
			<OfferBanner h1Class="fs2-5rem fs2-5rem-sm" text="Three Additional Data Room Providers Coming Soon" />
			<Hero h1={["Increased Margins,", "New Revenue Streams,", "Route to New Clients."]} buttonText="Register as Advisor" id='openRegisterPopup' dataShowPopup="registerAdvisor" buttonId="scrollDown" videoSrc="diligentsia_for_lawyers.mp4" videoThumbnail="diligentsia-for-lawyers.jpg" />
			<div className="bg1">
				<div className="container dg gtcr1-lg gg2rem py5rem">
					<HeadingSubheadingContent heading="Can We Help Your Clients?" subheading="Meet Diligentsia" paragraph="Warranty disclosure schedules, Director’s Questionnaires, up to date key corporate data - legal, IP, key contracts, HR, constitutional  - all in a single intuitive and easy to access repository. These are all things that give investors confidence in your clients’ businesses. But what else do they need and how do you know if your clients are are investment ready?" />
				</div>
			</div>
			<div className="bg1">
				<div className="dg gtcr2-lg gg2rem container pb5rem">
					<HeadingParagraph heading="Does your client’s repository deliver everything their investors and purchasers expect and more?" paragraph="Have they self-diligenced through a business audit in advance of going out for funding or prior to exit? How do they benchmark thmselves? Could you help? These are all questions that we can help you with. We help your clients grow or raise investment or exit, more quickly, at a higher valuation, and with more certainty." />
					<HeadingParagraph heading="So how do we do it?" paragraph="Our ultra-low-cost SaaS based platform helps you easily use tailored online forms and checklists to systematically help your clients create an easily accessible central “brain”, a living breathing data repository. Why waste valuable time every day looking for key documents when we can help you centralise your clients’ key documents within their existing cloud- based provider.  We get your clients organized!" />
				</div>
			</div>
			<FancyHeadingParagraph
				content={[
					["Helping You Help Yourselves", "Your clients can pull together their repository themselves using our easy-to-use checklists, or you can charge to assist them with setup, business audits and maintenance. Quarterly reviews provide you opportunities to identify additional areas for you to assist.  If you introduce five mor more clients to us, we will provide you a FREE DASHBOARD  to monitor repository completeness, new additions, business audit risk, investment readiness etc.* across your client base."],
					["Your Client Already has a Data Room?", "We’re data room agnostic. If your client  already has a data room, such as Seed Legals, or iDeals or DataSite, we help you organise it better to create more value.  Or we simply create one within our client’s existing cloud-based solution – Dropbox, Sharepoint, Drive. You tell us."],
				]}
			/>
			<Testimonials testimonials={testimonials} />
			<CallToAction callUsNow heading="Ready to Maximise Your Client's Value for as Little as &pound; 5 a Month? (Payable by your clients)" />
		</AnimatedPage>
	);
}
