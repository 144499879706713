
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import PassFailCertification from "./PassFailCertification";
import 'react-circular-progressbar/dist/styles.css';


export default function TestCertificate({ level, tab, company }) {

	const downloadPDF = async () => {
		const input = document.getElementById('rootElementId');
		html2canvas(input)
			.then((canvas) => {
				const imgData = canvas.toDataURL('image/png');
				const pdf = new jsPDF({ orientation: 'landscape', unit: 'pt', format: [1300, 700] });
				var width = pdf.internal.pageSize.getWidth();
            	var height = pdf.internal.pageSize.getHeight();
				pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
				pdf.save(`Test-Certificate.pdf`);
			})
	}

	return (
		<div className="container bg1 py2rem mih70vh">
			<div id="rootElementId" className="investmentcertificate-page">
				<div className="header-area">
					<div className="container">
						<h2 className="c1 ttc">{company['testType']} Ready Test</h2>
						<h2 className="fs2rem fw700 tac lh1 ttu usn wsn c1">certificate</h2>
					</div>
				</div>
				<div className="certificate-area">
					<div className="container py3rem">
						<div className="right-area df jcc">
							{level && <PassFailCertification className={'op70'} company={company} level={level} testType={company['testType']} expiryTimestamp={company['createdAt']} subscription={company['subscription']} tab={tab} />}
						</div>
						<div className="left-area">
							<h5 className="w100 c3 fs1rem ttu">presented to</h5>
							<h2 className="w100 fs2rem lh2 ttu">{company['cName']}</h2>
							{level == "Pass"
								? <p className="ass fs1rem mb0-5rem mt0-5rem">🎉 Hurrah! You've splendidly conquered the Two-Minute Test! 🎉 With every question answered and a risk score of less than 50%, you've truly showcased your business prowess.
									But remember, this is merely a teaser of the grand adventure our platform can embark upon with your business.
									Curious to see what lies beyond? Then, my noble ally, click the 'Pro Certification' button below. Witness how our Pro Certification weaves its magic, yielding a badge of honor you can proudly display to your investors, partners, and advisors. Let's proceed! ⚔️🚀</p>
								: level == "Fail"
									? <p className="ass fs1rem mb0-5rem mt0-5rem">🛡️ Stay strong, brave adventurer! 🛡️ It appears the Two-Minute Test posed quite a challenge. Either not all the questions were answered, or your risk score surged over 50%.
										Don't lose heart, though! This is merely a brief glimpse of the incredible journey our platform offers your business. It's time to level up and harness the power of our Lite and Pro tools to turn the tide in your favor.
										Interested in embarking on this transformative quest? Click on the 'Pro Certification' button below. Discover how our Pro Certification can enhance your standing, yielding a badge of trust to present to your investors, partners, and advisors. Onwards to victory! ⚔️🚀</p>
									: <h1>Not Certified Yet!</h1>
							}
							<span className="status-area ttc fs1rem">{company['testType']} Ready Test Status:
								<i className={`PassFail ${level} ttu fs1rem`}>{level}</i>
							</span>
							<div className="signure-area dg gtcr2-lg gg2rem py2rem">
								<div className="date-area">
									<p className="c4 ttu fs1rem">{moment(company['updatedAt']).format('ll')}</p>
									<span className="ttu fw600 fs1rem">Date</span>
								</div>
								<div className="date-area">
									<img className="logo-certified" src={require(`../../images/Dg-logo.png`)} alt="TODO" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button onClick={() => downloadPDF()} className="h100 my0-5rem mxa shadow bg3 bg4-hover c1 td250 br5px px1rem fs0-75rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
				<svg className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path className="fa-primary" d="M424.1 320.1l-88 88C334.4 411.5 328.5 416 320 416s-14.4-4.467-16.97-7.031l-88-88c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L296 334.1V184C296 170.8 306.8 160 320 160s24 10.75 24 24v150.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0S434.3 311.6 424.1 320.1z" /><path className="fa-secondary" d="M537.6 226.6C541.7 215.9 544 204.2 544 192c0-53-43-96-96-96c-19.69 0-38.09 6-53.31 16.2C367 64.2 315.3 32 256 32C167.6 32 96 103.6 96 192c0 2.703 .1094 5.445 .2031 8.133C40.2 219.8 0 273.2 0 336C0 415.5 64.5 480 144 480H512c70.69 0 128-57.3 128-128C640 290.1 596 238.4 537.6 226.6zM424.1 320.1l-88 88C334.4 411.5 328.5 416 320 416s-14.4-4.467-16.97-7.031l-88-88c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L296 334.1V184C296 170.8 306.8 160 320 160s24 10.75 24 24v150.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0S434.3 311.6 424.1 320.1z" /></svg>
				Download PDF
			</button>
		</div>
	);
};
