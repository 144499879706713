import ReactTable from 'react-table-6';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import AddEditInvestor from './AddEditInvestor';
import AnimatedPage from "../../../../components/AnimatedPage";
import { toggleModal } from "../../../../reducers/modalReducer";
import { getRequest, postRequest, showMessage } from '../../../../config';
import HeadingSubheadingContent from "../../../../components/HeadingSubheadingContent";

export default function ViewFirms(props) {

	let dispatch = useDispatch();
	let user = useSelector(state => state.user)
	let modal = useSelector(state => state.modal);

	let { onBack, setSelectedFirm, setTab, selectedFirm } = props;

	let [action, setAction] = useState('');
	let [stages, setStages] = useState([]);
	let [sectors, setSectors] = useState([]);
	let [allFirms, setAllFirms] = useState([]);
	let [auditLogs, setAuditLogs] = useState([]);
	let [investorId, setInvestorId] = useState('');
	let [investorCompleted, setInvestorCompleted] = useState(false);
	let [profileClass, setProfileClass] = useState('client-profile');

	useEffect(() => {
		getFirms();

		if (user) setProfileClass(user['role'] == 'Advisor' ? 'advisor-profile' : user['role'] == 'Investor' ? 'investor-profile' : 'client-profile')
	}, []);

	useEffect(() => {
		if (investorCompleted) {
			getFirms();
			setInvestorCompleted(false);
			dispatch(toggleModal({ open: '' }));
		}
	}, [investorCompleted]);

	const getFirms = async () => {
		let response = await getRequest(`/company/getFirms`);

		if (response['code'] == 200 && response['body']) {
			response['body'] = response['body'].slice().reverse()
			setAllFirms(response['body']);
		}
	};

	const openSummaryPopup = (firm) => {
		setSelectedFirm(firm);
		dispatch(toggleModal({ open: 'summaryPopup' }));
	}

	const openInvestmentCriteriaPopup = (firm) => {
		setSelectedFirm(firm);
		dispatch(toggleModal({ open: 'investmentCriteriaPopup' }));
	}

	const submitDeleteFirm = async (id, firm) => {
		let response = await postRequest(`/company/addEditDeleteInvestor`, { action: 'delete', investorId: id });
		if (response && response['code'] == 200) {
			await getFirms();
			dispatch(toggleModal({ open: '' }));
			showMessage(response['message']);
		};
	}

	const openDeletePopup = async (firm) => {
		await setSelectedFirm(firm);
		dispatch(toggleModal({ open: 'firmPopup' }))
	}

	const openViewFirmPopup = async (firm) => {
		let emptyArray = ['', 'not known', 'not selected', 'none selected', 'nil'];

		let tempSectors = [];
		if (firm['preferredSectors'] && !emptyArray.includes(firm['preferredSectors']['sector1']?.toLowerCase()) && !tempSectors.includes(firm['preferredSectors']['sector1'])) tempSectors.push(firm['preferredSectors']['sector1']);
		if (firm['preferredSectors'] && !emptyArray.includes(firm['preferredSectors']['sector2']?.toLowerCase()) && !tempSectors.includes(firm['preferredSectors']['sector2'])) tempSectors.push(firm['preferredSectors']['sector2']);
		if (firm['preferredSectors'] && !emptyArray.includes(firm['preferredSectors']['sector3']?.toLowerCase()) && !tempSectors.includes(firm['preferredSectors']['sector3'])) tempSectors.push(firm['preferredSectors']['sector3']);
		if (firm['preferredSectors'] && !emptyArray.includes(firm['preferredSectors']['sector4']?.toLowerCase()) && !tempSectors.includes(firm['preferredSectors']['sector4'])) tempSectors.push(firm['preferredSectors']['sector4']);
		if (firm['preferredSectors'] && !emptyArray.includes(firm['preferredSectors']['sector5']?.toLowerCase()) && !tempSectors.includes(firm['preferredSectors']['sector5'])) tempSectors.push(firm['preferredSectors']['sector5']);
		setSectors(tempSectors);

		let tempStages = [];
		if (firm['stageFocus'] && !emptyArray.includes(firm['stageFocus']['stage1']?.toLowerCase()) && !tempStages.includes(firm['stageFocus']['stage1'])) tempStages.push(firm['stageFocus']['stage1']);
		if (firm['stageFocus'] && !emptyArray.includes(firm['stageFocus']['stage2']?.toLowerCase()) && !tempStages.includes(firm['stageFocus']['stage2'])) tempStages.push(firm['stageFocus']['stage2']);
		if (firm['stageFocus'] && !emptyArray.includes(firm['stageFocus']['stage3']?.toLowerCase()) && !tempStages.includes(firm['stageFocus']['stage3'])) tempStages.push(firm['stageFocus']['stage3']);
		setStages(tempStages);

		await setSelectedFirm(firm);
		dispatch(toggleModal({ open: 'viewFirmPopup' }));
	};

	const openInvestorForm = (action, firmId) => {
		setAction(action);
		setInvestorId(firmId);
		dispatch(toggleModal({ open: 'sidebar-investor' }));
	};

	const firmsColumns = [
		{
			id: 'cName',
			Header: 'Company Name',
			accessor: firm => firm['cName'] ? firm['cName'] : '-',
			filterMethod: (filter, firm) => {
				let cName = firm['cName'].toLowerCase();
				return cName.includes(filter.value.toLowerCase());
			}
		},
		{
			id: 'subscription',
			Header: 'DPP Registered',
			accessor: firm => firm['subscription'] ? firm['subscription'] : '-',
			filterMethod: (filter, firm) => {
				let subscription = firm['subscription'].toLowerCase();
				return subscription.includes(filter.value.toLowerCase());
			}
		},
		{
			id: 'cCountry',
			Header: 'Country',
			accessor: firm => firm['cCountry'] ? firm['cCountry'] : '-',
			filterMethod: (filter, firm) => {
				let cCountry = firm['cCountry'].toLowerCase();
				return cCountry.includes(filter.value.toLowerCase());
			}
		},
		{
			id: 'bvcaMember',
			Header: 'BVCA Member',
			accessor: firm => (firm['classification'] && firm['classification'] !== '') ? firm['classification'] : '-',
			filterMethod: (filter, firm) => {
				let classification = firm['classification'].toLowerCase();
				return classification.includes(filter.value.toLowerCase());
			}
		},
		{
			id: 'sweetSpot',
			Header: 'Investment Sweet Spot',
			accessor: firm => firm['sweetSpot'] ? firm['sweetSpot'] : '-',
			filterMethod: (filter, firm) => {
				let sweetSpot = firm['sweetSpot'].toLowerCase();
				return sweetSpot.includes(filter.value.toLowerCase());
			}
		},
		// {
		// 	id: 'website',
		// 	Header: 'Website',
		// 	accessor: firm => firm['website'] ? <a className='cp c3 tdu' href={firm['website']} target='blank'>Website Link</a> : '-',
		// },
		{
			id: 'view',
			Header: 'View',
			accessor: firm => firm ? (
				<div style={{ display: 'flex', gap: '5px' }}>
					{firm.description && firm.description !== "" && (
						<button
							style={{
								backgroundColor: '#007bff',
								color: '#fff',
								padding: '3px 3px',
								border: 'none',
								borderRadius: '5px',
								cursor: 'pointer',
								Width: '92px',
								fontSize: '13px',
							}}
							onClick={() => openSummaryPopup(firm)}
						>
							Description
						</button>
					)}
					{firm.investmentCriteria && firm.investmentCriteria !== "" && (
						<button
							style={{
								backgroundColor: '#28a745',
								color: '#fff',
								padding: '3px 3px',
								border: 'none',
								borderRadius: '5px',
								cursor: 'pointer',
								Width: '92px',
								fontSize: '13px',
							}}
							onClick={() => openInvestmentCriteriaPopup(firm)}
						>
							Invest Criteria
						</button>
					)}
				</div>
			) : '-',
		},
		{
			id: 'actions',
			Header: 'actions',
			accessor: firm => firm ? <><button onClick={() => { openViewFirmPopup(firm) }} style={{ backgroundColor: "transparent" }}><i data-tooltip="View Investor" class="fa fa-eye c8 cp" style={{ fontSize: '20px', color: "green", }} /></button>
				<button onClick={() => { setSelectedFirm(firm); openInvestorForm('edit', firm['_id']) }} style={{ backgroundColor: "transparent" }}><i data-tooltip="Edit Investor" class="fa fa-edit c8 cp" style={{ fontSize: '20px', }} /></button>
				<button onClick={() => openDeletePopup(firm)} style={{ backgroundColor: "transparent" }}><i data-tooltip="Delete Investor" class="fa fa-trash c9 cp" style={{ fontSize: '20px', }} /></button></> : '-',
		},
	];

	const getAuditLogs = async (endpoint, id) => {
		let data;
		data = { endpoint, userId: id };

		const res = await postRequest('/log', data);
		if (res['code'] == 200) {
			await setAuditLogs(res['body']);
			dispatch(toggleModal({ open: 'firmAuditLogs' }));
		}
	};


	return (
		<AnimatedPage>
			<div className="container df fdc">
				{/* <HeadingSubheadingContent wrapperClass="mb2rem tac" heading="BVCA Firms" /> */}
				{/* Compiled field displaying the createdAt time of the last record */}
				{/* VC Firms */}
				<div className="df fdc aic bss bw2px bo5 br10px p2rem">
					<h3 className="fs1-5rem fs2rem-sm ttu tac c4">BVCA Investors Database</h3>
					{allFirms.length > 0 && (
						<p>
							Compiled: {new Date(allFirms[0].createdAt).toLocaleString()}
						</p>
					)}
					<div className='df jcsb w100'>
						<span onClick={() => openInvestorForm('add', '')} className="w200px tac shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb fs0-75rem">Add Investor</span>
						<span onClick={() => getAuditLogs(`/company/addEditDeleteInvestor`, user['_id'])} className="w200px tac shadow bg3 bg4-hover c1 td250 br5px py0-5rem fs0-75rem fw600 cp ttu bsbb tac" >Audit Log</span>
					</div>
					{allFirms && allFirms.length > 0
						? <div className='admin-table mt1rem w100 ttc tac bg1 p2rem br10px'>
							<ReactTable
								minRows={20}
								className="table"
								data={allFirms}
								resolveData={company => company.map(row => row)}
								columns={firmsColumns}
								filterable={true} />
						</div>
						: <p>No BVCA Investor added in the Database</p>
					}
				</div>
			</div>

			{/* Add/Edit Investor  */}
			<Modal open={modal['open'] == 'sidebar-investor'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100 h110vh df jcc aic sidebar-investor">
				<Slide direction="left" in={modal['open'] == 'sidebar-investor'}>
					<div className="mah90 oya w100 bsbb df fdc m1rem br5px pr">
						<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
							<svg style={{ position: 'relative', top: '4px' }} className="pen f1 h1rem mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path className="fa-primary pen" d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
								<path className="fa-secondary pen" d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l-32-32l32-32H416C433.7 224 447.1 238.3 447.1 256z" />
							</svg>
							Back to Menu
						</button>
						<div>
							<AddEditInvestor setInvestorCompleted={setInvestorCompleted} action={action} investorId={investorId} />
						</div>
					</div>
				</Slide>
			</Modal>

			{/* Audit logs  */}
			<Modal open={modal['open'] == 'firmAuditLogs'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic">
				<div className="mah90 bg1 shadow w100 p3rem bsbb df fdc m1rem maw800px pr pt5rem br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs1-5rem fw600 tac ttu">Audit Logs</h1>
					<h2 className="c4 fs0-75rem ttu w100 tac mb1rem">See the history of VC Firms</h2>
					<div className="ma oya dg gg0-5rem mb2rem w100">
						{(auditLogs && auditLogs.length > 0)
							? auditLogs.map(log => {
								return (
									log['message'] != 'Firm found!' && <div className="df my0-5rem">
										{<span className={`ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-75rem ${profileClass}`} data-tooltip={log?.['userId']?.['firstName'] ? (log?.['userId']?.['firstName'] + " " + log?.['userId']?.['lastName']) : "Register to show your name here"}>{log?.['userId']?.['firstName'][0] || "?"} {log?.['userId']?.['lastName'][0] || "?"}</span>
										}
										<div className="w100">
											<span className='fs0-75rem ml1rem'><b>{log['message']}</b> at <b>{new Date(log.timestamp).toLocaleString()}</b> by <b>{log?.['userId']?.['firstName']} {log?.['userId']?.['lastName']}</b></span>
											{/* Advisor Logs */}
											{(log['payload']) && log['message'] != 'Firm found!' &&
												<div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
													<p className="df fw500 fs1rem ttc">Firm {log['payload']['action']}:</p>
													<p className="df fs0-75rem"><span className="fw500 mr0-5rem">Name : </span> {log?.['payload']?.['cName'] ? log?.['payload']?.['cName'] : log['payload']?.['investorData']?.['cName'] ? log['payload']?.['investorData']?.['cName'] : '-'}</p>
													<p className="df fs0-75rem"><span className="fw500 mr0-5rem">Email : </span> {log?.['payload']?.['cEmail'] ? log['payload']?.['cEmail'] : log['payload']?.['investorData']?.['contactEmail'] ? log['payload']?.['investorData']?.['contactEmail'] : '-'}</p>
													<p className="df fs0-75rem"><span className="fw500 mr0-5rem">Address : </span> {log?.['payload']?.['cAddress'] ? log['payload']?.['cAddress'] : log['payload']?.['investorData']?.['cAddress'] ? log['payload']?.['investorData']?.['cAddress'] : '-'}</p>
												</div>
											}

										</div>
									</div>
								)
							})
							: <h2 className="text-gradient fs1-75rem ttu w100 tac mb1rem">No Logs Found!</h2>
						}
					</div>
				</div>
			</Modal>

			{/* Delete Investor */}
			<Modal open={modal['open'] == 'firmPopup'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100 h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 px2rem py3rem bsbb df fdc m1rem maw700px br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs2rem fw600 tac ttu mb1rem pb1rem">Delete Investor </h1>
					<div class="df fdc gg1rem px2rem">

						<p className="my1rem">Are you sure you want to delete the investor (<span className="fw500">{selectedFirm?.['cName']}</span>)?</p>

						<span onClick={() => submitDeleteFirm(selectedFirm._id, selectedFirm)} className="shadow bg3 bg4-hover c1 td250 br5px p1rem fw600 cp ttu w100 bsbb tac df jcc aic">Delete</span>
						<span onClick={() => dispatch(toggleModal({ open: '' }))} className="c3-hover td250 fs1-25rem cp">Cancel</span>
					</div>
				</div>
			</Modal>

			{/* View Investor */}
			<Modal open={modal['open'] == 'viewFirmPopup'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100 h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 px2rem py3rem bsbb df fdc m1rem maw700px br5px pr">
					<img className="h2rem pa t0 center-horizontally" src={require(`../../../../../images/logo.svg`)} alt="TODO" style={{ top: '15px' }} />
					<button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
						<svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
							<path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
						</svg>
					</button>
					<h1 className="text-gradient fs2rem fw600 tac ttu mb1rem pb1rem">Investor Details</h1>
					<div class="df fdc gg1rem px2rem">
						<div className="shadow bg5 br5px p1rem dg gg0-5rem mb1rem">
							{/* <h2 className="c4 fs1-5rem ttu w100 tac">Firm Info</h2> */}
							<span><b>Name:</b> {selectedFirm?.['cName'].toUpperCase()} </span>
							<span><b>UK House No:</b> {(selectedFirm?.['ukRegNo'] && selectedFirm?.['ukRegNo'] !== '') ? selectedFirm?.['ukRegNo'] : '--'} </span>
							<span><b>Website:</b> <a className='cp c3 tdu' target='_blank' href={selectedFirm?.['cWebsite']}>{selectedFirm?.['cWebsite']}</a></span>
							<span><b>Phone No:</b> {(selectedFirm?.['cPhone'] && selectedFirm?.['cPhone'] !== '') ? selectedFirm?.['cPhone'] : "--"} </span>
							<span><b>Address:</b> {selectedFirm?.['cAddress']} </span>
							<span><b>Contact Email:</b> {selectedFirm?.['contactEmail'] ? selectedFirm?.['contactEmail'] : "--"} </span>
							<span><b>Number of UK Employees:</b> {(selectedFirm?.['employees']?.['ukEmployees'] && selectedFirm?.['employees']?.['ukEmployees'] !== '') ? selectedFirm?.['employees']?.['ukEmployees'] : "--"} </span>
							<span><b>Number of Global Employees:</b> {(selectedFirm?.['employees']?.['globalEmployees'] && selectedFirm?.['employees']?.['globalEmployees'] !== '') ? selectedFirm?.['employees']?.['globalEmployees'] : '--'} </span>
							<span><b>Preferred Sectors:</b> {sectors.length > 0 ? sectors.join(', ') : '--'} </span>
							<span><b>Stage Focus:</b> {stages.length > 0 ? stages.join(', ') : '--'} </span>
							<span><b>Sweet Spot:</b> {selectedFirm?.['sweetSpot'] ? selectedFirm?.['sweetSpot'] : "--"} </span>
							<span><b>Funds Available:</b> {selectedFirm?.['fundSize'] ? selectedFirm?.['fundSize'] : "--"} </span>
							<span><b>Annual Revenue:</b> {selectedFirm?.['annualRevenues'] ? selectedFirm?.['annualRevenues'] : "--"} </span>
							<span><b>Annual Profit:</b> {selectedFirm?.['annualProfits'] ? selectedFirm?.['annualProfits'] : "--"} </span>
							<span><b>Number of Investments Made:</b> {(selectedFirm?.['investment']?.['noOfInvestments'] && selectedFirm?.['investment']?.['noOfInvestments'] !== '') ? selectedFirm?.['investment']?.['noOfInvestments'] : "--"} </span>
							<span><b>Date of Last Investment:</b> {(selectedFirm?.['investment']?.['lastInvestmentDate'] && selectedFirm?.['investment']?.['lastInvestmentDate'] !== '') ? selectedFirm?.['investment']?.['lastInvestmentDate'] : "--"} </span>
							<span><b>Description:</b> {(selectedFirm?.['description'] && selectedFirm?.['description'] !== '') ? selectedFirm?.['description'] : "--"} </span>
						</div>
					</div>
				</div>
			</Modal>

			{/* Investor Description */}
			<Modal open={modal['open'] === 'summaryPopup'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100 h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 px2rem py3rem bsbb df fdc m1rem maw700px br5px pr">
					{/* Other modal content can be added here */}
					<h1 className="text-gradient fs2rem fw600 tac ttu mb1rem pb1rem">Investor Description</h1>
					<div className="df fdc gg1rem px2rem">
						<div className="shadow bg1 br5px p1rem dg gg0-5rem mb1rem background-des">
							<span><b>Firm Name:</b> {selectedFirm?.['cName'].toUpperCase()} </span>
							<span><b>Description:</b> {selectedFirm?.['description'] ? selectedFirm?.['description'] : "--"} </span>
						</div>
					</div>
				</div>
			</Modal>

			{/* Investor Investment Criteria */}
			<Modal open={modal['open'] === 'investmentCriteriaPopup'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100 h110vh df jcc aic">
				<div className="mah90 oya bg1 shadow w100 px2rem py3rem bsbb df fdc m1rem maw700px br5px pr">
					{/* Other modal content can be added here */}
					<h1 className="text-gradient fs2rem fw600 tac ttu mb1rem pb1rem">Investor Investment Criteria</h1>
					<div className="df fdc gg1rem px2rem">
						<div className="shadow bg1 br5px p1rem dg gg0-5rem mb1rem background-des">
							<span><b>Firm Name:</b> {selectedFirm?.['cName'].toUpperCase()} </span>
							<span><b>Investment Criteria:</b> {selectedFirm?.['investmentCriteria'] ? selectedFirm?.['investmentCriteria'] : "--"} </span>
						</div>
					</div>
				</div>
			</Modal>
		</AnimatedPage>



	);
}
