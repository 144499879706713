import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
// typewtiter import 
import Typewriter from 'typewriter-effect';
import { HashLink } from 'react-router-hash-link';
import { LANDING_PAGE } from "../../../constants/routes";


// Menu list
const PAGE_MENU_LIST = [
    { title: 'Companies', link: LANDING_PAGE },
    { title: 'Accountants', link: `${LANDING_PAGE}-accountants` },
    { title: 'Lawyers', link: `${LANDING_PAGE}-lawyers` },
    { title: 'VCs', link: `${LANDING_PAGE}-vcs` },
    { title: 'PEs', link: `${LANDING_PAGE}-pes` },
    { title: 'Angel Networks', link: `${LANDING_PAGE}-angel-networks` },
    { title: 'Angels', link: `${LANDING_PAGE}-angels` }
]

const Banner = ({ data, isAnimationVisible, refs, calImage, }) => {

    const navigate = useNavigate();


    return (
        <div className="bg1 bpr brnr banner-bg" style={{ backgroundImage: `url(${require('../../../../images/landingpage/form-background-3.png')})`, backgroundPosition: 'right top', backgroundSize: '74% 61%' }} >
            <div className="main-banner-container">
                <div className="pt1rem df fdc">
                    <div className="row df pr" style={{ flexWrap: "nowrap" }}>
                        {/* Left side block content block */}
                        <div className="col-sm df jcc fdc gg2rem" style={{ width: "70%" }}>
                            <div ref={refs?.current?.[0]} className={`fs1-75rem c15 tslp ${isAnimationVisible[0] ? 'op1 tty0' : 'op0 ttx20'}`} style={{ lineHeight: '50px' }}>
                                {/* Welcome to Diligentsia */}
                                {data?.['bannersubTitle']}
                            </div>
                            <div className='fw500 c15 mih15rem '>
                                <span className="c15 fs3-5rem" style={{ lineHeight: '70px' }}>
                                    {data?.['bannerTitle']}
                                </span>
                            </div>
                            {data?.['bannerDescription'] &&
                                <span className="fs1-25rem c15 tslp w100">
                                    {data['bannerDescription']}
                                </span>
                            }
                        </div>
                        {/* Right side image block */}
                        <div className="col-sm mt2rem df jce pr right-img-area" style={{ width: '30%', height: '400px' }}>
                            <div className="pr">
                                <img src={require('../../../../images/landingpage/animation-cube.png')} className='ar pa zi0 cubes-img' style={{ ...data?.cubeStyle }} />
                                <img src={data?.bannerImage} className={`zi1 pr person-img`} style={{ ...data?.imageStyle, height: '100%' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Navbar part */}
            <div className={`bg1 pr pt1rem w100 df jcc fdc aic tic automated-sec`}>
                <div className={`br br35px bg18 df jcsa aic zi1 w60 p0-5rem mb4rem menu-bottom`} style={{ marginTop: "-61px", backgroundColor: '#6775b995', backdropFilter: 'blur(5px)' }}>
                    {PAGE_MENU_LIST.map((obj, index) => <div onClick={() => navigate(obj?.link)} key={obj?.title} className={`cp c1 ${data?.activeMenu === `${"menu-bar"}-${index}` ? "fs1-25rem-screen4 fw700 fs1rem-screen1" : 'fs1-125rem-screen4 fw400 fs1rem-screen1'}`} style={{ lineHeight: "33px" }}>{obj?.title}</div>)}
                </div>
            </div>

            {/* Calculator part */}
            <div className="pr bg1 pb3rem bsbb calcluter-sec">
                <div className="bg1 p4rem w100 df br45px bsbb" style={{ background: "radial-gradient(70% 70% at 24.51% 50.12%, #F5F7FF 0%, #E8ECFF 100%)" }}>
                    {/* Left text section */}
                    <div className='w30 df jcc'>
                        <img src={calImage} className="w90" />
                    </div>

                    {/* Right calculator section */}
                    <div className='w70 df jcc fdc pl2rem'>
                        {data?.gurantee?.point1 &&
                            <div className="fw400 fs1-75rem c15" style={{ lineHeight: '40px', }}>
                                {data?.gurantee?.point1}
                            </div>
                        }
                        {data?.gurantee?.point2 &&
                            <div className="fw400 fs1-75rem c15" style={{ lineHeight: '40px', }}>
                                {data?.gurantee?.point2}
                            </div>
                        }
                        {data?.gurantee?.point3 &&
                            <div className="fw400 fs1-75rem c15" style={{ lineHeight: '40px', }}>
                                {data?.gurantee?.point3}
                            </div>
                        }
                        <div className='fw400 df jcs'>
                            {data?.['description']?.['isTestBtn'] &&
                                <HashLink to="#not-sure-yet" className="bsbb jcc w400px bg1 tal df fdc aic fw500 mt1rem pt1rem px1rem c1 bg4 br19px cp arrowBtnAnimation secend-sec-btn td250">
                                    <>FREE 2-Minute Test
                                        <span className='fw400 c21 df fdc' style={{ marginTop: '-2px', fontSize: '13px', lineHeight: '15px', fontStyle: 'italic' }}>No registration or credit card required</span>
                                        <i className="icon">
                                            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.39612 10.336H15.818L11.43 14.7241C10.1696 15.9845 12.0602 17.875 13.3206 16.6147L17.3169 12.6121L19.9847 9.94019C20.5017 9.42008 20.5017 8.58008 19.9847 8.05996L13.3206 1.38812C13.0671 1.12758 12.7181 0.981397 12.3545 0.984421C11.1542 0.984572 10.5652 2.4465 11.43 3.2788L15.8284 7.66688H1.32711C-0.521408 7.75859 -0.383387 10.4281 1.39612 10.336Z" fill="#FC7785" />
                                            </svg>
                                        </i>
                                    </>
                                </HashLink>
                            }
                            {data?.['description']?.['isInvestorBtn'] &&
                                <HashLink to="#investor-lookup" style={{ width: '333px', paddingLeft: '30px' }} className='bsbb jcc w400px bg1 tal df fdc aic fw500 mt1rem pt1rem px1rem c1 bg4 br19px cp arrowBtnAnimation secend-sec-btn td250'>
                                    <>Investor Database Check
                                        <i className="icon">
                                            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.39612 10.336H15.818L11.43 14.7241C10.1696 15.9845 12.0602 17.875 13.3206 16.6147L17.3169 12.6121L19.9847 9.94019C20.5017 9.42008 20.5017 8.58008 19.9847 8.05996L13.3206 1.38812C13.0671 1.12758 12.7181 0.981397 12.3545 0.984421C11.1542 0.984572 10.5652 2.4465 11.43 3.2788L15.8284 7.66688H1.32711C-0.521408 7.75859 -0.383387 10.4281 1.39612 10.336Z" fill="#FC7785" />
                                            </svg>
                                        </i>
                                    </>
                                </HashLink>
                            }
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )

}
export default Banner

