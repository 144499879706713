import Modal from "@mui/material/Modal";
import Switch from '@mui/material/Switch';
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LANDING_PAGE } from "../../../constants/routes";
import AnimatedPage from '../../../components/AnimatedPage';
import { toggleModal } from '../../../reducers/modalReducer';


// Menu list
const PAGE_MENU_LIST = [
    { title: 'Companies', className: 'companies-page', link: LANDING_PAGE },
    { title: 'Accountants', link: `${LANDING_PAGE}-accountants` },
    { title: 'Lawyers', link: `${LANDING_PAGE}-lawyers` },
    { title: 'VCs', link: `${LANDING_PAGE}-vcs` },
    { title: 'PEs', link: `${LANDING_PAGE}-pes` },
    { title: 'Angel Networks', link: `${LANDING_PAGE}-angel-networks` },
    { title: 'Angels', link: `${LANDING_PAGE}-angels` }
]

const HomePage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const modal = useSelector(state => state.modal);

    const [activeTab, setActiveTab] = useState('Companies');
    const [isAllServices, setIsAllServices] = useState(true);
    const [isEssentialCookies, setIsEssentialCookies] = useState(true);
    const [isFunctionalCookies, setIsFunctionalCookies] = useState(true);
    const [isPerformanceCookies, setIsPerformanceCookies] = useState(true);

    useEffect(() => {
        let isCookies = localStorage.getItem("isCookies");
        if (isCookies == null) dispatch(toggleModal({ open: 'cookiesModal' }));
    }, []);

    const handleSaveCookies = () => {
        dispatch(toggleModal({ open: '' }))
        localStorage.setItem('isCookies', true);
    };


    return (
        <div className="poppins-font-style home-page-font oh *">
            <AnimatedPage>
                <div className="bg1 bpr brnr home-page-bg" style={{ backgroundImage: `url(${require('../../../../images/landingpage/form-background-2.png')})`, height: '91vh', backgroundPosition: 'top right' }} >
                    <div className="main-banner-container home-page-banner">
                        <div className="df fdc">
                            <div className="row df pr pt2rem" style={{ flexWrap: "nowrap" }}>
                                {/* Left side block content block */}
                                <div className="col-sm df fdc gg3rem pt4rem" style={{ width: "60%" }}>
                                    <div className={`fs1-75rem c15 tslp mt3rem`} style={{ lineHeight: '28px' }}>
                                        Welcome to Diligentsia
                                    </div>
                                    <div className={`fw500 fs3rem c15 tslp mt2rem`} style={{ fontSize: '105px', lineHeight: '115px' }}>
                                        Do <span className='c4'>diligence.</span> <br /> Simply.
                                    </div>
                                    <p className='fs1-25rem c15 tslp'>Diligentsia's automated platform turns diligence from a time-consuming overhead into a reusable living asset that creates value for everyone</p>
                                </div>
                                {/* Right side image block */}
                                <div className="col-sm df jce pr" style={{ width: '70%' }}>
                                    <div className="pr">
                                        <img className='w100' src={require('../../../../images/landingpage/cogs-homepage.gif')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Navbar part */}
                    <div className={`bg1 pr pt1rem w100 df jcc fdc aic tic home-page-menu automated-sec`}>
                        <div className={`br br35px df jcsa aic zi1 w60 p0-5rem mb2rem menu-bottom pr`} style={{ marginTop: "-40px", backgroundColor: '#6775b995', backdropFilter: 'blur(20px)' }}>
                            {PAGE_MENU_LIST.map(obj => {
                                return (
                                    <div onMouseEnter={() => setActiveTab(obj?.['title'])} onMouseLeave={() => setActiveTab('Companies')} onClick={() => navigate(obj?.link)}
                                        key={obj?.title} className={`cp c1 fs1-125rem-screen4 fw400 fs1rem-screen1 ${activeTab == obj?.['title'] && ' active'}`} style={{ lineHeight: "33px" }}>
                                        {obj?.['title']}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div >
            </AnimatedPage >

            {/* Cookies Popup */}
            <Modal id="cookiesModal" open={modal['open'] == 'cookiesModal'} className="backdrop td500 zi4 pf t0 l0 w100vw  df jcc aic" >
                <div className="poppins-font-style cookies-modal mah100 bg1 shadow w100 df fdc m1rem pr br10px pr" style={{ outline: 'none', maxWidth: '630px', }}>
                    <div className='w100' >
                        <div className="header-area w100">
                            <div className="df jcsb w100 pt1rem pb1rem pl2rem pr2rem bsbb aic">
                                <svg className="back-btn-modal cp" width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.2786 0.40705C7.40543 0.279703 7.55715 0.178129 7.7249 0.10825C7.89264 0.0383717 8.07306 0.00158847 8.25563 4.92302e-05C8.43819 -0.0014881 8.61924 0.0322472 8.78821 0.0992886C8.95719 0.16633 9.1107 0.265335 9.2398 0.390524C9.36889 0.515715 9.47098 0.664585 9.54012 0.828446C9.60925 0.992308 9.64404 1.16788 9.64245 1.34492C9.64087 1.52196 9.60294 1.69692 9.53088 1.85959C9.45882 2.02226 9.35407 2.16939 9.22275 2.29239L4.6951 6.68305L20.6251 6.68305C20.9897 6.68305 21.3394 6.82353 21.5973 7.07358C21.8551 7.32362 22 7.66276 22 8.01638C22 8.37001 21.8551 8.70914 21.5973 8.95919C21.3394 9.20924 20.9897 9.34972 20.6251 9.34972L4.6951 9.34972L9.22275 13.7404C9.47321 13.9919 9.61179 14.3287 9.60866 14.6783C9.60553 15.0278 9.46093 15.3623 9.20601 15.6095C8.95108 15.8567 8.60623 15.9969 8.24573 15.9999C7.88523 16.003 7.53792 15.8686 7.2786 15.6257L0.403938 8.95905C0.275896 8.8352 0.174307 8.68806 0.104993 8.52607C0.0356788 8.36409 -3.41367e-07 8.19043 -3.49033e-07 8.01505C-3.56699e-07 7.83967 0.0356787 7.66602 0.104993 7.50403C0.174307 7.34204 0.275896 7.19491 0.403938 7.07105L7.2786 0.40705Z" fill="#151313" />
                                </svg>
                                <p className="c15 fs1rem fw600">Powered by <span className="tdu">Klaro</span></p>
                            </div>
                        </div>
                        <div className="cookies-box p3rem" style={{ height: '55vh', overflow: 'auto' }}>
                            <h3 className="c15 fs1-5rem mb1rem">Cookie usage</h3>
                            <p className="c14 pb1rem">In order to enhance your experience across our platforms and show you more relevant information, we use cookies and similar technologies. For more information, see our <a href="/policy" target="_blank" className="cookies-btn fsi c3 tdu cp">Cookie Policy.</a></p>
                            <div className="radiobox-cookies df jcsb w100 aic mt1rem pt-05rem pl1rem pr1rem bsbb br10px" style={{ backgroundColor: '#D1DFFF66' }}>
                                <div className="title-area df aic">
                                    <h4 className="c15 fs1rem">Essential cookies</h4>
                                    <span className="required bg1 c7 bss bw1px bo7 br15px ml1rem w80px fs0-75rem tac">Required</span>
                                </div>
                                <div className="radio-area">
                                    <Switch
                                        name='essentialCookies'
                                        className="success w100"
                                        checked={isEssentialCookies}
                                        onChange={() => setIsEssentialCookies(!isEssentialCookies)}
                                    />
                                </div>
                            </div>
                            <div className="radiobox-cookies df jcsb w100 aic mt1rem pt-05rem pl1rem pr1rem bsbb br10px" style={{ backgroundColor: '#D1DFFF66' }}>
                                <div className="title-area df aic">
                                    <h4 className="c15 fs1rem">Functional cookies</h4>
                                </div>
                                <div className="radio-area">
                                    <Switch
                                        name='functionalCookies'
                                        className="success w100"
                                        checked={isFunctionalCookies}
                                        onChange={() => setIsFunctionalCookies(!isFunctionalCookies)}
                                    />
                                </div>
                            </div>
                            <div className="radiobox-cookies df jcsb w100 aic mt1rem pt-05rem pl1rem pr1rem bsbb br10px" style={{ backgroundColor: '#D1DFFF66' }}>
                                <div className="title-area df aic">
                                    <h4 className="c15 fs1rem">Performance cookies</h4>
                                </div>
                                <div className="radio-area">
                                    <Switch
                                        name='performanceCookies'
                                        className="success w100"
                                        checked={isPerformanceCookies}
                                        onChange={() => setIsPerformanceCookies(!isPerformanceCookies)}
                                    />
                                </div>
                            </div>
                            <div className="pt2rem pb1rem divedier"><hr /></div>
                            <div className="radiobox-cookies df jcsb w100 aic mt1rem pt-05rem pl1rem pr1rem bsbb br10px" style={{ backgroundColor: '#D1DFFF66' }}>
                                <div className="title-area df aic">
                                    <h4 className="c15 fs1rem">Accept all services</h4>
                                </div>
                                <div className="radio-area">
                                    <Switch
                                        name='allServices'
                                        className="success w100"
                                        checked={isAllServices}
                                        onChange={() => setIsAllServices(!isAllServices)}
                                    />
                                </div>
                            </div>
                            <div className="df jcc w100">
                                <button onClick={() => handleSaveCookies()} className="bg15 bg14-hover w200px p1rem mt2rem c1 br80px cp td250">Save</button>
                            </div>
                        </div>
                    </div>
                    {/* <svg className="pa cp" onClick={() => dispatch(toggleModal({ open: '' }))} style={{ left: '50%', bottom: '-80px', transform: 'translateX(-50%)' }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
                        <path d="M48.5626 11.4375C44.8913 7.76665 40.214 5.26685 35.1221 4.2542C30.0302 3.24156 24.7523 3.76155 19.9559 5.74843C15.1595 7.73531 11.06 11.0998 8.17569 15.4166C5.29142 19.7333 3.75195 24.8084 3.75195 30C3.75195 35.1917 5.29142 40.2667 8.17569 44.5835C11.06 48.9002 15.1595 52.2647 19.9559 54.2516C24.7523 56.2385 30.0302 56.7585 35.1221 55.7458C40.214 54.7332 44.8913 52.2334 48.5626 48.5625C51.0004 46.1249 52.9342 43.231 54.2535 40.0461C55.5729 36.8611 56.252 33.4474 56.252 30C56.252 26.5526 55.5729 23.1389 54.2535 19.954C52.9342 16.769 51.0004 13.8751 48.5626 11.4375ZM39.3751 36.6375C39.5624 36.8129 39.7117 37.0249 39.8138 37.2603C39.9159 37.4958 39.9686 37.7496 39.9686 38.0063C39.9686 38.2629 39.9159 38.5168 39.8138 38.7522C39.7117 38.9876 39.5624 39.1996 39.3751 39.375C39.1999 39.5488 38.9921 39.6863 38.7636 39.7796C38.5352 39.8729 38.2906 39.9202 38.0438 39.9188C37.5519 39.9167 37.0805 39.7214 36.7313 39.375L30.0001 32.6438L23.3626 39.375C23.0133 39.7214 22.5419 39.9167 22.0501 39.9188C21.8033 39.9202 21.5587 39.8729 21.3302 39.7796C21.1018 39.6863 20.894 39.5488 20.7188 39.375C20.3696 39.0237 20.1736 38.5485 20.1736 38.0531C20.1736 37.5578 20.3696 37.0826 20.7188 36.7313L27.3563 30L20.7188 23.3625C20.4116 23.0038 20.2511 22.5424 20.2694 22.0705C20.2876 21.5986 20.4832 21.151 20.8171 20.8171C21.1511 20.4832 21.5987 20.2875 22.0706 20.2693C22.5425 20.2511 23.0039 20.4116 23.3626 20.7188L30.0001 27.3563L36.6376 20.7188C36.8111 20.539 37.0184 20.3952 37.2476 20.2956C37.4768 20.196 37.7233 20.1424 37.9732 20.1381C38.223 20.1337 38.4713 20.1786 38.7038 20.2702C38.9363 20.3618 39.1484 20.4983 39.3282 20.6719C39.5079 20.8455 39.6517 21.0528 39.7514 21.2819C39.851 21.5111 39.9045 21.7576 39.9089 22.0075C39.9132 22.2573 39.8683 22.5056 39.7767 22.7381C39.6851 22.9706 39.5486 23.1828 39.3751 23.3625L32.6438 30L39.3751 36.6375Z" fill="white" />
                    </svg> */}
                </div>
            </Modal >
        </div>
    )
}

export default HomePage;