import React from 'react'


const VideoSection = ({ data, refs, isAnimationVisible, playVideo, isPlay }) => {
    return (
        <div className="video-container bg20 pr bscon brne" style={{ backgroundImage: `url(${require('../../../../images/landingpage/ceo-background-image.png')})` }}>
            <div ref={refs?.current?.[6]} className={`df jcc tslp ${isAnimationVisible?.[6] ? 'op1 tty0' : 'op0 ttx20'}`}>
                <p className="fw500 fs2-125rem c15 tac w60" style={{ lineHeight: '62.2px' }}>
                    {data?.title}
                </p>
            </div>

            <div className="pr ma cp w70 br40px mt3rem">
                <video id="video" className="bg1 bsbb pr bss bw5px bo5 w100 br40px" controls={true} poster={data?.thumbnail}>
                    <source src={data?.videoURL} type="video/mp4" />
                </video >
                <svg onClick={(e) => playVideo(e)} className={`${isPlay && 'dn'}  pa center`} style={{ width: '80px', height: '80px' }} width="146" height="146" viewBox="0 0 146 146" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M73.1295 0.384033C33.2267 0.384033 0.767578 32.8463 0.767578 72.7459C0.767578 112.646 33.2267 145.108 73.1295 145.108C113.032 145.108 145.491 112.646 145.491 72.7459C145.491 32.8463 113.032 0.384033 73.1295 0.384033ZM101.896 75.2811L59.6854 102.417C59.1908 102.738 58.6195 102.897 58.0542 102.897C57.5595 102.897 57.0589 102.773 56.6115 102.529C55.6397 101.999 55.039 100.986 55.039 99.8816V45.6102C55.039 44.5061 55.6397 43.4931 56.6115 42.9631C57.5654 42.439 58.7608 42.4712 59.6854 43.075L101.896 70.2107C102.756 70.7642 103.28 71.7213 103.28 72.7459C103.28 73.7706 102.756 74.7274 101.896 75.2811Z" fill="white" />
                </svg>
            </div >
            <img src={require('../../../../images/landingpage/light-cololr-circle.png')} className="w10 pa" style={{ top: '-70px', right: '0px', width: '110px' }} />
            <img src={require('../../../../images/landingpage/spirel.png')} className="pa" style={{ width: '14% ', top: '-70px', left: '-129px' }} />
        </div>
    )

}
export default VideoSection