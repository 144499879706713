export default function IconTicksCard({ children, title, ticks, wrapperClass }) {
	return (
		<div className={wrapperClass + " df pt5rem"}>
			<div className="mla-lg pr shadow p1rem bg5 pt5rem">
				<span className="f3 shadow bg6 pa t0 center p1rem w100px df jcc aic h100px br100">
					<div className="df jcc aic w100 h100 mah3rem maw3rem">{children}</div>
				</span>
				<h3 className="mb1rem tac fs1-75rem">{title}</h3>
				{ticks.map((tick, idx) => (
					<div key={idx} className="mb1rem df aic">
						<svg className="w1rem ha ass f4 mr1rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path className="fa-primary" d="M480 128c0 8.188-3.125 16.38-9.375 22.62l-256 256C208.4 412.9 200.2 416 192 416s-16.38-3.125-22.62-9.375l-128-128C35.13 272.4 32 264.2 32 256c0-18.28 14.95-32 32-32c8.188 0 16.38 3.125 22.62 9.375L192 338.8l233.4-233.4C431.6 99.13 439.8 96 448 96C465.1 96 480 109.7 480 128z" />
						</svg>
						<span className="w100 fs1rem">{tick}</span>
					</div>
				))}
			</div>
		</div>
	);
}
