import AnimatedPage from "../components/AnimatedPage";
import CallToAction from "../components/CallToAction";
import Hero from "../components/Hero";
import FancyHeadingParagraph from "../components/FancyHeadingParagraph";
import HeadingParagraph from "../components/HeadingParagraph";
import HeadingSubheadingContent from "../components/HeadingSubheadingContent";
import Testimonials from "../components/Testimonials";

export default function Accountants() {
	const testimonials = [
		{
			title: "The Professional",
			text: "Getting a client into a position where they can provide a draft disclosure letter schedule at the commencement of a transaction will reduce costs, speed up the transaction, and lessen the chance of its falling over right at the end.",
			author: "Rik Pancholi",
			company: "Director, Pattersons Commercial Law",
		},
		{
			title: "The Growth Manager",
			text: "Only when you work as part of a Senior Management Team around a central data repository, kept up to date, do you really realise the operational benefits of centralising key corporate data into one easy to access location.",
			author: "Katy Davidson",
			company: "CFO Wearable Technologies Limited",
		},
		{
			title: "The Early Stage CEO",
			text: "Having an investment ready data room and supporting documents has undoubtedly attracted more interested investors prepared to look at us seriously and accelerated our investment timetable.",
			author: "Louise Birritteri",
			company: "CEO Pikl Insurance Services Limited",
		},
	];
	return (
		<AnimatedPage>
			<Hero h1Class="fs2-5rem" h1={["Increased Margins,", "New Revenue Streams,", "Route to New Clients."]} buttonText="Register as Advisor" id='openRegisterPopup' dataShowPopup="registerAdvisor" buttonId="scrollDown" videoSrc="diligentsia_for_accountants.mp4" videoThumbnail="diligentsia-for-accountants.jpg" />
			<div className="bg1">
				<div className="container dg gtcr1-lg gg2rem py5rem">
					<HeadingSubheadingContent heading="Can We Help Your Clients?" subheading="Meet Diligentsia" paragraph="Can we help your clients grow or raise investment or exit, more quickly, at a higher valuation, and with more certainty? Their key corporate data – financial, tax, IP, key contracts, HR, constitutional etc. is probably today held in many siloes in Sharepoint or Drive or Dropbox. They and you waste valuable time every day looking for key documents. We help your clients centralise their key documents within their existing cloud-based provider. We get your clients organized. Your life is made easier!" />
				</div>
			</div>
			<div className="bg1">
				<div className="dg gtcr2-lg gg2rem container pb5rem">
					<HeadingParagraph heading="Does your repository deliver everything their investors expect and more?" paragraph="Have they self-diligenced through a business audit in advance of going out for funding? How do you benchmark yourselves? Could you help? These are all questions that we can help you with." />
					<HeadingParagraph heading="So how do we do it?" paragraph="Our ultra-low-cost SaaS based platform helps you easily use tailored online forms and checklists to systematically help your clients create an easily accessible central “brain”, a living breathing data repository. Why waste valuable time every day looking for key documents when we can help you centralise your clients’ key documents within their existing cloud- based provider. We get your clients organized!" />
				</div>
			</div>
			<FancyHeadingParagraph
				content={[
					["Helping You Help Your Clients", "Your clients can pull together their repository themselves using our easy-to-use checklists, or you can charge to assist them with setup, business audits and maintenance. Chargeable quarterly reviews provide you additional fee-generating opportunities.  If you introduce five mor more clients to us, we will provide you a FREE DASHBOARD  to monitor repository completeness, new additions, business audit risk, investment readiness etc.* across your client base."],
					["Your Client Already has a Data Room?", "We’re data room agnostic. If your client  already has a data room, such as Seed Legals, or iDeals or DataSite, we help you organise it better to create more value.  Or we simply create one within our client’s existing cloud-based solution – Dropbox, Sharepoint, Drive. You tell us."],
				]}
			/>
			<Testimonials testimonials={testimonials} />
			<CallToAction callUsNow heading="Ready to Maximise Your Clients' Value for as Little as &pound; 5 a Month? (Payable by your clients)" />
		</AnimatedPage>
	);
}
