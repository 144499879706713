export default function FancyHeadingParagraph({ content, wrapperClass }) {
	return (
		<div className={wrapperClass + " bw0px btw5px bss bo3 bg1 py5rem bg6"}>
			<div className="container df fdc">
				<div className={"dg gtcr" + content.length + "a-lg bg6 c1 cg3rem rg2rem rg5rem-lg"}>
					{content.map((text, i) => (
						<div key={i}>
							{text.map((s, j) => {
								if (j === 0 && text.length > 1) {
									if (i === 0) return <h3 className="mih3rem ass c3 fs1-25rem fs1-5rem-sm mb1rem c4 ttu lh2">{s}</h3>;
									else return <h4 className="mih3rem ass c1 fs1-25rem fs1-5rem-sm mb1rem">{s}</h4>;
								}
								return <p className="fs1rem">{s}</p>;
							})}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
