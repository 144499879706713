import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggleModal } from "../reducers/modalReducer";

export default function CallToAcction({ heading, callUsNow }) {
	const dispatch = useDispatch();
	const modal = useSelector(state => state.modal);

	return (
		<div className="bg6 bss bw0px bo3 py3rem btw5px w100">
			<div className="container df jcsb aic fww c1">
				<h4 className="fw600 m1rem fs2rem">{heading}</h4>
				{callUsNow
					? <a href="tel:01234567890" className="fs1-5rem br5px shadow cp c1 px1rem my0-5rem fw600 m1rem bg4 bg3-hover td250">Call us now for more information</a>
					: <Link href="/register" className="fs1-5rem br5px shadow cp c1 px1rem my0-5rem fw600 m1rem bg4 bg3-hover td250">Register Now</Link>
				}
			</div>
		</div>
	);
}
