import moment from "moment";
import React, { Fragment } from "react";
import { useEffect, useState } from 'react';

import AllReportSidebar from './AllReportSidebar';
import 'react-circular-progressbar/dist/styles.css';
import { postRequest, showMessage } from '../../../config';
import Certification from "../../../components/Certification";
import PassFailCertification from "../../../components/PassFailCertification";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export default function AllReports({ user, company, modules, tab, setTab, aiEnhanced, setAiEnchaned }) {

	const getHeight = child => child.offsetHeight + parseFloat(getComputedStyle(child).marginTop) + parseFloat(getComputedStyle(child).marginBottom);

	// const [showReport, setShowReport] = useState(modules?.[`two-minute-test-${company?.['testType']}`]?.['type']);
	const [showReport, setShowReport] = useState();
	const directorModules = Object.values(modules).filter((item) => item.type?.includes('directors-questionnaire'))

	// useEffect(() => {
	// 	setShowReport(modules?.[`two-minute-test-${company?.['testType']}`]?.['type']);
	// }, [modules]);

	useEffect(() => {
		document.getElementById("pdfContent").classList.add("a4-pdf", "p3rem");
		const wrapper = document.getElementById("pdf");
		wrapper.innerHTML = "";
		const children = Array.from(document.getElementById("pdfContent").children);
		let i = 0;
		while (children.length && i < 250) {
			i++;
			const page = document.createElement("div");
			page.classList.add("a4-pdf", "oyh", "bg1", "bsbb", "oh", "pr", "p3rem", "shadow", "new-report");
			if (aiEnhanced) page.classList.add('report-ai');

			wrapper.appendChild(page);
			const watermark = document.createElement("img");
			watermark.classList.add("op20", "pa");
			watermark.style.transform = "translate(-50%, -50%) rotate(30deg)";
			watermark.style.top = "50%";
			watermark.style.left = "50%";
			watermark.style.width = "297mm";
			page.appendChild(watermark);
			const pageHeight = getHeight(page);
			let usedPageHeight = 96;
			let childrenToRemove = 0;

			let footer = document.createElement("div");
			footer.classList.add("tac", "fw600", "df", "fdc", "jcfe", "h100");
			footer.innerText = "Page " + i
			let footerHeight = 8;

			if (i > 1) {
				const header = document.createElement("img");
				header.classList.add("db", "ma", "h3rem");
				header.innerText = "Page " + i
				page.appendChild(header);

				const title = document.createElement("span");
				title.classList.add("db", "ma", "mb1-3rem", "fw700", "fs1-25rem", "h2rem", "tac", "c6");
				title.innerText = showReport.toUpperCase() + "-REPORT";
				page.appendChild(title);

				usedPageHeight += getHeight(header);
			}

			for (const child of children) {
				const childHeight = getHeight(child);
				if (usedPageHeight + childHeight + footerHeight < pageHeight || childHeight > pageHeight - 96) {
					child.classList.add("pr");
					page.appendChild(child.cloneNode(true));
					usedPageHeight += childHeight;
					childrenToRemove++;
				} else {
					page.classList.add("df", "fdc");
					page.appendChild(footer);
					break;
				}
			}
			if (childrenToRemove == 0) {
				break;
			}
			children.splice(0, childrenToRemove);

		}
		wrapper.classList.remove("op0", "pen");
		document.getElementById("pdfContent").classList.remove("a4", "p3rem");

		let pdfInputs = wrapper.querySelectorAll("#pdf input");
		for (let j = 0; j < pdfInputs.length; j++) pdfInputs[j].addEventListener('click', (event) => saveAnswerInput(event));

		let pdfTextareas = wrapper.querySelectorAll("#pdf textarea");
		for (let j = 0; j < pdfTextareas.length; j++) pdfTextareas[j].addEventListener('click', (event) => saveAnswerTextarea(event));
	});

	const handleContent = (type) => {
		if (type == showReport) setShowReport('');
		else setShowReport(type);
	};

	useEffect(() => {
		for (let i = 0; i < Object.entries(modules).length; i++) {
			let contentBtn = document.getElementById(`content-btn-${i}`);
			if (modules[contentBtn?.value]) contentBtn.addEventListener('click', () => handleContent(contentBtn['value']));
		}

		let opt = document.getElementById(`director-opt`);
		if (opt) {
			if (modules[opt?.['value']]) {
				opt.addEventListener('change', () => handleContent(opt['value']))
				opt.value = showReport
			}
			return () => opt.removeEventListener('change', handleContent);
		}
	}, [showReport, handleContent]);

	const getOverviewContent = () => {
		return <div className="minh100vh">
			<h2 className="w200 tac c6 lh2 fs1-75rem">{'Company Report' + ' - ' + company['cName']}</h2>
			<div className="mt0-5rem mr1rem dg gg1rem minh100vh-report">
				<div className="h100 df fdc jcsb aic">
					<div className="logo-report">
						<img className="h2rem" src={require(`../../../../images/Dg-logo.png`)} alt="TODO" />
					</div>
				</div>
				<div className="report-start">
					<div className="dg gtcr2a gg2rem mb1-3rem">
						<div className="h100 df fdc jcsb aic">
							<div>
								<p className="ass fs1rem mb0-5rem">Company Name</p>
								<h2 className="fs1rem fw700 lh1 ttu usn c6">{company['cName']}</h2>
								{company['testType'] &&
									<>
										<p className="ass fs1rem mt0-5rem mb0-5rem">Test Type</p>
										<h2 className="fs1rem fw700 lh1 ttu usn c6">{company['testType']}</h2>
									</>
								}
							</div>
						</div>
						<div>
							<ul className="right-list">
								{[["Subscription", ((company?.['creatorId']?.['createdBy']?.['role'] == 'Advisor' || company?.['creatorId']?.['createdBy']?.['role'] == 'Investor') && company['subscription'] == 'Pro') ? 'Portfolio PRO' : <>{company['subscription']?.toUpperCase()}</>],
								["Company Reg", company.ukRegNo], ["Issue Date", moment().format("MMM Do YYYY")], ["Generated By", company.creatorId.email]].map((arr, idx) =>
									<li key={idx}>{arr[0]} <span>{arr[1]}</span></li>
								)}
							</ul>
						</div>
					</div>
					<div className="heading-area mb1rem tac df fdc jcc aic">
						<h2 className="w200 c6 lh2 fs1-25rem" style={{ borderBottom: '3px solid #002d63' }}>Your Overall Result</h2>
					</div>
					<div className={`progress-area-report`}>
						<div className='progress-area' style={{ display: 'flex', position: 'relative', justifyContent: 'space-between', alignItems: 'center' }}>
							<div className='progress-box'>
								<div className="progress-bar" style={{ width: 80, height: 80, backgroundColor: '#ebeeff', borderRadius: '50%' }}>
									<CircularProgressbar
										className={`${company?.['stats']?.['completionPercent'] > 67 ? 'green'
											: company?.['stats']?.['completionPercent'] > 33 ? 'orange' : 'red'}`}
										value={company?.['stats']?.['completionPercent'] || 0}
										text={`${company?.['stats']?.['completionPercent'] || 0}%`}
										styles={buildStyles({
											strokeLinecap: 'round',
											strokeWidth: '5px',
											textSize: '22px',
											pathTransitionDuration: 0.5,
											trailColor: '#d6d6d6',
											backgroundColor: '#fc7785',
										})}
									/>
								</div>
								<span style={{ marginBottom: '0', fontSize: '18px' }} className="text-center-area">Completeness</span>
							</div>
							{company['cLevel'] && <>
								{company['subscription'] == "Free"
									? <PassFailCertification className={'op70'} testType={company['testType']} level={company?.moduleCertification?.['two-minute-test-' + company['testType']]} expiryTimestamp={company['createdAt']} company={company} subscription={company['subscription']} />
									: <Certification className={'op70'} component='AllReports' level={company['subscription'] == "Lite" ? 'None' : company['cLevel']} expiryTimestamp={company['createdAt']} company={company} subscription={company['subscription']} />
								}</>
							}
							<div className='progress-box'>
								<div className="progress-bar" style={{ width: 80, height: 80, backgroundColor: '#ebeeff', borderRadius: '50%' }}>
									<CircularProgressbar
										className={`${company?.['stats']?.['riskPercent'] > 67 ? 'red'
											: company?.['stats']?.['riskPercent'] > 33 ? 'orange' : 'green'}`}
										value={company?.['stats']?.['riskPercent'] || 0}
										text={company?.['stats']?.['completionPercent'] ? `${company?.['stats']?.['riskPercent'] || 0}%` : "0%"}
										styles={buildStyles({
											strokeLinecap: 'butt',
											strokeWidth: '5px',
											textSize: '22px',
											pathTransitionDuration: 0.5,
											trailColor: '#d6d6d6',
											backgroundColor: '#fc7785',
										})}
									/>
								</div>
								<span style={{ marginBottom: '0', fontSize: '18px' }} className="text-center-area">Risk</span>
							</div>
						</div>
					</div>
					{modules && Object.values(modules).map((value, idx) => {
						return <>{value?.['heading'] && !value['type'].includes('directors-questionnaire') && <>
							<button id={`content-btn-${idx}`} value={value['type']} className={`${value['type'] == showReport && 'active '} df c6 jcc ttc td250 c3-hover px1rem py0-5rem cp new-report-btn reports-btn-area`}>
								<span className="pen usn ass mr0-5rem tac fs1rem">{company?.['modules']?.[value['type']]?.heading} <i><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreIcon"><path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg></i></span>
							</button>
							{showReport !== '' && <div>{(value['type'] == showReport) && moduleContents(showReport)}</div>}</>
						}</>
					})}
					{directorModules.length > 0 && <>
						<label className="fs1rem fw600">Select Director</label>
						<select id={`director-opt`} placeholder="Select Director" className="df c6 ttc td250 c3-hover px1rem py0-5rem cp new-report-btn">
							{directorModules?.map((item, idx) => <option value={item?.type} className="pen usn ass mr0-5rem tal" label={`Director-${item?.director?.name}`}></option>)}
						</select>
					</>}
				</div>
			</div>
		</div>
	};

	const moduleContents = (moduleType) => {
		return <>
			{(modules[moduleType]?.['type']?.includes('warranty-disclosure') || modules[moduleType]['type']?.includes('directors-questionnaire') || modules[moduleType]['type']?.includes('software-development') || modules[moduleType]['type']?.includes('esg')
				? Object.values(modules[moduleType].sections[0].subFolders) : modules[moduleType].sections).filter(s => !s.isHidden).map((section, idx) => {

					let completeness = section.stats.completionPercent;
					let riskColour;
					if (modules[moduleType]?.type === "two-minute-test-growth" || section.stats.riskPercent < 38) riskColour = 7;
					else if (section.stats.riskPercent > 62) riskColour = 9;
					else riskColour = 8;

					let hexColour = riskColour === 8 ? "#ddb64044" : riskColour === 9 ? "#dd404044" : "#40dda144";
					let colorChange = false;
					if (modules?.[moduleType]['type'] == "warranty-disclosure") {
						section?.questions.map(question => {
							if (question['answer']['radioOption'] == 'Disclosure' || question['answer']['radioOption'] == 'Not Sure') {
								colorChange = true;
								return;
							}
						})
					}

					return <button key={idx} style={{ background: `linear-gradient(90deg, ${colorChange ? "#f9922a" : hexColour} 0%, ${colorChange ? "#f9922a" : hexColour} ${completeness}%, #f0f0f8 ${completeness}%)` }} data-scroll-to={section.sectionNo} className=" df c3 c6 td250 c3-hover px1rem py0-5rem cp new-report-btn">
						<span className="pen usn ass mr0-5rem tal left-heading">{section.sectionNo}</span>
						<span className="pen uss ass fw600 tal right-detail">{section.sectionName}</span>
					</button>

				})}
		</>
	};

	const saveAnswerInput = async (event) => {
		let answerId = event.target.id.split('_')[1];
		const res = await postRequest(`/question/addNote/${answerId}`, { done: true });
		showMessage(res?.['message']);
	};

	const saveAnswerTextarea = async (event) => {
		let answerId = event.target.id.split('_')[1];
		const res = await postRequest(`/question/addNote/${answerId}`, { note: event.target.value });
		showMessage(res?.['message']);
	};

	const sortQuestions = (a, b) => {
		let valueA = a.qNo.split('.')[a.qNo.split('.').length - 1]
		let valueB = b.qNo.split('.')[a.qNo.split('.').length - 1]

		return valueA - valueB;
	};

	const getAuditReportContent = (data) => {
		let array = [];
		if (data?.['type']?.includes("warranty-disclosure") || data?.['type']?.includes("directors-questionnaire") || data?.['type']?.includes("software-development") || data?.['type']?.includes("esg")) {
			if (data?.sections[0]?.subFolders && Object?.values(data?.sections[0]?.subFolders)?.length > 0) array = Object.values(data?.sections[0]?.subFolders)?.filter(s => !s.isHidden);
		}
		else array = data.sections.filter(s => !s.isHidden);

		return <>
			{array.length > 0 && array.map(section => {
				return (
					<Fragment>
						<div className="mt1rem" id={section['sectionNo']}>
							<h2 className="df jcc aic mb1rem fs1rem">
								{section['sectionNo']} - {section['sectionName']} Report &nbsp;-&nbsp;
								{section.stats.completionPercent && section.stats.completionPercent > 0
									? <>
										<span className={section.stats.completionPercent < 34 ? "c9" : section.stats.completionPercent < 67 ? 'c8' : 'c7'}>Completed {section.stats.completionPercent || 0}%</span>
										{(section.stats.riskPercent > 0 && data['type'] !== "repository") &&
											<>
												&nbsp;-&nbsp;
												<span className={section.stats.riskPercent < 38 ? "c7" : section.stats.riskPercent < 62 ? 'c8' : 'c9'}>{section.stats.riskPercent || 0}% Risk</span>
											</>
										}
									</>
									: <>
										<span>Not Started</span>
										&nbsp;-&nbsp;
										<span>No Risk Score</span>
									</>
								}
							</h2>
							{section['stats']['completionPercent'] > 0 &&
								<div className={`dg gtcr-audit fs0-75rem bg6 c1`}>
									<h3 className="tac">Question</h3>
									<h3 className="tac">Answer</h3>
									{data['type'] == "repository" && <>
										<h3 className="tac">Files Uploaded</h3>
										<h3 className="tac">Notes</h3>
									</>}
									{data['type'].includes("directors-questionnaire") && <>
										<h3 className="tac">Text</h3>
										<h3 className="tac">Files Uploaded</h3>
									</>}
									{(data['type'].includes("business-audit") || data['type'] == "software-development" || data['type'] == "esg") && <>
										<h3 className="tac">Risk</h3>
										<h3 className="tac">Recommendation</h3>
									</>}
									{data['type'] == "warranty-disclosure" && <>
										<h3 className="tac">Disclosure</h3>
										<h3 className="tac">Files Uploaded</h3>
									</>}
								</div>
							}

							{section['stats']['completionPercent'] == 0 &&
								<p className="df jcc tac fs1rem fw500 c4">Section Not Started</p>
							}
						</div>
						{section['stats']['completionPercent'] > 0 && <>
							{section?.questions.slice().sort(sortQuestions).filter(q => q.answer).map((question, idx) => {
								if (!aiEnhanced && Object.values(question?.['answer']?.['aiResponse'] || {}).length > 0) setAiEnchaned(true);
								let isRisky = question.answer.radioOption === "No";
								// let addClass = (data['type'].includes("repository") || data['type'].includes("business-audit") || data['type'].includes("warranty-disclosure") || data['type'].includes("software-development") || data['type'].includes("esg")) ? true : false;
								return (
									<>
										<div key={idx} className={`dg tableRow  fs0-75rem ${(data['type'].includes("repository") || data['type'].includes("directors-questionnaire")) ? 'gtcr-audit' : 'gtcr-audit-two'}`}>
											<div className={`df fdc aic jcfs pt0-25rem`}>{question.qDescription}</div>
											{data['type'].includes("directors-questionnaire")
												? <div className="df fdc aic tac jcfs pt1rem">{question?.questionType.includes("YN") ? (question.answer['radioOption'] || question.answer['status']) : "-"}</div>
												: <div className={`df fdc aic tac jcfs pt1rem`}> {question.answer['radioOption'] || question.answer['status']} </div>
											}
											{data['type'] == "repository" && <>
												<div className="df fdc aic tac jcfs pt1rem">
													{question?.answer?.files?.length > 0
														? <>{question?.['answer']?.['files'] && question['answer']['files'].map((file, idx) => <a key={idx} href={file['location']} target="'_blank"><b>File</b>: {file['originalname']}</a>)}</>
														: <p>No Files Uploaded</p>
													}
												</div>
												<div className="df fdc aic tac jcfs pt1rem">
													{question?.['answer'] && <p>{question['answer']['comment']}</p>}
												</div>
											</>
											}
											{data['type'].includes("directors-questionnaire") && <>
												<div className="df fdc aic tac jcfs pt1rem">
													{question?.questionType.includes("Date")
														? <>{question?.answer && <p>{moment(question?.answer?.date).format('ll')}</p>}</>
														: <>{question?.['answer'] && <p>{question['answer'] ? (question['answer']['comment'] || question['answer']['dropdown']) : '-'}</p>}</>
													}
												</div>
												<div className="df fdc aic tac jcfs pt1rem">
													{question.answer.files.length > 0
														? <>{question?.['answer']?.['files'] && question['answer']['files'].map((file, idx) => <a key={idx} href={file['location']} target="'_blank"><b>File</b>: {file['originalname']}</a>)}</>
														: <p>-</p>
													}
												</div>
											</>
											}
											{data['type'].includes("business-audit") &&
												<div className="df fdc tal jcfs pt0-5rem">
													<div className="df  pb0-5rem">
														<div className="w50 tac px0-5rem">
															{isRisky ? <>
																{question?.['answer']?.['aiResponse']?.['ifriskDescription'] && question?.['answer']?.['aiResponse']?.['ifriskDescription']}
																{question['riskDescription'] && question['riskDescription']}
															</> : "Well done"
															}
														</div>
														<div className="w50 tac px0-5rem">
															{isRisky ? <>
																{question?.['answer']?.['aiResponse']?.['ifrecommendDescription'] && question?.['answer']?.['aiResponse']?.['ifrecommendDescription']}
																{question['recommendDescription'] && question['recommendDescription']}
															</> : "No recommendation at this time"
															}
														</div>
													</div>
													<div style={{ background: '#e1e1f155' }}>
														{question['answer'] &&
															<div className="df fdc jcc aic">
																<div><textarea className=" h100 br5px p0-5rem fs0-75rem" id={'note_' + question['answer']['_id']} name='note' onChange={saveAnswerTextarea} value={question['answer']['note']} style={{ backgroundColor: "transparent", resize: "vertical" }} placeholder="Add note" rows="1" cols="50"></textarea></div>
																<div className=" df jcsa">
																	{(question['answer']['done'] || question['answer']['note']) &&
																		<p>Last Edited: {moment(Date.now(question?.['answer']?.['updatedAt'] && question?.['answer']?.['updatedAt'])).format('ll')}</p>
																	}
																	<span className="checkobox-report asc pr ml1rem">
																		<input style={{ width: '25px', height: '25px' }} id={'done_' + question['answer']['_id']} name='status' onClick={saveAnswerInput} checked={question['answer']['done'] ? true : false} className="checkbox bg5 shadow bo5 bw2px bss cp td250 br5px" type="checkbox" defaultChecked={false} />
																	</span>
																</div>
															</div>
														}
													</div>
												</div>
											}
											{(data['type'] == "warranty-disclosure" || data['type'] == "software-development" || data['type'] == "esg") &&
												<div className='df fdc tal jcfs pt0-5rem'>
													<div className="df pb0-5rem">
														<div className="w50 tac px0-5rem">
															{data['type'] == 'warranty-disclosure' && question['answer']['radioOption'] == 'Disclosure'
																? question?.['answer']?.['comment'] && question?.['answer']?.['comment']
																: data['type'] == 'warranty-disclosure' ? '-' : (question?.answer?.['questionId']?.['recommendDescription'] && question.answer['questionId']['recommendDescription'])
															}
														</div>
														<div className="w50 tac px0-5rem">
															{data['type'] == 'warranty-disclosure'
																? question?.['answer']?.['files'] && question['answer']['files'].map((file, idx) => <a key={idx} href={file['location']} target="'_blank"><b>File</b>: {file['originalname']}</a>)
																: question?.answer?.['questionId']?.['riskDescription'] && question.answer['questionId']['riskDescription']
															}
														</div>
													</div>
													{(data['type'] == "software-development" || data['type'] == "esg") && question?.['answer']?.['files'] && question?.['answer']?.['files'].length > 0 &&
														<p class="w90 p1rem my0-5rem" style={{ background: '#e1e1f155' }}>Files: {question['answer']['files'].map(file => <a class="ml0-5rem tdu" href={file['location']} target="'_blank">{file['originalname']}</a>)}</p>}
													<div style={{ background: '#e1e1f155' }}>
														{question['answer'] &&
															<div className="df fdc jcc aic">
																<div><textarea id={'note_' + question['answer']['_id']} name="note" className=" h100 br5px p0-5rem fs0-75rem" type="checkbox" onChange={saveAnswerTextarea} value={question['answer']['note']} style={{ backgroundColor: "transparent", resize: "vertical" }} placeholder="Add note" rows="1" cols="50"></textarea></div>
																<div className=" df jcsa">
																	<p>Last Edited:- {moment(Date.now(question?.['answer']?.['updatedAt'] && question?.['answer']?.['updatedAt'])).format('ll')}</p>
																	<span className="checkobox-report asc pr ml1rem">
																		<input id={'done_' + question['answer']['_id']} name="status" style={{ width: '25px', height: '25px' }} onClick={saveAnswerInput} checked={question['answer']['done'] ? true : false} className="checkbox bg5 shadow bo5 bw2px bss cp td250 br5px" type="checkbox" defaultChecked={false} />
																	</span>
																</div>
															</div>
														}
													</div>
												</div>
											}
										</div>
									</>
								)
							})}
						</>}
					</Fragment>
				)
			}
			)}
		</>
	};

	const getTwoMinuteTestContent = (data) => {
		if (data) {
			const writeSections = sections => Object.values(sections).filter(s => !s?.isHidden).map(section => {
				const questionsAndAnswers = section?.questions.slice().sort(sortQuestions).filter(q => q?.qDescription);
				if (questionsAndAnswers.length)
					return <>
						{questionsAndAnswers.map((question, idx) => {
							const { answer } = question;
							if (!aiEnhanced && Object.values(answer?.['aiResponse'] || {}).length > 0) setAiEnchaned(true);
							return <Fragment key={idx}>
								<h3 id={section.sectionNo} className="usn pen fs1rem fw600 mt-3 tac">{section.sectionNo} - {section.sectionName}</h3>
								<div className="question-box  c1">
									<div className=""><h4>Question: {question['qNo']}</h4></div>
									<p>{question['qDescription']}</p>
									<p className="mt1rem"><b>Answer:</b> <span className={`${(question['answer']['radioOption'] == 'Yes' || question['answer']['radioOption'] < 3) ? 'green' : 'red'} c4`}>
										{question['answer']['radioOption'] || 'Not Answered'}
									</span></p>
								</div>
								<div className="recomended-box">
									{(() => {
										const recommendationKey = ["ifYes", "ifNo", "", "if1", "if2", "if3", "if4", "if5"].find(key => key.includes("if" + answer.radioOption));
										return recommendationKey && question[recommendationKey] && <>
											<p><b>Recommendation:</b></p>
											<p className="fs1rem">{answer?.['aiResponse']?.[recommendationKey] || question[recommendationKey]}</p>
										</>
									})()}
								</div>
							</Fragment>
						})}
					</>
			});
			return writeSections(data['sections']);
		}
	};

	return <div className="td250 container bg1 py2rem shadow w100 df">
		<div className="w80 mr1rem dg bg5 py2rem">
			{company && <>
				<table id="pdf" className="op0 pen ma td250 dg gg2rem"></table>

				<table id="pdfContent" className="bsbb w0 h0 oh op0 pen db">
					<>
						{/* content-deatils */}
						{getOverviewContent()}
						{/* two-minute-test-report */}
						{(modules?.[`two-minute-test-${company?.['testType']}`] && showReport == modules[`two-minute-test-${company['testType']}`]['type']) &&
							getTwoMinuteTestContent(modules[`two-minute-test-${company['testType']}`])}

						{/* repository-report */}
						{(modules?.['repository'] && showReport == modules?.['repository']['type']) &&
							getAuditReportContent(modules?.['repository'])}

						{/* business-audit-report */}
						{(modules?.[`business-audit-${company?.['testType']}`] && showReport == modules?.[`business-audit-${company?.['testType']}`]['type']) &&
							getAuditReportContent(modules?.[`business-audit-${company?.['testType']}`])}

						{/* warranty-disclosure-report */}
						{(modules?.['warranty-disclosure'] && showReport == modules?.['warranty-disclosure']['type']) &&
							getAuditReportContent(modules?.['warranty-disclosure'])}

						{/* software-development-report */}
						{(modules?.['software-development'] && showReport == modules?.['software-development']['type']) &&
							getAuditReportContent(modules?.['software-development'])}

						{/* esg-report */}
						{(modules?.['esg'] && showReport == modules?.['esg']['type']) &&
							getAuditReportContent(modules?.['esg'])}

						{showReport && showReport?.includes('directors-questionnaire') && getAuditReportContent(modules?.[showReport])}
					</>
				</table>
			</>}
		</div>
		<AllReportSidebar user={user} company={company} modules={modules} tab={tab} setTab={setTab} showReport={showReport} handleContent={handleContent} />
	</div>
} 
